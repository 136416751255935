import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TIME_SNACK_BAR } from 'src/app/paywall/shared/paywall-constants';
import { TableColumn } from './column.interface';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent<T> implements OnInit, OnChanges {
  @Input() thereIsData: boolean;
  @Input() dataSource: MatTableDataSource<T>;
  @Input() columns: TableColumn<T>[];
  @Input() actions: { id: number; name: string }[];
  @Input() actionsPerRow: boolean; // use this when actions are different for each row
  @Input() tableStyle: string;
  @Output() actionChange = new EventEmitter<{ element: T; event: MatSelectChange }>();
  @Output() pageChange = new EventEmitter<{ event: PageEvent }>();
  @Output() outputPageChange = new EventEmitter<{ event: PageEvent }>();
  @Output() pageManagedByBackendChange = new EventEmitter<string>();
  @Input() paginatorLength: number;
  @Input() pageSize = 10;
  @Input() pageIndex: number;
  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() paginationManagedByBackend: boolean;
  @Input() paginationByBackendLength: number;
  @Output() buttonClick = new EventEmitter<{ element: T }>();

  columnsNames: string[];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  pageEvent: PageEvent;

  @ViewChild(MatSort) public set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) public set matPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    this.setDataSourceAttributes();
  }
  private sort: MatSort;
  private paginator: MatPaginator;

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.loadColumns();
  }

  loadColumns = () => {
    this.columnsNames = this.columns.map(e => e.columnDef);
    if ((this.actions && this.actions.length > 0) || this.actionsPerRow) {
      this.columnsNames.push('actions');
    }
  };

  handlePageEvent(event: PageEvent) {
    this.outputPageChange.emit({ event });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadColumns();
    if (!!this.paginator && !!changes.pageIndex && changes.pageIndex.currentValue === 0) this.paginator.pageIndex = 0;
  }

  public copyToClipboard(text: string): void {
    // TODO ⚽ --> sustituir función cuando se actualice angular materials --> https://material.angular.io/cdk/clipboard/overview
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.style.left = '0';
    selectBox.style.top = '0';
    selectBox.style.opacity = '0';
    selectBox.value = text;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);
    this.snackBar.open('Texto copiado', 'OK', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: TIME_SNACK_BAR,
    });
  }

  public setDataSourceAttributes(): void {
    if (!!this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  public onButtonClick(element: T): void {
    this.buttonClick.emit({ element });
  }

  onActionChange(element: T, event: MatSelectChange): void {
    this.actionChange.emit({ element, event });
  }

  onPageChange(event: PageEvent): void {
    this.pageChange.emit({ event });
  }

  public getLength = () => this.paginatorLength || (!!this.dataSource && this.dataSource.data.length);
}
