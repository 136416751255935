<form [formGroup]="form2" class="mt-3">
    <div class="row-md-12 text-center my-3" *ngIf="isModalSimple">
      <input type="file"
              (change)="handleFileInput($event.target.files)"
              accept=".jpg,.png,.jpeg"
              class="col-md-6">

      <mat-slide-toggle
          class="mt-4 ml-3"
          formControlName="imageEnable"
          [checked]="form2.get('imageEnable').value"
          color="primary"
          (change)="changeDisable('image')">
      </mat-slide-toggle>
      <button
          mat-mini-fab
          color="primary"
          class="mt-4 ml-3 button-action"
          [disabled]="!form2.get('imageEnable').value"
          (click)="configActionField('imageAction', 'imageType')"
      >
          <mat-icon *ngIf="!form3.get('imageAction').value">add</mat-icon>
          <mat-icon *ngIf="form3.get('imageAction').value">mode_edit</mat-icon>
      </button>
    </div>

    <div class="row-md-12 text-center" *ngIf="isModalFS">
        <input type="file"
              (change)="handleLogoInput($event.target.files)"
              accept=".jpj,.png"
              class="col-md-6">
        <mat-slide-toggle
            class="mt-4 ml-3"
            formControlName="logoEnable"
            [checked]="form2.get('logoEnable').value"
            color="primary"
            (change)="changeDisable('logo')"
        >
        </mat-slide-toggle>
        <button
            mat-mini-fab
            color="primary"
            class="mt-4 ml-3 button-action"
            [disabled]="!form2.get('logoEnable').value"
            (click)="configActionField('logoAction', 'logoType')"
        >
            <mat-icon *ngIf="!form3.get('imageAction').value">add</mat-icon>
            <mat-icon *ngIf="form3.get('imageAction').value">mode_edit</mat-icon>
        </button>
    </div>

    <div class="row-md-12 text-center" *ngIf="isModalSimple">
      <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Nombre de la Imagen</mat-label>
        <input matInput name="imageName" formControlName="imageName" placeholder="Nombre de la Imagen" />
      </mat-form-field>
      <mat-slide-toggle class="mt-4 ml-3" disabled checked color="primary"></mat-slide-toggle>
      <button mat-mini-fab color="primary" class="mt-4 ml-3 button-action" disabled>
        <mat-icon *ngIf="!form2.get('imageName').value">add</mat-icon>
        <mat-icon *ngIf="form2.get('imageName').value">mode_edit</mat-icon>
      </button>
    </div>

    <div class="row-md-12 text-center" *ngIf="isModalSimple || isBox">
        <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Pre. Titulo</mat-label>
            <input matInput name="preTitle" formControlName="preTitle" placeholder="Pre. titulo" />
            <mat-hint *ngIf="form3.get('preTitleAction').value"
                >Tipo de activador: {{ form3.get('preTitleType').value }}; Acción:
                {{ form3.get('preTitleAction').value }}

                <mat-icon (click)="deleteAction('preTitleType', 'preTitleAction')" class="icon-cursor">cancel</mat-icon>
            </mat-hint>
        </mat-form-field>
        <mat-slide-toggle
            class="mt-4 ml-3"
            formControlName="preTitleEnable"
            [checked]="form2.get('preTitleEnable').value"
            color="primary"
            (change)="changeDisable('preTitle')"
        >
        </mat-slide-toggle>
        <button
            mat-mini-fab
            color="primary"
            class="mt-4 ml-3 button-action"
            [disabled]="!form2.get('preTitleEnable').value"
            (click)="configActionField('preTitleAction', 'preTitleType')"
        >
            <mat-icon *ngIf="!form3.get('preTitleAction').value">add</mat-icon>
            <mat-icon *ngIf="form3.get('preTitleAction').value">mode_edit</mat-icon>
        </button>
    </div>
    <div class="row-md-12 text-center">
        <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Titulo</mat-label>
            <input matInput name="title" formControlName="title" placeholder="Titulo" />
            <mat-hint *ngIf="form3.get('titleAction').value"
                >Tipo de activador: {{ form3.get('titleType').value }}; Acción:
                {{ form3.get('titleAction').value }}

                <mat-icon (click)="deleteAction('titleType', 'titleAction')" class="icon-cursor">cancel</mat-icon>
            </mat-hint>
        </mat-form-field>
        <mat-slide-toggle
            class="mt-4 ml-3"
            formControlName="titleEnable"
            [checked]="form2.get('titleEnable').value"
            color="primary"
            (change)="changeDisable('title')"
        >
        </mat-slide-toggle>
        <button
            mat-mini-fab
            color="primary"
            class="mt-4 ml-3 button-action"
            [disabled]="!form2.get('titleEnable').value"
            (click)="configActionField('titleAction', 'titleType')"
        >
            <mat-icon *ngIf="!form3.get('titleAction').value">add</mat-icon>
            <mat-icon *ngIf="form3.get('titleAction').value">mode_edit</mat-icon>
        </button>
    </div>
    <div class="row-md-12 text-center">
        <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Descripcion</mat-label>
            <input matInput name="description" formControlName="description" placeholder="Descripcion" />
            <mat-hint *ngIf="form3.get('descriptionAction').value"
                >Tipo de activador: {{ form3.get('descriptionType').value }}; Acción:
                {{ form3.get('descriptionAction').value }}

                <mat-icon (click)="deleteAction('descriptionType', 'descriptionAction')" class="icon-cursor"
                    >cancel</mat-icon
                >
            </mat-hint>
        </mat-form-field>
        <mat-slide-toggle
            class="mt-4 ml-3"
            formControlName="descriptionEnable"
            [checked]="form2.get('descriptionEnable').value"
            color="primary"
            (change)="changeDisable('description')"
        >
        </mat-slide-toggle>
        <button
            mat-mini-fab
            color="primary"
            class="mt-4 ml-3 button-action"
            [disabled]="!form2.get('descriptionEnable').value"
            (click)="configActionField('descriptionAction', 'descriptionType')"
        >
            <mat-icon *ngIf="!form3.get('descriptionAction').value">add</mat-icon>
            <mat-icon *ngIf="form3.get('descriptionAction').value">mode_edit</mat-icon>
        </button>
    </div>
    <div class="row-md-12 text-center">
        <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Botón</mat-label>
            <input matInput name="button" formControlName="button" placeholder="Botón" />
            <mat-hint *ngIf="form3.get('buttonAction').value"
                >Tipo de activador: {{ form3.get('buttonType').value }}; Acción:
                {{ form3.get('buttonAction').value }}

                <mat-icon (click)="deleteAction('buttonType', 'buttonAction')" class="icon-cursor">cancel</mat-icon>
            </mat-hint>
            <mat-hint *ngIf="form3.get('buttonType').value && !form3.get('buttonAction').value"
              >Tipo de activador: {{ form3.get('buttonType').value }}

              <mat-icon (click)="deleteAction('buttonType', 'buttonAction')" class="icon-cursor">cancel</mat-icon>
            </mat-hint>
        </mat-form-field>
        <mat-slide-toggle
            class="mt-4 ml-3"
            formControlName="buttonEnable"
            [checked]="form2.get('buttonEnable').value"
            color="primary"
            (change)="changeDisable('button')"
        >
        </mat-slide-toggle>
        <button
            mat-mini-fab
            color="primary"
            class="mt-4 ml-3 button-action"
            [disabled]="!form2.get('buttonEnable').value"
            (click)="configActionField('buttonAction', 'buttonType')"
        >
            <mat-icon *ngIf="!form3.get('buttonAction').value">add</mat-icon>
            <mat-icon *ngIf="form3.get('buttonAction').value">mode_edit</mat-icon>
        </button>
    </div>
    <div class="row-md-12 text-center" *ngIf="isModalFS || isModalSimple">
        <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Footer</mat-label>
            <input matInput name="footer" formControlName="footer" placeholder="Footer" />
            <mat-hint *ngIf="form3.get('footerAction').value"
                >Tipo de activador: {{ form3.get('footerType').value }}; Acción:
                {{ form3.get('footerAction').value }}

                <mat-icon (click)="deleteAction('footerType', 'footerAction')" class="icon-cursor">cancel</mat-icon>
            </mat-hint>
        </mat-form-field>
        <mat-slide-toggle
            class="mt-4 ml-3"
            formControlName="footerEnable"
            [checked]="form2.get('footerEnable').value"
            color="primary"
            (change)="changeDisable('footer')"
        >
        </mat-slide-toggle>
        <button
            mat-mini-fab
            color="primary"
            class="mt-4 ml-3 button-action"
            [disabled]="!form2.get('footerEnable').value"
            (click)="configActionField('footerAction', 'footerType')"
        >
            <mat-icon *ngIf="!form3.get('footerAction').value">add</mat-icon>
            <mat-icon *ngIf="form3.get('footerAction').value">mode_edit</mat-icon>
        </button>
    </div>
    <div class="row-md-12 text-center" *ngIf="isModalSimple || isModalFS">
        <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Link</mat-label>
            <input matInput name="link" formControlName="link" placeholder="Link" />
            <mat-hint *ngIf="form3.get('linkAction').value"
                >Tipo de activador: {{ form3.get('linkType').value }}; Acción:
                {{ form3.get('linkAction').value }}

                <mat-icon (click)="deleteAction('linkType', 'linkAction')" class="icon-cursor">cancel</mat-icon>
            </mat-hint>
        </mat-form-field>
        <mat-slide-toggle
            class="mt-4 ml-3"
            formControlName="linkEnable"
            [checked]="form2.get('linkEnable').value"
            color="primary"
            (change)="changeDisable('link')"
        >
        </mat-slide-toggle>
        <button
            mat-mini-fab
            color="primary"
            class="mt-4 ml-3 button-action"
            [disabled]="!form2.get('linkEnable').value"
            (click)="configActionField('linkAction', 'linkType')"
        >
            <mat-icon *ngIf="!form3.get('linkAction').value">add</mat-icon>
            <mat-icon *ngIf="form3.get('linkAction').value">mode_edit</mat-icon>
        </button>
    </div>
    <div class="row-md-8 d-flex justify-content-center" *ngIf="isModalSimple || isModalFS">
      <div class="col-md-8">
        <div class="ml-4">
          <mat-slide-toggle
              class="ml-2 mt-2"
              formControlName="closeButtonEnable"
              color="primary"
              [checked]="form2.get('closeButtonEnable').value"
          >
              Botón de cerrado
          </mat-slide-toggle>
        </div>

      </div>
    </div>

</form>
