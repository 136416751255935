import { Component, Input, OnChanges} from '@angular/core';
import {
  PUSH_NOTIFICATION_STATISTIC_DEVICES,
  PUSH_NOTIFICATION_STATISTIC_DEVICE_ICONS,
  PUSH_NOTIFICATION_STATISTIC_STATUS,
  PUSH_NOTIFICATION_STATISTIC_STATUS_TITLE,
} from 'src/app/components/push-notifications/web/scheduled/config/constants';
import TopicInterface from 'src/app/core/interfaces/push-notification/topic.interface';
import {
  StatisticsPushNotificationInterface,
} from 'src/app/core/interfaces/statistics/statistics-push-notification.interface';
import { SubGroupType } from 'src/app/core/types/sub-group.type';

@Component({
  selector: 'app-statistic-push-notification-topic',
  templateUrl: './statistic-push-notification-topic.component.html',
  styleUrls: ['./statistic-push-notification-topic.component.scss'],
})
export class StatisticsPushNotificationTopicComponent implements OnChanges {
  @Input() areaChartOptions: any;
  @Input() histogramChartOptions: any;
  @Input() metrics: SubGroupType<StatisticsPushNotificationInterface>[];
  @Input() dates: any;
  @Input() topic: TopicInterface;

  summaryCards: {
    [x in PUSH_NOTIFICATION_STATISTIC_STATUS]?: {
      [y in PUSH_NOTIFICATION_STATISTIC_DEVICES]?: { value?: number; ctr?: number };
    };
  } = {};

  lostInteractions: {
    [y in PUSH_NOTIFICATION_STATISTIC_DEVICES]?: { value?: number; ctr?: number };
  } = {};

  readonly ALL_STATUS = PUSH_NOTIFICATION_STATISTIC_STATUS.ALL;
  readonly ALL_DEVICES = PUSH_NOTIFICATION_STATISTIC_DEVICES.ALL;

  readonly STATUS = Object.values(PUSH_NOTIFICATION_STATISTIC_STATUS);
  readonly DEVICES = Object.values(PUSH_NOTIFICATION_STATISTIC_DEVICES);
  readonly TITLES = PUSH_NOTIFICATION_STATISTIC_STATUS_TITLE;
  readonly ICONS = PUSH_NOTIFICATION_STATISTIC_DEVICE_ICONS;
  statisticLoaded = false;

  ngOnChanges(): void {
    this.statisticLoaded = false;
    this.STATUS.forEach(status => {
      this.summaryCards[status] = {};
      this.DEVICES.forEach(device => {
        this.summaryCards[status][device] = { value: 0, ctr: 0 };
      });
    });
    this.getSummaryCards();
    this.getLostInteractions();
    this.statisticLoaded = true;
  }

  public getSummaryCards(): void {
    if (!this.topic || !this.metrics || !this.metrics.length) return;
    this.metrics.forEach(({ eventAction, uaDevice, totalActivations }) => {
      if (!totalActivations) return;
      this.summaryCards[this.ALL_STATUS][this.ALL_DEVICES].value += totalActivations;
      this.summaryCards[eventAction][this.ALL_DEVICES].value += totalActivations;
      try {
        this.summaryCards[this.ALL_STATUS][uaDevice].value += totalActivations;
        this.summaryCards[eventAction][uaDevice].value += totalActivations;
      } catch {
        this.summaryCards[this.ALL_STATUS][PUSH_NOTIFICATION_STATISTIC_DEVICES.OTHERS].value += totalActivations;
        this.summaryCards[eventAction][PUSH_NOTIFICATION_STATISTIC_DEVICES.OTHERS].value += totalActivations;
      }
    });
    this.STATUS.forEach(status => {
      this.DEVICES.forEach(device => {
        this.summaryCards[status][device].ctr = this.percentage(
          this.summaryCards[status][device].value,
          this.summaryCards[this.ALL_STATUS][this.ALL_DEVICES].value
        );
      });
    });

  }

  getLostInteractions(): void {
    this.DEVICES.forEach(device => {
      this.lostInteractions[device] = {};
      this.lostInteractions[device].value =
        this.summaryCards[PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED][device].value -
        this.summaryCards[PUSH_NOTIFICATION_STATISTIC_STATUS.OPENED][device].value -
        this.summaryCards[PUSH_NOTIFICATION_STATISTIC_STATUS.CLOSED][device].value;
    });
    this.DEVICES.forEach(device => {
      this.lostInteractions[device].ctr = this.percentage(
        this.lostInteractions[device].value,
        this.summaryCards[this.ALL_STATUS][this.ALL_DEVICES].value
      );
    });
    this.lostInteractions[this.ALL_DEVICES].ctr = this.percentage(
      this.lostInteractions[this.ALL_DEVICES].value,
      this.summaryCards[this.ALL_STATUS][this.ALL_DEVICES].value
    );
  }

  private percentage(value: number, total: number): number {
    if(!total) return 0;
    return Math.round((value / total) * 1000) / 10;
  }

}
