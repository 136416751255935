import { UserInterface } from '../interfaces/user.interface';
import firebase from 'firebase/compat/app';
import { CONSTANTS } from 'src/app/shared/utils/constants';

interface TempUserInterface {
  roles: string[] | {
    [x: string]: boolean;
  };
  role?: string;
}

export class User implements UserInterface {
    public uid: string;
    public active: boolean;
    public medias: string[];
    public email: string;
    public photoURL: string;
    public displayName: string;
    public emailVerified: boolean;
    public role: string;
    public roles: string[];
    public lastSignInTime: firebase.firestore.Timestamp;
    public lastLogSeen: string;
    public lastLogSeenByMedia: any;
    public access?: string;
    public teams?: string[];
    public retailMedias?: {id: string, name: string}[];

    constructor(user?: UserInterface) {
        this.uid = (user && user.uid) || '';
        this.active = (user && user.active) || false;
        this.medias = (user && user.medias) || [];
        this.email = (user && user.email) || '';
        this.photoURL = (user && user.photoURL) || 'https://goo.gl/Fz9nrQ';
        this.displayName = (user && user.displayName) || '';
        this.emailVerified = (user && user.emailVerified) || false;
        //TODO this considerations are temporal because of the structure change.
        this.role = (user && user.role) || "";
        this.roles = User.getRoles(user);
        this.lastSignInTime = (user && user.lastSignInTime) || firebase.firestore.Timestamp.now();
        this.lastLogSeen = (user && user.lastLogSeen) || '';
        this.lastLogSeenByMedia = (user && user.lastLogSeenByMedia) || {};
        this.access = (user && user.access) || CONSTANTS.USER_ACCESS_TYPE_MEDIAS;
        this.teams = (user && user.teams) || [];
        this.retailMedias = (user && user.retailMedias) || [];
    }

    //TODO this function is to avoid and eventually remove an old logic of roles with the form of {[x: string]: boolean}
    private static getRoles(user?: TempUserInterface): string[]{
      if(!user) return [];
      const roles = user.roles;
      if (Array.isArray(roles) && roles.length && roles.every(role => typeof role === 'string')) return roles;
      if (!user.role) return [];
      return [user.role];
    }

    static fromFirebase(user: UserInterface): User {
      if(!user) return null;

      return new User(user);
    }

    static mapFromFirebase(users: UserInterface[]): User[] {
      return users.map(User.fromFirebase);
    }
}
