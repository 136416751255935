export default class ConstantsDatasources {
  public static readonly PROMETEO_DATASOURCES_JSON_AUTO_TYPE: number = 1;
  public static readonly PROMETEO_DATASOURCES_MANUAL_TYPE: number = 2;
  public static readonly PROMETEO_DATASOURCES_HTML_TYPE: number = 3;
  public static readonly PROMETEO_DATASOURCES_MACHINE_LEARNING_ARTICLE_TYPE: number = 4;
  public static readonly PROMETEO_DATASOURCES_SEGMENTED_TYPE: number = 8;
  public static readonly PROMETEO_DATASOURCES_OURBRAIN_TYPE: number = 9;
  public static readonly PROMETEO_DATASOURCES_NATIVE_TYPE: number = 10;


  public static readonly PROMETEO_DATASOURCES_JSON_AUTO_TYPE_LITERAL: string = 'JSON AUTO';
  public static readonly PROMETEO_DATASOURCES_MANUAL_TYPE_LITERAL: string = 'Manual';
  public static readonly PROMETEO_DATASOURCES_HTML_TYPE_LITERAL: string = 'HTML Libre';
  public static readonly PROMETEO_DATASOURCES_MACHINE_LEARNING_ARTICLE_TYPE_LITERAL: string = 'Machine Learning Article';
  public static readonly PROMETEO_DATASOURCES_SEGMENTED_TYPE_LITERAL: string = 'Segmentado';
  public static readonly PROMETEO_DATASOURCES_OURBRAIN_TYPE_LITERAL: string = 'Outbrain';
  public static readonly PROMETEO_DATASOURCES_NATIVE_TYPE_LITERAL: string = 'Publicidad Nativa';

  public static readonly PROMETEO_DATASOURCES_SHARE_TO_ACCOUNT: number = 1;
  public static readonly PROMETEO_DATASOURCES_SHARE_TO_EVERYONE: number = 2;
  public static readonly PROMETEO_DATASOURCES_SHARE_TO_ACCOUNT_LITERAL: string = 'El grupo';
  public static readonly PROMETEO_DATASOURCES_SHARE_TO_EVERYONE_LITERAL: string = 'Pública';

  public static readonly PROMETEO_DATASOURCES_CLICKS_OBJETIVE: number = 1;
  public static readonly PROMETEO_DATASOURCES_PRINTS_OBJETIVE: number = 2;
  public static readonly PROMETEO_DATASOURCES_USERS_OBJETIVE: number = 3;
  public static readonly PROMETEO_DATASOURCES_CLICKS_OBJETIVE_LITERAL: string = 'Clicks';
  public static readonly PROMETEO_DATASOURCES_PRINTS_OBJETIVE_LITERAL: string = 'Impresiones';
  public static readonly PROMETEO_DATASOURCES_USERS_OBJETIVE_LITERAL: string = 'Usuarios';
}
