import { environment } from '../../../environments/environment';

export class ConstantsAudiences {
  public static readonly CREATE_AUDIENCE = environment.newsletterAPI.createAudience;
  public static readonly UPDATE_AUDIENCE = environment.newsletterAPI.updateAudience;
  public static readonly DELETE_AUDIENCE = environment.newsletterAPI.deleteAudience;
  public static readonly NEWSLETTER_GET_AUDIENCE_BY_ID = environment.newsletterAPI.getAudienceById;
  public static readonly NEWSLETTER_GET_AUDIENCE_BY_ID_POSTGRE = environment.newsletterAPI.getAudienceByIdPostgre;
  public static readonly NEWSLETTER_UPDATE_SHARED_SITES_BY_ID = environment.newsletterAPI.updateSharedSites;
  public static readonly NEWSLETTER_GET_SHARED_SITES_BY_ID = environment.newsletterAPI.getSharedSites;
  public static readonly NEWSLETTER_GET_AUDIENCES_BY_SITE_POSTGRE = environment.newsletterAPI.getAudiencesBySitePostgre;
}
