import { CONSTANTS } from './constants';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { DatasourcesTypeToStringPipe } from 'src/app/shared/pipes/datasources-type-to-string.pipe';
import { TriggersTypeToStringPipe } from 'src/app/shared/pipes/triggers-type-to-string.pipe';
import { ContentListExcellInterface } from 'src/app/core/interfaces/content-list-excell.interface';

export class DownloadContentListUtils {
  public static exportContentsListAsCSV(excelService: ExcelService, elementsList: any[], elementsType: string, mediaId: string,
                                  datasourcesTypeToStringPipe: DatasourcesTypeToStringPipe, triggersTypeToStringPipe: TriggersTypeToStringPipe): void {
    const list = this.adaptContentsToCSV(elementsList, elementsType, mediaId, datasourcesTypeToStringPipe, triggersTypeToStringPipe);
    const fileName = `${elementsType}-list-` + new Date().toLocaleDateString() + '.xlsx';
    excelService.exportAsExcelFile(list, fileName);
  }

  private static formatDate(notification: any): Date {
    const date = new Date(`${notification.date}`);
    if (notification.time) {
      const hour = parseInt(notification.time.substring(0, 2));
      const minute = parseInt(notification.time.substring(3, 5));
      date.setHours(hour, minute);
    }
    return date;
  }

  public static infoNotification(notification: any, isUpdateColumn: boolean): string {

    const date = this.formatDate(notification);
    const today = new Date();
    const dateString = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + String(date.getFullYear()).substring(2, 4);

    if (!isUpdateColumn) {
      const envio = notification.messageType === CONSTANTS.PROMETEO_SCHEDULES_NOW_TYPE ?
        CONSTANTS.PROMETEO_SCHEDULES_NOW_TYPE_LITERAL :
        CONSTANTS.PROMETEO_SCHEDULES_SCHEDULED_TYPE_LITERAL;
      const mensaje = notification.contentType === CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_DYNAMIC_TYPE ?
        CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_DYNAMIC_TYPE_LITERAL :
        CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_MANUAL_TYPE_LITERAL;
      const estado = notification.messageType === CONSTANTS.PROMETEO_SCHEDULES_NOW_TYPE || today > date ? 'enviado' : `programado el ${dateString} a las ${notification.time}`;

      return `Envío: ${envio}\nMensaje: ${mensaje}\nEstado: ${estado}`;
    }
    else return dateString + ' ' + notification.time;
  }

  private static adaptContentsToCSV(elementsList: any[], elementsType: string, mediaId: string, datasourcesTypeToStringPipe: DatasourcesTypeToStringPipe,
                              triggersTypeToStringPipe: TriggersTypeToStringPipe): ContentListExcellInterface[] {

    const response: ContentListExcellInterface[] = elementsList.map(element => {
      const isActive = (element.active || element.status) ? "Activo" : "Inactivo";

      switch(elementsType) {
        case "historicalSegmentsWeb": case "historicalSegmentsApp" :
          return {
            ID: element.idSegment,
            Nombre: element.segmentName,
            Actualizado: element.updateDate,
            Activo: isActive,
            "Nº de usuarios": element.users && element.users.length ? element.users.length : 0,
            Compartido:
              element.shared && element.shared.length ?
                element.mediaId === mediaId ? 'Sí'
                : 'Compartido por ' + element.mediaId
              : 'No',
          };
        case "operationsHistoricalSegments":
          return {
            ID: element.idSegment,
            Nombre: element.segmentName,
            Actualizado: element.updateDate,
            Activo: isActive,
            "Nº de usuarios": element.users && element.users.length ? element.users.length : 0,
            Compartido:
              element.shared && element.shared.length ?
                element.mediaId === mediaId ? 'Sí'
                : 'Compartido por ' + element.mediaId
              : 'No',
            Operación: element.operator
          };
        case "datasources": case "triggersWeb": case "triggersApp": case "triggerTemplates":
          return {
            ID: element.id,
            Nombre: element.name,
            Tipo: elementsType === "datasources" ? datasourcesTypeToStringPipe.transform(element.type) :
            triggersTypeToStringPipe.transform(element.type),
            Actualizado: new Date(element.updatedAt.toMillis()).toLocaleString(),
            Activo: isActive
          };
        case "triggerFunctions":
          return {
            ID: element.id,
            Nombre: element.name,
            Actualizado: element.updatedAt && element.updatedAt.seconds ? new Date(element.updatedAt.seconds * 1000).toLocaleString() : ""
          };
        case "pushSubscriptions": case "newslettersSubscription":
          return {
            ID: element.id,
            Nombre: element.name,
            Actualizado: new Date(element.updatedAt.toMillis()).toLocaleString(),
            Activo: isActive,
            Tópico: elementsType === "pushSubscriptions" ? element.tag : element.topic
          };
        case "notificationsWeb": case "notificationsApp":
          const isNotificationWeb = elementsType === "notificationsWeb";
          const title = element.firebasePayload ? element.firebasePayload.message.title : element.payload.message.title;
          const topic = element.firebasePayload ? element.firebasePayload.message.topic : element.payload.message.topic;
          return {
            ID: element.id,
            Nombre: title,
            "Fecha de envío": !isNotificationWeb && element.messageType !== 2 && element.updated ? new Date(element.updated).toLocaleString() :
              !isNotificationWeb && element.messageType === 2 ? this.infoNotification(element, true) :
              element.schedule && element.schedule.Date !== null && element.schedule.Date !== "" ?
              `${new Date(element.schedule.Date).toLocaleDateString()} - ${element.schedule.Time}` : '',
            Tópico: topic
          };
        default:
          const isRealTimeSegments = elementsType === "realtimeSegments";
          const isImportedSegments = elementsType === "importedSegments";
          const isTopics = elementsType === "topics";

          return {
            ID: isRealTimeSegments ? element.idRule : isImportedSegments ? element.idSegment : element.id,
            Nombre: isImportedSegments ? element.segmentName : isTopics ? element.topic :
              elementsType === "newsLettersTemplates" || elementsType === "newslettersSubscription" ? element.title : element.name,
            Actualizado: isRealTimeSegments ? new Date(element.dateUpdated).toLocaleString() :
            isImportedSegments ? element.updateDate : isTopics ? new Date(element.updated).toLocaleString() :
            new Date(element.updatedAt.toMillis()).toLocaleString(),
            Activo: isActive
          };
      }
    });
    return response;
  }
}
