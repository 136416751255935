import { PipeTransform, Pipe } from '@angular/core';
import { CONSTANTS } from '../utils/constants';

@Pipe({ name: 'schedulesScheduleTypeToStringPipe' })
export class SchedulesScheduleTypeToStringPipe implements PipeTransform {
    public transform(value: number): string {
        switch (value) {
            case CONSTANTS.PROMETEO_SCHEDULES_NOW_TYPE:
                return CONSTANTS.PROMETEO_SCHEDULES_NOW_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_SCHEDULES_SCHEDULED_TYPE:
                return CONSTANTS.PROMETEO_SCHEDULES_SCHEDULED_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_SCHEDULES_PERIODICALLY_TYPE:
                return CONSTANTS.PROMETEO_SCHEDULES_PERIODICALLY_TYPE_LITERAL;
            default:
                return '';
        }
    }
}
