import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { from, Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { PushSubscriptionInterface } from '../interfaces/push-subscription.interface';
import { TriggerUtils } from 'src/app/shared/utils/triggers-utils';
import { deepConvertDates } from '../../shared/utils/utils';

const PUSH_SUBSCRIPTIONS_COLLECTION = 'push-subscriptions';

@Injectable()
export class PushSubscriptionService {
    private pushSubscriptionsCollection: AngularFirestoreCollection<PushSubscriptionInterface>;

    public constructor(private readonly firestore: AngularFirestore, private readonly authService: AuthService) {
        this.pushSubscriptionsCollection = this.firestore.collection<PushSubscriptionInterface>(
            PUSH_SUBSCRIPTIONS_COLLECTION
        );
    }

    public getAll(): Observable<PushSubscriptionInterface[]> {
        return this.pushSubscriptionsCollection.valueChanges();
    }

    public getBySite(siteId: string): Observable<PushSubscriptionInterface[]> {
        return this.firestore
            .collection<PushSubscriptionInterface>(
                PUSH_SUBSCRIPTIONS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges();
    }

    public getActiveBySite(siteId: string): Observable<PushSubscriptionInterface[]> {
        return this.firestore
            .collection<PushSubscriptionInterface>(
                PUSH_SUBSCRIPTIONS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', true)
            )
            .valueChanges();
    }

     // TODO: It should not look for programForceActualStatus, is going to be deprecated
    public getAllProgram(siteId: string ): Observable<PushSubscriptionInterface[]> {
        const pushSubscriptionPeriodic$ = this.firestore.collection<PushSubscriptionInterface>(
          PUSH_SUBSCRIPTIONS_COLLECTION,
            (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', false).where('hasPeriodicSchedule', '==', true)
        ).valueChanges();
        const pushSubscriptionInactiveNotPeriodic$ = this.firestore.collection<PushSubscriptionInterface>(
          PUSH_SUBSCRIPTIONS_COLLECTION,
            (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', false)
        ).valueChanges().pipe(
          map((trigger) => trigger.filter((trigger) => trigger?.programDates?.length > 0 && !trigger?.hasPeriodicSchedule)),
        );

        return combineLatest([pushSubscriptionPeriodic$, pushSubscriptionInactiveNotPeriodic$])
          .pipe(map(([pushPeriodic, pushProgram]) => [...pushPeriodic, ...pushProgram]))
    }

    public get(pushSubscriptionId: string): Observable<PushSubscriptionInterface> {
        return this.pushSubscriptionsCollection.doc<PushSubscriptionInterface>(pushSubscriptionId).valueChanges();
    }

    //TODO: refactor this function
    private replaceValues(pushSubscription: Partial<PushSubscriptionInterface>): void {

      if(!pushSubscription.siteId || !pushSubscription.topic)
        return;

      TriggerUtils.replaceTriggerIdInTriggerCode(pushSubscription, pushSubscription.id);
      const paramsToReplace = {
        triggerId: pushSubscription.id,
        topic: pushSubscription.topic,
        mediaId: pushSubscription.siteId,
      };

      pushSubscription.javascript = TriggerUtils.replaceTriggerIdMediaIdAndTopicInString(pushSubscription.javascript, paramsToReplace);
      pushSubscription.html = TriggerUtils.replaceTriggerIdMediaIdAndTopicInString(pushSubscription.html, paramsToReplace);
    }

    public add(pushSubscription: PushSubscriptionInterface): Observable<void> {
        const pushSubscriptionId = this.firestore.createId();
        pushSubscription.id = pushSubscriptionId;
        pushSubscription.createdAt = firebase.firestore.Timestamp.now();
        pushSubscription.updatedAt = firebase.firestore.Timestamp.now();
        pushSubscription.updatedBy = this.authService.getEmail();
        this.replaceValues(pushSubscription);
        return from(
            this.pushSubscriptionsCollection
                .doc<PushSubscriptionInterface>(pushSubscriptionId)
                .set(deepConvertDates(pushSubscription))
        );
    }

    public update(pushSubscriptionId: string, dataToUpdate: Partial<PushSubscriptionInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        this.replaceValues(dataToUpdate);
        return from(
            this.pushSubscriptionsCollection
                .doc<PushSubscriptionInterface>(pushSubscriptionId)
                .update(deepConvertDates(dataToUpdate))
        );
    }

    public delete(pushSubscriptionId: string): Observable<void> {
        return this.update(pushSubscriptionId, { deletedBy: this.authService.getEmail() }).pipe(
            switchMap(
                (): Observable<void> =>
                    from(this.pushSubscriptionsCollection.doc<PushSubscriptionInterface>(pushSubscriptionId).delete())
            )
        );
    }

    public getCollectionName(){
      return PUSH_SUBSCRIPTIONS_COLLECTION;
    }
}
