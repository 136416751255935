import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { PushNotificationInterface } from 'src/app/core/interfaces/push-notification/push-notification.interface';
import { MediaService } from 'src/app/core/services/media.service';
import { TopicsService } from 'src/app/shared/services/topics.service';
import { CONSTANTS } from 'src/app/shared/utils/constants';

@Component({
    selector: 'app-dynamic-notification-form',
    templateUrl: './dynamic-notification-form.component.html',
    styleUrls: ['./dynamic-notification-form.component.scss'],
})
export class DynamicNotificationFormComponent implements OnInit, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() pushNotification: PushNotificationInterface;
    public sections: string[] = [];
    public endPointTypes: number[] = [
        CONSTANTS.PROMETEO_SCHEDULES_ENDPOINT_MOSTREAD_TYPE,
        CONSTANTS.PROMETEO_SCHEDULES_ENDPOINT_MOSTRECENT_TYPE,
    ];
    private unsubscribe: Subject<void> = new Subject();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor(private mediaService: MediaService, private topicsService: TopicsService) {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit(): void {
        this.mediaService
            .getCurrentSite()
            .pipe(
                switchMap(
                    (currentSiteId: string): Observable<string[]> => {
                        return this.topicsService.getAllTopicsById(currentSiteId);
                    }
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe((sections: string[]) => {
                this.sections = sections;
            });
    }

    public subscribeToFormDinamic(): void {
        this.formGroup
            .get('endpoint')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((endpoint: number): void => {
                this.pushNotification.endpoint = endpoint;
            });

        this.formGroup
            .get('section')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((section: string): void => {
                this.pushNotification.section = section;
            });

        this.formGroup
            .get('tag')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((tag: string): void => {
                this.pushNotification.tag = tag;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
