import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, merge } from 'rxjs';

interface OrderModel {
    items: any[];
    total_count: number;
}

@Injectable()
export class ApiService {
    constructor(private http: HttpClient) {}

    post(endpoint, payload): Observable<any> {
        return this.http.post<any>(endpoint, payload);
    }

    get(endpoint): Observable<any> {
        return this.http.get<any>(endpoint);
    }

    put(endpoint, payload, id): Observable<any> {
        return this.http.put<any>(endpoint, payload, id);
    }

    delete(endpoint, id): Observable<any> {
        return this.http.delete(endpoint, id);
    }

    getRepoIssues(url, page: number): Observable<OrderModel> {
        const requestUrl = `${url}?q=repo:angular/components&page=${page + 1}`;

        return this.http.get<OrderModel>(requestUrl);
    }

    /**
     * rxjs - merge, se usa para recibir datos asincronos para aumentar la velocidad de carga (diferenciar con forkjoin)
     * @param payload observables
     */
    merge(payload: Observable<any>): any {
        const elementsToMerge: Observable<any>[] = [];
        elementsToMerge.push(payload);
        return merge(...elementsToMerge);
    }
}
