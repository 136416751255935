import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { FileSaverService } from './file-saver.service';
import { CONSTANTS } from '../utils/constants';

export interface ExportAsExcelFileParams {
    [sheetName: string]: any[],
}

@Injectable()
export class ExcelService {
    public constructor(private readonly fileSaverService: FileSaverService) {}

    /**
     * @deprecated
     */
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.fileSaverService.saveFile(excelBuffer, CONSTANTS.TYPE_XML, excelFileName);
    }

    public exportAsExcelFileMultiSheet(data: ExportAsExcelFileParams, excelFileName: string): void {
        const workbook = XLSX.utils.book_new();
        Object.entries(data).map(([key, value = []]) => ({
            worksheet: XLSX.utils.json_to_sheet(value),
            sheetName: key,
        })).forEach(({sheetName, worksheet}) => {
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.fileSaverService.saveFile(excelBuffer, CONSTANTS.TYPE_XML, excelFileName);
    }
}
