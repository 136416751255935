import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { from, Observable, combineLatest } from 'rxjs';
import { UserInterface } from '../interfaces/user.interface';
import { map, first } from 'rxjs/operators';
import { MediaInterface } from '../interfaces/media.interface';
import { SiteInterface } from '../interfaces/site.interface';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { HttpClient } from '@angular/common/http';
import { deepConvertDates } from '../../shared/utils/utils';
import { User } from '../models/user.model';

const USERS_COLLECTION = 'users';

@Injectable()
export class UserService {
    public usersCollection: AngularFirestoreCollection<UserInterface>;

    public constructor(private readonly firestore: AngularFirestore, private readonly httpClient: HttpClient) {
        this.usersCollection = this.firestore.collection<UserInterface>(USERS_COLLECTION);
    }

    public getAll(): Observable<UserInterface[]> {
        return this.usersCollection.valueChanges().pipe(map(User.mapFromFirebase));
    }

    public getAllAccount(): Observable<UserInterface[]> {
        return this.firestore
            .collection<UserInterface>(
                USERS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('roles.account', '==', true)
            )
            .valueChanges().pipe(map(User.mapFromFirebase));
    }

    public getAllKeyAccount(): Observable<UserInterface[]> {
        return this.firestore
            .collection<UserInterface>(
                USERS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('roles.keyaccount', '==', true)
            )
            .valueChanges();
    }

    public getAllAllowed(sitesIds: string[]): Observable<UserInterface[]> {
      //TODO esta función se podría realizar utilizando el query de in de firebase. Sin necesidad de hacer una consulta de getAllBySite por cada rol y media
      const queries = new Array<Observable<UserInterface[]>>();
      const allowedRoles = [CONSTANTS.ROLE_ACCOUNT, CONSTANTS.ROLE_ADMIN, CONSTANTS.ROLE_PROPERTY, CONSTANTS.ROLE_READER, CONSTANTS.ROLE_PUSH_MANAGER];
      const totalSites = sitesIds.length;
      const numberOfSlices =  Math.ceil(totalSites/10);
      for(let i=0;i<numberOfSlices;i++){
        const pending = (i + 1)* 10 < totalSites ? 10 : totalSites - i*10;
        const medias = sitesIds.slice(i*10, i*10 + pending);
        for (const role of allowedRoles) {
            const users$ = this.getAllBySite(role, medias);
            queries.push(users$);
        }
      }
      return combineLatest(queries).pipe(
          map((data: UserInterface[][]): UserInterface[] => {
              const users = [].concat(...data);
              return users.filter(
                  (user: UserInterface, index: number): boolean =>
                      users.findIndex((u: UserInterface): boolean => user.uid === u.uid) === index
              );
          })
      );
  }

    public getAllBySite(role: string, siteIds: string[]): Observable<UserInterface[]> {
        return this.firestore
            .collection<UserInterface>(
                USERS_COLLECTION,
                (ref: CollectionReference): Query =>
                    ref.where('role', '==', role).where('medias', 'array-contains-any', siteIds)
            )
            .valueChanges().pipe(map(User.mapFromFirebase));
    }

    public get(userId: string): Observable<UserInterface> {
        return this.usersCollection.doc<UserInterface>(userId).valueChanges().pipe(map(User.fromFirebase));
    }

    public add(user: UserInterface): Observable<void> {
        const userId = this.firestore.createId();
        user.uid = userId;
        return from(this.usersCollection.doc<UserInterface>(userId).set(Object.assign({}, user)));
    }

    public addWithCustomId(userId: string, user: UserInterface): Observable<void> {
        return from(this.usersCollection.doc<UserInterface>(userId).set(Object.assign({}, user)));
    }

    public deleteFromAuthFirebase(userId: string): Observable<void> {
        const DELETE_USER_FROM_AUTH = CONSTANTS.API_FUNCTION_DELETE_USER_FROM_AUTH.replace(':userId', userId);
        return this.httpClient.delete<void>(DELETE_USER_FROM_AUTH);
    }

    public delete(userId: string): Observable<void> {
        return from(this.usersCollection.doc<UserInterface>(userId).delete());
    }

    public update(userId: string, dataToUpdate: Partial<UserInterface>): Observable<void> {
        return from(this.usersCollection.doc<UserInterface>(userId).update(deepConvertDates(dataToUpdate)));
    }
}
