import firebase from 'firebase/compat/app';
import TestAbInterface from "../interfaces/trigger/test-ab.interface";
import TriggerMetaInterface from "../interfaces/trigger/trigger-meta.interface";
import { TriggerPeriod } from 'src/app/core/interfaces/trigger-period-in-testab';
import { TestABUtils } from 'src/app/shared/utils/test-ab-utils';

export default class TestAbModel implements TestAbInterface {
  active: boolean = true;
  description: string = '';
  frequency: number = null;
  id: string = '';
  isCompatible: boolean = false;
  triggers: TriggerMetaInterface[] = [];
  name: string = '';
  segments: string[] = [];
  siteId: string = '';
  priority: number = Number.MAX_VALUE;
  type: number = null;
  triggerType: string = "Test A/B";
  templatesNumber: number = null;
  resolveTest: boolean = false;
  minCTR: number = null;
  addMoreConditions: boolean = false;
  minImpressionsCheck: boolean = false;
  minImpressions: number = null;
  minDateCheck: boolean = false;
  resolveDateStart: number = TestABUtils.getNowMilliseconds();
  resolveDateEnd: number = TestABUtils.getNowMilliseconds();
  triggersPeriod: TriggerPeriod[] = [];
  updatedBy: string = '';
  updatedAt: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  createdAt: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  programDates: firebase.firestore.Timestamp[] = [];
  compatibleTriggers: string[] = [];

  constructor(testAB?: Partial<TestAbInterface>) {
    if (!testAB) return;

    Object.assign(this, { ...this, ...testAB });
  }
}
