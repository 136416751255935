import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WidgetInterface } from 'src/app/core/interfaces/widget.interface';

@Component({
    selector: 'app-widget-preview',
    templateUrl: './widget-preview.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class WidgetPreviewComponent {
    @Input() public widget: WidgetInterface = null;

    constructor() {}
}
