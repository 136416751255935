<div class="container d-flex h-100">
    <div class="row w-100 align-self-center">
        <div class="col-md-8 mx-auto">
            <div class="c-login-card card">
                <div class="card-header text-center bg-primary-color py-4">
                    <img class="c-login-card__logo" src="/assets/images/logo-white.svg" alt="Hipatia Logo" />
                </div>
                <!--Card content-->
                <div class="card-body px-lg-5 pt-0">
                    <!-- Form -->
                    <form [formGroup]="formGroupLogin" class="text-center" (ngSubmit)="signIn()">
                        <!-- Email -->
                        <mat-form-field appearance="outline" color="primary" class="col-md-12 pt-5">
                            <mat-label>Correo electrónico</mat-label>
                            <input
                                matInput
                                formControlName="email"
                                placeholder="Introduzca su correo electrónico"
                                required
                            />
                            <mat-icon matSuffix color="primary" fontSet="fas" fontIcon="fa-envelope"></mat-icon>
                            <mat-error *ngIf="formGroupLogin.get('email').hasError('email')">
                                El e-mail debe ser válido
                            </mat-error>
                        </mat-form-field>

                        ​
                        <!-- Password -->
                        <mat-form-field appearance="outline" color="primary" class="col-md-12">
                            <mat-label>Contraseña</mat-label>
                            <input
                                matInput
                                formControlName="password"
                                placeholder="Introduzca su contraseña"
                                type="password"
                                required
                            />
                            <mat-icon matSuffix color="primary" fontSet="fas" fontIcon="fa-lock"></mat-icon>
                        </mat-form-field>

                        <div class="d-flex justify-content-around">
                            <div>
                                <!-- Forgot password -->
                                <a [routerLink]="['/forgot']">¿Olvidaste tu contraseña?</a>
                            </div>
                        </div>
                        ​
                        <!-- Sign in button -->
                        <button
                            mat-raised-button
                            class="c-button c-button--text-big c-button--mw c-button--br-10 c-button--bg-primary-color py-2"
                            color="primary"
                            type="submit"
                        >
                            Acceder
                        </button>
                    </form>
                    <!-- Form -->
                </div>
            </div>
        </div>
    </div>
</div>
