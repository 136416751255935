import { Injectable } from "@angular/core";
import Swal, { SweetAlertResult, SweetAlertIcon } from "sweetalert2";
import ConstantMessages, { NOTIFY_BUTTON, NOTIFY_INPUT, NOTIFY_MESSAGE, NOTIFY_TYPE } from "../utils/constants-messages/constants-messages";

export {SweetAlertResult};

interface CustomAlertParams {
    title: string;
    type: NOTIFY_TYPE | SweetAlertIcon;
    message?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
    showCloseButton?: boolean;
    input?: NOTIFY_INPUT;
    inputOptions?: {
        [inputValue: string]: string;
    };
    inputValidator?: (inputValue: string) => string;
}

interface AlertParams {
    title?: string;
    message?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    showCancelButton?: boolean;
    inputOptions?: {
        [inputValue: string]: string;
    };
    callback?: (params: any) => string;
}

@Injectable()
export class AlertService {
    private readonly baseSwalAlert = Swal.mixin({
        title: '',
        icon: NOTIFY_TYPE.SUCCESS,
        html: '',
        confirmButtonText: ConstantMessages.AcceptText,
        cancelButtonText: NOTIFY_BUTTON.CANCEL,
        showCancelButton: false,
        showCloseButton: true,
    });

    success = ({ title, message }: AlertParams = {}): Promise<SweetAlertResult> => this.baseSwalAlert.fire({
        title: title || NOTIFY_MESSAGE.ALERT.GENERIC.TITLE.SUCCESS,
        html: message || NOTIFY_MESSAGE.ALERT.GENERIC.MSG.SUCCESS,
    });

    error = ({ title, message }: AlertParams = {}): Promise<SweetAlertResult> => this.baseSwalAlert.fire({
        // TODO valorar recibir el error para sacar el mensaje desde el error
        title: title || NOTIFY_MESSAGE.ALERT.GENERIC.TITLE.ERROR,
        icon: NOTIFY_TYPE.ERROR,
        html: message || NOTIFY_MESSAGE.ALERT.GENERIC.MSG.ERROR,
    });

    info = ({ title, message }: AlertParams = {}): Promise<SweetAlertResult> => this.baseSwalAlert.fire({
        title: title || NOTIFY_MESSAGE.ALERT.GENERIC.TITLE.INFO,
        icon: NOTIFY_TYPE.INFO,
        html: message || NOTIFY_MESSAGE.ALERT.GENERIC.MSG.INFO,
    });

    warning = ({
        title,
        message,
        confirmButtonText,
        cancelButtonText,
        showCancelButton
    }: AlertParams = {}): Promise<SweetAlertResult> => this.baseSwalAlert.fire({
        title: title || NOTIFY_MESSAGE.ALERT.GENERIC.TITLE.WARNING,
        icon: NOTIFY_TYPE.WARNING,
        html: message || NOTIFY_MESSAGE.ALERT.GENERIC.MSG.WARNING,
        confirmButtonText: confirmButtonText || NOTIFY_BUTTON.CONFIRM,
        cancelButtonText: cancelButtonText || NOTIFY_BUTTON.CANCEL,
        showCancelButton: showCancelButton || true,
    });

    question = ({
        title,
        message,
        confirmButtonText,
        cancelButtonText,
    }: AlertParams): Promise<SweetAlertResult> => this.baseSwalAlert.fire({
        title,
        icon: NOTIFY_TYPE.QUESTION,
        html: message || '',
        showCancelButton: true,
        confirmButtonText: confirmButtonText || NOTIFY_BUTTON.CONFIRM,
        cancelButtonText: cancelButtonText || NOTIFY_BUTTON.CANCEL,
    });

    questionWithRadioInput = ({
        title,
        message,
        confirmButtonText,
        cancelButtonText,
        inputOptions,
    }: AlertParams): Promise<SweetAlertResult> => this.baseSwalAlert.fire({
        title,
        icon: NOTIFY_TYPE.QUESTION,
        html: message || '',
        showCancelButton: true,
        confirmButtonText: confirmButtonText || NOTIFY_BUTTON.CONFIRM,
        cancelButtonText: cancelButtonText || NOTIFY_BUTTON.CANCEL,
        input: NOTIFY_INPUT.RADIO,
        inputOptions,
        inputValidator: (response: string): string => {
            if (!response) {
              return NOTIFY_MESSAGE.ALERT.RADIO_INPUT.VALIDATOR_MSG;
            }
          }
    });

    input = ({ title, callback }: AlertParams = {}): Promise<SweetAlertResult> => this.baseSwalAlert.fire({
        title: title || NOTIFY_MESSAGE.ALERT.GENERIC.TITLE.INPUT,
        input: 'text',
        showCancelButton: true,
        didOpen: () => Swal.getInput().addEventListener('input', ({target}: { target: any}) => target.value = callback(target.value)),
        inputValidator: (value) => {
            if (!value) {
                return NOTIFY_MESSAGE.ALERT.TEXT_INPUT.VALIDATOR_MSG;
            }
        },
      } as any);

    custom = (params: CustomAlertParams): Promise<SweetAlertResult> => this.baseSwalAlert.fire(params as any);
}
