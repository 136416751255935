import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { combineLatest, map } from 'rxjs/operators';
import { LogInterface } from '../interfaces/log.interface';
import { AuthService } from './auth.service';
import { MediaService } from './media.service';

const LOGS_COLLECTION = 'logs';

@Injectable()
export class LogService {
    public constructor(
        private readonly firestore: AngularFirestore,
        private readonly authService: AuthService,
        private readonly mediaService: MediaService
    ) {}

    public getAll(): Observable<LogInterface[]> {
        return this.firestore
            .collection<LogInterface>(
                LOGS_COLLECTION,
                (ref: CollectionReference): Query => ref.orderBy('createdAt', 'desc').limit(15)
            )
            .valueChanges();
    }

    public getAllAllowed(): Observable<LogInterface[]> {
        const logsUserAndMedia$ = this.getAll().pipe(combineLatest(this.mediaService.getCurrentSite()));
        const logs$: Observable<LogInterface[]> = logsUserAndMedia$.pipe(
            map(([logs, mediaId]) => {
                const user = this.authService.userDetails;
                const logsByMedia$ = logs.filter(log => {
                    return log.entity.siteId === mediaId;
                });
                const lastLogSeenIndex: number = logsByMedia$.findIndex((log: LogInterface) => {
                    if (user.lastLogSeenByMedia && user.lastLogSeenByMedia[mediaId]) {
                        const isLogFromUser = user.lastLogSeenByMedia[mediaId];
                        return isLogFromUser === log.id;
                    }
                });

                let newLogs = [];
                if (lastLogSeenIndex < 0) {
                    newLogs = logsByMedia$.slice(0, 6);
                } else {
                    newLogs = logsByMedia$.slice(0, lastLogSeenIndex);
                }

                return newLogs;
            })
        );

        return logs$;
    }

    public getBySite(siteId: string): Observable<LogInterface[]> {
        return this.firestore
            .collection<LogInterface>(
                LOGS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('entity.siteId', '==', siteId)
            )
            .valueChanges();
    }

    getBySiteLastXDays(siteId: string, days: number): Observable<LogInterface[]> {
        const limitDate = this.getLimitDay(days);

        return this.firestore
            .collection<LogInterface>(
                LOGS_COLLECTION,
                (ref: CollectionReference): Query =>
                    ref
                        .where('entity.siteId', '==', siteId)
                        .where('createdAt', '>=', limitDate)
                        .orderBy('createdAt', 'desc')
            )
            .valueChanges();
    }

    public getByName(name: string): Observable<LogInterface> {
        return this.firestore
            .collection(
                LOGS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('name', '==', name)
            )
            .snapshotChanges()
            .pipe(
                map(
                    (actions: DocumentChangeAction<LogInterface>[]): LogInterface => {
                        return actions.map(
                            (action: DocumentChangeAction<LogInterface>): LogInterface => {
                                const log = action.payload.doc.data();
                                if (log) log.id = action.payload.doc.id;
                                return log;
                            }
                        )[0];
                    }
                )
            );
    }

    private getLimitDay(days: number): Date {
        const limitDate = new Date();
        limitDate.setDate(limitDate.getDate() - days);
        limitDate.setHours(0);
        limitDate.setMinutes(0);
        limitDate.setSeconds(0);
        limitDate.setMilliseconds(0);
        return limitDate;
    }
}
