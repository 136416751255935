import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import TableAction from 'src/app/core/interfaces/table/table-action.interface';
import TestAbInterface from 'src/app/core/interfaces/trigger/test-ab.interface';
import { MediaService } from 'src/app/core/services/media.service';
import { AllTriggersService } from 'src/app/core/services/all-triggers.service';
import { ConstantsTriggers } from 'src/app/shared/utils/constants-triggers';
import { PushSubscriptionService } from 'src/app/core/services/push-subscription.service';
import { NewslettersSubscriptionService } from 'src/app/core/services/newsletters-subscription.service';
import { TriggersComponentTypeToStringPipe } from 'src/app/shared/pipes/triggers-component-type-to-string.pipe';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { MetricService } from 'src/app/core/services/metric.service';
import { RoleService } from 'src/app/core/services/role.service';
import { TestAbService } from 'src/app/core/services/test-ab.service';
import { TableColumn } from 'src/app/shared/components/generic-table/column.interface';
import { TriggerRedisActionService } from 'src/app/shared/services/trigger-redis-action.service';
import { AppPermissions } from 'src/app/shared/utils/app-permissions';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { DownloadContentListUtils } from 'src/app/shared/utils/download-content-list-utils';
import { TriggersTypeToStringPipe } from 'src/app/shared/pipes/triggers-type-to-string.pipe';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { DatasourcesTypeToStringPipe } from 'src/app/shared/pipes/datasources-type-to-string.pipe'
import { CacheService } from 'src/app/core/services/cache.service';
import ConstantMessages from 'src/app/shared/utils/constants-messages/constants-messages';
import { ConstantsStatistics } from 'src/app/shared/utils/constants-statistics';
import { SectionInterface } from 'src/app/core/interfaces/filter.interface';
import { PROGRAMMABLE_SORT_INFO } from 'src/app/shared/utils/constants-programmable';
import { RolePermissions } from 'src/app/shared/types/types';

const UPDATE_VALUE = 1;
const CLONE_VALUE = 2;
const DELETE_VALUE = 3;

@Component({
  selector: 'app-triggers-test-ab-list',
  templateUrl: './triggers-test-ab-list.component.html',
  styleUrls: ['./triggers-test-ab-list.component.scss']
})
export class TriggersTestAbListComponent implements OnInit {
  testAbList: MatTableDataSource<TestAbInterface>;
  private unsubscribe: Subject<void> = new Subject();

  actions: TableAction[];
  tableData: TableColumn<TestAbInterface>[];
  canCreate: boolean;
  mediaId: string;
  allTriggers: any[];

  public sections: SectionInterface[] = [
      {
          name: 'Activo',
          filters: [{ name: 'Activos', value: 2 }, { name: 'No activos', value: 3 }]
      },
      {
          name: 'Estado',
          filters: [{ name: 'Programados', value: 4 }]
      }
  ];

  constructor(
    private readonly roleService: RoleService,
    private readonly mediaService: MediaService,
    private readonly testAbService: TestAbService,
    private readonly triggerService: TriggerService,
    private readonly pushSubscriptionService: PushSubscriptionService,
    private readonly newslettersSubscriptionService: NewslettersSubscriptionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly triggerRedisActionService: TriggerRedisActionService,
    private readonly allTriggersService: AllTriggersService,
    private readonly triggersComponentTypeToStringPipe: TriggersComponentTypeToStringPipe,
    private readonly triggersTypeToStringPipe: TriggersTypeToStringPipe,
    private readonly excelService: ExcelService,
    private readonly datasourcesTypeToStringPipe: DatasourcesTypeToStringPipe,
    private readonly metricService: MetricService
    ) { }

  ngOnInit() {
    this.loadColumns();
    this.setAllowedActions(this.roleService.getPermissions());

    this.mediaService.getCurrentSite().pipe(
      switchMap((currentSiteId => {
        return this.allTriggersService.getAllByMedia(currentSiteId);
      }))
    )
    .subscribe(triggers => this.allTriggers = triggers)

    this.mediaService
      .getCurrentSite()
      .pipe(switchMap((mediaId) => {
        this.mediaId = mediaId;
        return this.testAbService.getAllByMedia(mediaId);
      }))
      .subscribe((testsAb: TestAbInterface[]): void => {
        this.testAbList = new MatTableDataSource(testsAb);
        this.setFilterPredicate();
        this.filter({ id: '', name: '', filter: 2 });
      },
      error => {
        console.log(error);
        this.testAbList = new MatTableDataSource([]);
      }
    );
  }

  public filter(event: any): void {
    this.testAbList.filter = JSON.stringify({
      id: event.id,
      name: event.name,
      active: event.filter
    })
  }

  setFilterPredicate() {
    this.testAbList.filterPredicate = (data, filter) => {
      const parsedFilters = JSON.parse(filter);
      return Object.keys(parsedFilters).every(column => {
        const filterValue = parsedFilters[column];
        const fieldValue = data[column];
        switch (column) {
          case 'name':
            return fieldValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
          case 'id':
            return fieldValue.indexOf(filterValue) > -1;
          case 'active':
            return (
                (filterValue === 1 ) ||
                (filterValue === 2 && fieldValue) ||
                (filterValue === 3 && !fieldValue) ||
                (filterValue === 4 && data['isProgram'])
            );
          default:
            return false;
        }
      });
    };

    this.testAbList.sortingDataAccessor = (item: TestAbInterface, property: string): string | number => {
      switch (property) {
        case 'id':
          return item.id.toLocaleLowerCase();
        case 'info':
          return item.programDates && item.programDates.length ? PROGRAMMABLE_SORT_INFO.PROGRAMMED : PROGRAMMABLE_SORT_INFO.NOT_PROGRAMMED;;
        default:
          return item[property];
      }
    }
  }

  setAllowedActions(permissions: RolePermissions): void {
    this.actions = [];
    if(permissions[AppPermissions.ACTIVADORES.TEST_AB_UPDATE.id]) {
      this.actions.push({ id: UPDATE_VALUE, name: 'Editar' });
    }
    if(permissions[AppPermissions.ACTIVADORES.TEST_AB_CREATE.id]) {
      this.canCreate = true;
      this.actions.push({ id: CLONE_VALUE, name: 'Copiar' });
    }
    if(permissions[AppPermissions.ACTIVADORES.TEST_AB_DELETE.id]) {
      this.actions.push({ id: DELETE_VALUE, name: 'Eliminar' });
    }
  }

  loadColumns(): void {
    this.tableData = [
      {
        Title: 'ID',
        columnDef: 'id',
        type: 'text',
        Value: (t: TestAbInterface) => t.id,
        isSortable: true,
        isLongId: true,
      },
      {
        Title: 'NOMBRE',
        columnDef: 'name',
        type: 'text',
        Value: (t: TestAbInterface) => t.name,
        isSortable: true
      },
      {
        Title: 'TIPO',
        columnDef: 'type',
        type: 'text',
        Value: (t: TestAbInterface) => this.triggersComponentTypeToStringPipe.transform(t.type),
      },
      {
        Title: 'ACTUALIZADO POR',
        columnDef: 'updated',
        type: 'text',
        Value: (t: TestAbInterface) => new Date(t.updatedAt.toMillis()).toLocaleString(),
        ValueFooter: (t: TestAbInterface) => t.updatedBy,
        isSortable: true
      },
      {
        Title: 'ACTIVE',
        columnDef: 'active',
        type: 'boolean',
        Value: (t: TestAbInterface) => t.active,
        isSortable: true
      },
      {
        Title: 'INFO',
        columnDef: 'info',
        type: 'icon',
        Value: (t: TestAbInterface) => this.getInfoMessageData(t),
        valueColor: (t: TestAbInterface) => (t.programDates && t.programDates.length) ? 'orange' : '#1B55E3',
        isSortable: true
      },
      {
        Title: 'ESTADISTICAS',
        columnDef: 'statistics',
        type: 'statistics',
        Value: (t: TestAbInterface) => ''
      }
    ]
  }

  getInfoMessageData( testAb: TestAbInterface): string {
    if (testAb.programDates && testAb.programDates.length === 0) {
      return  `Id: ${testAb.id}
        Nombre: ${testAb.name}
        Descripción: ${testAb.description}
        Segmentos: ${testAb.segments.join(', ')}`;
    } else {
      return `Se ejecutará el día:`;
    }
  }

  async onActionChange(element: TestAbInterface, event) {
    switch (event.value) {
      case UPDATE_VALUE:
        this.edit(element.id);
        break;
      case CLONE_VALUE:
        const resp = await Utils.notify(ConstantMessages.CloneWarningTitle, ConstantMessages.WarningType, ConstantMessages.CloneWarningText);
        if (resp.value) this.clone(element);
        break;
      case DELETE_VALUE:
        const { value } = await Utils.notify(ConstantMessages.DeleteWarningTitle, ConstantMessages.WarningType, ConstantMessages.DeleteWarningText);
        if (value) this.delete(element.id, element.name);
        break;
      }
  }

  private edit(id: string): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  private clone(testAb: TestAbInterface): void {
      testAb.name = CONSTANTS.COPY_OF + testAb.name;
      this.allTriggersService
        .getMaxPriorityByMedia(this.mediaId)
        .pipe(
          first(),
          switchMap( (maxPriority: number) => {
            testAb.priority = maxPriority;
            return  this.testAbService.add(testAb, false)
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe(
            () => Utils.notify(
                    ConstantMessages.CloneTitle.replace(':typeName', ConstantMessages.TestABName),
                    ConstantMessages.SuccessType,
                    ConstantMessages.CloneSuccess.replace(':typeName', ConstantMessages.TestABName).replace(':name', testAb.name)
                  ),
            () => Utils.notify(
                    ConstantMessages.CloneTitle.replace(':typeName', ConstantMessages.TestABName),
                    ConstantMessages.ErrorType,
                    ConstantMessages.CloneError.replace(':typeName', ConstantMessages.TestABName).replace(':name', testAb.name)
                  )
        );
  }

  private delete(id: string, name: string): void {
      this.testAbService.getById(id).pipe(first())
      .subscribe(testAb => {
        testAb.triggers.forEach(t => {
          let genericTrigger = this.allTriggers.find(trigger => trigger.id === t.id);
          this.updateTriggers(genericTrigger, id);
        })
        this.deleteTestAB(id, name);
      })
  }

  private updateTriggers(genericTrigger, testABID: string) {
    if (genericTrigger && genericTrigger.type === "triggers") {
      this.triggerService.get(genericTrigger.id).pipe(first())
      .subscribe(trigger => {
        this.deleteFromTestABList(trigger, genericTrigger.type, testABID);
      })
    }
    else if (genericTrigger && genericTrigger.type === "push-subscription") {
        this.pushSubscriptionService.get(genericTrigger.id).pipe(first())
        .subscribe(pushSubscription => {
          this.deleteFromTestABList(pushSubscription, genericTrigger.type, testABID);
        })
    }
    else if (genericTrigger && genericTrigger.type === "newsletter-subscription") {
        this.newslettersSubscriptionService.getById(genericTrigger.id).pipe(first())
        .subscribe(newslettersSubscription => {
          this.deleteFromTestABList(newslettersSubscription, genericTrigger.type, testABID)
        })
    }
  }

  private deleteFromTestABList(trigger: any, triggerType: string, testABID: string) {
    const index = trigger.testABList ? trigger.testABList.indexOf(testABID) : -1;
    if (index != -1) trigger.testABList.splice(index, 1);
    trigger.isIntoTestAB = trigger.testABList.length ? true : false;

    if (triggerType === ConstantsTriggers.globalTriggerTypes[2]) this.triggerService.update(trigger.id, { isIntoTestAB: trigger.isIntoTestAB, testABList: trigger.testABList });
    else if (triggerType === ConstantsTriggers.globalTriggerTypes[3]) this.pushSubscriptionService.update(trigger.id, { isIntoTestAB: trigger.isIntoTestAB, testABList: trigger.testABList });
    else if (triggerType === ConstantsTriggers.globalTriggerTypes[4]) this.newslettersSubscriptionService.update(trigger.id, { isIntoTestAB: trigger.isIntoTestAB, testABList: trigger.testABList });
  }

  private deleteTestAB(id: string, name: string): void {
    this.testAbService
          .delete(id)
          .pipe(
              takeUntil(this.unsubscribe),
              switchMap(() =>  this.triggerRedisActionService.deleteTestAbOnRedis(this.mediaId, id)),
          )
          .subscribe(
              () => Utils.notify(
                      ConstantMessages.DeleteTitle.replace(':typeName', ConstantMessages.TestABName),
                      ConstantMessages.SuccessType,
                      ConstantMessages.DeleteSuccess.replace(':typeName', ConstantMessages.TestABName).replace(':name', name)
                    ),
              (err: unknown): void => {
                  console.log(err);
                  Utils.notify(
                    ConstantMessages.DeleteTitle.replace(':typeName', ConstantMessages.TestABName),
                    ConstantMessages.ErrorType,
                    ConstantMessages.DeleteError.replace(':typeName', ConstantMessages.TestABName).replace(':name', name)
                  );
              }
          );
  }

  public async onButtonStatisticsClicked(testAb: TestAbInterface): Promise<void> {
    this.metricService.waitingForStatisticCheckNotification(testAb.name, testAb.id, ConstantsStatistics.testABType, testAb.siteId, CONSTANTS.SITE_WEB,
                                                            ConstantsStatistics.TESTSAB_ROUTE, this.router);
  }

  public exportContentsListAsCSV() {
    DownloadContentListUtils.exportContentsListAsCSV(this.excelService, this.testAbList.filteredData, "testsABTriggers", this.mediaId, this.datasourcesTypeToStringPipe,
                                                     this.triggersTypeToStringPipe);
  }
}
