import { Component, Input, OnInit } from '@angular/core';

const typesAlerts = {
  danger: 'alert alert-danger',
  info: 'alert alert-primary'
}
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})

export class AlertsComponent implements OnInit {
  @Input() public data: string;
  @Input() public type: string;

  constructor() { }

  ngOnInit() {
  }

  getClass(type) {
    return typesAlerts[type];
  }

}
