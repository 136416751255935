import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NewslettersSubscriptionInterface } from 'src/app/core/interfaces/newsletters-subscription.interface';
import { PushSubscriptionInterface } from 'src/app/core/interfaces/push-subscription.interface';
import { TriggerInterface } from 'src/app/core/interfaces/trigger.interface';

@Component({
    selector: 'app-amp-template-editor',
    templateUrl: './amp-template-editor.component.html',
    styleUrls: ['./amp-template-editor.component.scss'],
})
export class AmpTemplateEditorComponent implements OnInit, OnDestroy {
    @Input() data: TriggerInterface | PushSubscriptionInterface | NewslettersSubscriptionInterface;
    @Output() loadAmpConfig = new EventEmitter();
    subscriptions = new Subscription();

    formGroup: FormGroup;

    constructor(private readonly formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            title: [this.data.ampConfig.title],
            text: [this.data.ampConfig.text],
            action: [this.data.ampConfig.action],
        });
        const form$ = this.formGroup.valueChanges.subscribe(() => this.loadAmpConfig.emit(this.formGroup.value));
        this.subscriptions.add(form$);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
