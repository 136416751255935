import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';


@Injectable()
export class AWSService {
    public constructor(
        private httpClient: HttpClient
    ) {
    }

    private uploadToBucket(image: File, base64: string): Observable<{key: string}> {
      const request = {
        fileName: image.name,
        base64,
      };
      return this.httpClient.post<{key: string}>(CONSTANTS.UPLOAD_IMAGE_URL, request);
    }

    public uploadImage(image: File): Observable<any> {
        return from(Utils.fileToBase64(image)).pipe(
            switchMap((base64File: string)  => this.uploadToBucket(image, base64File)),
            switchMap(({ key }: { key: string }) => {
              const imageUrl = CONSTANTS.CLOUDFRONT_URL + key;
              return of(imageUrl.replace(/ /g, '-'));
            })
        );
    }

}
