import { Component, Input, OnChanges } from '@angular/core';
import { ConstantsStatistics } from '../../utils/constants-statistics';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-statistics-triggers-metrics',
  templateUrl: './statistics-triggers-metrics.component.html',
  styleUrls: ['./statistics-triggers-metrics.component.scss']
})
export class StatisticsTriggersMetricsComponent implements OnChanges {
  @Input() triggerId: string;
  @Input() siteType: string;
  @Input() currentSiteId: string;
  @Input() dates: { begin: Date, end: Date };
  @Input() metrics: any;

  methods: { name: string, value: number, icon?: string }[] = []
  devices: { name: string, value: {name: string, value: number}[], icon?: string }[] = []

  ctrNull: string = '00.00 %';

  ngOnChanges() {
    this.setMethodsArray();
    this.setDevicesArray();
    this.setMethodsIcons();
    this.setDevicesIcons();
  }

  public getAllMetricsByDevice(value: number, ctr: boolean, show: number): number {
    let acc = value;

    if (ctr) {
      return value && acc ? (acc / show) * 100 : 0.00;
    }
    return acc;
  }

  public getCTRFormated(value: number): string {
    const method = this.methods.find(method => method.name === 'show');
    if (!method) return this.ctrNull;
    const show = method.value;

    let ctr = value && show ? (value / show) * 100 : 0.00;
    let ctrString = ctr.toFixed(2).toString();
    if (parseInt((ctr / 10).toString()) <= 0) ctrString = '0' + ctrString;

    return ctrString + ' %';
  }

  public getDeviceCTRFormated(deviceName: string, action: string): string {
    const device = this.devices.find(device => device.name === deviceName);
    if (!device) return this.ctrNull;

    const showAction = device.value.find(value => value.name === ConstantsStatistics.METRICS_SHOW);
    if (!showAction) return this.ctrNull;
    const show = showAction.value;

    const valueAction = device.value.find(value => value.name === action);
    if (!valueAction) return this.ctrNull;
    const value = valueAction.value;

    let ctr = value && show ? (value / show) * 100 : 0.00;
    let ctrString = ctr.toFixed(2).toString();
    if (parseInt((ctr / 10).toString()) <= 0) ctrString = '0' + ctrString;

    return ctrString + ' %';
  }

  public setMethodsArray(): void {
    this.methods = [];
    this.metrics && this.metrics.forEach(d => {
      for (let keys in d.devices) {
        for (let key in d.devices[keys]) {
          //if name is repeated, don't push but sum the values
          if (this.methods.some(method => method.name === key)) {
            this.methods.find(method => method.name === key).value += d.devices[keys][key];
          } else {
            this.methods.push({ name: key, value: d.devices[keys][key], icon: '' });
          }
        }
      }
    })
    this.methods.sort((a, b) => {
      return this.getMethodPriority(a.name) - this.getMethodPriority(b.name);
    });
  }

  public setDevicesArray(): void {
    this.devices = [];
    this.metrics && this.metrics.forEach(metric => {
      for (let key in metric.devices) {
        for (let keys in metric.devices[key]) {
            const device = this.devices.find(device => device.name === key);
          if (device) {
            const index = device.value.findIndex(value => value['name'] === keys);
            if (index !== -1) {
              device.value[index]['value'] += metric.devices[key][keys];
            } else {
              device.value.push({ name: keys, value: metric.devices[key][keys] });
            }
          } else {
            this.devices.push({ name: key, value: [{ name: keys, value: metric.devices[key][keys] }], icon: '' });
          }
        }
      }
    }
    )
  }

  public getValueForDevice(device: any, action: any): number {
    //El 0 se devuelve para la visualización en la tabla, para indicar que no hay datos de ese dispositivo o acción
    const deviceObject = this.devices.find(data => data.name == device);
      if (!deviceObject) return 0;
    const actionObject = deviceObject.value.find(actions => actions.name === action);
      if (!actionObject) return 0;

    return actionObject.value;
  }

  public setMethodsIcons(): void {
    const iconMap = {
      'show': 'fa-eye',
      'close': 'fa-times',
    };

    this.methods.forEach(method => {
      method.icon = iconMap[method.name] || 'fa-check';
    });

  }

  public setDevicesIcons(): void {

    const iconMap = {
      'desktop': 'fa-desktop',
      'mobile': 'fa-mobile-alt',
      'tablet': 'fa-tablet-alt',
    };

    this.devices.forEach(device => {
      device.icon = iconMap[device.name] || 'fa-ellipsis-h';
    });
  }

  public isNotShow(method: any): boolean {
    return method.name !== ConstantsStatistics.METRICS_SHOW;
  }

  public isShow(method: any): string {
    return method.name === ConstantsStatistics.METRICS_SHOW ? 'column3' : 'column2';
  }

  public getMethodName(method: any): string {
    return Utils.getStatisticsMethodsName(method);
  }

  private getMethodPriority(methodName: string): number {

    switch (methodName) {
      case ConstantsStatistics.METRICS_SHOW:
        return ConstantsStatistics.PRIORITY_SHOW;
      case ConstantsStatistics.METRICS_CLOSE:
        return ConstantsStatistics.PRIORITY_CLOSE;
      default:
        return ConstantsStatistics.PRIORITY_OTHERS;
    }
  }
}
