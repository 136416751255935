<form [formGroup]="formGroup">
  <div class="row">
      <mat-form-field class="col-md-6" appearance="outline">
          <mat-label>Titulo</mat-label>
          <input
              matInput
              name="title"
              formControlName="title"
              placeholder="Titulo"
              required
          />
      </mat-form-field>
      <mat-form-field class="col-md-6" appearance="outline">
          <mat-label>Texto</mat-label>
          <input
              matInput
              name="text"
              formControlName="text"
              placeholder="Texto"
          />
      </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="col-md-6" appearance="outline">
      <mat-label>Botón Acción</mat-label>
      <input
           matInput
           name="action"
           formControlName="action"
           placeholder="Botón Acción"

      />
    </mat-form-field>
  </div>
</form>
