import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { from, Observable, combineLatest, of } from 'rxjs';
import { switchMap, map, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { HttpClient } from '@angular/common/http';
import { TriggerUtils } from 'src/app/shared/utils/triggers-utils';
import { TriggerTemplateInterface } from '../interfaces/trigger-template.interface';

const TRIGGERS_TEMPLATE_COLLECTION = 'trigger-templates';

@Injectable()
export class TriggerTemplateService {
    private triggersTemplateCollection: AngularFirestoreCollection<TriggerTemplateInterface>;
    triggersTemplatesTotal = 0;

    public constructor(
        private readonly firestore: AngularFirestore,
        private readonly authService: AuthService,
        private readonly httpClient: HttpClient
    ) {
        this.triggersTemplateCollection = this.firestore.collection<TriggerTemplateInterface>(TRIGGERS_TEMPLATE_COLLECTION);
    }

    public getAll(): Observable<TriggerTemplateInterface[]> {
        return this.triggersTemplateCollection.valueChanges();
    }

    public getAllBySite(siteId: string): Observable<TriggerTemplateInterface[]> {
      return this.firestore
          .collection<TriggerTemplateInterface>(
            TRIGGERS_TEMPLATE_COLLECTION,
              (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
          )
          .valueChanges();
    }

    public getAllBasic(): Observable<TriggerTemplateInterface[]> {
        return this.firestore
            .collection<TriggerTemplateInterface>(
                TRIGGERS_TEMPLATE_COLLECTION,
                (ref: CollectionReference): Query => ref.where('basic', '==', true)
            )
            .valueChanges();
    }

    public getAllAllowed(sitesIds: string[]): Observable<TriggerTemplateInterface[]> {
      const queries = new Array<Observable<TriggerTemplateInterface[]>>();
      for (const siteId of sitesIds) {
          const templates$ = this.getAllBySite(siteId);
          queries.push(templates$);
      }

      const basicTemplates$ =
          this.authService.userDetails && this.authService.hasRole(CONSTANTS.ROLE_KEYACCOUNT)
          ? this.getAll()
          : of([]);

      queries.push(basicTemplates$);

      return combineLatest(queries).pipe(
          map((allTemplates: TriggerTemplateInterface[][]): TriggerTemplateInterface[] => {
              const templates = [];
              for (const t of allTemplates) {
                  templates.push(...t);
              }
              return templates.filter(
                  (template: TriggerTemplateInterface, index: number): boolean =>
                      templates.findIndex((t: TriggerTemplateInterface): boolean => template.id === t.id) === index
              );
          })
      );
  }


    public getBySite(siteId: string): Observable<TriggerTemplateInterface[]> {
        return this.firestore
            .collection<TriggerTemplateInterface>(
              TRIGGERS_TEMPLATE_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges()
            .pipe(
                tap(list => {
                    this.triggersTemplatesTotal = list.length;
                })
            );
    }

    public getActiveBySite(siteId: string): Observable<TriggerTemplateInterface[]> {
        return this.firestore
            .collection<TriggerTemplateInterface>(
              TRIGGERS_TEMPLATE_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', true)
            )
            .valueChanges();
    }

    getBySiteAndSharedWithSiteAndBasic(siteId: string): Observable<TriggerTemplateInterface[]> {
      return this.getAll().pipe(
          map(all =>
              all.filter(
                  template =>
                      template.siteId === siteId ||
                      template.basic ||
                      (template.sitesToShareWith &&
                          template.sitesToShareWith.includes(siteId))
              )
          )
      );
    }


    public get(triggerTemplateId: string): Observable<TriggerTemplateInterface> {
        return this.triggersTemplateCollection.doc<TriggerTemplateInterface>(triggerTemplateId).valueChanges();
    }

    public updateVersion(media: string): Observable<{ message: string }> {
        const versionUrl = CONSTANTS.API_REST_UPDATE_TRIGGER_VERSION.replace(':mediaId', media);
        return this.httpClient.post<{ message: string }>(versionUrl, {}).pipe(take(1));
    }

    public add(triggerTemplate: TriggerTemplateInterface): Observable<void> {
        const triggerTemplateId = this.firestore.createId();
        triggerTemplate.id = triggerTemplateId;
        triggerTemplate.createdAt = firebase.firestore.Timestamp.now();
        triggerTemplate.updatedAt = firebase.firestore.Timestamp.now();
        triggerTemplate.updatedBy = this.authService.getEmail();
        return from(this.triggersTemplateCollection.doc<TriggerTemplateInterface>(triggerTemplateId).set(Object.assign({}, triggerTemplate)));
    }

    public delete(triggerTemplateId: string): Observable<void> {
        return this.update(triggerTemplateId, { deletedBy: this.authService.getEmail() }).pipe(
            switchMap((): Observable<void> => from(this.triggersTemplateCollection.doc<TriggerTemplateInterface>(triggerTemplateId).delete()))
        );
    }

    public update(triggerId: string, dataToUpdate: Partial<TriggerTemplateInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return from(this.triggersTemplateCollection.doc<TriggerTemplateInterface>(triggerId).update(Object.assign({}, dataToUpdate)));
    }


}
