import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { RoleInterface } from '../interfaces/role.interface';
import { map, shareReplay } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { AuthService } from './auth.service';
import { RolePermissions } from 'src/app/shared/types/types';

const ROLES_COLLECTION = 'roles';

const TemporalNameMapper = {
  account: 'Gestor',
  admin: 'Admin',
  property: 'Propietario',
  reader: 'Lector',
  keyaccount: 'Superadmin'
};

@Injectable()
export class RoleService {
    private rolesCollection: AngularFirestoreCollection<RoleInterface>;
    private currentUserRoles: RoleInterface[];
    private allRoles: Observable<RoleInterface[]>;

    public constructor(private firestore: AngularFirestore, private readonly authService: AuthService) {
        this.rolesCollection = this.firestore.collection<RoleInterface>(ROLES_COLLECTION);
    }

    public getAll(): Observable<RoleInterface[]> {
      if(!this.allRoles) {
        this.allRoles = this.rolesCollection.snapshotChanges().pipe(
            map((actions: DocumentChangeAction<RoleInterface>[]): RoleInterface[] => {
                return actions.map(
                    (action: DocumentChangeAction<RoleInterface>): RoleInterface => {
                        const role = action.payload.doc.data();
                        if (role) role.id = action.payload.doc.id;
                        return role;
                    }
                );
            }),
            shareReplay(1)
        );
      }
      return this.allRoles;
    }

    public getAllAllowed(): Observable<RoleInterface[]> {
      return this.getAll()
         .pipe(map((roles: RoleInterface[]): RoleInterface[] => {

                if (this.authService.hasRole(CONSTANTS.ROLE_KEYACCOUNT)) {
                    return roles;
                } else if (this.authService.hasRole(CONSTANTS.ROLE_ACCOUNT)) {
                    return roles.filter((role: RoleInterface): boolean => role.name !== CONSTANTS.ROLE_KEYACCOUNT);
                } else if (this.authService.hasRole(CONSTANTS.ROLE_PROPERTY)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_ADMIN)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_READER)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT &&
                            role.name !== CONSTANTS.ROLE_ACCOUNT &&
                            role.name !== CONSTANTS.ROLE_PROPERTY &&
                            role.name !== CONSTANTS.ROLE_ADMIN
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_NAVARRA)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT &&
                            role.name !== CONSTANTS.ROLE_ACCOUNT &&
                            role.name !== CONSTANTS.ROLE_PROPERTY &&
                            role.name !== CONSTANTS.ROLE_ADMIN &&
                            role.name !== CONSTANTS.ROLE_READER &&
                            role.name !== CONSTANTS.ROLE_AXEL &&
                            role.name !== CONSTANTS.ROLE_SERRA
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_AXEL)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT &&
                            role.name !== CONSTANTS.ROLE_ACCOUNT &&
                            role.name !== CONSTANTS.ROLE_PROPERTY &&
                            role.name !== CONSTANTS.ROLE_ADMIN &&
                            role.name !== CONSTANTS.ROLE_READER &&
                            role.name !== CONSTANTS.ROLE_NAVARRA &&
                            role.name !== CONSTANTS.ROLE_SERRA
                    );
                } else if (this.authService.hasRole(CONSTANTS.ROLE_SERRA)) {
                    return roles.filter(
                        (role: RoleInterface): boolean =>
                            role.name !== CONSTANTS.ROLE_KEYACCOUNT &&
                            role.name !== CONSTANTS.ROLE_ACCOUNT &&
                            role.name !== CONSTANTS.ROLE_PROPERTY &&
                            role.name !== CONSTANTS.ROLE_ADMIN &&
                            role.name !== CONSTANTS.ROLE_READER &&
                            role.name !== CONSTANTS.ROLE_NAVARRA &&
                            role.name !== CONSTANTS.ROLE_AXEL
                    );
                }
            })
        );
    }

    public getRolesByNames(roleNames: string[]): Observable<RoleInterface[]> {
        return this.rolesCollection
            .snapshotChanges()
            .pipe(
                map((actions: DocumentChangeAction<RoleInterface>[]) => {
                    this.currentUserRoles = actions.map(action =>
                        action.payload.doc.data())
                        .filter(role => role && roleNames.includes(role.name));
                    return this.currentUserRoles;
                })
            );
    }

    filterRolesByPermissions(roles: RoleInterface[], permissions: string[]): RoleInterface[] {
      return roles.filter(role => !permissions.includes(role.name));
    }

    getRoleById(roleId: string) {
      return this.rolesCollection.doc<RoleInterface>(roleId).valueChanges();
    }

    updateRole(roleId: string, role: RoleInterface) {
      return this.rolesCollection.doc<RoleInterface>(roleId).update(role);
    }

    createRole(role: RoleInterface) {
      const roleId = this.firestore.createId();
      return this.rolesCollection.doc<RoleInterface>(roleId).set(role);
    }

    deleteRole(roleId: string) {
      return this.rolesCollection.doc(roleId).delete();
    }

    public mapRoleName(name: string): string {
      return TemporalNameMapper[name] || name ;
    }

    getRoles(): RoleInterface[] {
      return this.currentUserRoles;
    }

    getPermissions(): RolePermissions {
      return this.currentUserRoles.reduce((prev, curr) => {
        const permissionEntries = Object.entries(curr.permissions);
        permissionEntries.forEach(([key,value])=> {
          if(!value) delete curr.permissions[key];
        });
        return {...prev, ...curr.permissions};
      }, {});
    }

    hasPermission(permission: string): boolean {
      return !!this.currentUserRoles.some(role=> role?.permissions?.[permission]);
    }

    getActivePermissionsKeys(): string[] {
      return Object.entries(this.getPermissions())
      .filter(([, value]) => !!value)
      .map(([key]) => key);
    }
}
