<form [formGroup]="formGroup">
  <div class="row">
    <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Fecha de envío</mat-label>
        <input
            matInput
            formControlName="dateNow"
            [matDatepicker]="picker"
            [min]="minDate"
            placeholder="Fecha de envío"
            [required]="formGroup.get('messageType').value === 2"
            readonly
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Hora de envío</mat-label>
        <input
            matInput
            name="time"
            formControlName="time"
            placeholder="HH:MM (formato 24 horas)"
            aria-label="HH:MM (formato 24 horas)"
            [ngxTimepicker]="default"
            [format]="24"
            [required]="formGroup.get('messageType').value === 2"
            readonly>
        <ngx-material-timepicker #default></ngx-material-timepicker>
    </mat-form-field>
  </div>
</form>
