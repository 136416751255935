<div class="row mt-3">
  <div class="col p-0">
    <div class="card elevation">
      <h4 class="font-weight-bold pl-5 pt-4">Segmentos Asociados al Activador</h4>
      <app-spinner-wrapper [isLoading]="segmentsLoading" [ngClass]="{ 'spinner-center': segmentsLoading }">
        <!-- TODO: we have to check the spinner wrapper behaviour, it does not work like an ngIf and it should  -->
        <app-generic-table
          *ngIf="structureSegmentsColumns.length > 1"
          [thereIsData]="!!triggerSegmentsTable && !!triggerSegmentsTable.filteredData.length"
          [dataSource]="triggerSegmentsTable"
          [columns]="structureSegmentsColumns"
          (buttonClick)="onButtonStatisticsClicked($event.element)"
          tableStyle="c-statistics-triggerSegments-table"
        ></app-generic-table>
      </app-spinner-wrapper>
    </div>
  </div>
</div>
