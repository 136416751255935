import {
  PUSH_NOTIFICATION_STATISTIC_DEVICES,
  PUSH_NOTIFICATION_STATISTIC_STATUS,
} from 'src/app/components/push-notifications/web/scheduled/config/constants';

export interface StatisticsPushNotificationInterface {
  totalActivations: number;
  eventId: string;
  articleTitle: string;
  eventUrl: string;
  eventAction: PUSH_NOTIFICATION_STATISTIC_STATUS;
  mediaId: string;
  topic: string;
  eventDateTime: Date;
  uaDevice: PUSH_NOTIFICATION_STATISTIC_DEVICES;
  uaPlatform: string;
  uaOs: string;
  uaVersion: string;
  uaBrowser: string;
}

export const PUSH_NOTIFICATION_COLUMN_NAME = {
  totalActivations: 'Impresiones',
  eventId: 'Id de la notificación',
  articleTitle: 'Título',
  eventUrl: 'Noticia',
  eventAction: 'Acción',
  mediaId: 'Medio',
  topic: 'Tópico',
  eventDateTime: 'Fecha y Hora',
  eventDate: 'Fecha',
  eventTime: 'Hora',
  uaDevice: 'Dispositivo',
  uaPlatform: 'Sistema Operativo',
  uaOs: 'Sistema Operativo',
  uaVersion: 'Versión',
  uaBrowser: 'Navegador',
};

export const PUSH_NOTIFICATION_TOP_CONTENT_COLUMNS = [
  'topic',
  'eventDate',
  'eventTime',
];
export interface PushNotificationTopContentTableDataInterface {
  totalActivations: number;
  eventUrl: string;
  topic: string;
  eventDate: string;
  eventTime: string;
  [PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED]?: number;
  [PUSH_NOTIFICATION_STATISTIC_STATUS.OPENED]?: number;
  [PUSH_NOTIFICATION_STATISTIC_STATUS.CLOSED]?: number;
}
