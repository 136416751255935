import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
    OnInit,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { BooleanBasicOperator } from 'src/app/core/types/boolean-basic-operator';

type ItemType = {
    id: string;
    name: string;
    selected: boolean;
    type: number;
};

@Component({
    selector: 'app-dual-list',
    templateUrl: './dual-list.component.html',
    styleUrls: ['./dual-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DualListComponent implements OnInit {
    @Input() public initialListTitle = '';
    @Input() public finalListTitle = '';
    @Input() public initialData: ItemType[] = [];
    @Input() public finalData: ItemType[] = [];
    @Input() public showChips = false;
    @Input() public chipsTitles: string[] = [];
    @Input() public isInTestAB = false;
    @Input() public isRetailMedia = false;
    @Input() public requiresSegmentsOperator = false;
    @Input() public segmentsOperator: BooleanBasicOperator = 'or';
    @Output() public itemChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() public segmentsOperatorChange: EventEmitter<BooleanBasicOperator> = new EventEmitter<BooleanBasicOperator>();

    @ViewChild('filter') filterInput: ElementRef;

    public filteredData: ItemType[];

    public ngOnInit(): void {
        this.filteredData = this.initialData;
    }

    public get areInitialItemsSelected(): boolean {
        return this.initialData.some((data: ItemType): boolean => data.selected);
    }

    public get areFinalItemsSelected(): boolean {
        return this.finalData.some((data: ItemType): boolean => data.selected);
    }

    public toggleItem(item: ItemType): void {
        item.selected = !item.selected;
    }

    public addAll(): void {
        this.initialData.forEach((data: ItemType): void => {
            data.selected = true;
        });
        this.add();
    }

    public onChangeFilter(name: string): void {
        this.filteredData = this.initialData.filter((item: ItemType) =>
            item.name.toLowerCase().includes(name.toLowerCase())
        );
    }

    public changeSegmentsOperator(): void {
      this.segmentsOperatorChange.emit(this.segmentsOperator);
    }

    public add(): void {
        const itemsToMove = this.initialData.filter((item: ItemType): boolean => item.selected);
        for (const itemToMove of itemsToMove) {
            itemToMove.selected = false;
            const indexToMove = this.initialData.findIndex((data: ItemType): boolean => data.id === itemToMove.id);
            const movedItem = this.initialData.splice(indexToMove, 1);
            this.finalData.push(...movedItem);
        }
        const values = this.finalData.map((data: ItemType): string => data.id);
        this.filterInput.nativeElement.value = '';
        this.filteredData = this.initialData;
        this.itemChange.emit(values);
    }

    public deleteAll(): void {
        this.finalData.forEach((data: ItemType): void => {
            data.selected = true;
        });
        this.delete();
    }

    public delete(): void {
        const itemsToMove = this.finalData.filter((item: ItemType): boolean => item.selected);
        for (const itemToMove of itemsToMove) {
            itemToMove.selected = false;
            const indexToMove = this.finalData.findIndex((data: ItemType): boolean => data.id === itemToMove.id);
            const movedItem = this.finalData.splice(indexToMove, 1);
            this.initialData.push(...movedItem);
        }
        const values = this.finalData.map((data: ItemType): string => data.id);
        this.filterInput.nativeElement.value = '';
        this.filteredData = this.initialData;
        this.itemChange.emit(values);
    }
}
