<div class="row mt-3">
  <div class="col p-0">
    <div class="card elevation">
      <form [formGroup]="articlesFiltersForm" class="d-flex align-items-center px-5 pt-4">
        <h4 class="font-weight-bold flex-grow-1">Artículos con más activaciones</h4>

        <mat-form-field
          *ngIf="metricsArticles"
          class="c-mat-form c-mat-form--no-padding mr-4 flex-grow-1"
          appearance="outline"
        >
          <mat-label>Título del Artículo</mat-label>
          <input
              type="text"
              placeholder="Seleccione un título"
              matInput
              formControlName="articleTitle"
              [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
              <mat-option> Ninguno </mat-option>
              <mat-option *ngFor="let articleTitle of getNotNullArticlesTitles()" [value]="articleTitle">
                  {{ articleTitle }}
              </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          *ngIf="metricsArticles"
          class="c-mat-form c-mat-form--no-padding mr-4"
          appearance="outline"
        >
          <mat-label>Categoría del Artículo</mat-label>
          <input
              type="text"
              placeholder="Seleccione una categoría"
              matInput
              formControlName="category"
              [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
              <mat-option> Ninguno </mat-option>
              <mat-option *ngFor="let category of getUniqueCategories()" [value]="category">
                {{ category }}
              </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <button
            *ngIf="metricsArticles"
            class="align-self-stretch my-1"
            style="font-size : 16px;"
            type="button"
            color="primary"
            title="Descargar Datos"
            (click)="exportArticlesMetricsAsCSV()"
            mat-raised-button
        >
          Descargar Datos
        </button>
      </form>

      <span class="px-5 pt-3" style="font-weight: 500;">
        En esta tabla aparecen los 100 artículos con más impresiones. Haz clic en el botón descargar para
        obtener la información completa. Filtra por título o categoría para personalizar la descarga.
      </span>

      <app-spinner-wrapper [isLoading]="articlesLoading" [ngClass]="{ 'spinner-center': articlesLoading }">
        <app-generic-table
          [thereIsData]="!!metricsArticlesTable && !!metricsArticlesTable.filteredData.length"
          [dataSource]="metricsArticlesTable"
          [columns]="structureArticlesColumns"
          tableStyle="c-statistics-triggerArticles-table"
        ></app-generic-table>
      </app-spinner-wrapper>
    </div>
  </div>
</div>
