export enum PUSH_NOTIFICATION_WHERE_CLAUSE {
  MEDIA_ID = 'mediaId',
  SCHEDULE_SECONDS = 'schedule.Seconds',
  TOPIC = 'firebasePayload.message.topic',
  TITLE = 'firebasePayload.message.title',
}

export const PUSH_NOTIFICATIONS_COLLECTIONS = [
  {
    collection: 'send-notifications',
    tag: 'Enviadas',
  },
  {
    collection: 'scheduled-notifications',
    tag: 'Programadas',
  },
];

export enum PUSH_NOTIFICATION_GET_LIST {
  SENT = 'sent',
  SCHEDULED = 'scheduled',
}
export enum PUSH_NOTIFICATION_COLLECTIONS {
  SENT = 'send-notifications',
  SCHEDULED= 'scheduled-notifications'
}

export enum PUSH_NOTIFICATION_SEND_TYPES {
  IMMEDIATE = 'Inmediato',
  SCHEDULED = 'Planificado'
}

export enum PUSH_NOTIFICATION_TYPE {
  DYNAMIC = 'Dinámico',
  MANUAL = 'Manual'
}

export enum PUSH_NOTIFICATION_STATUS {
  SENT = 'Enviado',
  SCHEDULED = 'Programado'
}

export enum PUSH_NOTIFICATION_STATISTIC_STATUS {
  ALL = 'all',
  RECEIVED = 'received',
  OPENED = 'open',
  CLOSED = 'closed',
}

export const PUSH_NOTIFICATION_STATISTIC_STATUS_TITLE: Record<PUSH_NOTIFICATION_STATISTIC_STATUS, string> = {
  [PUSH_NOTIFICATION_STATISTIC_STATUS.ALL]: 'Total Eventos',
  [PUSH_NOTIFICATION_STATISTIC_STATUS.RECEIVED]: 'Recibidas',
  [PUSH_NOTIFICATION_STATISTIC_STATUS.OPENED]: 'Abiertas',
  [PUSH_NOTIFICATION_STATISTIC_STATUS.CLOSED]: 'Cerradas',
};

export enum PUSH_NOTIFICATION_STATISTIC_DEVICES {
  ALL = 'Total',
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  TABLET = 'Tablet',
  OTHERS = 'Others',
}

export const PUSH_NOTIFICATION_STATISTIC_DEVICE_ICONS: Record<PUSH_NOTIFICATION_STATISTIC_DEVICES, string> = {
  [PUSH_NOTIFICATION_STATISTIC_DEVICES.ALL]: '',
  [PUSH_NOTIFICATION_STATISTIC_DEVICES.DESKTOP]: 'fa-desktop',
  [PUSH_NOTIFICATION_STATISTIC_DEVICES.MOBILE]: 'fa-mobile-alt',
  [PUSH_NOTIFICATION_STATISTIC_DEVICES.TABLET]: 'fa-tablet-alt',
  [PUSH_NOTIFICATION_STATISTIC_DEVICES.OTHERS]: 'fa-ellipsis-h',
};

export const PUSH_NOTIFICATION_CONSTANTS = {
  params: {
    sentIndexTab: 0,
    scheduledIndexTab: 1,
    sentFilterParam: 'sent',
    scheduledFilterParam: 'scheduled',
    color: 'orange',
    pageOptions: [10, 15],
    pageSize: 10,
    placeholder: 'Ingrese texto',
    downloadButtonText: 'Descargar Listado de Notificaciones',
    columns: ['id', 'title', 'topic', 'date', 'info', 'actions'],
    inputSearch: 'Titulo',
  },
  notify: {
    successNotifyTitle: 'Acción realizada',
    errorNotifyTitle: 'Error',
    successNotifyText: 'Se ha realizado la acción con éxito',
    errorNotifyText: 'Ha ocurrido un error',
    questionConfirmText: '¿Estás seguro?',
  },
  filter: {
    wordQueryBetween: 'between',
    equal: 'equal',
    searchTitle: 'firebasePayload.message.title',
    searchTopic: 'payload.message.topic',
    searchTopicSecondChance: 'payload.message.topic',
    searchRange: 'schedule.Seconds',
  },
  operations: {
    detailValue: { id: 0, name: 'Ver Notificación', key : 'SEND_PUSH_READ' },
    updateValue: { id: 1, name: 'Editar', key: 'SEND_PUSH_UPDATE' },
    deleteValue: { id: 2, name: 'Eliminar', key: 'SEND_PUSH_DELETE' },
  },
};
