import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { DatasourceInterface } from 'src/app/core/interfaces/datasource.interface';
import { ConstantsUnlayer } from '../../utils/constants-unlayer';

declare var unlayer;

@Component({
    selector: 'app-html-template-editor',
    templateUrl: './html-template-editor.component.html',
    styleUrls: ['./html-template-editor.component.scss'],
})
export class TemplateEditorComponent implements OnInit, AfterContentInit {
    @Input()
    datasources;
    @Input()
    config;
    @Input()
    design;

    constructor() {}

    ngOnInit() {
        const actualConfig = this.config;
        if (this.datasources) {
            const allDatasources: { value: string; label: string }[] = this.datasources.map(this.getOptions.bind(this));
            actualConfig.tools['custom#datasources'].data.default = allDatasources[0].value;
            actualConfig.tools['custom#datasources'].properties.datasources.editor.data.options = allDatasources;
        }
        const unlayerConfig = {
          ...actualConfig,
          features: {
            textEditor: {
              inlineFontControls: true
            }
          }
        };
        unlayer.init(unlayerConfig);
        if (this.design) {
            unlayer.loadDesign(this.design);
        }
        unlayer.setLinkTypes(ConstantsUnlayer.linkTypesTriggers);

    }

    ngAfterContentInit() {}

    getIdForSelectOption(datasource): string {
        return datasource.articles
            ? `${datasource.id}:::${datasource.articles[0].url}:::${datasource.articles[0].title}:::${datasource.articles[0].imageUrl}`
            : `${datasource.id}:::${datasource.datasources[0].articles[0].url}:::${datasource.datasources[0].articles[0].title}:::${datasource.datasources[0].articles[0].imageUrl}`;
    }

    getOptions(datasource: DatasourceInterface): { value: string; label: string } {
        return {
            value: this.getIdForSelectOption(datasource),
            label: datasource.name,
        };
    }
}
