<app-spinner-wrapper [isLoading]='processing' [diameter]='50'>
  <div class='text-center mt-5'>
    <button
      (click)='cancel()'
      *ngIf='cancelText'
      class='col-1 mx-2'
      color='accent'
      mat-raised-button>
      {{ cancelText }}
    </button>
    <button
      (click)='save()'
      [disabled]='saveDisabled'
      class='col-1 mx-2'
      color='primary'
      mat-raised-button>
      {{ saveText }}
    </button>
  </div>
</app-spinner-wrapper>
