import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum FINISH_BUTTONS_ACTION {
  CANCEL,
  SAVE,
}

@Component({
  selector: 'app-finish-buttons',
  templateUrl: './finish-buttons.component.html',
})
export class FinishButtonsComponent {
  @Input() cancelText?: string;
  @Input() saveText = 'Guardar';
  @Input() saveDisabled = false;
  @Input() processing = false;

  @Output() action = new EventEmitter<FINISH_BUTTONS_ACTION>();

  cancel(): void {
    this.action.emit(FINISH_BUTTONS_ACTION.CANCEL);
  }

  save(): void {
    this.action.emit(FINISH_BUTTONS_ACTION.SAVE);
  }
}
