import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs";
import { TeamInterface } from "../interfaces/team.interface";

const TEAMS_COLLECTION = 'teams';

@Injectable()
export class RetailMediaService {
    public teamsCollection: AngularFirestoreCollection<TeamInterface>;
    public constructor(
        private readonly httpClient: HttpClient,
        private readonly firestore: AngularFirestore
    ) {
        this.teamsCollection = this.firestore.collection<TeamInterface>(TEAMS_COLLECTION);
    }

    public getAll(): Observable<TeamInterface[]> {
        return this.firestore
        .collection<TeamInterface>(
            TEAMS_COLLECTION,
            (ref: CollectionReference): Query => ref.where('active', '==', true)
        )
        .valueChanges();
    }

}
