import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './shared/global-error-handler/global-error-handler.module';
import { DatasourcesTypeToStringPipe } from 'src/app/shared/pipes/datasources-type-to-string.pipe';
import { TriggersTypeToStringPipe } from 'src/app/shared/pipes/triggers-type-to-string.pipe';
import { register } from 'swiper/element/bundle';

register(); // Init Swiper

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    DatasourcesTypeToStringPipe,
    TriggersTypeToStringPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
