import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import PushNotificationScheduledInterface from '../interfaces/push-notification/push-notification-scheduled.interface';
import { PaginationHandler } from 'src/app/shared/paginator/paginator-handler.class';
import { HttpClient } from '@angular/common/http';
import { PaginatorStringQuery } from 'src/app/components/push-notifications/web/scheduled/classes/paginator-string-query';
import { environment } from 'src/environments/environment';
import { ResultPaginated } from 'src/app/components/push-notifications/web/scheduled/interfaces/paginator.interface';

const PUSH_NOTIFICATION_SCHEDULED_COLLECTION = 'scheduled-notifications';
const PUSH_NOTIFICATION_SEND_COLLECTION = 'send-notifications';

/**
 * TODOs:
 * - pasar a tabla genérica
 * - crear un servicio para delegar ciertas operaciones.
 */
@Injectable()
export class AllNotificationsService {
  public scheduledStored: Array<string> = [];
  public sendedStored: Array<string> = [];
  public paginator: PaginationHandler;
  private pushNotificationScheduledCollection: AngularFirestoreCollection<PushNotificationScheduledInterface>;
  private pushNotificationSendCollection: AngularFirestoreCollection<PushNotificationScheduledInterface>;
  constructor(private readonly firestore: AngularFirestore, private httpClient: HttpClient) {
    this.pushNotificationScheduledCollection = this.firestore.collection<PushNotificationScheduledInterface>(
      PUSH_NOTIFICATION_SCHEDULED_COLLECTION
    );
    this.pushNotificationSendCollection = this.firestore.collection<PushNotificationScheduledInterface>(
      PUSH_NOTIFICATION_SEND_COLLECTION
    );
  }

  getAllNotifications(data: PaginatorStringQuery): Observable<ResultPaginated | any> {
    return this.httpClient.get<ResultPaginated | any>(`${environment.endpoints.pushNotifications}/list`, {
      params: {
        ...data,
      },
    });
  }

  public getById(id: string, type: string): Observable<PushNotificationScheduledInterface> {
    if (type == 'scheduled') {
      return this.pushNotificationScheduledCollection.doc<PushNotificationScheduledInterface>(id).valueChanges();
    } else if (type == 'send') {
      return this.pushNotificationSendCollection.doc<PushNotificationScheduledInterface>(id).valueChanges();
    }
  }
}
