import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TriggerMetricsArticles } from 'src/app/core/interfaces/statistics-triggers-article.interface';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { TableColumn } from 'src/app/shared/components/generic-table/column.interface';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MetricService } from 'src/app/core/services/metric.service';
import { DecimalPipe } from '@angular/common';
import { ConstantsStatistics } from '../../utils/constants-statistics';

@Component({
  selector: 'app-statistics-articles',
  templateUrl: './statistics-articles.component.html',
  styleUrls: ['./statistics-articles.component.scss']
})
export class StatisticsArticlesComponent implements OnChanges, OnDestroy {
  @Input() triggerId: string;
  @Input() dates: { begin: Date, end: Date };
  @Input() currentSiteId: string;

  public metricsArticlesTable: MatTableDataSource<TriggerMetricsArticles> = new MatTableDataSource<TriggerMetricsArticles>([]);
  public uniqueCategories: string[];
  public acc = 0;
  public metricsArticles: TriggerMetricsArticles[];
  private unsubscribe: Subject<void> = new Subject<void>();
  public articlesLoading: boolean = true;
  public articlesFiltersForm: FormGroup;
  public structureArticlesColumns: TableColumn<TriggerMetricsArticles>[] = [
    {
      Title: 'Noticia',
      columnDef: 'articleTitle',
      type: 'url',
      Value: (article: TriggerMetricsArticles): string => article.articleTitle,
      ValueUrl: (article: TriggerMetricsArticles): string => article.articleUrl,
      isSortable: true,
      isBlank: true
    },
    {
      Title: 'Categoría',
      columnDef: 'category',
      type: 'text',
      Value: (article: TriggerMetricsArticles): string => article.category1 || article.category2 || '',
      isSortable: true,
    },
    {
      Title: 'Impresiones',
      columnDef: 'shows',
      type: 'text',
      Value: (article: TriggerMetricsArticles) => article.impressions,
      isSortable: true,
    },
    {
      Title: 'Acción',
      columnDef: 'action',
      type: 'text',
      Value: (article: TriggerMetricsArticles) => article.activate,
      isSortable: true,
    },
    {
      Title: 'Cierre',
      columnDef: 'close',
      type: 'text',
      Value: (article: TriggerMetricsArticles) => article.closed,
      isSortable: true,
    },
    {
      Title: 'Acción 1',
      columnDef: 'action1',
      type: 'text',
      Value: (article: TriggerMetricsArticles) => article.activate1,
      isSortable: true,
    },
    {
      Title: 'Acción 2',
      columnDef: 'action2',
      type: 'text',
      Value: (article: TriggerMetricsArticles) => article.activate2,
      isSortable: true,
    }
  ];

  constructor(
    private readonly excelService: ExcelService,
    private readonly formBuilder: FormBuilder,
    private readonly metricService: MetricService,
    private readonly decimalPipe: DecimalPipe
  ) { }

  ngOnChanges(): void {
    this.articlesLoading = true;
    this.unsubscribe.next();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private createFiltersForm(): void {
    this.articlesFiltersForm = this.formBuilder.group({
      articleTitle: [''],
      category: [''],
    });
  }

  private subscribeToFilters(): void {
    this.articlesFiltersForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((): void => this.filter());
  }

  public filter(): void {
    const articleTitle = this.articlesFiltersForm.value.articleTitle;
    const category = this.articlesFiltersForm.value.category;

    this.metricsArticlesTable.filter = JSON.stringify({
        articleTitle,
        category
    });
}

  public getCategory(article: any): string {
    return article.category1 || article.category2 || "";
  }

  private adaptTriggerArticlesMetricsToCSV(): any[] {
    const metricsDTO: {
        Title: string;
        URL: string;
        Categoria1: string;
        Categoria2: string;
        Show: string;
        Close: string;
        Action: string;
        Action1: string;
        Action2: string;
    }[] = this.metricsArticlesTable.filteredData.map(article => ({
        Title: article.articleTitle,
        URL: article.articleUrl,
        Categoria1: article.category1 || '',
        Categoria2: article.category2 || '',
        Show: this.decimalPipe.transform(article.impressions, '', ConstantsStatistics.decimalPipeLocale),
        Close: this.decimalPipe.transform(article.closed, '', ConstantsStatistics.decimalPipeLocale),
        Action: this.decimalPipe.transform(article.activate, '', ConstantsStatistics.decimalPipeLocale),
        Action1: this.decimalPipe.transform(article.activate1, '', ConstantsStatistics.decimalPipeLocale),
        Action2: this.decimalPipe.transform(article.activate2, '', ConstantsStatistics.decimalPipeLocale),
      })
    );

    return metricsDTO.sort((a, b) => Number(b.Show.replace(/\./g, '')) - Number(a.Show.replace(/\./g, '')));
  }

  public exportArticlesMetricsAsCSV(): void {
    const metrics = this.adaptTriggerArticlesMetricsToCSV();
    const fileName = 'trigger-articles-metrics-' + new Date().toLocaleDateString() + '.xlsx';
    this.excelService.exportAsExcelFile(metrics, fileName);
  }

  public async loadData() {
    this.createFiltersForm();
    this.subscribeToFilters();
    this.metricService.getArticlesTriggerStats(this.triggerId, this.currentSiteId, this.dates.begin, this.dates.end).pipe(
      takeUntil(this.unsubscribe)).subscribe((triggerMetricsArticles: TriggerMetricsArticles[]): void => {
        this.metricsArticles = triggerMetricsArticles;
        this.metricsArticlesTable = new MatTableDataSource(this.metricsArticles);
        this.metricsArticlesTable.filterPredicate = (data: TriggerMetricsArticles, filter: string): boolean => {
          const parsedFilters = JSON.parse(filter);
          return Object.keys(parsedFilters).every(column => {
              const filterValue = parsedFilters[column];
              if (column === 'category') {
                column = data['category1'] ? 'category1' : 'category2';
              }
              const fieldValue = data[column];
              switch (column) {
                case 'articleTitle':
                  return !filterValue || fieldValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                case 'category1':
                  return !filterValue || fieldValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                case 'category2':
                  return !filterValue || fieldValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                default:
                  return false;
              }
          });
        };

        this.metricsArticlesTable.sortingDataAccessor = (item: TriggerMetricsArticles, property: string): string | number => {
          switch (property) {
              case 'articleTitle':
                if (item.articleTitle && item.articleTitle.length) return item.articleTitle.toLowerCase();
                return '';
              case 'category':
                if (item.category2) return item.category2.toLowerCase();
                else if (item.category1) return item.category1.toLowerCase();
                return "";
              case 'shows':
                return item.impressions;
              default:
                return item[property];
          }
      };
      this.articlesLoading = false;
    });
  }

  public getUniqueCategories(): string[] {
    return this.metricsArticlesTable.filteredData.map(art => this.getCategory(art)).filter((value, index, self) => self.indexOf(value) === index);
  }

  public getNotNullArticlesTitles(): string[] {
    return this.metricsArticlesTable.filteredData.map(art => art.articleTitle).filter(articleTitle => articleTitle && articleTitle.length);
  }
}
