import { Injectable } from '@angular/core';
import { Event } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class RouteService {
    private currentRouteSubject: Subject<Event> = new ReplaySubject<Event>();
    private currentRoute$: Observable<Event>;

    public constructor() {
        this.currentRoute$ = this.currentRouteSubject.asObservable();
    }

    public getCurrentRoute(): Observable<Event> {
        return this.currentRoute$;
    }

    public changeRoute(route: Event): void {
        this.currentRouteSubject.next(route);
    }
}
