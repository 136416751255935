import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-input-validation-icon',
    templateUrl: './input-validation-icon.component.html',
    styleUrls: ['./input-validation-icon.component.scss'],
})
export class InputValidationIconComponent {
    @Input() control: AbstractControl;

    get icon(): string {
        if (!this.control) return;
        if (this.control.valid || this.control.disabled) return 'check_circle';
        if (this.control.pending) return 'help';
        if (this.control.invalid) return 'cancel';
    }

    get validationClass(): string {
        if (!this.control) return;
        if (this.control.valid || this.control.disabled) return 'validation-ok';
        if (this.control.pending) return 'validation-pending';
        if (this.control.invalid) return 'validation-ko';
    }
}
