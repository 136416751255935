import { PipeTransform, Pipe } from '@angular/core';
import { CONSTANTS } from '../utils/constants';

@Pipe({ name: 'schedulesEndpointTypeToStringPipe' })
export class SchedulesEndpointTypeToStringPipe implements PipeTransform {
    public transform(value: number): string {
        switch (value) {
            case CONSTANTS.PROMETEO_SCHEDULES_ENDPOINT_MOSTREAD_TYPE:
                return CONSTANTS.PROMETEO_SCHEDULES_ENDPOINT_MOSTREAD_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_SCHEDULES_ENDPOINT_MOSTRECENT_TYPE:
                return CONSTANTS.PROMETEO_SCHEDULES_ENDPOINT_MOSTRECENT_TYPE_LITERAL;
            default:
                return '';
        }
    }
}
