export class ConstantsSegments {
    public static SegmentsTypes = {
        dynamicType: { id: 'd', value: 'dynamic' },
        staticType: { id: 's', value: 'static' },
        allType: { id: 'all', value: 'all' },
        allTypes: {id: 'allTypes', value: 'allTypes'}
    };
    public static SegmentsTypesText = {
        'd': "Dinámico",
        's': "Estático",
    };
    public static UsersTypes = {
        userType: { id: 'u', value: 'userId' },
        suType: { id: 's', value: 'su' },
        clientType: { id: 'c', value: 'clientId' },
        allType: { id: 'all', value: 'all' },
    };
    public static UsersTypesText = {
        "userId": "Id Usuario",
        "su": "Super Usuario",
        "clientId": "Id Cliente",
        "anonymousAlayansID":"Alayans ID Anónimo",
        "loggedAlayansID":"Alayans ID logado"
    };

    public static readonly SEGMENTS_TYPES_NAMES = {
        historic: 'historico',
        realTime: 'tiempo real',
        imported: 'importado',
    };

    public static readonly UNKNOW_SEGMENT_TEXT = 'Segmento desconocido';

    public static KeyEncrypt = 'hg8+7Z+r-CW46N_z';
    public static AvailableComponentsType = {
        triggerType: 'trigger',
        testABType: 'test-ab',
        widgetType: 'widget',
        pushSubscriptionType: 'push subscription',
        newsletterSubscriptionType: 'newsletter subscription',
        historicalOperationType: 'operation segment',
        testABWidgetsType: 'test-ab-widgets',
        datasourceType: 'datasource',
        segmentedDatasourceType: 'segmented datasource'
    };

    public static readonly SEGMENT_INFO_TEXT = {
        alertUsers: "Datos de usuario teniendo en cuenta páginas vistas y eventos del último día",
        alertPageViews:"Datos solo teniendo en cuenta páginas vistas del último día",
        alertUsersEvent: "Datos de usuario teniendo en cuenta solo eventos del último día",
        alertServiceUnavailable: "No se permite la creación ni edición de segmentos por corte de servicio en la base de datos",
        alertData: "Los datos mostrados en las gráficas corresponden con la ejecución de las dimensiones seleccionadas teniendo en cuenta solo el último día.",
        alertGetDataError: 'Error al traer los datos'
    };
}
