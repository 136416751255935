import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router } from '@angular/router';
import { of, Subscription, Observable } from 'rxjs';
import { map, take, tap, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { RoleService } from '../services/role.service';
import { UserInterface } from '../interfaces/user.interface';
import { RoleInterface } from '../interfaces/role.interface';
import { AlertService } from '../../shared/services/alert.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad, OnDestroy {
    public actionsSubscription: Subscription; // TODO revisar, parece que no se usa

    public constructor(
        private readonly location: Location,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly roleService: RoleService,
        private readonly alertService: AlertService,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): boolean {
      const user = this.authService.userDetails;
      if(!!user && user.emailVerified && user.active && !!user.medias) {
        const permission = route.data?.permission;
        if (permission) return this.verifyAccessPermission(permission);
        return true;
      } else {
        this.banUser();
        return false;
      }
    }

    public canLoad(): Observable<boolean> {
      return this.authService.user$.pipe(
          take(1),
          switchMap((user: UserInterface) => {
            if(!!user && user.emailVerified && user.active && !!user.medias) {
              return this.roleService.getRolesByNames(user.roles);
            } else {
              return of(false);
            }
          }),
          map((roles: RoleInterface[]): boolean => {
            return !!roles && roles.some(role => !!role.permissions);
          }),
          tap((userIsValid: boolean): void => {
              if (!userIsValid) {
                  this.banUser();
              }
          })
      );
  }

    private verifyAccessPermission(permission: string): boolean {
      if (!this.roleService.getPermissions()[permission]) {
        this.alertService.error({
          title: 'Access denied',
          message: 'You cannot access this page',
        });
        this.redirectHome();
        return false;
      }
      return true;
    }

    private redirectHome(): void {
        if (this.router.url !== '/login') {
            this.router.navigate(['/medias']);
            this.location.go('/medias');
        }
    }

    private banUser(): void {
        if (this.router.url !== '/login') {
            this.router.navigate(['/login']);
            this.location.go('/login');
        }
    }

    public ngOnDestroy(): void {
        if (this.actionsSubscription) {
            this.actionsSubscription.unsubscribe();
        }
    }
}
