import { PipeTransform, Pipe } from '@angular/core';
import ConstantsDatasources from '../utils/constants-datasources';
import { CONSTANTS } from '../utils/constants';

@Pipe({ name: 'datasourcesTypeToStringPipe' })
export class DatasourcesTypeToStringPipe implements PipeTransform {
    public transform(value: number): string {
        switch (value) {
            case ConstantsDatasources.PROMETEO_DATASOURCES_JSON_AUTO_TYPE:
                return ConstantsDatasources.PROMETEO_DATASOURCES_JSON_AUTO_TYPE_LITERAL;
            case ConstantsDatasources.PROMETEO_DATASOURCES_MANUAL_TYPE:
                return ConstantsDatasources.PROMETEO_DATASOURCES_MANUAL_TYPE_LITERAL;
            case ConstantsDatasources.PROMETEO_DATASOURCES_HTML_TYPE:
                return ConstantsDatasources.PROMETEO_DATASOURCES_HTML_TYPE_LITERAL;
            case ConstantsDatasources.PROMETEO_DATASOURCES_MACHINE_LEARNING_ARTICLE_TYPE:
                return ConstantsDatasources.PROMETEO_DATASOURCES_MACHINE_LEARNING_ARTICLE_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_SEGMENTED_DATASOURCE_ENTITY_TYPE:
                return ConstantsDatasources.PROMETEO_DATASOURCES_SEGMENTED_TYPE_LITERAL;
            case ConstantsDatasources.PROMETEO_DATASOURCES_OURBRAIN_TYPE:
                return ConstantsDatasources.PROMETEO_DATASOURCES_OURBRAIN_TYPE_LITERAL;
            case ConstantsDatasources.PROMETEO_DATASOURCES_NATIVE_TYPE:
                return ConstantsDatasources.PROMETEO_DATASOURCES_NATIVE_TYPE_LITERAL;
            default:
                return '';
        }
    }
}
