import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { RouteService } from './core/services/route.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    constructor(
        private readonly router: Router,
        private readonly routeService: RouteService
    ) {}

    public ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.router.events
            .pipe(
                tap((event: Event): void => this.routeService.changeRoute(event)),
                filter((event: Event): boolean => event instanceof NavigationEnd)
            )
            .subscribe(() => {
            });
    }
}
