<form [formGroup]="formGroup">
  <div class="row mt-3">
    <mat-slide-toggle
        class="mt-4 col-md-2"
        formControlName="draft"
        color="primary"
        [checked]="formGroup.get('draft').value">
        Borrador
    </mat-slide-toggle>
    <mat-form-field class="col-md-5" appearance="outline">
        <mat-label>Tipo de envío</mat-label>
        <mat-select name="messageType" formControlName="messageType">
            <mat-option *ngFor="let messageType of messageTypes" [value]="messageType">
                {{ messageType | schedulesScheduleTypeToStringPipe }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-5" appearance="outline">
        <mat-label>Tipo de mensaje</mat-label>
        <mat-select name="contentType" formControlName="contentType">
            <mat-option
                *ngFor="let contentType of contentTypes"
                [value]="contentType"
            >
                {{ contentType | schedulesContentTypeToStringPipe }}
            </mat-option>
        </mat-select>
    </mat-form-field>
  </div>
</form>
