<div class='row'>
  <div class='col-md-12'>
    <mat-accordion>
      <mat-expansion-panel #expansionPanel [disabled]='data.length < 1' [expanded]='expanded'>
        <mat-expansion-panel-header>
          <mat-panel-title>Previsualización</mat-panel-title>
        </mat-expansion-panel-header>

        <swiper-container *ngIf='data?.length > 0; else noData' [config]='config' appSwiper class='pb-5 px-5'>
          <swiper-slide *ngFor='let article of data; let i = index; trackBy: trackById'>
            <div class='pb-5'>
              <app-article [article]='article'></app-article>
            </div>
          </swiper-slide>
        </swiper-container>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<ng-template #noData>No hay datos para cargar</ng-template>
