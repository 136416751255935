import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProgramNewslettersSubscriptionInterface } from '../interfaces/program-newsletters-subscription.interface';

const PROGRAM_NEWSLETTER_SUBSCRIPTION_COLLECTION = 'newsletters-subscription-program';

@Injectable()
export class ProgramNewslettersSubscriptionService {
    private programNewsletterSubscriptionCollection: AngularFirestoreCollection<ProgramNewslettersSubscriptionInterface>;
    programNewsletterSubscriptionTotal = 0;

    public constructor(
        private readonly firestore: AngularFirestore,
    ) {
        this.programNewsletterSubscriptionCollection = this.firestore.collection<ProgramNewslettersSubscriptionInterface>(
          PROGRAM_NEWSLETTER_SUBSCRIPTION_COLLECTION
        );
    }

    public getAll(): Observable<ProgramNewslettersSubscriptionInterface[]> {
        return this.programNewsletterSubscriptionCollection.valueChanges();
    }

    public getBySite(siteId: string): Observable<ProgramNewslettersSubscriptionInterface[]> {
        return this.firestore
            .collection<ProgramNewslettersSubscriptionInterface>(
              PROGRAM_NEWSLETTER_SUBSCRIPTION_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges()
            .pipe(
                tap(list => {
                    this.programNewsletterSubscriptionTotal = list.length;
                })
            );
    }

    public getActiveBySite(siteId: string): Observable<ProgramNewslettersSubscriptionInterface[]> {
        return this.firestore
            .collection<ProgramNewslettersSubscriptionInterface>(
              PROGRAM_NEWSLETTER_SUBSCRIPTION_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', true)
            )
            .valueChanges();
    }

    public get(programNewslettersSubscriptionId: string): Observable<ProgramNewslettersSubscriptionInterface> {
        return this.programNewsletterSubscriptionCollection.doc<ProgramNewslettersSubscriptionInterface>(programNewslettersSubscriptionId).valueChanges();
    }

    public add(programNewslettersSubscription: ProgramNewslettersSubscriptionInterface): Observable<void> {
        return from(
            this.programNewsletterSubscriptionCollection
                .doc<ProgramNewslettersSubscriptionInterface>(programNewslettersSubscription.id)
                .set(Object.assign({}, programNewslettersSubscription))
        );
    }

    public delete(programNewslettersSubscriptionId: string): Observable<void> {
        return from(this.programNewsletterSubscriptionCollection.doc<ProgramNewslettersSubscriptionInterface>(programNewslettersSubscriptionId).delete());
    }

    public update(programNewslettersSubscriptionId: string, dataToUpdate: Partial<ProgramNewslettersSubscriptionInterface>): Observable<void> {
        return from(
            this.programNewsletterSubscriptionCollection
                .doc<ProgramNewslettersSubscriptionInterface>(programNewslettersSubscriptionId)
                .update(Object.assign({}, dataToUpdate))
        );
    }
}
