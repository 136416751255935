import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NewslettersSendingInterface } from '../interfaces/newsletters-sending.interface';
import { AuthService } from './auth.service';

const NEWSLETTERS_SENDINGS_COLLECTION = 'newsletters-sendings';

@Injectable()
export class NewslettersSendingService {
    private newslettersSendingsCollection: AngularFirestoreCollection<NewslettersSendingInterface>;

    public constructor(private readonly firestore: AngularFirestore, private readonly authService: AuthService) {
        this.newslettersSendingsCollection = this.firestore.collection<NewslettersSendingInterface>(
            NEWSLETTERS_SENDINGS_COLLECTION
        );
    }

    public getAll(): Observable<NewslettersSendingInterface[]> {
        return this.newslettersSendingsCollection.valueChanges();
    }

    public getBySite(siteId: string): Observable<NewslettersSendingInterface[]> {
        return this.firestore
            .collection<NewslettersSendingInterface>(
                NEWSLETTERS_SENDINGS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges();
    }

    public get(newslettersSendingId: string): Observable<NewslettersSendingInterface> {
        return this.newslettersSendingsCollection.doc<NewslettersSendingInterface>(newslettersSendingId).valueChanges();
    }

    public add(newslettersSending: NewslettersSendingInterface): Observable<void> {
        const newslettersSendingId = this.firestore.createId();
        newslettersSending.id = newslettersSendingId;
        newslettersSending.createdAt = firebase.firestore.Timestamp.now();
        newslettersSending.updatedAt = firebase.firestore.Timestamp.now();
        newslettersSending.updatedBy = this.authService.getEmail();
        console.log(newslettersSending);
        return from(
            this.newslettersSendingsCollection
                .doc<NewslettersSendingInterface>(newslettersSendingId)
                .set(Object.assign({}, newslettersSending))
        );
    }

    public update(newslettersSendingId: string, dataToUpdate: Partial<NewslettersSendingInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return from(
            this.newslettersSendingsCollection
                .doc<NewslettersSendingInterface>(newslettersSendingId)
                .update(Object.assign({}, dataToUpdate))
        );
    }

    public delete(newslettersSendingId: string): Observable<void> {
        return this.update(newslettersSendingId, { deletedBy: this.authService.getEmail() }).pipe(
            switchMap(
                (): Observable<void> =>
                    from(
                        this.newslettersSendingsCollection
                            .doc<NewslettersSendingInterface>(newslettersSendingId)
                            .delete()
                    )
            )
        );
    }
}
