import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewslettersTopicInterface } from '../interfaces/newsletters-topic.interface';
import { TopicSubscribersInterface } from '../interfaces/topic-subscribers.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NewslettersAudienceInterface } from '../interfaces/newsletters-audience.interface';
import NLTOPICENDPOINTS from 'src/app/shared/utils/newsletters-topic-endpoints';

@Injectable()
export class NewslettersTopicService {
  public constructor(private readonly httpClient: HttpClient) {}

  public getTopicBySite(siteId: string, params = { active: false }): Observable<NewslettersTopicInterface[]> {
    const SUBSCRIPTION_GET_TAG_BY_SITE = `${NLTOPICENDPOINTS.endpoints.prefixSubscriptionV2}${NLTOPICENDPOINTS.endpoints.getSubcriptionsTagsBySite}?siteId=${siteId}&active=${params.active}`;
    return this.httpClient.get<NewslettersTopicInterface[]>(SUBSCRIPTION_GET_TAG_BY_SITE);
  }

  public getTopicById(id: string): Observable<NewslettersTopicInterface> {
    const SUBSCRIPTION_GET_TAG_BY_ID = `${NLTOPICENDPOINTS.endpoints.prefixSubscriptionV2}${NLTOPICENDPOINTS.endpoints.getSubcriptionsTagsById}`;
    let params = new HttpParams();
    params = params.set('id', id);
    return this.httpClient.get<any>(SUBSCRIPTION_GET_TAG_BY_ID, { params: params });
  }

  public getAudienceBySite(siteId: string): Observable<NewslettersAudienceInterface[]> {
    const SUBSCRIPTION_GET_AUDIENCE_BY_SITE = `${NLTOPICENDPOINTS.endpoints.prefixSubscription}${NLTOPICENDPOINTS.endpoints.getSubscriptionAudienceBySite}/${siteId}`;
    return this.httpClient.get<any>(SUBSCRIPTION_GET_AUDIENCE_BY_SITE);
  }

  public getSubscribersByTopic(topicId: string): Observable<TopicSubscribersInterface[]> {
    const GET_SUBSCRIBERS_BY_TOPIC = `${NLTOPICENDPOINTS.endpoints.prefixSubscription}${NLTOPICENDPOINTS.endpoints.getSubscribersByTag}`.replace(
      ':tagI',
      topicId
    );
    return this.httpClient.get<any>(GET_SUBSCRIBERS_BY_TOPIC);
  }

  public createTopic(payload: NewslettersTopicInterface): Observable<any> {
    const SUBSCRIPTION_CREATE_TAG = `${NLTOPICENDPOINTS.endpoints.prefixSubscriptionV2}${NLTOPICENDPOINTS.endpoints.createSuscriptionTags}`;
    return this.httpClient.post(SUBSCRIPTION_CREATE_TAG, payload);
  }

  public updatePriorityTopic(payload: Partial<NewslettersTopicInterface>): Observable<any> {
    const SUBSCRIPTION_UPDATE_ORDER_TAG = `${NLTOPICENDPOINTS.endpoints.prefixSubscriptionV2}${NLTOPICENDPOINTS.endpoints.updatePriorityTag}`;
    return this.httpClient.post(SUBSCRIPTION_UPDATE_ORDER_TAG, payload);
  }

  public deleteTopic(payload: NewslettersTopicInterface): Observable<any> {
    const SUBSCRIPTION_DELETE_TAG = `${NLTOPICENDPOINTS.endpoints.prefixSubscription}${NLTOPICENDPOINTS.endpoints.deleteSuscriptionTags}`;
    return this.httpClient.post(SUBSCRIPTION_DELETE_TAG, payload);
  }
}
