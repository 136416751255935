import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    header: string;
    typeActions: JSON;
    typeAction: string;
    action: string;
    button: {
        close: {
            name: string;
        };
        confirm: {
            name: string;
        };
    };
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
    constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    close(): void {
        this.dialogRef.close();
    }
}
