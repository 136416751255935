import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '../utils/constants';
import { ConstantsAudiences } from '../utils/constants-audiences';
import { Observable } from 'rxjs';
import { CampaignsInterface } from 'src/app/core/interfaces/newsletter/campaigns.interface';
import { TagsInterface } from 'src/app/core/interfaces/newsletter/tags.interface';
import { TemplatesInterface } from 'src/app/core/interfaces/newsletter/templates.interface';
import { CampaignContentInterface } from 'src/app/core/interfaces/newsletter/campaignContent.interface';
import { AudienceInterface } from 'src/app/core/interfaces/newsletter/audiences.interface';
import { NewslettersAudienceInterface } from 'src/app/core/interfaces/newsletters-audience.interface';
import { WebHookInterface } from 'src/app/core/interfaces/newsletter/webhook.interface';
@Injectable()
export class NewsletterService {
    constructor(private readonly httpClient: HttpClient) {}

    public createCampaign(mediaId: string, body: any): Observable<any> {
        const CREATE_CAMPAIGN_URL = CONSTANTS.API_REST_NEWSLETTER_URL + '/' + mediaId + CONSTANTS.CREATE_CAMPAIGN;
        return this.httpClient.post<any>(CREATE_CAMPAIGN_URL, body);
    }

    public getCampaigns(mediaId: string, audienceId: string): Observable<CampaignsInterface[]> {
        const GET_CAMPAIGN_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.GET_CAMPAIGNS}/${audienceId}`;
        return this.httpClient.get<CampaignsInterface[]>(GET_CAMPAIGN_URL);
    }

    public sendCampaign(mediaId: string, campaignId: string): Observable<any> {
        const SEND_CAMPAIGN_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.SEND_CAMPAIGN}/${campaignId}`;
        return this.httpClient.get<any>(SEND_CAMPAIGN_URL);
    }

    public createTag(mediaId: string, body: any): Observable<any> {
        const GET_TAGS_URL = CONSTANTS.API_REST_NEWSLETTER_URL + '/' + mediaId + CONSTANTS.CREATE_TAG;
        return this.httpClient.post<any>(GET_TAGS_URL, body);
    }

    public getTags(mediaId: string, audienceId: string): Observable<TagsInterface[]> {
        const GET_TAGS_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.GET_TAGS}/${audienceId}`;
        return this.httpClient.get<TagsInterface[]>(GET_TAGS_URL);
    }

    public getTemplates(mediaId: string, audienceId: string): Observable<TemplatesInterface[]> {
        const GET_TEMPLATES_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.GET_TEMPLATES}/${audienceId}`;
        return this.httpClient.get<TemplatesInterface[]>(GET_TEMPLATES_URL);
    }

    public getCampaign(mediaId: string, campaignId: string): Observable<CampaignsInterface> {
        const GET_CAMPAIGN_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.NEWSLETTER_GET_CAMPAIGN_BY_ID}/${campaignId}`;
        return this.httpClient.get<CampaignsInterface>(GET_CAMPAIGN_URL);
    }

    public getCampaignContent(mediaId: string, campaignId: string): Observable<CampaignContentInterface> {
        const GET_CAMPAIGN_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.NEWSLETTER_GET_CAMPAIGN_CONTENT}/${campaignId}`;
        return this.httpClient.get<CampaignContentInterface>(GET_CAMPAIGN_URL);
    }

    public assignTemplateAndReplicateCampaign(mediaId: string, body: any): Observable<CampaignContentInterface> {
        const ASSIGN_TEMPLATE_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.NEWSLETTER_ADD_TEMPLATE_AND_REPLICATE_CAMPAIGN}`;
        return this.httpClient.post<CampaignContentInterface>(ASSIGN_TEMPLATE_URL, body);
    }

    public assignTemplateToCampaign(mediaId: string, body: any): Observable<CampaignContentInterface> {
        const ASSIGN_TEMPLATE_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.NEWSLETTER_ADD_TEMPLATE}`;
        return this.httpClient.post<CampaignContentInterface>(ASSIGN_TEMPLATE_URL, body);
    }

    public getAudiences(mediaId: string): Observable<AudienceInterface[]> {
        const AUDIENCE_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL_V2}/${mediaId}${CONSTANTS.NEWSLETTER_GET_AUDIENCES}`;
        return this.httpClient.get<AudienceInterface[]>(AUDIENCE_URL);
    }

    public getAudiencesBySitePostgre(mediaId: string): Observable<NewslettersAudienceInterface[]> {
      const AUDIENCE_URL = `${CONSTANTS.API_REST_SUBSCRIPTIONS_URL_V2}${ConstantsAudiences.NEWSLETTER_GET_AUDIENCES_BY_SITE_POSTGRE}`.replace(':siteId', mediaId);
      return this.httpClient.get<NewslettersAudienceInterface[]>(AUDIENCE_URL);
    }

    public createAudience(audience: any, mediaId: string): Observable<any> {
      const CREATE_AUDIENCE_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL_V2}/${mediaId}${ConstantsAudiences.CREATE_AUDIENCE}`;
      return this.httpClient.post<any>(CREATE_AUDIENCE_URL, audience);
    }

    public updateAudience(audience: any, mediaId: string, audienceId: string): Observable<any> {
      const UPDATE_AUDIENCE_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL_V2}/${mediaId}${ConstantsAudiences.UPDATE_AUDIENCE}/${audienceId}`;
      return this.httpClient.post<any>(UPDATE_AUDIENCE_URL, audience);
    }

    public deleteAudience(mediaId: string, audienceId: string): Observable<any> {
      const DELETE_AUDIENCE_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL_V2}${ConstantsAudiences.DELETE_AUDIENCE}`
        .replace(':mediaId', mediaId)
        .replace(':idAudience', audienceId);
      return this.httpClient.get<any>(DELETE_AUDIENCE_URL, {});
    }

    public getAudienceById(mediaId: string, audienceId: string): Observable<AudienceInterface> {
      const AUDIENCE_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL_V2}/${mediaId}${ConstantsAudiences.NEWSLETTER_GET_AUDIENCE_BY_ID}/${audienceId}`;
      return this.httpClient.get<AudienceInterface>(AUDIENCE_URL);
    }

    public getAudienceByIdPostgre(audienceId: string): Observable<NewslettersAudienceInterface> {
      const AUDIENCE_URL = `${CONSTANTS.API_REST_SUBSCRIPTIONS_URL_V2}${ConstantsAudiences.NEWSLETTER_GET_AUDIENCE_BY_ID_POSTGRE}`.replace(':audienceId', audienceId);
      return this.httpClient.get<NewslettersAudienceInterface>(AUDIENCE_URL);
    }

    public updateSharedSitesByAudienceId(audienceId: string, sitesToCreate: string[], sitesToDelete: string[]): Observable<string[]> {
      const UPDATE_SHARED_SITES_URL = `${CONSTANTS.API_REST_SUBSCRIPTIONS_URL_V2}${ConstantsAudiences.NEWSLETTER_UPDATE_SHARED_SITES_BY_ID}/${audienceId}`;
      const body = {sitesToCreate: sitesToCreate, sitesToDelete: sitesToDelete};
      return this.httpClient.post<string[]>(UPDATE_SHARED_SITES_URL, body);
    }

    public getSharedSitesByAudienceId(audienceId: string): Observable<string[]> {
      const GET_SHARED_SITES_URL = `${CONSTANTS.API_REST_SUBSCRIPTIONS_URL_V2}${ConstantsAudiences.NEWSLETTER_GET_SHARED_SITES_BY_ID}/${audienceId}`;
      return this.httpClient.get<string[]>(GET_SHARED_SITES_URL);
    }

    public setAudienceBySite(mediaId: string, audiences: any): Observable<AudienceInterface[]> {
      const body = {mediaId: mediaId, audiences: audiences};
      return this.httpClient.post<any>(CONSTANTS.CREATE_WEBHOOK_V2, body);
    }

    public subscribeUrlCallbackWebHook(
        mediaId: string,
        audiences: string[],
        urlCallback: string
    ): Observable<WebHookInterface> {
        const WEBHOOK_URL = `${CONSTANTS.API_REST_NEWSLETTER_URL}/${mediaId}${CONSTANTS.NEWSLETTER_WEBHOOK}`;
        return this.httpClient.post<WebHookInterface>(WEBHOOK_URL, { urlCallback, audiences });
    }
}
