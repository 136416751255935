import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private fireAdmin: AngularFireAuth) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.fireAdmin.currentUser && !req.url.includes('cloudfunctions')) {
          return from(this.fireAdmin.currentUser).pipe(
              switchMap(user => user ? from(user.getIdToken()) : of()),
              switchMap(token => {
                  const requestCloned = req.clone({
                      headers: req.headers.set('Authorization', `Bearer ${token}`),
                  });
                  return next.handle(requestCloned);
              })
          );
        } else {
          return next.handle(req);
        }
    }
}
