import { CONSTANTS } from 'src/app/shared/utils/constants';

export class ConstantsTriggers {
  public static globalTriggerTypes = {
    1: 'all',
    2: 'triggers',
    3: 'push-subscription',
    4: 'newsletter-subscription'
  };

  public static globalTriggerTypesVector = [
    CONSTANTS.PROMETEO_ALL_TYPE,
    CONSTANTS.PROMETEO_TRIGGERS_TYPE,
    CONSTANTS.PROMETEO_PUSH_SUBSCRIPTION_TYPE,
    CONSTANTS.PROMETEO_NEWSLETTER_SUBSCRIPTION_TYPE
  ];

  public static readonly closeDefaultActionTrigger = `document.getElementById('body_modal').parentElement.remove(); MODAL_HELPER.closeModal();`;
  public static readonly closeDefaultActionTriggerRegex = /document.getElementById\('body_modal[A-Za-z0-9_]*'\).parentElement.remove\([A-Za-z0-9_']*\); MODAL_HELPER.closeModal\([A-Za-z0-9_']*\);/g;

  public static readonly wallTypes = [
    { id: 'register', label: 'Registro' },
    { id: 'subscription', label: 'Subscripción' }
  ];
}

export enum TriggerLoadButtonType {
  GET = 'get',
  SET = 'set'
}

export enum TriggerType {
  BASIC = 'basic',
  PUSH = 'push',
  NEWSLETTER = 'newsletter'
}
export class ConstantsStylesWidgetsTriggers {
  public static triggerWidgetTemplate = `
    <div id="body_modal" style=":STYLE">
      <div style="background-color: white; margin: auto;">
        <div style="display:flex; flex-direction:row-reverse;">
            <button id="close_button" onclick="${ConstantsTriggers.closeDefaultActionTrigger}"
            style="TRIGGERSTYLES">
              X
            </button>
          </div>
          <div id=":widgetId"
              data-prometeo-mediaid=":mediaId"
              data-prometeo-widget="WIDGET-PROMETEO-V2"
              class="WIDGET-PROMETEO-V2 prometeo-content content-:widgetId"
          >:WIDGET_HTML</div>
      </div>
    </div>
    `;
  public static triggerBoxTemplate = `
    <div id=":widgetId"
          data-prometeo-mediaid=":mediaId"
          data-prometeo-widget="WIDGET-PROMETEO-V2"
          class="WIDGET-PROMETEO-V2 prometeo-content content-:widgetId"
        >:WIDGET_HTML</div>
    `;

  public static modal = `position: fixed;
    z-index: 100000;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);`;

  public static stickyTop = `position: fixed;
                                z-index: 100000000;
                                top: 0; left: 0; width: 100%;
                                min-height: 0vh !important;
                                box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);`;

  public static stickyBottom = `position: fixed;
    z-index: 100000;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 0vh !important;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);`;

  public static sideBar = `z-index: 100000;
    position: fixed;
    width: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    top: 0;
    height: 100vh;
    background: #FFF;
    right: 0;
    width: 20%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);`;

}
