<ul *ngIf='recursiveErrorsNode'>
  <li>
    {{translateField(recursiveErrorsNode.label)}}
    <ul *ngIf='recursiveErrorsNode.errors'>
      <li *ngFor='let key of getKeys(recursiveErrorsNode.errors)'>
        {{translateError(key)}}
      </li>
    </ul>
    <app-error-list-item *ngFor='let child of recursiveErrorsNode.children'
                         [recursiveErrorsNode]='child'></app-error-list-item>
  </li>
</ul>
