import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { SegmentRealTimeInterface } from '../interfaces/query-builder/segment-real-time.interface';
import { ResponseSegmentInterface } from '../interfaces/response-segments.interface';

@Injectable()
export class RealTimeSegmentService {
    public constructor(private readonly httpClient: HttpClient) {}

    public getAllBySite(siteId: string, active?: boolean): Observable<SegmentRealTimeInterface[]> {
        const SEGMENT_REALTIME_BY_MEDIA_URL = `${CONSTANTS.API_REST_SEGMENTS_REALTIME_URL}${siteId}`;
        const params = new HttpParams().set('typePlatform', CONSTANTS.SITE_WEB);
        if (active) {
            params.set('active', String(active));
        }
        return this.httpClient
            .get<ResponseSegmentInterface>(SEGMENT_REALTIME_BY_MEDIA_URL, { params: params })
            .pipe(map(segments => segments.data));
    }

    public getAllAppBySite(siteId: string, typePlatform: string): Observable<SegmentRealTimeInterface[]> {
        const SEGMENT_REALTIME_BY_MEDIA_URL = `${CONSTANTS.API_REST_SEGMENTS_REALTIME_URL}${siteId}`;
        const params = new HttpParams().set('typePlatform', typePlatform);
        return this.httpClient
            .get<ResponseSegmentInterface>(SEGMENT_REALTIME_BY_MEDIA_URL, { params: params })
            .pipe(map(segments => segments.data));
    }

    public createOrUpdateSegment(segment: SegmentRealTimeInterface, typePlatform: string): Observable<ResponseSegmentInterface> {
        const ADD_HISTORICAL_SEGMENT = `${CONSTANTS.API_REST_SEGMENTS_REALTIME_URL}create`;
        return this.httpClient.post<ResponseSegmentInterface>(ADD_HISTORICAL_SEGMENT, {
            segment: segment,
            typePlatform: typePlatform,
        });
    }

    public updateComponentsInSegment(segment: SegmentRealTimeInterface): Observable<ResponseSegmentInterface> {
        const UPDATE_REALTIME_SEGMENT_COMPONENTS = `${CONSTANTS.API_REST_SEGMENTS_REALTIME_URL}updateComponents`;
        return this.httpClient.post<ResponseSegmentInterface>(UPDATE_REALTIME_SEGMENT_COMPONENTS, segment);
    }

    public getSegmentById(mediaId: string, segmentId: string): Observable<SegmentRealTimeInterface[]> {
        const params = new HttpParams().set('segmentId', segmentId);
        const SEGMENT_BY_ID_HISTORICAL = `${CONSTANTS.API_REST_SEGMENTS_REALTIME_URL}${mediaId}`;
        return this.httpClient
            .get<ResponseSegmentInterface>(SEGMENT_BY_ID_HISTORICAL, { params })
            .pipe(map(segments => segments.data));
    }

    public getSegmentByMedia(mediaId: string, segmentId: string, typePlatform : string): Observable<SegmentRealTimeInterface[]> {
        const params = new HttpParams().set('segmentId', segmentId).set('typePlatform', typePlatform);
        const SEGMENT_BY_ID_REALTIME = `${CONSTANTS.API_REST_SEGMENTS_REALTIME_URL}segment/${mediaId}`;
        return this.httpClient
            .get<ResponseSegmentInterface>(SEGMENT_BY_ID_REALTIME, { params })
            .pipe(map(segments => segments.data));
    }

    public updateVersion(mediaId: string): Observable<{ message: string }> {
        const versionUrl = CONSTANTS.API_REST_UPDATE_SEGMENT_VERSION.replace(':mediaId', mediaId);
        return this.httpClient.post<{ message: string }>(versionUrl, {}).pipe(take(1));
    }

    public delete(segmentId: string, mediaId: string, typePlatform: string): Observable<ResponseSegmentInterface> {
        const DELETE_SEGMENT_URL = `${CONSTANTS.API_REST_SEGMENTS_REALTIME_URL}${mediaId}/${segmentId}`;
        const params = new HttpParams().set('typePlatform', typePlatform);
        return this.httpClient.delete<ResponseSegmentInterface>(DELETE_SEGMENT_URL, { params: params });
    }
}
