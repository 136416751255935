import { NewslettersTemplate } from 'src/app/core/models/newsletters-template.model';
import * as moment from 'moment';
import { NewslettersSubscriptionInterface } from 'src/app/core/interfaces/newsletters-subscription.interface';
import { PushSubscriptionInterface } from 'src/app/core/interfaces/push-subscription.interface';
import { TriggerInterface } from 'src/app/core/interfaces/trigger.interface';
import SegmentMetaInterface from 'src/app/core/interfaces/trigger/segment-meta.interface';
import ConstantMessages from './constants-messages/constants-messages';
import { CONSTANTS } from './constants';
import firebase from 'firebase/compat/app';
import { TriggerPeriodicSchedule } from 'src/app/core/interfaces/trigger-schedule-period.interface';

export class TriggerUtils {
  public static replaceTriggerIdInHtml(content: string, triggerId: string): string {
    return content
      .replace(/\bbody_modal(_[a-zA-Z0-9]+)?\b/g, `body_modal_${triggerId}`)
      .replace(/MODAL_HELPER\.closeModal\((\'([a-zA-Z0-9]*)\')?\)/g, `MODAL_HELPER.closeModal('${triggerId}')`)
      .replace(
        /MODAL_HELPER\.closeModalNoEvent\((\'([a-zA-Z0-9]*)\')?\)/g,
        `MODAL_HELPER\.closeModalNoEvent('${triggerId}')`
      )
      .replace(/TRIGGER_EVENTS\.action\((\'?([a-zA-Z0-9]*)\'?)?\)/g, `TRIGGER_EVENTS.action('${triggerId}')`)
      .replace(
        /TRIGGER_EVENTS\.action\((\'?([a-zA-Z0-9]*)\'?)\,\s*(\'([a-zA-Z0-9]*)\')?\)/g,
        `TRIGGER_EVENTS.action('${triggerId}', $3)`
      )
      .replace(/TRIGGER_EVENTS\.action\((\'?([a-zA-Z0-9]*)\'?)?/g, `TRIGGER_EVENTS.action('${triggerId}'`);
  }

  //TODO: This is really the same? WHY?, Test later if we can remove it
  public static replaceTriggerIdInDesignHtml(content: string, triggerId: string): string {
    return content
      .replace(/\bbody_modal(_[a-zA-Z0-9]+)?\b/g, `body_modal_${triggerId}`)
      .replace(/MODAL_HELPER\.closeModal\((\'([a-zA-Z0-9]*)\')?\)/g, `MODAL_HELPER.closeModal('${triggerId}')`)
      .replace(
        /MODAL_HELPER\.closeModalNoEvent\((\'([a-zA-Z0-9]*)\')?\)/g,
        `MODAL_HELPER\.closeModalNoEvent('${triggerId}')`
      )
      .replace(/TRIGGER_EVENTS\.action\((\'?([a-zA-Z0-9]*)\'?)?\)/g, `TRIGGER_EVENTS.action('${triggerId}')`)
      .replace(
        /TRIGGER_EVENTS\.action\((\'?([a-zA-Z0-9]*)\'?)\,\s*(\'([a-zA-Z0-9]*)\')?\)/g,
        `TRIGGER_EVENTS.action('${triggerId}', $3)`
      )
      .replace(/TRIGGER_EVENTS\.action\((\'?([a-zA-Z0-9]*)\'?)?/g, `TRIGGER_EVENTS.action('${triggerId}'`);
  }
  public static replaceTriggerIdInJavascript(content: string, triggerId: string): string {
    return content
      .replace(/MODAL_HELPER\.closeModal\((\'([a-zA-Z0-9]*)\')?\)/, `MODAL_HELPER.closeModal('${triggerId}')`)
      .replace(
        /MODAL_HELPER\.closeModalNoEvent\((\'([a-zA-Z0-9]*)\')?\)/,
        `MODAL_HELPER.closeModalNoEvent('${triggerId}')`
      )
      .replace(/TRIGGER_EVENTS\.show\((\'([a-zA-Z0-9]*)\')?\)/, `TRIGGER_EVENTS.show('${triggerId}')`)
      .replace(/TRIGGER_EVENTS\.action\((\'?([a-zA-Z0-9]*)\'?)?\)/g, `TRIGGER_EVENTS.action('${triggerId}')`)
      .replace(
        /TRIGGER_EVENTS\.action\((\'?([a-zA-Z0-9]*)\'?)\,\s*(\'([a-zA-Z0-9]*)\')?\)/g,
        `TRIGGER_EVENTS.action('${triggerId}', $3)`
      )
      .replace(/TRIGGER_EVENTS\.close((\'([a-zA-Z0-9]*)\')?)/, `TRIGGER_EVENTS.close('${triggerId}')`)
      .replace(/_LOVER_PUSH\.getTopic\((\'([a-zA-Z0-9]*)\')?\)/g, `_LOVER_PUSH.getTopic('${triggerId}')`)
      .replace(/_LOVER_PUSH\.getMid\((\'([a-zA-Z0-9]*)\')?\)/g, `_LOVER_PUSH.getMid('${triggerId}')`)
      .replace(/this\.getMid\((\'([a-zA-Z0-9]*)\')?\)/g, `this.getMid('${triggerId}')`)
      .replace(/this\.getTopic\((\'([a-zA-Z0-9]*)\')?\)/g, `this.getTopic('${triggerId}')`)
      .replace(/\bbody_modal(_[a-zA-Z0-9]+)?\b/g, `body_modal_${triggerId}`);
  }

  public static replaceTriggerIdInText(content: string, triggerId: string): string {
    //TODO: weird stuff, it is the same function in the end, test if we can remove it.
    let edited = TriggerUtils.replaceTriggerIdInHtml(content, triggerId);
    edited = TriggerUtils.replaceTriggerIdInDesignHtml(edited, triggerId);
    return TriggerUtils.replaceTriggerIdInJavascript(edited, triggerId);
  }

  public static isGenericCodePresentInTriggerContent(genericCode: string, content: string, triggerId: string): boolean {
    return content.includes(genericCode) || content.includes(this.replaceTriggerIdInText(genericCode, triggerId));
  }

  public static addGenericCodeToTriggerContent(genericCode: string, content: string, triggerId: string): string {
    if (this.isGenericCodePresentInTriggerContent(genericCode, content, triggerId)) return;
    return `${content} ${genericCode}`;
  }

  public static removeGenericCodeFromTriggerContent(genericCode: string, content: string, triggerId: string): string {
    return content.replace(genericCode, '').replace(this.replaceTriggerIdInText(genericCode, triggerId), '');
  }

  public static consolidateFunctionsInContent(
    functionsToAdd: string[],
    functionsToRemove: string[],
    content: string,
    triggerId: string
  ): string {
    let updatedContent = content;
    functionsToAdd.forEach(
      genericCode => (updatedContent = this.addGenericCodeToTriggerContent(genericCode, updatedContent, triggerId))
    );
    functionsToRemove.forEach(
      genericCode => (updatedContent = this.removeGenericCodeFromTriggerContent(genericCode, updatedContent, triggerId))
    );
    return updatedContent;
  }

  public static replaceTriggerIdInTriggerCode(
    genericTrigger: Partial<
      TriggerInterface | PushSubscriptionInterface | NewslettersSubscriptionInterface | NewslettersTemplate
    >,
    triggerId: string
  ): void {
    if (!genericTrigger) return;
    if (genericTrigger.html) genericTrigger.html = this.replaceTriggerIdInHtml(genericTrigger.html, triggerId);

    if (genericTrigger.design && genericTrigger.design.html) {
      genericTrigger.design.html = this.replaceTriggerIdInDesignHtml(genericTrigger.design.html, triggerId);
    }
    if (genericTrigger.javascript) genericTrigger.javascript = this.replaceTriggerIdInJavascript(genericTrigger.javascript, triggerId);
  }

    public static replaceTriggerIdMediaIdAndTopicInString(initialString, {triggerId, mediaId, topic}): string {
      return initialString
                .replace(/:TRIGGER_ID/g, triggerId)
                .replace(/:MEDIA_ID/g, mediaId)
                .replace(/:TOPIC/g, topic);
    }


    public static  getSegmentType(segment: any): number {
      if (segment.historical) return 0;
      if (segment.imported) return 2;
      return 1;
    }

    public static refreshSegments(segments: any[], triggerObject: any): { initialSegments: SegmentMetaInterface[], finalSegments: SegmentMetaInterface [] } {


      const initialSegments = (segments || []).map((segment: any): SegmentMetaInterface => ({
        id: segment.historical || segment.imported ? segment.segmentID : segment.idRule,
        name: segment.name,
        type: this.getSegmentType(segment),
      }));
      const finalSegments = [];
      for (const triggerSegment of triggerObject.segments) {
        const foundSegmentIndex = initialSegments.findIndex(
          (segment: SegmentMetaInterface): boolean => segment.id === triggerSegment
        );
        if (foundSegmentIndex > -1) {
          const deletedSegment = initialSegments.splice(foundSegmentIndex, 1)[0];
          finalSegments.push(deletedSegment);
        }
      }
      return { initialSegments, finalSegments };
    }

    public static getTriggerMinDate() {
      return moment()
        .add(1.5, 'minute')
        .startOf('minute');

    }

    public static loadPrevisualization(html: string): void {
        document.getElementById('previsualization').innerHTML =
            html
                .replace('min-height: 100vh;', '')
                .replace(
                    'width:400px !important;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%) !important;transform:translate(-50%, -50%);',
                    ''
                )
                .replace('width:50px;position:absolute;', 'width:50px;')
                .replace('body {', '#u_body {');
    }

    public static getMessageForTitle(element: string[]): string{
        return element.length ?
          ConstantMessages.TriggerCompatibleWithTitle :
          ConstantMessages.TriggerInTestAbTitle;
    }

    public static getMessageForText(element: string[]): string{
      return element.length ? ConstantMessages.TriggerCompatibleWithText.replace(':TRIGGERS', element.join(',')) : ConstantMessages.TriggerInTestAbText;
    }

    public static getColorIcon({isIntoTestAB, programDates, hasPeriodicSchedule, periodicScheduleConfig }: {isIntoTestAB?: boolean; programDates?: firebase.firestore.Timestamp[]; hasPeriodicSchedule?: boolean;
      periodicScheduleConfig?: TriggerPeriodicSchedule; }): string {
      if (isIntoTestAB){
        return CONSTANTS.RED_COLOR;
      }
      else if (programDates &&programDates.length && programDates.some(date => date.seconds > Math.floor((new Date()).getTime() / 1000))) {
        return CONSTANTS.ORANGE_COLOR;
      }
      else if (hasPeriodicSchedule && periodicScheduleConfig && periodicScheduleConfig.endDateMiliseconds  > Date.now()) {
        return CONSTANTS.ORANGE_COLOR;
      }
      return CONSTANTS.PRIMARY_COLOR;
    }
}
