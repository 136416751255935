<form [formGroup]="formGroup">
  <div class="row">
    <mat-form-field class="col-md-6" appearance="outline">
      <mat-label>Título</mat-label>
      <input matInput name="title" formControlName="title" placeholder="Título" />
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="col-md-12" appearance="outline">
      <mat-label>Mensaje</mat-label>
      <input matInput name="message" formControlName="message" placeholder="Título" />
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col-md-6">
      <mat-form-field class="col-md-12" appearance="outline">
        <mat-label>Url redirección</mat-label>
        <input matInput name="url" formControlName="url" placeholder="Url redirección" />
      </mat-form-field>
      <mat-form-field *ngIf="notificationType === 'app'" class="col-md-12" appearance="outline">
        <mat-label>Content Id</mat-label>
        <input matInput name="contentId" formControlName="contentId" placeholder="Url redirección" />
      </mat-form-field>
      <mat-form-field *ngIf="notificationType === 'app'" class="col-md-12" appearance="outline">
        <mat-label>Tipo de plantilla</mat-label>
        <mat-select name="templateType" formControlName="templateType">
          <mat-option *ngFor="let template of templateTypes" [value]="template.value">
            {{ template.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-4" appearance="outline">
      <app-file-picker accept="image/*" (fileSelect)="setImage($event)">
        <img class="w-100 px-3 mb-3" [src]="
                      formGroup.get('urlImage').value == '' || !formGroup.get('urlImage').value
                          ? '/assets/images/image-placeholder.png'
                          : formGroup.get('urlImage').value
                  " />
      </app-file-picker>
    </div>
  </div>
</form>
