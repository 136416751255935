import { ConstantsStylesWidgetsTriggers, ConstantsTriggers } from "./constants-triggers";
import { CONSTANTS } from "./constants";

export class ConstantStyles {
    public static readonly closeButton = `<div style="display:flex; flex-direction:row-reverse">
                                              <button id="close_button" onclick="${ConstantsTriggers.closeDefaultActionTrigger}"
                                              style="TRIGGERSTYLES">
                                                X
                                              </button>
                                          </div>`;

    public static readonly stickyTopBodyModalStyle = `<body  id="body_modal" style="position: fixed;
                                                                                    z-index: 100000000;
                                                                                    top: 0;
                                                                                    left: 0;
                                                                                    width: 100%;
                                                                                    min-height: 0vh !important;
                                                                                    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);"`;

    public static readonly stickyTopCloseButtonStyles = `margin-right: 2%;
                                                          margin-top: 2%;
                                                          border-radius: 10em;
                                                          border: solid;
                                                          height: 33px;
                                                          width: 33px;
                                                          border-color: #585858;
                                                          border-width: 1px;
                                                          background-color: white;
                                                          z-index: 1000000000;`;

    public static readonly stickyBottomStyle = `<body id="body_modal" style="position: fixed;
                                                                            z-index: 100000;
                                                                            bottom: 0;
                                                                            left: 0;
                                                                            width: 100%;
                                                                            min-height: 0vh !important;
                                                                            box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);"`;

    public static readonly stickyBottomCloseButtonStyles = `margin-top: 1%;
                                                            margin-right: 2%;
                                                            border-radius: 10em;
                                                            border: solid;
                                                            height: 33px;
                                                            width: 33px;
                                                            border-color: #585858;
                                                            border-width: 1px;
                                                            background-color: white;
                                                            z-index: 1000000000;`;

    public static readonly sideBarStyle = `<body id="body_modal" style="z-index: 100000;
                                                                        position: fixed;
                                                                        width: 100%;
                                                                        overflow: auto;
                                                                        background-color: rgb(0,0,0);
                                                                        background-color: rgba(0,0,0,0.4);
                                                                        top: 0;
                                                                        height: 100vh;
                                                                        background: #FFF;
                                                                        right: 0;
                                                                        width: 20%;
                                                                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);"`;

    public static readonly sideBarCloseButtonStyle = `margin-right: 8%;
                                                      margin-top: 2%;
                                                      border-radius: 10em;
                                                      border: solid;
                                                      height: 2.5em;
                                                      width: 2.5em;
                                                      border-color: #585858;
                                                      border-width: 1px;
                                                      background-color: white;
                                                      z-index: 1000000000;`;

    public static readonly modalStyle = `<body id="body_modal" style="position: fixed;
                                                                      z-index: 100000;
                                                                      padding-top: 100px;
                                                                      left: 0;
                                                                      top: 0;
                                                                      width: 100%;
                                                                      height: 100%;
                                                                      background-color: rgb(0,0,0);
                                                                      background-color: rgba(0,0,0,0.4);"`;

    public static readonly modalBodyStyle = `class="u_body" style="
                                                                    margin: auto;
                                                                    border: 1px solid #888;
                                                                    max-height: 70vh;
                                                                    overflow: auto;
                                                                    width: auto;
                                                                    max-width: 90vw;`;

    public static readonly modalCloseButtonStyle = `margin-left: 90%;
                                                    border-radius: 10em;
                                                    border: solid;
                                                    height: 35px;
                                                    width: 35px;
                                                    font-size: 16px
                                                    border-color: #585858;
                                                    border-width: 1px;
                                                    z-index: 1000000000;`;

    public static readonly ampTriggerTemplate = `
                                                  <style>
                                                    .modal-button {
                                                        background: #1593ff;
                                                        color: white;
                                                        width: 20%;
                                                        height: 4vh;
                                                        border: none;
                                                        cursor: pointer;
                                                        border-radius: 8px;
                                                    }

                                                    .modal-close-button {
                                                        margin-left: 90%;
                                                        border-radius: 10em;
                                                        border: solid;
                                                        height: 35px;
                                                        width: 35px;
                                                        font-size: 16px;
                                                        border-color: #585858;
                                                        border-width: 1px;
                                                        z-index: 1000000000;
                                                        cursor: pointer;
                                                    }
                                                  </style>
                                                  <div id="trigger" style="text-align:center">
                                                    <div>
                                                        <div style="display:flex; flex-direction:row-reverse">
                                                            <button id="close_button" class="modal-close-button" >
                                                              X
                                                            </button>
                                                          </div>
                                                        <div >
                                                            <h4>{{title}}</h4>
                                                        </div>
                                                        <p >{{text}}</p>
                                                        <div>
                                                            <a href="{{button}}">
                                                                <button class="modal-button">Aceptar</button>
                                                            </a>

                                                        </div>
                                                    </div>`;
}

export const triggerStyles = {
  [CONSTANTS.PROMETEO_TRIGGERS_STICKY_TOP_TYPE]: {
    triggerStyle: ConstantsStylesWidgetsTriggers.stickyTop,
    closeButtonStyle: ConstantStyles.stickyTopCloseButtonStyles
  },
  [CONSTANTS.PROMETEO_TRIGGERS_STICKY_BOTTOM_TYPE]: {
    triggerStyle: ConstantsStylesWidgetsTriggers.stickyBottom,
    closeButtonStyle: ConstantStyles.stickyBottomCloseButtonStyles
  },
  [CONSTANTS.PROMETEO_TRIGGERS_SIDEBAR_TYPE]: {
    triggerStyle: ConstantsStylesWidgetsTriggers.sideBar,
    closeButtonStyle: ConstantStyles.sideBarCloseButtonStyle
  },
  [CONSTANTS.PROMETEO_TRIGGERS_MODAL_TYPE]: {
    triggerStyle: ConstantsStylesWidgetsTriggers.modal,
    closeButtonStyle: ConstantStyles.modalCloseButtonStyle
  },

};

