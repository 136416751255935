import { Component, OnInit, Input } from '@angular/core';

interface Opts {
    w: {
        globals: {
            series: [];
        };
    };
    seriesIndex: number;
    dataPointIndex: string;
}

interface Legend {
    show: boolean;
    floating: boolean;
    fontSize: string;
    position: string;
    offsetX: number;
    offsetY: number;
    labels: {
        useSeriesColors: boolean;
    };
    markers: {
        width: number;
        height: number;
    };
    formatter: (seriesName: string, opts: Opts) => string;
    tooltipHoverFormatter: (
        seriesName: string,
        opts: {
            w: {
                globals: {
                    series: [];
                };
            };
            seriesIndex: number;
            dataPointIndex: string;
        }
    ) => string;
    itemMargin: {
        horizontal: number;
    };
}

interface ApexChart {
    series: Array<number>;
    labels: Array<string>;
    chart: {
        width: string;
        height: number;
        type: string;
        background: string;
    };
    plotOptions: {
        radialBar: {
            offsetY: number;
            startAngle: number;
            endAngle: number;
            hollow: {
                margin: number;
                size: string;
                background: string;
            };
            dataLabels: {
                name: {
                    show: boolean;
                };
                value: {
                    show: boolean;
                };
            };
        };
    };
    legend: Legend;
}

@Component({
    selector: 'app-radio-chart',
    templateUrl: './radio-chart.component.html',
    styleUrls: ['./radio-chart.component.scss'],
})
export class RadioChartComponent implements OnInit {
    @Input() value: number;
    @Input() total: number;
    @Input() innerLabel: string[] = [];
    @Input() outerLabel: string;
    //@ViewChild('objetivesChart', { static: false }) public objetivesChart: ChartComponent;
    public apexchart: ApexChart;

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        //TODO: Refactorr!
        this.apexchart = {
            series: [0],
            labels: this.innerLabel,
            chart: {
                width: '100%',
                height: 380,
                type: 'radialBar',
                background: 'transparent',
            },
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 360,
                    hollow: {
                        margin: 0,
                        size: '50%',
                        background: 'transparent',
                    },
                    dataLabels: {
                        name: {
                            show: true,
                        },
                        value: {
                            show: true,
                        },
                    },
                },
            },
            legend: {
                show: false,
                floating: true,
                fontSize: '30px',
                position: 'left',
                offsetX: 100,
                offsetY: 20,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    width: 15,
                    height: 15,
                },
                formatter: (seriesName: string, opts: Opts): string => {
                    return opts.w.globals.series[opts.seriesIndex] + '% de ' + this.total;
                },
                tooltipHoverFormatter: (seriesName: string, opts: Opts): string => {
                    return (
                        seriesName +
                        ' - <strong>' +
                        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                        '</strong>'
                    );
                },
                itemMargin: {
                    horizontal: 1,
                },
            },
        };
    }
}
