import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { TemplateInterface } from '../interfaces/template.interface';
import { WidgetAppTemplate } from '../interfaces/widget-app-template';
import { AuthService } from './auth.service';

const TEMPLATES_COLLECTION = 'templates';
const APPS_TEMPLATES_COLLECTION = 'widget-apps-templates';

@Injectable()
export class TemplateService {
    public templatesCollection: AngularFirestoreCollection<TemplateInterface>;
    public widgetAppsTemplatesCollection: AngularFirestoreCollection<WidgetAppTemplate>;

    public constructor(private readonly firestore: AngularFirestore, private readonly authService: AuthService) {
        this.templatesCollection = this.firestore.collection<TemplateInterface>(TEMPLATES_COLLECTION);
        this.widgetAppsTemplatesCollection = this.firestore.collection<WidgetAppTemplate>(APPS_TEMPLATES_COLLECTION);
    }

    public getAll(): Observable<TemplateInterface[]> {
        return this.templatesCollection.valueChanges();
    }

    public getAllBasic(): Observable<TemplateInterface[]> {
        return this.firestore
            .collection<TemplateInterface>(
                TEMPLATES_COLLECTION,
                (ref: CollectionReference): Query => ref.where('basic', '==', true)
            )
            .valueChanges();
    }

    public getAllApps(): Observable<WidgetAppTemplate[]> {
        return this.widgetAppsTemplatesCollection.valueChanges();
    }

    public getAllAllowed(sitesIds: string[]): Observable<TemplateInterface[]> {
        const queries = new Array<Observable<TemplateInterface[]>>();
        for (const siteId of sitesIds) {
            const templates$ = this.getAllNoBasicBySite(siteId);
            queries.push(templates$);
        }

        const basicTemplates$ =
            this.authService.userDetails && this.authService.hasRole(CONSTANTS.ROLE_KEYACCOUNT)
                ? this.getAllBasic()
                : of([]);

        queries.push(basicTemplates$);

        return combineLatest(queries).pipe(
            map((allTemplates: TemplateInterface[][]): TemplateInterface[] => {
                const templates = [];
                for (const t of allTemplates) {
                    templates.push(...t);
                }
                return templates.filter(
                    (template: TemplateInterface, index: number): boolean =>
                        templates.findIndex((t: TemplateInterface): boolean => template.id === t.id) === index
                );
            })
        );
    }

    public getAllBySite(siteId: string): Observable<TemplateInterface[]> {
        return this.firestore
            .collection<TemplateInterface>(
                TEMPLATES_COLLECTION,
                (ref: CollectionReference): Query => ref.where('site', '==', siteId)
            )
            .valueChanges();
    }

    public getAllNoBasicBySite(siteId: string): Observable<TemplateInterface[]> {
        return this.firestore
            .collection<TemplateInterface>(
                TEMPLATES_COLLECTION,
                (ref: CollectionReference): Query => ref.where('basic', '==', false).where('site', '==', siteId)
            )
            .valueChanges();
    }

    public get(templateId: string): Observable<TemplateInterface> {
        return this.templatesCollection.doc<TemplateInterface>(templateId).valueChanges();
    }

    public add(template: TemplateInterface): Observable<void> {
        const templateId = this.firestore.createId();
        template.id = templateId;
        template.createdAt = firebase.firestore.Timestamp.now();
        template.updatedAt = firebase.firestore.Timestamp.now();
        template.updatedBy = this.authService.getEmail();
        return from(this.templatesCollection.doc<TemplateInterface>(templateId).set(Object.assign({}, template)));
    }

    public delete(templateId: string): Observable<void> {
        return this.update(templateId, { deletedBy: this.authService.getEmail() }).pipe(
            switchMap(
                (): Observable<void> => from(this.templatesCollection.doc<TemplateInterface>(templateId).delete())
            )
        );
    }

    public update(templateId: string, dataToUpdate: Partial<TemplateInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return from(
            this.templatesCollection.doc<TemplateInterface>(templateId).update(Object.assign({}, dataToUpdate))
        );
    }
}
