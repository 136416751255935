import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '../utils/constants';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ArticleTopContentInterface } from 'src/app/core/interfaces/article-top-content.interface';

@Injectable()
export class ScheduleService {
    constructor(private readonly httpClient: HttpClient) {}

    public generateArticuleDynamically(
        typeArticle: number,
        mediaId: string,
        limit: number,
        section: string,
        category2: string,
        category3: string,
        tag: string,
        metakeyword: string,
        layouts: string,
        nsection: string,
        ncategory2: string,
        ncategory3: string,
        ntag: string,
        nmetakeyword: string,
        nlayouts: string,
        accessTypes: string,
        excludeAccessTypes: string
    ): Observable<ArticleTopContentInterface[]> {
        return this.getArticleRecommendation(
            mediaId,
            typeArticle,
            limit,
            section,
            category2,
            category3,
            tag,
            metakeyword,
            layouts,
            nsection,
            ncategory2,
            ncategory3,
            ntag,
            nmetakeyword,
            nlayouts,
            accessTypes,
            excludeAccessTypes
        ).pipe(map((articles: { article: ArticleTopContentInterface[] }) => articles.article));
    }

    public generateArticuleCustom(urlCustom: string): Observable<ArticleTopContentInterface[]> {
        return this.httpClient
            .get<{ article: ArticleTopContentInterface[] }>(urlCustom)
            .pipe(map((articles: { article: ArticleTopContentInterface[] }) => articles.article));
    }

    private getArticleRecommendation(
        mediaId: string,
        type: number,
        limit: number,
        section: string,
        category2: string,
        category3: string,
        tag: string,
        metakeyword: string,
        layouts: string,
        nsection: string,
        ncategory2: string,
        ncategory3: string,
        ntag: string,
        nmetakeyword: string,
        nlayouts: string,
        accessTypes: string,
        excludeAccessTypes: string,
    ): Observable<{ article: ArticleTopContentInterface[] }> {
        const urlRecommendation = this.getUrl(
            mediaId,
            type,
            limit,
            section,
            category2,
            category3,
            tag,
            metakeyword,
            layouts,
            nsection,
            ncategory2,
            ncategory3,
            ntag,
            nmetakeyword,
            nlayouts,
            accessTypes,
            excludeAccessTypes,
        );
        return this.httpClient.get<{ article: ArticleTopContentInterface[] }>(urlRecommendation);
    }

    public getUrl(
        mediaId: string,
        type: number,
        limit: number,
        section: string,
        category2: string,
        category3: string,
        tag: string,
        metakeyword: string,
        layouts: string,
        nsection: string,
        ncategory2: string,
        ncategory3: string,
        ntag: string,
        nmetakeyword: string,
        nlayouts: string,
        accessTypes: string,
        excludeAccessTypes: string,
    ): string {
        const queryParamMediaId = `media_id=${mediaId}`;
        const queryParamSection = (section && `&section=${section}`) || '';
        const queryParamCategory2 = (category2 && `&sub_section=${category2}`) || '';
        const queryParamCategory3 = (category3 && `&category3=${category3}`) || '';
        const queryParamType = (type && `&type=${type}`) || 1;
        const queryParamTag = (tag && `&tag=${encodeURIComponent(tag)}`) || '';
        const queryParamMetakeywords = (metakeyword && `&metakeyword=${encodeURIComponent(metakeyword)}`) || '';
        const queryParamLayouts = (layouts && `&layouts=${layouts}`) || '';
        const queryParamSkipDefaultLayout = '&skipDefaultLayout=true';
        const queryParamNSection = (nsection && `&nsection=${nsection}`) || '';
        const queryParamNCategory2 = (ncategory2 && `&nsub_section=${ncategory2}`) || '';
        const queryParamNCategory3 = (ncategory3 && `&ncategory3=${ncategory3}`) || '';
        const queryParamNTag = (ntag && `&ntag=${encodeURIComponent(ntag)}`) || '';
        const queryParamNMetakeywords = (nmetakeyword && `&nmetakeyword=${encodeURIComponent(nmetakeyword)}`) || '';
        const queryParamNLayouts = (nlayouts && `&nlayouts=${nlayouts}`) || '';
        const queryParamsAccessTypes = (accessTypes && `&accessTypes=${accessTypes}`) || '';
        const queryParamsExcludeAccessTypes = (excludeAccessTypes && `&excludeAccessTypes=${excludeAccessTypes}`) || '';
        //TODO: Revisar la construcción de la URL
        const urlRecommendation = `${CONSTANTS.API_REST_HENNEO_RECOMMENDATIONS_RECOMMENDATION_BY_TYPE}?${queryParamMediaId}${queryParamTag}${queryParamMetakeywords}${queryParamSection}${queryParamCategory2}${queryParamCategory3}${queryParamType}${queryParamLayouts}${queryParamSkipDefaultLayout}&limit=${limit}${queryParamNTag}${queryParamNMetakeywords}${queryParamNSection}${queryParamNCategory2}${queryParamNCategory3}${queryParamNLayouts}${queryParamsAccessTypes}${queryParamsExcludeAccessTypes}`;
        return urlRecommendation;
    }
}
