<div *ngIf="metrics && metrics.length" class="row card elevation mt-3 p-4">
  <div class="col-md-12">
    <h4 class="font-weight-bold">TOP contenidos</h4>
    <form *ngIf="filterForm" [formGroup]="filterForm" class="mx-5 p-6 mt-4">
      <div class="d-flex justify-content-end">

        <mat-form-field appearance="outline" class="c-mat-form c-mat-form--no-padding col-4">
          <mat-label>URL de la noticia</mat-label>
          <input formControlName="eventUrl" matInput />
        </mat-form-field>

      </div>
    </form>
    <app-generic-table [thereIsData]="!!dataSource?.filteredData?.length" [dataSource]="dataSource" [columns]="columns"
      [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" tableStyle="c-statistics-push-top-content-components-table">
    </app-generic-table>
  </div>
</div>
