import { Component, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
// import { SwiperOptions } from 'swiper/types/swiper-options'; // It should work with typescript 5+
import { ArticleInterface } from '../../../core/interfaces/article.interface';
import { trackById } from '../../utils/utils';

@Component({
  selector: 'app-articles-viewer',
  templateUrl: './articles-viewer.component.html',
})
export class ArticlesViewerComponent {
  @ViewChild('expansionPanel') public expansionPanel: MatExpansionPanel;

  @Input() data: ArticleInterface[] = [];
  @Input() items = 4;
  @Input() expanded = false;

  readonly config /*: SwiperOptions*/ = {
    slidesPerView: this.items,
    spaceBetween: 30,
    navigation: true,
    autoHeight: true,
    pagination: {
      clickable: true,
      type: 'bullets',
    },
  };
  readonly trackById = trackById;

  setExpanded(value: boolean): void {
    this.expansionPanel.expanded = value;
  }
}
