import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import TestAbInterface from '../interfaces/trigger/test-ab.interface';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

const TESTAB_COLLECTION = 'test-ab';

@Injectable({
  providedIn: 'root'
})
export class TestAbService {

  public testsAbTotal = 0;
  private testAbCollection: AngularFirestoreCollection<TestAbInterface>;

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly authService: AuthService,
    private readonly httpClient: HttpClient
  ) {
    this.testAbCollection = this.firestore.collection<TestAbInterface>(TESTAB_COLLECTION);
  }

  getAllByMedia(siteId: string) {
    return this.firestore
            .collection<TestAbInterface>(
              TESTAB_COLLECTION,
              (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges();
  }

  public getActiveBySite(siteId: string): Observable<TestAbInterface[]> {
      return this.firestore
            .collection<TestAbInterface>(
            TESTAB_COLLECTION,
              (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', true)
            )
            .valueChanges();
  }

  public getActiveIdBySite(siteId: string): Observable<string[]> {
    return this.getActiveBySite(siteId).pipe(map((testsAb: TestAbInterface[]): string[] =>
                                              testsAb.map((testAb: TestAbInterface): string => testAb.id)));
}

  getById(id: string) {
    return this.testAbCollection.doc<TestAbInterface>(id).valueChanges();
  }

  add(testAb: TestAbInterface, isIdCreated: boolean) {
    testAb.id = !isIdCreated ? this.firestore.createId() : testAb.id;
    testAb.createdAt = firebase.firestore.Timestamp.now();
    testAb.updatedAt = firebase.firestore.Timestamp.now();
    testAb.updatedBy = this.authService.getEmail();
    return from(this.testAbCollection.doc<TestAbInterface>(testAb.id).set(Object.assign({}, testAb)));
  }

  update(id: string, testAb: Partial<TestAbInterface>) {
    testAb.updatedAt = firebase.firestore.Timestamp.now();
    testAb.updatedBy = this.authService.getEmail();
    return from(this.testAbCollection.doc<TestAbInterface>(id).update(Object.assign({}, testAb)));
  }

  delete(id: string){
    return from(this.testAbCollection.doc<TestAbInterface>(id).delete())
  }
}
