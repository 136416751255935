import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { switchMap, take } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { ConfigurationInterface } from '../interfaces/configuration.interface';
import { SiteInterface } from 'src/app/core/interfaces/site.interface';
import { ConstantsRealTimeSegments } from 'src/app/shared/utils/costants-real-time-segments';
import { TriggerService } from './trigger.service';
import { TriggerInterface } from 'src/app/core/interfaces/trigger.interface';
import { NewslettersSubscriptionInterface } from '../interfaces/newsletters-subscription.interface';
import { PushSubscriptionInterface } from '../interfaces/push-subscription.interface';
interface DeleteCacheResponse {
    success: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    constructor(private readonly httpClient: HttpClient,
      private readonly triggerService: TriggerService) {}

    deleteCacheForWidget(mediaId: string, widgetId: string): Observable<any> {
        const DELETE_CACHE_WIDGET_URL = CONSTANTS.API_REST_DELETE_WIDGET_MODAL.replace(':mediaId', mediaId).replace(
            ':widgetId',
            widgetId
        );
        return this.httpClient.delete<any>(DELETE_CACHE_WIDGET_URL).pipe(take(1));
    }

    deleteCacheForWidgetApp(mediaId: string, widgetId:string): Observable<any> {
        const DELETE_CACHE_WIDGET_URL = CONSTANTS.API_REST_DELETE_WIDGET_APP
          .replace(':mediaId', mediaId)
          .replace(':widgetId', widgetId)
        return this.httpClient.delete<any>(DELETE_CACHE_WIDGET_URL).pipe(take(1));
    }

    deleteCacheForTestABWidget(mediaId: string, idTestAbWidget: string): Observable<DeleteCacheResponse> {
        const deleteCacheTestABWidgetUrl = CONSTANTS.API_REST_DELETE_TESTAB_WIDGET.replace(':mediaId', mediaId).replace(
            ':idTestAbWidget',
            idTestAbWidget
        );
        return this.httpClient.delete<DeleteCacheResponse>(deleteCacheTestABWidgetUrl).pipe(take(1));
    }

    deleteCacheForTriggerApp(mediaId: string): Observable<any> {
        const DELETE_CACHE_TRIGGER_URL = CONSTANTS.API_REST_DELETE_TRIGGER_APP.replace(':mediaId', mediaId);
        return this.httpClient.delete<any>(DELETE_CACHE_TRIGGER_URL).pipe(take(1));
    }

    deleteCacheForRealTimeSegmentsApp(mediaId: string): Observable<any> {
        const DELETE_CACHE_REAL_TIME_SEGMENT_URL = CONSTANTS.API_REST_DELETE_REAL_TIME_SEGMENT_APP.replace(':mediaId', mediaId);
        return this.httpClient.delete<any>(DELETE_CACHE_REAL_TIME_SEGMENT_URL).pipe(take(1));
    }

    deleteCacheForDatasourceApp(mediaId: string, type: string, datasourceId: string): Observable<any> {
      const DELETE_CACHE_DATASOURCE_APP_URL = CONSTANTS.API_REST_DELETE_DATASOURCE_APP
          .replace(':mediaId', mediaId)
          .replace(':type', type)
          .replace(':datasourceId', datasourceId);
      return this.httpClient.delete<any>(DELETE_CACHE_DATASOURCE_APP_URL).pipe(take(1));
    }

    deleteCacheForDatasource(mediaId: string, type: string, datasourceId: string): Observable<{ success: boolean }> {
        const DELETE_CACHE_DATASOURCE_URL = CONSTANTS.API_REST_DELETE_DATASOURCE.replace(':mediaId', mediaId)
            .replace(':type', type)
            .replace(':datasourceId', datasourceId);
        return this.httpClient.delete<{ success: boolean }>(DELETE_CACHE_DATASOURCE_URL).pipe(take(1));
    }

    updateCacheForConfig(mediaId: string, configuration: ConfigurationInterface): Observable<{ message: string }> {
        const UPDATE_CACHE_CONFIG_URL = CONSTANTS.API_REST_UPDATE_CONFIG.replace(':mediaId', mediaId);
        return this.httpClient.post<{ message: string }>(UPDATE_CACHE_CONFIG_URL, configuration).pipe(take(1));
    }

    updateCacheForPrometeoConfig(mediaId: string, prometeoConfiguration: Partial<SiteInterface>): Observable<{ message: string }> {
        const UPDATE_CACHE_PROMETEO_CONFIG_URL = CONSTANTS.API_REST_UPDATE_PROMETEO_CONFIG.replace(':mediaId', mediaId);
        return this.httpClient.post<{ message: string }>(UPDATE_CACHE_PROMETEO_CONFIG_URL, prometeoConfiguration).pipe(take(1));
    }

    deleteSegmentsForWidgetCache(mediaId: string): Observable<any> {
      const params = new HttpParams().set('mediaId', mediaId);
      return this.httpClient.delete(ConstantsRealTimeSegments.deleteWidgetsSegmentsCacheUrl, { params }).pipe(take(1));
    }

    deleteSegmentsForDatasourceCache(mediaId: string): Observable<any> {
      const params = new HttpParams().set('mediaId', mediaId);
      return this.httpClient.delete(ConstantsRealTimeSegments.deleteDatasourceSegmentsCacheUrl, { params }).pipe(take(1));
    }

    deleteSegmentsForTriggerCache(mediaId: string): Observable<any> {
      const params = new HttpParams().set('mediaId', mediaId);
      return this.httpClient.delete(ConstantsRealTimeSegments.deleteTriggersSegmentsCacheUrl, { params }).pipe(take(1));
    }

    updateSegmentVersion(mediaId: string): Observable<any> {
      const versionUrl = CONSTANTS.API_REST_UPDATE_SEGMENT_VERSION.replace(':mediaId', mediaId);
      return this.httpClient.post<{ message: string }>(versionUrl, {}).pipe(take(1));
    }

    updateTriggerVersion(media: string): Observable<{ message: string }> {
        const versionUrl = CONSTANTS.API_REST_UPDATE_TRIGGER_VERSION.replace(':mediaId', media);
        return this.httpClient.post<{ message: string }>(versionUrl, {}).pipe(take(1));
    }

    updateSegmentsCacheForWidgets(mediaId: string): Observable<any> {
      return this.deleteSegmentsForWidgetCache(mediaId).pipe(
        switchMap(() => this.updateSegmentVersion(mediaId))
      );
    }

    updateSegmentsCacheForDatasource(mediaId: string): Observable<any> {
      return this.deleteSegmentsForDatasourceCache(mediaId).pipe(
        switchMap(() => this.updateSegmentVersion(mediaId))
      );
    }

    updateSegmentsCacheForTriggers(mediaId: string): Observable<any> {
      return this.deleteSegmentsForTriggerCache(mediaId).pipe(
        switchMap(() => this.updateSegmentVersion(mediaId))
      );
    }

    triggersCacheActivationProcessor(triggerId: string, collection: string): Observable<any>{
      const url = CONSTANTS.TRIGGER_ACTIVATION_URL;
      const data = {
        triggerId,
        collection
      };
      return this.httpClient.post(url, data).pipe(take(1));
    }

    triggersCacheInactivationProcessor(triggerId: string, collection: string): Observable<any>{
      const url = CONSTANTS.TRIGGER_INACTIVATION_URL;
      const data = {
        triggerId,
        collection
      };
      return this.httpClient.post(url, data).pipe(take(1));
    }

    triggersPurgeScheduledJobs(triggerId: string, collection: string): Observable<any>{
      const url = CONSTANTS.TRIGGER_PURGE_SCHEDULE_JOBS_URL;
      const data = {
        triggerId,
        collection
      };
      return this.httpClient.post(url, data).pipe(take(1));
    }

    triggersCacheProcessors(trigger: TriggerInterface | NewslettersSubscriptionInterface | PushSubscriptionInterface, collection: string) {
      if(trigger.programDates.length && trigger.hasPeriodicSchedule){
        return combineLatest([
          this.triggersCachePunctualProcessor(trigger.id, collection),
          this.triggersCachePeriodicProcessor(trigger.id, collection)
        ]);
      }
      if(trigger.hasPeriodicSchedule){
        return combineLatest([
          this.triggersCacheInactivationProcessor(trigger.id, collection),
          this.triggersCachePeriodicProcessor(trigger.id, collection)
        ]);
      }
      if(trigger.programDates.length){
        return this.triggersCachePunctualProcessor(trigger.id, collection);
      }
      if(trigger.active) return this.triggersCacheActivationProcessor(trigger.id, collection);
      if(!trigger.active) return this.triggersCacheInactivationProcessor(trigger.id, collection);
    }

    triggersCachePunctualProcessor(triggerId: string, collection: string): Observable<any>{
      const url = CONSTANTS.TRIGGER_PUNCTUAL_TASK_URL;
      const data = {
        triggerId,
        collection,
        operation: CONSTANTS.TRIGGER_TAG_TASK,
      };
      return this.httpClient.post(url, data).pipe(take(1));
    }

    triggersCachePeriodicProcessor(triggerId: string, collection: string): Observable<any>{
      const url = CONSTANTS.TRIGGER_PERIODIC_TASK_URL;
      const data = {
        triggerId,
        collection,
        operation: CONSTANTS.TRIGGER_TAG_TASK
      };
      return this.httpClient.post(url, data).pipe(take(1));
    }
}
