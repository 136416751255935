export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export const REALTIME = {
  REFERENCES: {
    LANGUAGE: '/mediaConfig/language',
    HTML: '/mediaConfig/public/html',
  },
};

export const ACTIONS = {
  INSPECT: { id: 1, name: 'Ver detalles' },
  UPDATE: { id: 2, name: 'Editar' },
  COPY: { id: 3, name: 'Copiar' },
  DELETE: { id: 4, name: 'Eliminar' },
  ARCHIVE: { id: 5, name: 'Cambiar estado' },
  CONTRACT_DETAILS: { id: 6, name: 'Ver contratos' },
  CUSTOMER_DETAILS: { id: 7, name: 'Ver cliente' },
  SUBSCRIPTION_DETAILS: { id: 8, name: 'Ver suscripción' },
  PRODUCT_DETAILS: { id: 9, name: 'Ver producto' },
  OPERATION_DETAILS: { id: 10, name: 'Ver operación' },
};

export const ENDPOINTS = {
  PRODUCTS: {
    COLLECTION: '/api/products',
    SEARCH: '/api/products/search',
    PRIORITY: '/api/products/priority',
  },
  CUSTOMERS: {
    COLLECTION: '/api/customers',
    TAX_ID_TYPE: '/api/customers/taxid-type',
  },
  CAMPAIGNS: {
    COLLECTION: '/api/campaigns',
  },
  TRACEABILITY: {
    COLLECTION: '/api/campaigns-traceability',
  },
  SUBSCRIPTION_TYPES: '/api/subscription-types',
  TAXES: {
    COLLECTION: '/api/taxes',
    ARCHIVE: '/api/taxes/archive',
    SEARCH: '/api/taxes/search',
  },
  SUPPORT: '/api/support',
  GIGYA: '/api/gigya',
  SUBSCRIPTIONS: {
    COLLECTION: '/api/subscriptions',
    GRACE_CANCEL: (id: string): string => `${ENDPOINTS.SUBSCRIPTIONS.COLLECTION}/${id}/grace-cancel`,
  },
  SCHEDULES: { COLLECTION: '/api/schedules' },
  OPERATIONS: { COLLECTION: '/api/operations' },
  REFUNDS: { COLLECTION: '/api/refunds' },
  CONFIG: {
    REASONS: '/api/config/reasons',
    TYPES: '/api/config/types',
    LANGUAGES: '/api/config/languages',
  },
  MAINTENANCE: {
    FORCE_RETROACTIVITY: '/api/maintenance/force-retroactivity',
  },
  RRM: {
    BASE: '/api/rrm',
    CUSTOMERS: '/api/rrm/monetization/events/customers',
    LINKING: {
      ENTITLEMENTS: (gigyaUid: string): string => `/api/rrm/linking/${gigyaUid}/entitlements`,
    },
    ENTITLEMENTS: '/entitlements',
    MONETIZATION: {
      EVENTS: '/api/rrm/monetization/events',
      PLANS: (readerId: string, planId?: string): string => {
        const base = `/api/rrm/monetization/${readerId}/entitlements`;
        return planId ? `${base}/${planId}` : base;
      },
      ORDER: (readerId: string, orderId: string): string => `/api/rrm/monetization/${readerId}/order/${orderId}`,
    },
  },
  AUTH: {
    LOGIN: '/api/auth/login',
  },
};

export enum ENTRYPOINTS {
  CREATE = 'create',
  COPY_FROM = 'copy-from',
  EDIT = 'edit',
  INSPECT = 'inspect',
  FORM = 'form',
  STRIPE = 'stripe',
}

export const STRIPE_PRODUCT_IMAGES = 8;

export const TEXTS = {
  GENERIC: {
    COPY_NAME: 'copia',
  },
  PRODUCT: 'Producto',
};

export const ASYNC_VALIDATOR_PULSE = 500;

export const FILTER_APPLY_DELAY = 500;

export const TIME_SNACK_BAR = 5000;

//TODO revisar si se requiere aún mas especificidad, por ahora un slug puede ser '-----------' por ejemplo
export const SLUG_PATTERN = {
  ACCEPTED: new RegExp(/^[a-z0-9-]+$/),
  REJECTED: new RegExp(/[^a-z0-9-]/g),
  REPLACE: '-',
};

export const MODE = {
  CREATE: 'create',
};

export const PUBSUB_MIN_NAME_LENGTH = 3;

export const TEXT = {
  //PUBSUB CONFIG SECTION
  PUBSUB: {
    TOPIC_ID: 'paywall-event',
    SUB_ID: 'paywall-webhook',
    STATUS: {
      INVALID: 'INVALID',
      VALID: 'VALID',
    },
    FIELD_ENDPOINT: 'endpoint',
    FIELD_SUBSCRIPTION: 'nombre de suscripción',
  },

  //GOOGLE ANALYTICS CONFIG SECTION
  GA_EXAMPLE: {
    SCRIPT: `<!-- Google Tag Manager -->
    <script>
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-*******');
    </script>
<!-- End Google Tag Manager -->`,
    NO_SCRIPT: `<!-- Google Tag Manager (noscript) -->
    <noscript><iframe src='https://www.googletagmanager.com/ns.html?id=GTM-*******'
    height='0' width='0' style='display:none;visibility:hidden'></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->`,
  },

  //CAMPAIGN SECTION
  CAMPAIGN_HELP:
    'El nombre que se dé a la campaña será el identificador, en un futuro este identificador no podrá ser editado.',
  ID_EXISTS: 'ID ya registrado.',
  ID_LENGTH: 'ID demasiado corto, debe tener al menos tres carácteres.',

  //TAX SECTION
  PERCENTAGE_ZERO: 'El porcentaje debe ser mayor que cero.',
  PERCENTAGE_HELP: 'No podrá ser editado.',
};

export enum STRIPE_END_BEHAVIOR {
  CANCEL = 'cancel',
  RELEASE = 'release',
}

export enum PaywallTranslation {
  // TODO convendría usar enums en las claves, revisar stripe-status.ts
  // Prueba de concepto para alternativa de traducción:
  // https://www.typescriptlang.org/play?#code/KYOwrgtgBAygqgIRgYQEoEkAKAVdB5AOQH0ZsBBbOGKAbwCgpGp0Dk8BZTAGQFFseoAXigByAJYgAxgHsIABwA2wAC7ARAGgZMWbTr35EeADUzpUPACJDREmfKWqiwAB5yxAJ2AATDVsbYMMi4WAHFrEWV3MQBDBQkAc18mKDJkXAA1AWERaMllMQA3NU1kzDJSIgs4LNE5aIBnZSIvMGK-KGQyVh5eK2zJaKlgJR8SpjgCMvQ+0TAQOrFR9oI8bBJyVH4ZkRBpJsbo91UlgF86OhkQRthEFAwcfGJSCioiAK6YLgpLAC4oVGAMncXgAPPAkGgsLhCOsXjB1FBGlEQPEAHzWejJADa4LuUMesMoMAAdDoONw+DwALp-EToKSyRQqaJJJg426Qh4w55E0mscn6HiGExmSw00Q8VweaJeFljRjsiH3aFPci8gLoIKhcUidjSLzSKBydytABGcvairxXNVcOJqQy1NpZDyhQt2NxnJVhKoxLKFSqTtE6VAkkW7rZnuVBJ5vs63V6OuQg0kwxlEYVUfx3LVvomUwsOvQ8mi8XTrMzHOjObtKzWz02YtpBENwHkwAAXuXNGcLtIrsooP3gBjEcposowPVaXVGs1WiJGGdLtdlAB3Q3CGhjidT2mRGJxFGLho3JXZ21EqC9lfSJTEhTSeIACizNp9MDeqA+Xy2WOHxIHJO9RQKeb7erGMBUgAlAA3H2Vx3sAD5Pq+VYXh+X4-t8FhYuu0iAeOwEwbBQA

  //INVOICE STATUS
  paid = 'Pagado',
  open = 'Incompleto',
  void = 'Cancelado',
  uncollectible = 'Anulado',
  draft = 'En proceso',

  //SUBSCRIPTION STATUS
  incomplete = 'Incompleta',
  trialing = 'Modo prueba',
  active = 'Activa',
  canceled = 'Finalizada', //Tener en cuenta para definiciones
  unpaid = 'Impagada',

  /* eslint-disable @typescript-eslint/camelcase */
  // TODO dato traído directamente desde Stripe, por eso esta en snake_case, queda pendiente realizar proceso de "conversión" de estos datos a camelCase
  incomplete_expired = 'Expirada',
  past_due = 'Vencida',
  not_started = 'No empezada',
  /* eslint-enable */

  //PAYMENT INTENT FAILED REASON
  technicalCancellation = 'Cancelación por baja técnica',

  //PAYMENT METHOD
  card = 'Tarjeta de crédito',
}

export const SUPPORT = {
  COMMENTS: {
    HIPATIA_OPERATION: 'Operación desde Hipatia',
  },
  // This should mirror backend types on src/shared/constants/constants-support.ts
  // enum SUPPORT_REASONS and SUPPORT_TYPE
  REASONS: {
    UNWORKING_SERVICE: 'unworkingService',
    EDITORIAL_DISAGREEMENT: 'editorialDisagreement',
    NOT_INTERESTED: 'notInterested',
    OTHER_MEDIUM: 'otherMedium',
    IS_A_GIFT: 'isAGift',
    MISTAKE_NEWS_STAND: 'mistakeNewsStand',
    EXCESSIVE_ADVERTISING: 'excessiveAdvertising',
    COMMENT_SERVICE: 'commentService',
    TOO_EXPENSIVE: 'tooExpensive',
    UNUSED_PRODUCT: 'unusedProduct',
    OTHER: 'other',
    TECHNICAL_CANCELLATION: 'technicalCancellation',
    GRACE_CANCEL: 'graceCancel',
  },
  TYPES: {
    RENOVATION_CANCELED: 'renovationCanceled',
    COMMENT: 'comment',
    REFUND: 'refund',
    DATE_CHANGE: 'dateChange',
    DELETED: 'deleted',
  },
};

export enum HTTP {
  DELETE = 'DELETE',
}

export enum PRICE_TYPE {
  INITIAL = 'initial',
  RENOVATION = 'renovation',
}

export const STANDARD_PAGES = [
  { id: 'showcase', order: 1, restricted: true },
  { id: 'checkout', order: 2, restricted: true },
  { id: 'success', order: 3, restricted: true },
  { id: 'error', order: 4, restricted: true },
  { id: 'recovery', order: 5, restricted: true },
  { id: 'suscripciones', order: 6, restricted: false }, // account/
  { id: 'metodos-de-pago', order: 7, restricted: false }, // account/
];

export enum SUBSCRIPTION_MODE {
  CURRENT = 'subscription', // Subscription with or without attached schedule
  FUTURE = 'schedule', // Schedule not started, without subscription
}

export const DIALOG_WIDTH = '85vw';

// TODO cambiar el valor igualado a número y poner comentario de mantener alineado con el back
export enum ALERT_LEVEL {
  OFF = 'OFF',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CATASTROPHE = 'CATASTROPHE',
  APOCALYPTIC = 'APOCALYPTIC',
}

export const REQUEST_RETRIES = {
  COUNT: 5,
  DELAY: 1_000, // milliseconds
  STATUS_WHITE_LIST: [400, 403, 404, 412, 500],
};

// TODO: Acoplado al api de Paywall. Hay que documentar los tipos de errores en el Swagger
export enum API_ERROR_CODE {
  INVALID_TAX_ID = 'invalid-tax-id',
  DISABLE_TAX = 'disable-tax',
  ACTIVE_PRODUCT = 'active-product',
  PRODUCT_DELETED = 'product-deleted',
  CONFIG = 'config-error',
  CAMPAIGN = 'campaign-error',
  DELETE_CAMPAIGN = 'delete-campaign',
  PRODUCT = 'product-error',
  UNKNOWN_ERROR = 'unknown-error',
}

export enum PRODUCT_CUSTOMER_CONDITION {
  NO_RESTRICTION = 'noRestriction',
  NEW_CLIENTS = 'newClients',
  SUBSCRIBERS = 'subscribers',
  EXSUBSCRIBERS = 'exSubscribers',
}

export enum CUSTOMER_CONDITION {
  NEW_CLIENT = 'newClient',
  SUBSCRIBER = 'subscriber',
  EXSUBSCRIBER = 'exSubscriber',
}

export const PAGINATE_BASE_SIZE = 50;

export const PAGINATE_SIZE_RESTRICTED_OPTIONS = [10]; // TODO meter en el resto de componentes de paginado progresivo

export const CUSTOMER_DELETED = 'Cliente borrado';

export enum PRICE_CONDITION {
  INITIAL = 'inicial',
  RENOVATION = 'de renovación',
  INTERMEDIATE = 'intermedia',
}

export const rrmTrial = [0, 7, 14, 30, 60];

export const RRM_MIN_PRICE = 1;

export const RRM_PUBLISHER_CENTER_URL = new URL(`https://publishercenter.google.com/reader-revenue-manager`);
