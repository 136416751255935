import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { combineLatest, map } from 'rxjs/operators';
import { ImportedSegmentInterface } from '../interfaces/query-builder/imported-segment.interface';
import { SegmentRealTimeInterface } from '../interfaces/query-builder/segment-real-time.interface';
import { SegmentHistoricInterface } from '../interfaces/segment-historic.interface';
import { ImportedSegmentsService } from './imported-segments.service';
import { RealTimeSegmentService } from './real-time-segment.service';
import { SegmentService } from './segment.service';

@Injectable()
export class AllSegmentsService {
    constructor(
        private realTimeSegmentService: RealTimeSegmentService,
        private historicalSegmentService: SegmentService,
        private importedSegmentService: ImportedSegmentsService
    ) {}

    getAllBySite(siteId: string): Observable<any> {
        const segments$: Observable<SegmentHistoricInterface[]> = this.historicalSegmentService
            .getSegmentsByMedia(siteId, true)
            .pipe(
                map((segments: SegmentHistoricInterface[]): SegmentHistoricInterface[] =>
                    segments.map(
                        (segment: SegmentHistoricInterface): SegmentHistoricInterface => {
                            segment.historical = true;
                            segment.name = segment.segmentName;
                            segment.segmentID = segment.idSegment;
                            return segment;
                        }
                    )
                )
            );
        const segmentsRealTime$: Observable<SegmentRealTimeInterface[]> = this.realTimeSegmentService
            .getAllBySite(siteId, true)
            .pipe(
                map((segments: SegmentRealTimeInterface[]): SegmentRealTimeInterface[] =>
                    segments.filter((segment: SegmentRealTimeInterface): boolean => segment.status)
                ),
                map((segments: SegmentRealTimeInterface[]): SegmentRealTimeInterface[] =>
                    segments.map(
                        (segment: SegmentRealTimeInterface): SegmentRealTimeInterface => {
                            segment.historical = false;
                            return segment;
                        }
                    )
                )
            );
        const importedSegments$: Observable<ImportedSegmentInterface[]> = this.importedSegmentService
            .getAllBySite(siteId)
            .pipe(
                map(segments => segments.filter(s => s.active)),
                map(segments =>
                    segments.map(s => ({
                        historical: false,
                        name: s.segmentName,
                        segmentID: s.idSegment,
                        ...s,
                        imported: true,
                    }))
                )
            );
        return segments$.pipe(
            combineLatest(segmentsRealTime$, importedSegments$),
            map(
                ([segments, segmentsRealTime, importedSegments]: [
                    SegmentHistoricInterface[],
                    SegmentRealTimeInterface[],
                    ImportedSegmentInterface[]
                ]): any[] => [...segments, ...segmentsRealTime, ...importedSegments]
            )
        );
    }

    getAllBySiteApp(siteId: string, typePlatform: string): Observable<any> {
        const segments$: Observable<SegmentHistoricInterface[]> = this.historicalSegmentService
            .getAllSegmentsAppsBySite(siteId)
            .pipe(
                map((segments: SegmentHistoricInterface[]): SegmentHistoricInterface[] =>
                    segments.map(
                        (segment: SegmentHistoricInterface): SegmentHistoricInterface => {
                            segment.historical = true;
                            segment.name = segment.segmentName;
                            segment.segmentID = segment.idSegment;
                            return segment;
                        }
                    )
                )
            );
        const segmentsRealTime$: Observable<SegmentRealTimeInterface[]> = this.realTimeSegmentService
            .getAllAppBySite(siteId, typePlatform)
            .pipe(
                map((segments: SegmentRealTimeInterface[]): SegmentRealTimeInterface[] =>
                    segments.filter((segment: SegmentRealTimeInterface): boolean => segment.status)
                ),
                map((segments: SegmentRealTimeInterface[]): SegmentRealTimeInterface[] =>
                    segments.map(
                        (segment: SegmentRealTimeInterface): SegmentRealTimeInterface => {
                            segment.historical = false;
                            return segment;
                        }
                    )
                )
            );
        return segments$.pipe(
            combineLatest(segmentsRealTime$),
            map(([segments, segmentsRealTime]: [SegmentHistoricInterface[], SegmentRealTimeInterface[]]): any[] => [
                ...segments,
                ...segmentsRealTime,
            ])
        );
    }
}
