import { PUSH_NOTIFICATION_STATISTIC_STATUS, PUSH_NOTIFICATION_STATISTIC_DEVICES } from "src/app/components/push-notifications/web/scheduled/config/constants";
import { StatisticsPushNotificationInterface } from "../../interfaces/statistics/statistics-push-notification.interface";


export class StatisticsPushNotificationModel implements StatisticsPushNotificationInterface {
  totalActivations: number;
  eventId: string;
  articleTitle: string;
  eventUrl: string;
  eventAction: PUSH_NOTIFICATION_STATISTIC_STATUS;
  mediaId: string;
  topic: string;
  eventDateTime: Date;
  uaDevice: PUSH_NOTIFICATION_STATISTIC_DEVICES;
  uaPlatform: string;
  uaOs: string;
  uaVersion: string;
  uaBrowser: string;

  private constructor(data?: Partial<StatisticsPushNotificationModel>) {
    if (!data) return;
    Object.assign(this, data);
  }

  static fromVPCPost(origin: any): StatisticsPushNotificationModel {
    const pushEvents = new StatisticsPushNotificationModel(origin);
    pushEvents.eventDateTime = pushEvents.getClientBasedDate(pushEvents.eventDateTime);
    return pushEvents;
  }

  private getClientBasedDate(eventDateTime: any): Date {
    return new Date(new Date(eventDateTime).getTime() - new Date().getTimezoneOffset() * 60 * 1000);
  }

}
