export class ConstantsTriggersApps {
    public static readonly PROMETEO_TRIGGERS_APP_MODAL_TYPE: number = 9;
    public static readonly PROMETEO_TRIGGERS_APP_BOX_TYPE: number = 10;
    public static readonly PROMETEO_TRIGGERS_APP_BOX_WALL_TYPE: number = 11;
    public static readonly PROMETEO_TRIGGERS_APP_MODAL_TYPE_LITERAL: string = 'Modal';
    public static readonly PROMETEO_TRIGGERS_APP_BOX_TYPE_LITERAL: string = 'Box';
    public static readonly PROMETEO_TRIGGERS_APP_BOX_WALL_LITERAL: string = 'Box-Wall';

    public static readonly PROMETEO_TRIGGERS_APP_FMT_MODAL_FS: string = 'FMT-MODAL-FS';
    public static readonly PROMETEO_TRIGGERS_APP_FMT_MODAL_SIMPLE: string = 'FMT-MODAL-SIMPLE';
    public static readonly PROMETEO_TRIGGERS_APP_FMT_BOX: string = 'FMT-BOX';
    public static readonly PROMETEO_TRIGGERS_APP_FMT_BOX_WALL: string = 'FMT-BOX-WALL';

    public static readonly WALL_POSITION_ID = 'wall';
    public static readonly WALL_POSITION_LABEL = 'Muro';

    public static readonly PROMETEO_TRIGGERS_APP_TYPES = [
        { id: 'dboxm', label: 'Artículo y opinión 0' },
        { id: 'dbox1', label: 'Artículo y opinión 1' },
        { id: 'dbox2', label: 'Artículo y opinión 2' },
        { id: 'pbox1', label: 'Portada 1' },
        { id: 'pbox2', label: 'Portada 2' },
        { id: 'pbox3', label: 'Portada 3' },
        { id: 'pbox4', label: 'Portada 4' },
        { id: ConstantsTriggersApps.WALL_POSITION_ID, label: ConstantsTriggersApps.WALL_POSITION_LABEL },
    ];

    public static readonly PROMETEO_TRIGGERS_APP_SCREEN = [
        'Home',
        'Cpush',
        'Perfil',
        'Login',
        'Registro',
        'Suscripcion',
        'Guardar',
        'Explora',
        'Temas',
        'MisDatos',
        'Portadilla video',
    ];

    public static readonly PROMETEO_TRIGGERS_APP_ACTION = ['Cierre'];
}
