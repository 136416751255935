<div class="row">
    <div class="col-md-12">
        <mat-card class="c-article">
            <img
                mat-card-image
                class="c-article__img"
                [src]="article?.imageUrl"
                onerror="this.src='/assets/images/image-placeholder.png';"
            />
            <mat-card-content>
                <div class="align-middle d-flex row mb-2" *ngIf="activateObjectiveChecked" [ngStyle]="{'background-color': article?.isSomeObjetiveCompleted ? 'rgba(121, 210, 163, 0.3)' : 'rgba(155, 155, 179, 0.3)'}">
                    <span class="col-md-10">{{ article?.isSomeObjetiveCompleted ? 'Objetivo cumplido' : 'Objetivo no cumplido'}}</span>
                    <span class="material-icons col-md-2" [ngClass]="{ 'text-success': article?.isSomeObjetiveCompleted, 'text-primary': !article?.isSomeObjetiveCompleted}">
                        {{ article?.isSomeObjetiveCompleted ? 'check' : 'close'}}
                    </span>
                </div>
                <p class="c-article__title overflow-hidden">
                    {{ article && article.title != '' ? article.title : 'Título del widget' }}
                </p>
                <small class="c-article__signature overflow-hidden text-muted m-2">{{
                    article && article.signature != '' ? article.signature : 'Firma'
                }}</small>
            </mat-card-content>
            <mat-card-actions *ngIf="showButtons" class="text-center mb-2">
                <button mat-raised-button color="primary" class="mx-2" (click)="edit()">
                    <mat-icon class="align-text-top h5" fontSet="fas" fontIcon="fa-edit"></mat-icon>
                </button>
                <button *ngIf="showDeleteButton" mat-raised-button color="warn" class="mx-2" (click)="delete()">
                    <mat-icon class="align-text-top h5" fontSet="fas" fontIcon="fa-times"></mat-icon>
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
