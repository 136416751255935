import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { QueryBuilderModule } from 'angular2-query-builder';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ArticleComponent } from './components/article/article.component';
import { WidgetStatisticsChartComponents } from './components/widget-statistics-chart/widget-statistics-chart.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DualListComponent } from './components/dual-list/dual-list.component';
import { FilePickerComponent } from './components/image-picker/file-picker.component';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { ArticlesViewerComponent } from './components/articles-viewer/articles-viewer.component';
import { MatToastComponent } from './components/mat-toast/mat-toast.component';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
import { TemplatePreviewComponent } from './components/template-preview/template-preview.component';
import { WidgetPreviewComponent } from './components/widget-preview/widget-preview.component';
import { MetricsChartComponent } from './components/metrics-chart/metrics-chart.component';
import { ObjetivesChartComponent } from './components/objetives-chart/objetives-chart.component';
import { MaterialModule } from './material/material.module';
import { FillPipe } from './pipes/fill.pipe';
import { ModeToStringPipe } from './pipes/mode-to-string.pipe';
import { ObjetiveToStringPipe } from './pipes/objetive-to-string.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { SafeJavascriptPipe } from './pipes/safe-javascript.pipe';
import { ShareToStringPipe } from './pipes/share-to-string.pipe';
import { DatasourcesTypeToStringPipe } from './pipes/datasources-type-to-string.pipe';
import { ApiService } from './services/api.service';
import { ExcelService } from './services/excel.service';
import { FileSaverService } from './services/file-saver.service';
import { TriggersTypeToStringPipe } from './pipes/triggers-type-to-string.pipe';
import { TriggersComponentTypeToStringPipe } from './pipes/triggers-component-type-to-string.pipe';
import { TriggersLayoutToStringPipe } from './pipes/triggers-layout-to-string.pipe';
import { SchedulesContentTypeToStringPipe } from './pipes/schedules-content-type-to-string.pipe';
import { SchedulesScheduleTypeToStringPipe } from './pipes/schedules-schedule-type-to-string.pipe';
import { WeekdaysToStringPipe } from './pipes/weekdays-to-string.pipe';
import { TopicsService } from './services/topics.service';
import { NewsletterService } from './services/newsletter.service';
import { PushNotificationService } from './services/push-notification.service';
import { SchedulesEndpointTypeToStringPipe } from './pipes/schedules-endpoint-type.pipe';
import { ScheduleService } from './services/schedule-notification.service';
import { TemplateEditorComponent } from './components/html-template-editor/html-template-editor.component';
import { CampaignStatusTypeToStringPipe } from './pipes/campaign-status-type-to-string.pipe';
import { OrderListDragDropComponent } from './components/order-list-drag-drop/order-list-drag-drop.component';
import { TriggerRedisActionService } from './services/trigger-redis-action.service';
import { RecommendationService } from './services/recommendation.service';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { StructureSegmentTypeToStringPipe } from './pipes/structure-segment-type-to-string.pipe';
import { SpinnerWrapperComponent } from './components/spinner-wrapper/spinner-wrapper.component';
import { AmpTemplateEditorComponent } from './components/amp-template-editor/amp-template-editor.component';
import { RadioChartComponent } from '../components/query-builder/radio-chart/radio-chart.component';
import { PrevisualizationComponent } from './components/notifications/previsualization/previsualization.component';
import { TriggersPriorityComponent } from './components/triggers-priority/triggers-priority.component';
import { TriggersTestAbListComponent } from './components/triggers-test-ab/triggers-test-ab-list/triggers-test-ab-list.component';
import { TriggersTestAbFormComponent } from './components/triggers-test-ab/triggers-test-ab-form/triggers-test-ab-form.component';
import { ManualNotificationFormComponent } from './components/notifications/manual-notification-form/manual-notification-form.component';
import { DynamicNotificationFormComponent } from './components/notifications/dynamic-notification-form/dynamic-notification-form.component';
import { TypeNotificationFormComponent } from './components/notifications/type-form/type-form.component';
import { ScheduledNotificationFormComponent } from './components/notifications/scheduled-notification-form/scheduled-notification-form.component';
import { PeriodicallyNotificationFormComponent } from './components/notifications/periodically-notification-form/periodically-notification-form.component';
import { NoDataWrapperComponent } from './components/no-data-wrapper/no-data-wrapper.component';
import { GenericTableTitleComponent } from './components/generic-table-title/generic-table-title.component';
import { RouterModule } from '@angular/router';
import { FormTriggerAppComponent } from './components/form-trigger-app/form-trigger-app.component';
import { TriggerAppPreviewComponent } from './components/trigger-app-preview/trigger-app-preview.component';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import { GenericStatisticsSelectorComponent } from './components/generic-statistics-selector/generic-statistics-selector.component';
import {
  StatisticsArticlesComponent,
  StatisticsSegmentsComponent,
  StatisticsCategoriesComponent,
  StatisticsTriggerComponent,
  StatisticsTriggersMetricsComponent,
} from './components';

import { AlertsComponent } from './components/alerts/alerts.component';
import { ProgramDatePickerComponent } from './components/program-date-picker/program-date-picker.component';
import { InputValidationIconComponent } from './components/input-validation-icon/input-validation-icon.component';
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decima-number.directive';
import { StatisticsWidgetsSegmentsComponent } from './components/statistics-widgets-segments/statistics-widgets-segments.component';
import { GenericFilterSelectComponent } from './components/generic-filter-select/generic-filter-select.component';
import { ConstantMessageService } from './utils/constants-messages/constant-message.service';
import { AdvertisersService } from '../core/services/advertisers.service';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { AlertService } from './services/alert.service';
import { StatisticsPushNotificationTopicComponent } from './components/statistic-push-notification-topic/statistic-push-notification-topic.component';
import { StatisticsPushNotificationTopContentComponent } from './components/statistic-push-notification-topic/statistic-push-notification-top-content/statistic-push-notification-top-content.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { HtmlSanitizerService } from './services/html-sanitizer.service';
import { SwiperDirective } from './directives/swiper.directive';
import { FilterComponent } from './components/generic-filter/filter.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { ErrorListItemComponent } from './components/error-list-item/error-list-item.component';
import { FinishButtonsComponent } from './components/finish-buttons/finish-buttons.component';

const components = [
  ArticleComponent,
  DualListComponent,
  SelectCheckAllComponent,
  TriggersPriorityComponent,
  TriggersTestAbListComponent,
  TriggersTestAbFormComponent,
  JsonViewerComponent,
  ArticlesViewerComponent,
  FilePickerComponent,
  TemplateEditorComponent,
  WidgetPreviewComponent,
  MetricsChartComponent,
  ObjetivesChartComponent,
  TemplatePreviewComponent,
  DialogComponent,
  MatToastComponent,
  OrderListDragDropComponent,
  RadioChartComponent,
  WidgetStatisticsChartComponents,
  SpinnerWrapperComponent,
  AmpTemplateEditorComponent,
  PrevisualizationComponent,
  ManualNotificationFormComponent,
  DynamicNotificationFormComponent,
  TypeNotificationFormComponent,
  ScheduledNotificationFormComponent,
  PeriodicallyNotificationFormComponent,
  NoDataWrapperComponent,
  GenericTableTitleComponent,
  FormTriggerAppComponent,
  TriggerAppPreviewComponent,
  GenericTableComponent,
  AlertsComponent,
  ProgramDatePickerComponent,
  GenericStatisticsSelectorComponent,
  InputValidationIconComponent,
  StatisticsArticlesComponent,
  StatisticsSegmentsComponent,
  StatisticsCategoriesComponent,
  StatisticsTriggerComponent,
  StatisticsPushNotificationTopicComponent,
  StatisticsPushNotificationTopContentComponent,
  StatisticsTriggersMetricsComponent,
  StatisticsWidgetsSegmentsComponent,
  InputSearchComponent,
  HtmlEditorComponent,
  GenericFilterSelectComponent,
  FilterComponent,
  ErrorListComponent,
  ErrorListItemComponent,
  FinishButtonsComponent,
];

const modules = [
  CommonModule,
  FormsModule,
  MaterialModule,
  NgApexchartsModule,
  NgbModule,
  QueryBuilderModule,
  ReactiveFormsModule,
  RouterModule,
  SweetAlert2Module,
];

const directives = [
  TwoDigitDecimaNumberDirective,
  SwiperDirective,
];

const pipes = [
  CampaignStatusTypeToStringPipe,
  DatasourcesTypeToStringPipe,
  FillPipe,
  ModeToStringPipe,
  ObjetiveToStringPipe,
  SafeHTMLPipe,
  SafeJavascriptPipe,
  SafeUrlPipe,
  SchedulesContentTypeToStringPipe,
  SchedulesEndpointTypeToStringPipe,
  SchedulesScheduleTypeToStringPipe,
  ShareToStringPipe,
  StructureSegmentTypeToStringPipe,
  TriggersComponentTypeToStringPipe,
  TriggersLayoutToStringPipe,
  TriggersTypeToStringPipe,
  WeekdaysToStringPipe,
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes
  ],
  imports: [...modules, SweetAlert2Module.forRoot()],
  exports: [
    ...components,
    ...modules,
    ...directives,
    ...pipes
  ],
  providers: [
    ApiService,
    ConstantMessageService,
    FileSaverService,
    ExcelService,
    TopicsService,
    NewsletterService,
    PushNotificationService,
    ScheduleService,
    TriggerRedisActionService,
    RecommendationService,
    TriggersTypeToStringPipe,
    TriggersComponentTypeToStringPipe,
    AdvertisersService,
    AlertService,
    HtmlSanitizerService,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {}
