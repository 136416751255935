import {
    Component,
    Output,
    EventEmitter,
    Input,
    ViewEncapsulation,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { AWSService as AWS } from 'src/app/core/services/aws.service';
import { Utils } from '../../utils/utils';
import ConstantMessages from '../../utils/constants-messages/constants-messages';

@Component({
    selector: 'app-file-picker',
    templateUrl: './file-picker.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FilePickerComponent {
    @ViewChild('AudienceInput') AudienceInput: ElementRef<HTMLInputElement>;
    @Input() public accept = '*';
    @Input() public disabled = false;
    @Output() public fileSelect: EventEmitter<File> = new EventEmitter();
    @Output() public fileUploadUrl: EventEmitter<string> = new EventEmitter();
    constructor(
        private readonly AWSService: AWS
    ) {}

    public onFileSelect(files: FileList): void {
        const file = files[0];
        this.fileSelect.emit(file);
    }

    public uploadImage(files: File[]): void {
        if (files.length === 0) {
            return;
        }

        const imageFile = files[0];
        if (imageFile && !Utils.isValidImage(imageFile)) {
            Utils.notify(
                ConstantMessages.ERROR_LOADING_IMAGE,
                ConstantMessages.InfoType,
                ConstantMessages.ERROR_WRONG_IMAGE,
            );
            return;
        }

        this.AWSService.uploadImage(imageFile)
            .subscribe(
                (sanitizedUrl: string): void => {
                    this.fileUploadUrl.emit(sanitizedUrl);
                },
                (err): void => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    Utils.notify(
                        ConstantMessages.ERROR_LOADING_IMAGE,
                        ConstantMessages.ErrorType,
                        ConstantMessages.ERROR_LOADING_IMAGE,
                    );
                }
            );
    }
}
