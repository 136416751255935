export class ConstantsUnlayer {

  public static readonly linkTypesTriggers = [
    {
      name: 'onClick And Url',
      label: 'onClick And Url',
      attrs: {
        onClick: '{{onClickWithUrl}}',
        href: '{{Url}}',
        target: '{{Target}}'

      },
      fields: [
        {
          name: 'onClickWithUrl',
          label: 'onClick',
          defaultValue: '',
          inputType: null,
          placeholderText: null,
        },
        {
          name: 'Url',
          label: 'Url',
          defaultValue: '',
          inputType: null,
          placeholderText: null
        },
        {
          name: 'Target',
          label: 'Target',
          defaultValue: '_self',
          inputType: null,
          placeholderText: null,
          options: [
            { value: '_self', label: 'Same' },
            { value: '_blank', label: 'Other' },
          ],
        },
      ],
    },
    {
      name: 'onClick',
      label: 'onClick',
      attrs: {
        onClick: '{{onClick}}',
        target: '_self',

      },
      fields: [
        {
          name: 'onClick',
          label: 'onClick',
          defaultValue: '',
          inputType: null,
          placeholderText: null,
        }
      ],
    }
  ];
}
