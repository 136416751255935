import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { SegmentInterface } from '../interfaces/segment.interface';
import { ResponseSegmentInterface } from '../interfaces/response-segments.interface';
import { SegmentHistoricInterface } from '../interfaces/segment-historic.interface';
import { SegmentOperationsHistoricInterface } from '../interfaces/segment-operations-historic.interface';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { HistoricalQueryResult } from '../interfaces/query-builder/historical-query-result.interface';
import { HistoricalSegment } from '../interfaces/query-builder/historical-segment.interface';
import { Json } from 'aws-sdk/clients/robomaker'; //TODO: remove this.

const SegmentCollection = 'segment';
const SegmentOperationCollection = 'segment-operations';

@Injectable()
export class SegmentService {
    public segmentCollection: AngularFirestoreCollection<SegmentHistoricInterface>;

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly firestore: AngularFirestore
    ) {
        this.segmentCollection = this.firestore.collection<SegmentHistoricInterface>(SegmentCollection);
    }

    public getHistoricalUsers(
        condition: string,
        mediaId: string,
        nViews: string,
        viewsCondition: string,
        opSegment: string,
        dateInit: string,
        dateEnd: string,
        shared: Array<string>,
        typeSegment: string,
        currentSiteType: string,
        calculateTotals: string,
        nViewsMax: string,
        eventCondition: Json
    ): Observable<HistoricalQueryResult> {
        let params = new HttpParams();
        params = params
            .set('condition', encodeURIComponent(condition))
            .set('mediaId', encodeURIComponent(mediaId))
            .set('nViews', encodeURIComponent(nViews))
            .set('viewsCondition', encodeURIComponent(viewsCondition))
            .set('opSegment', encodeURIComponent(opSegment))
            .set('dateInit', encodeURIComponent(dateInit))
            .set('dateEnd', encodeURIComponent(dateEnd))
            .set('shared', encodeURIComponent(shared.join(',')))
            .set('typeSegment', encodeURIComponent(typeSegment))
            .set('typePlatform', encodeURIComponent(currentSiteType))
            .set('calculateTotals',encodeURIComponent(calculateTotals))
            .set('nViewsMax', encodeURIComponent(nViewsMax))
            .set('eventCondition', encodeURIComponent(eventCondition));
        const SEGMENT_HISTORICAL_USERS = CONSTANTS.API_RULES_SEGMENT_USERS;
        return this.httpClient.get<HistoricalQueryResult>(SEGMENT_HISTORICAL_USERS, { params: params });
    }

    public createHistoricalSegment(payload: HistoricalSegment, typeSegment: string): Observable<any> {
        const SEGMENT_HISTORICAL_CREATE = CONSTANTS.API_RULES_SEGMENT_CREATE.replace(':type', typeSegment);
        return this.httpClient.post(SEGMENT_HISTORICAL_CREATE, payload);
    }

    public getAll(): Observable<SegmentHistoricInterface[]> {
      return this.segmentCollection.valueChanges();
  }

    public getAllSegmentsBySite(mediaId: string): Observable<SegmentHistoricInterface[]> {
        const SEGMENTS_HISTORICAL = CONSTANTS.API_RULES_SEGMENTS.replace(':mediaId', mediaId);
        return this.httpClient.get<SegmentHistoricInterface[]>(SEGMENTS_HISTORICAL);
    }

    public getAllSegmentsAppsBySite(mediaId: string): Observable<SegmentHistoricInterface[]> {
        const SEGMENTS_HISTORICAL = CONSTANTS.API_RULES_SEGMENTS_APPS.replace(':mediaId', mediaId);
        return this.httpClient.get<SegmentHistoricInterface[]>(SEGMENTS_HISTORICAL);
    }

    public getSegmentsBySite(mediaId: string): Observable<SegmentInterface[]> {
        const SEGMENTS_BY_MEDIA_URL = `${CONSTANTS.API_REST_SEGMENTS_HISTORICAL_URL}${mediaId}`;
        return this.httpClient.get<ResponseSegmentInterface>(SEGMENTS_BY_MEDIA_URL).pipe(map(segments => segments.data));
    }

    private getActiveSegmentsByMedia<T>(mediaId: string, collection: string):  Observable<T[]> {
      return this.firestore
        .collection<T>(
            collection,
            (ref: CollectionReference): Query => ref.where('mediaId', '==', mediaId).where('active', '==', true)
        )
        .valueChanges();
    }

    public getHistoricalSegmentByMedia<T>(mediaId: string, collection: string): Observable<T[]> {
      return this.firestore
          .collection<T>(
              collection,
              (ref: CollectionReference): Query => ref.where('mediaId', '==', mediaId)
          )
          .valueChanges();
    }

    public getHistoricalSegmentsByMedia(mediaId): Observable<SegmentHistoricInterface[]> {
      return this.getHistoricalSegmentByMedia<SegmentHistoricInterface>(mediaId, SegmentCollection);
    }

    private getSharedSegmentsForMedia<T>(mediaId: string, collection: string): Observable<T[]> {
        return this.firestore
                .collection<T>(
                    collection,
                    (ref: CollectionReference): Query => ref.where('shared', 'array-contains', mediaId)
                )
                .valueChanges();
    }

    private getActiveSharedSegmentsForMedia<T>(mediaId: string, collection: string): Observable<T[]> {
      return this.firestore
              .collection<T>(
                  collection,
                  (ref: CollectionReference): Query => ref.where('shared', 'array-contains', mediaId)
                                                          .where('active', '==', true)
              )
              .valueChanges();
  }

    public getSegmentsByMedia(mediaId: string, shared: boolean): Observable<SegmentHistoricInterface[]> {
        const segmentsByMediaId = this.getHistoricalSegmentByMedia<SegmentHistoricInterface>(mediaId, SegmentCollection);
        const segmentsShared: Observable<SegmentHistoricInterface[]> = shared
          ? this.getSharedSegmentsForMedia<SegmentHistoricInterface>(mediaId, SegmentCollection)
          : of([]);
        return combineLatest([segmentsByMediaId, segmentsShared]).pipe(map(([segments, sharedSegments]) => [
            ...segments,
            ...sharedSegments,
        ]));
    }

    public getAllSegmentsByMedia(mediaId: string, shared: boolean): Observable<SegmentHistoricInterface[]> {
        const segmentsByMediaId = this.getActiveSegmentsByMedia<SegmentHistoricInterface>(mediaId, SegmentCollection);
        const segmentsShared: Observable<SegmentHistoricInterface[]> = shared
          ? this.getActiveSharedSegmentsForMedia<SegmentHistoricInterface>(mediaId, SegmentCollection)
          : of([]);
        return combineLatest([segmentsByMediaId, segmentsShared]).pipe(map(([segments, sharedSegments]) => [
            ...segments,
            ...sharedSegments,
        ]));
    }

    public getSegmentsOperationsByMedia(
        mediaId: string,
        shared: boolean
    ): Observable<SegmentOperationsHistoricInterface[]> {
        const segmentsByMediaId = this.getHistoricalSegmentByMedia<SegmentOperationsHistoricInterface>(mediaId, SegmentOperationCollection);
        const segmentsShared: Observable<SegmentOperationsHistoricInterface[]> = shared
          ? this.getSharedSegmentsForMedia<SegmentOperationsHistoricInterface>(mediaId, SegmentOperationCollection)
          : of([]);
        return combineLatest([segmentsByMediaId, segmentsShared]).pipe(map(([segments, sharedSegments]) => [
            ...segments,
            ...sharedSegments,
        ]));
    }

    public getActiveSegmentsOperationsByMedia(mediaId: string): Observable<SegmentOperationsHistoricInterface[]> {
      const segmentsByMediaId = this.getActiveSegmentsByMedia<SegmentOperationsHistoricInterface>(mediaId, SegmentOperationCollection);
      const segmentsShared: Observable<SegmentOperationsHistoricInterface[]> = this.getActiveSharedSegmentsForMedia<SegmentOperationsHistoricInterface>(mediaId, SegmentOperationCollection);
      return combineLatest([segmentsByMediaId, segmentsShared]).pipe(map(([segments, sharedSegments]) => [
          ...segments,
          ...sharedSegments,
      ]));
  }

    public getHistoricalOperationUsers(
        mediaId: string,
        operator: string,
        segments: Array<string>
    ): Observable<any> {
        let params = new HttpParams();
        params = params
            .set('mediaId', encodeURIComponent(mediaId))
            .set('operator', encodeURIComponent(operator))
            .set('segments', encodeURIComponent(segments.join(',')));
        const OPERATION_SEGMENT_USERS = CONSTANTS.API_RULES_OPERATION_SEGMENT_USERS;
        return this.httpClient.get(OPERATION_SEGMENT_USERS, { params: params });
    }

    public createOrUpdateSegment(segment: SegmentInterface): Observable<ResponseSegmentInterface> {
        const ADD_HISTORICAL_SEGMENT = `${CONSTANTS.API_REST_SEGMENTS_HISTORICAL_URL}create`;
        return this.httpClient.post<ResponseSegmentInterface>(ADD_HISTORICAL_SEGMENT, segment);
    }

    public getSegmentById(mediaId: string, segmentId: string): Observable<SegmentHistoricInterface[]> {
        return this.firestore
            .collection<SegmentHistoricInterface>(
                SegmentCollection,
                (ref: CollectionReference): Query =>
                    ref.where('idSegment', '==', segmentId).where('mediaId', '==', mediaId)
            )
            .valueChanges();
    }

    public getSegmentOperationById(segmentId: string): Observable<SegmentOperationsHistoricInterface[]> {
        return this.firestore
            .collection<SegmentOperationsHistoricInterface>(
                SegmentOperationCollection,
                (ref: CollectionReference): Query => ref.where('idSegment', '==', segmentId)
            )
            .valueChanges();
    }

    public createHistoricalSegmentOperation(payload: {
        mediaId: string;
        opSegment: string;
        segmentName: string;
        segmentDescrip: string;
        createBy?: string;
        adManagerSegment?: boolean;
        nUsers?: number;
        nTotalUsers?: number;
        segments: Array<string>;
        operator: string;
        active: boolean;
        typeSegment: string;
        groupId?: string;
    }): Observable<any> {
        const SEGMENT_HISTORICAL_OPERATION_CREATE = CONSTANTS.API_RULES_OPERATION_SEGMENT_CREATE;
        return this.httpClient.post(SEGMENT_HISTORICAL_OPERATION_CREATE, payload);
    }

    public updateHistoricalSegmentOperation(payload: {
        idSegment?: string;
        mediaId: string;
        opSegment: string;
        segmentName: string;
        segmentDescrip: string;
        updateBy?: string;
        adManagerSegment?: boolean;
        nUsers?: number;
        nTotalUsers?: number;
        segments: Array<string>;
        operator: string;
        active: boolean;
        typeSegment: string;
        groupId?: string;
    }): Observable<any> {
      const SEGMENT_HISTORICAL_OPERATION_UPDATE = CONSTANTS.API_RULES_OPERATION_SEGMENT_UPDATE;
      return this.httpClient.post(SEGMENT_HISTORICAL_OPERATION_UPDATE, payload);
    }

    public addSegmentAdManager(id: string, name: string): Observable<any> {
        const data = {
            nameKey: CONSTANTS.API_ADMANAGER_KEY_TARGETING,
            nameValue: id,
            displayName: name,
        };

        return this.httpClient.post(CONSTANTS.API_ADMANAGER_SEGMENTS_ADD, data);
    }

    public deleteSegmentAdManager(nameSegment: string): Observable<any> {
        const data = {
            nameKey: CONSTANTS.API_ADMANAGER_KEY_TARGETING,
            nameValue: nameSegment,
        };
        return this.httpClient.post(CONSTANTS.API_ADMANAGER_SEGMENTS_DELETE, data);
    }

    public getSegmentAdManager(id: string): Observable<any> {
      const params = new HttpParams()
      .set('nameKey', CONSTANTS.API_ADMANAGER_KEY_TARGETING)
      .set('nameValue', id);
      return this.httpClient.get(CONSTANTS.API_ADMANAGER_SEGMENTS_EXISTS, {params});
    }

    public updateHistoricalSegment(segment: SegmentHistoricInterface, typeSegment: string): Observable<any> {
        const SEGMENT_HISTORICAL_UPDATE = CONSTANTS.API_RULES_SEGMENT_UPDATE.replace(':type', typeSegment);
        return this.httpClient.post(SEGMENT_HISTORICAL_UPDATE, segment);
    }

    public updateHistoricalSegmentComponents(segment: SegmentHistoricInterface): Observable<any> {
      const SEGMENT_HISTORICAL_UPDATE_COMPONENTS = CONSTANTS.API_RULES_OPERATION_UPDATE_SEGMENT_COMPONENTS;
      return this.httpClient.post(SEGMENT_HISTORICAL_UPDATE_COMPONENTS, { idSegment: segment.idSegment, mediaId: segment.mediaId, components: segment.components });
  }

    public deleteHistoricalSegment(segmentId: string, mediaId: string, typePlatform: string): Observable<any> {
        const SEGMENT_HISTORICAL_DELETE = CONSTANTS.API_RULES_SEGMENT_DELETE.replace(':type', typePlatform);
        const data = {
            idSegment: segmentId,
            mediaId: mediaId,
        };
        return this.httpClient.post(SEGMENT_HISTORICAL_DELETE, data);
    }

    public deleteHistoricaOperationSegment(segmentId: string, mediaId: string): Observable<any> {
        const SEGMENT_OPERATION_HISTORICAL_DELETE = CONSTANTS.API_RULES_OPERATION_SEGMENT_DELETE;
        const data = {
            idSegment: segmentId,
            mediaId: mediaId,
        };
        return this.httpClient.post(SEGMENT_OPERATION_HISTORICAL_DELETE, data);
    }

    public getAuthorByMedia(mediaId: string): Observable<any> {
        const AUTHOR_LIST_URL = `${CONSTANTS.API_REST_SEGMENTS_HISTORICAL_URL}${CONSTANTS.API_SEGMENT_AUTHOR}${mediaId}`;
        return this.httpClient.get<ResponseSegmentInterface>(AUTHOR_LIST_URL).pipe(map(segments => segments.data));
    }

    public getLayoutByMediaId(mediaId: string): Observable<string[]> {
        const SEGMENT_LIST_URL = `${CONSTANTS.API_REST_SEGMENTS_GENERATOR_URL}/${CONSTANTS.API_SEGMENT_LAYOUT}${mediaId}`;
        return this.httpClient.get<ResponseSegmentInterface>(SEGMENT_LIST_URL).pipe(map(segments => segments.data));
    }

    public componentsUsingSegment(mediaId: string, segmentId: string): Observable<{id: string, active: boolean, type: string}[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('segmentId', segmentId);
      return this.httpClient.get<any>(CONSTANTS.API_REST_COMPONENTS_USING_SEGMENT_URL_NEST, { params });
    }
}
