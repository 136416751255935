<div [innerHTML]="'<style>' + template.styles + '</style>' | safeHTMLPipe"></div>
<div>
    <section class="prometeo-main-content col contentBoxPrometeo wrap">
        <h3 class="prometeo-main-title tituloSeccion">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
        <ul class="prometeo-articles-content contentListado content-prometeo-article">
            <ng-container *ngFor="let slot of template.slots | fill; let index = index">
                <li class="prometeo-article-box boxNoticia col-prometeo-li">
                    <div class="prometeo-article-image-box boxImagen">
                        <figure>
                            <a class="prometeo-article-image-link enlaceImagen" title="accede a más información">
                                <span
                                    class="prometeo-article-image-bg contentImagen"
                                    style="background-image: url('/assets/images/image-placeholder.png');"
                                >
                                </span>
                            </a>
                            <figcaption class="prometeo-article-image-cap pieNoticia">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </figcaption>
                        </figure>
                    </div>
                    <div class="prometeo-article-title-box">
                        <h4 class="prometeo-article-title titularNoticia">
                            <a class="prometeo-article-title-link enlaceNoticia" title="accede a más información">
                                Lorem ipsum
                            </a>
                        </h4>
                        <span class="prometeo-article-signature firmaNoticia"
                            >Lorem ipsum dolor sit amet, consectetur adipiscing elit</span
                        >
                    </div>
                </li>
            </ng-container>
        </ul>
    </section>
</div>
