import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '../utils/constants';
import { ConstantsTriggers } from 'src/app/shared/utils/constants-triggers';

@Pipe({
    name: 'triggersComponentTypeToStringPipe',
})

export class TriggersComponentTypeToStringPipe implements PipeTransform {
  public transform(value: number): string {
      switch (ConstantsTriggers.globalTriggerTypes[value]) {
          case ConstantsTriggers.globalTriggerTypes[1]:
              return CONSTANTS.PROMETEO_TRIGGERS_ALL_TYPE_LITERAL;
          case ConstantsTriggers.globalTriggerTypes[2]:
              return CONSTANTS.PROMETEO_TRIGGERS_TRIGGER_TYPE_PLURAL_LITERAL;
          case ConstantsTriggers.globalTriggerTypes[3]:
              return CONSTANTS.PROMETEO_TRIGGERS_PUSH_TYPE_PLURAL_LITERAL;
          case ConstantsTriggers.globalTriggerTypes[4]:
              return CONSTANTS.PROMETEO_TRIGGERS_NEWSLETTER_TYPE_PLURAL_LITERAL;
          default:
              return '';
      }
  }
}
