import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-trigger-app-preview',
    templateUrl: './trigger-app-preview.component.html',
    styleUrls: ['./trigger-app-preview.component.scss'],
})
export class TriggerAppPreviewComponent implements OnInit {
    @Input() public form2: FormGroup;
    @Input() public form3: FormGroup;
    @Input() public isModalFS: boolean;
    @Input() public isModalSimple: boolean;
    @Input() public isBox: boolean;
    @Input() public data: boolean;
    constructor() {}

    ngOnInit() {}
}
