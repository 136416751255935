import { environment } from "src/environments/environment";

export class CONSTANTSENDPOINTS {
    public static readonly triggersRedis = {
        baseUrl: environment.triggerRedisActions.baseUrl,
        prefixUpdate: '/triggers/update',
        prefixDelete: '/triggers/delete',
        prefixDeleteApp: '/triggers/app/delete',
        prefixAmpDelete: '/triggers/:siteId/amp',
        prefixUpdatePriority: '/update-triggers/priority',
        testAbUpdate: '/testAb/update',
        testAbDelete: '/testAb/delete',
    };

    public static readonly scheduledNotification = {
        prefixSchedule: 'schedule-notification',
        prefixScheduleUpdate: 'schedule-notification/:id',
        prefixScheduleApp: 'app/schedule-notification',
        prefixScheduleDeleteApp: 'app/schedule-notification/:mediaId/:notificationId'
    };
}
