import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'app-json-viewer',
    templateUrl: './json-viewer.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class JsonViewerComponent {
    @Input() public data: any[] = [];

    constructor() {}
}
