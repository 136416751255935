import { Component, Input, OnChanges } from '@angular/core';
import { TriggerInterface } from 'src/app/core/interfaces/trigger.interface';
import { DecimalPipe } from '@angular/common';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { ConstantsStatistics } from '../../utils/constants-statistics';

@Component({
  selector: 'app-statistics-trigger',
  templateUrl: './statistics-trigger.component.html',
  styleUrls: ['./statistics-trigger.component.scss']
})
export class StatisticsTriggerComponent implements OnChanges {
  @Input() triggerType: string;
  @Input() metrics: any;
  @Input() usersMetrics: any;
  @Input() metricsChartOptions: any;
  @Input() trigger: TriggerInterface;
  @Input() siteType: string;
  @Input() currentSiteId: string;
  @Input() dates: any;
  @Input() triggerId: string;

  public methods: string[] = ['show', 'activate', 'close', 'action1', 'action2'];

  public toggleOptions: any = {
    show: {
      name: 'Impresiones',
      icon: 'fa-eye',
      format: '1.0-0',
    },
    close: {
      name: 'Botón \'cierre\'',
      icon: 'fa-times',
      format: '1.0-0',
    },
    activate: {
      name: 'Botón \'acción\'',
      icon: 'fa-check',
      format: '1.0-0',
    },
    action1: {
      name: 'Botón \'acción 1\'',
      icon: 'fa-check',
      format: '1.0-0',
    },
    action2: {
      name: 'Botón \'acción 2\'',
      icon: 'fa-check',
      format: '1.0-0',
    }
  };

  public devices: any = {
    total_imp: {
      name: 'total_imp',
      title: 'Total impresiones perdidas'
    },
    total_user: {
      name: 'total_user',
      title: 'Total usuarios únicos'
    },
    desktop: {
        name: 'desktop',
        title: 'Desktop',
        fontSet: "fas",
        icon: 'fa-desktop',
        format: '1.0-0',
    },
    mobile: {
        name: 'mobile',
        title: 'Móvil',
        fontSet: "fas",
        icon: 'fa-mobile-alt',
        format: '1.0-0',
    },
    tablet: {
        name: 'tablet',
        title: 'Tablet',
        fontSet: "fas",
        icon: 'fa-tablet-alt',
        format: '1.0-0',
    },
    android: {
        name: 'android',
        title: 'Android',
        fontSet: "fab",
        icon: 'fa-android',
        format: '1.0-0',
    },
    ios: {
        name: 'ios',
        title: 'iOS',
        fontSet: "fab",
        icon: 'fa-apple',
        format: '1.0-0',
    },
    other: {
        name: 'other',
        title: 'Otros',
        fontSet: "fas",
        icon: 'fa-ellipsis-h',
        format: '1.0-0',
    }
  };

  public devicesName: string[];

  public siteWebName = CONSTANTS.SITE_WEB;

  constructor(
    private readonly decimalPipe: DecimalPipe
  ) { }

  ngOnChanges() {
    this.devicesName = this.siteType === this.siteWebName ? ['desktop', 'mobile', 'tablet', 'other'] : ['android', 'ios'];
  }

  public getAllMetricsByDevice(event: string, device: string, ctr: boolean): number {
    let acc = 0;
    for (let x = 0; x < this.metrics.length; x++) {
      for (let key in this.metrics[x].devices) {
        if (
          key === device &&
          this.metrics[x].devices.hasOwnProperty(device) &&
          this.metrics[x].devices[device].hasOwnProperty(event)
        ) {
          acc += this.metrics[x].devices[device][event];
        }
      }
    }

    if (ctr) {
      const impressions =  this.getAllMetricsByDevice('show', device, false);
      return impressions && acc ? (acc / impressions) * 100 : 0.00;
    }
    return acc;
  }

  public getAllMetricsByEvent(event: string, ctr: boolean): number {
    let acc = 0;
    for (let x = 0; x < this.metrics.length; x++) {
        for (let key in this.metrics[x].devices) {
          if (this.metrics[x].devices[key][event]) acc += this.metrics[x].devices[key][event];
        }
    }
    if (ctr) return (acc / this.getAllMetricsByEvent('show', false)) * 100
    return acc;
  }

  public getLostImpressions(device: string): string {
    const totalImpressions = device === 'total_imp' ? this.getAllMetricsByEvent('show', false) : this.getAllMetricsByDevice('show', this.devices[device].name, false);
    const closeInteractions = device === 'total_imp' ? this.getAllMetricsByEvent('close', false) :this.getAllMetricsByDevice('close', this.devices[device].name, false);
    const actionInteractions = device === 'total_imp' ? this.getAllMetricsByEvent('activate', false) :this.getAllMetricsByDevice('activate', this.devices[device].name, false);
    const action1Interactions = device === 'total_imp' ? this.getAllMetricsByEvent('action1', false) :this.getAllMetricsByDevice('action1', this.devices[device].name, false);
    const action2Interactions = device === 'total_imp' ? this.getAllMetricsByEvent('action2', false) :this.getAllMetricsByDevice('action2', this.devices[device].name, false);

    const lostImpressions = totalImpressions - (closeInteractions + actionInteractions + action1Interactions + action2Interactions);
    const percentage = (lostImpressions * 100) / this.getAllMetricsByEvent('show', false);

    return this.decimalPipe.transform(lostImpressions, '', ConstantsStatistics.decimalPipeLocale) + ' (' + percentage.toFixed(1).toString() + '%)';
  }

  public getTotalImpactedUsers(device: string): number {
    if (device !== 'total_user') {
      const metrics = this.usersMetrics ? this.usersMetrics.filter(metric => metric.device.toLowerCase() === device) : [];
      return metrics.length ? metrics[0].totalUsers : 0;
    }

    if (this.usersMetrics) {
      let acc = 0;
      this.usersMetrics.forEach(userMetric => acc += userMetric.totalUsers);
      return acc;
    }
  }

  public getCTRFormated(method: string, device: string): string {
    let ctr = device !== '' ? this.getAllMetricsByDevice(method, this.devices[device].name, true) : this.getAllMetricsByEvent(method, true)
    let ctrString = ctr.toFixed(2).toString();
    if (parseInt((ctr / 10).toString()) <= 0) ctrString = '0' + ctrString;

    return ctrString + ' %';
  }

  public getArticlesCTRFormated(interactions: number, impressions: number): string {
    let ctr = interactions && impressions ? interactions / impressions : 0;
    let ctrString = ctr.toFixed(2).toString();
    if (parseInt((ctr / 10).toString()) <= 0) ctrString = '0' + ctrString;

    return '(' + ctrString + '%)';
  }

}
