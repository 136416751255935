import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProgramPushSubscriptionInterface } from '../interfaces/program-push-subscription.interface';

const PROGRAM_PUSH_SUBSCRIPTION_COLLECTION = 'push-subscription-program';

@Injectable()
export class ProgramPushSubscriptionService {
    private programPushSubscriptionCollection: AngularFirestoreCollection<ProgramPushSubscriptionInterface>;
    programPushSubscriptionTotal = 0;

    public constructor(
        private readonly firestore: AngularFirestore,
    ) {
        this.programPushSubscriptionCollection = this.firestore.collection<ProgramPushSubscriptionInterface>(
          PROGRAM_PUSH_SUBSCRIPTION_COLLECTION
        );
    }

    public getAll(): Observable<ProgramPushSubscriptionInterface[]> {
        return this.programPushSubscriptionCollection.valueChanges();
    }

    public getBySite(siteId: string): Observable<ProgramPushSubscriptionInterface[]> {
        return this.firestore
            .collection<ProgramPushSubscriptionInterface>(
              PROGRAM_PUSH_SUBSCRIPTION_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId)
            )
            .valueChanges()
            .pipe(
                tap(list => {
                    this.programPushSubscriptionTotal = list.length;
                })
            );
    }

    public getActiveBySite(siteId: string): Observable<ProgramPushSubscriptionInterface[]> {
        return this.firestore
            .collection<ProgramPushSubscriptionInterface>(
              PROGRAM_PUSH_SUBSCRIPTION_COLLECTION,
                (ref: CollectionReference): Query => ref.where('siteId', '==', siteId).where('active', '==', true)
            )
            .valueChanges();
    }

    public get(programPushSubscriptionId: string): Observable<ProgramPushSubscriptionInterface> {
        return this.programPushSubscriptionCollection.doc<ProgramPushSubscriptionInterface>(programPushSubscriptionId).valueChanges();
    }

    public add(programPushSubscription: ProgramPushSubscriptionInterface): Observable<void> {
        return from(
            this.programPushSubscriptionCollection
                .doc<ProgramPushSubscriptionInterface>(programPushSubscription.id)
                .set(Object.assign({}, programPushSubscription))
        );
    }

    public delete(programPushSubscriptionId: string): Observable<void> {
        return from(this.programPushSubscriptionCollection.doc<ProgramPushSubscriptionInterface>(programPushSubscriptionId).delete());
    }

    public update(programPushSubscriptionId: string, dataToUpdate: Partial<ProgramPushSubscriptionInterface>): Observable<void> {
        return from(
            this.programPushSubscriptionCollection
                .doc<ProgramPushSubscriptionInterface>(programPushSubscriptionId)
                .update(Object.assign({}, dataToUpdate))
        );
    }
}
