<h5>{{ title }}</h5>

<div class="row">
    <section class='col-6 mb-4'>
        <p>Editor</p>
        <div class="card">
            <!-- TODO revisar como poder hacerlo con un mapa de características -->
            <ul class="d-flex m-0 p-0 editorBar">
                <li>
                    <button title="Añadir título de tamaño 1" (click)="inputTag('h1')">Título 1</button>
                </li>
                <li>
                    <button title="Añadir título de tamaño 2" (click)="inputTag('h2')">Título 2</button>
                </li>
                <li>
                    <button title="Añadir título de tamaño 3" (click)="inputTag('h3')">Título 3</button>
                </li>
                <li>
                    <button title="Añadir título de tamaño 4" (click)="inputTag('h4')">Título 4</button>
                </li>
                <li>
                    <button title="Añadir título de tamaño 5" (click)="inputTag('h5')">Título 5</button>
                </li>
                <li>
                    <button title="Añadir título de tamaño 6" (click)="inputTag('h6')">Título 6</button>
                </li>
                <li>
                    <button title="Añadir párrafo" (click)="inputTag('p')">Párrafo</button>
                </li>
                <li>
                    <button title="Añadir negrita" (click)="inputTag('strong')"><strong>B</strong></button>
                </li>
                <li>
                    <button title="Añadir cursiva" (click)="inputTag('i')"><i>i</i></button>
                </li>
                <li>
                    <button title="Añadir subrayado" (click)="inputTag('underlined')"><span
                            style="text-decoration: underline;">u</span></button>
                </li>
                <li>
                    <button title="Añadir contenedor" (click)="inputTag('span')">Span</button>
                </li>
                <li>
                    <button title="Añadir enlace" (click)="inputTag('a')"><i
                            class='material-icons'>add_link</i></button>
                </li>
                <li>
                    <button title="Añadir imagen" (click)="inputTag('img')"><i class='material-icons'>image</i></button>
                </li>
                <li>
                    <button title="Añadir listado" (click)="inputTag('ul')"><i
                            class='material-icons'>list_alt</i></button>
                </li>
                <li>
                    <button title="Añadir elemento a la lista" (click)="inputTag('li')"><i
                            class='material-icons'>playlist_add</i></button>
                </li>
            </ul>

            <div class="separator"></div>

            <mat-form-field class="p-0" appearance="outline">
                <textarea matInput name="control" [formControl]="control" rows="10"
                    (blur)="storeCursor($event)"></textarea>
            </mat-form-field>
        </div>
    </section>
    <section class="col-6">
        <p>Previsualización</p>
        <div class='card d-flex' id="preview-card" #divResizable>
            <div class="preview" #divPreview></div>
            <div class="draggable" (mousedown)="startResize($event)">||</div>
        </div>
        <br />
        <p>Anchura ventana previsualización: {{ sumWidth }}px</p>
    </section>
</div>
