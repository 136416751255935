import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { combineLatest, forkJoin, of, Subscription } from 'rxjs';
import { MediaService } from 'src/app/core/services/media.service';
import { NewslettersSubscriptionService } from 'src/app/core/services/newsletters-subscription.service';
import { PushSubscriptionService } from 'src/app/core/services/push-subscription.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { TestAbService } from 'src/app/core/services/test-ab.service';
import { TriggerRedisActionService } from 'src/app/shared/services/trigger-redis-action.service';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import swal, { SweetAlertIcon } from 'sweetalert2';
import { switchMap } from 'rxjs/operators';
import { CacheService } from 'src/app/core/services/cache.service';

@Component({
  selector: 'app-triggers-priority',
  templateUrl: './triggers-priority.component.html',
  styleUrls: ['./triggers-priority.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
//TODO: Refactor, there is too much code that it is not easy to read.
export class TriggersPriorityComponent implements OnInit, OnDestroy {
  private listSubscription: Subscription;
  public triggersList: Array<any>;
  public triggersCompatibleList: Array<any>;
  private modifiedIndexes: Array<boolean>;
  private modifiedCompatibleList: Array<boolean>;

  public currentSiteType;
  public currentSiteId;

  constructor(
    private triggerService: TriggerService,
    private readonly mediaService: MediaService,
    private pushSubscriptionService: PushSubscriptionService,
    private newsletterSubscriptionService: NewslettersSubscriptionService,
    private triggerRedisActionService: TriggerRedisActionService,
    private readonly testAbService: TestAbService,
    private readonly cacheService: CacheService
  ) { }

  ngOnInit(): void {
    this.mediaService
      .getCurrentSiteType()
      .pipe(switchMap((currentSiteType: string) => {
        this.currentSiteType = currentSiteType;
        return this.mediaService.getCurrentSite();
      }),
      switchMap((currentSiteId: string) => this.currentSiteId = currentSiteId))
      .subscribe(() => {
        this.getListElements(this.currentSiteId);
      },
      err => console.log(err)
    );
  }

  ngOnDestroy() {
    this.listSubscription && this.listSubscription.unsubscribe();
  }

  getListElements(siteId: string) {
    const triggers$ = this.triggerService.getActiveBySite(siteId, this.currentSiteType);
    const testsAB$ = this.testAbService.getActiveBySite(siteId);
    const pushNotifications$ = this.currentSiteType != 'app'
      ?  this.pushSubscriptionService.getActiveBySite(siteId)
      : of([]);
    const newsletters$ = this.currentSiteType != 'app'
      ? this.newsletterSubscriptionService.getActiveBySite(siteId)
      : of([]);
    const programTriggers$ = this.triggerService.getAllProgram(siteId);
    const programNewsletters$ = this.currentSiteType != 'app'
      ? this.newsletterSubscriptionService.getAllProgram(siteId)
      : of([]);
    const programPushNotifications$ = this.currentSiteType != 'app'
      ? this.pushSubscriptionService.getAllProgram(siteId)
      : of([]);



    this.listSubscription = combineLatest([triggers$, testsAB$, pushNotifications$, newsletters$, programTriggers$, programNewsletters$, programPushNotifications$])
    .subscribe(([triggers, testsAB, pushSubscriptions, newsletterSubscriptions, programTriggers, programNewsletters, programPushNotifications]) => {
      triggers = triggers.filter(t => !t.isIntoTestAB);
      pushSubscriptions = pushSubscriptions.filter(p => !p.isIntoTestAB);
      newsletterSubscriptions = newsletterSubscriptions.filter(n => !n.isIntoTestAB);
      const allTriggers = [
        ...triggers.map(e => ({...e, triggerType: CONSTANTS.TRIGGER_TYPE })),
        ...pushSubscriptions.map(e => ({...e, triggerType: CONSTANTS.PUSH_SUBSCRIPTION_TYPE })),
        ...newsletterSubscriptions.map(e => ({...e, triggerType: CONSTANTS.NEWSLETTER_SUBSCRIPTION_TYPE })),
        ...testsAB.map(e => ({...e, triggerType: CONSTANTS.TESTAB_TYPE })),
        ...programTriggers.map(e => ({...e, triggerType: CONSTANTS.TRIGGER_TYPE })),
        ...programPushNotifications.map(e => ({...e, triggerType: CONSTANTS.PUSH_SUBSCRIPTION_TYPE })),
        ...programNewsletters.map(e => ({...e, triggerType: CONSTANTS.NEWSLETTER_SUBSCRIPTION_TYPE })),
      ];

      allTriggers.sort((a, b) =>  a.priority - b.priority );
      this.triggersList = JSON.parse(JSON.stringify(allTriggers));
      this.modifiedIndexes = new Array(allTriggers.length).fill(false);
      this.modifiedCompatibleList = new Array(allTriggers.length).fill(false);
      this.validateDuplicatedPriorities(this.triggersList);
      this.triggersCompatibleList = this.triggersList.filter(trigger => trigger.isCompatible);
    });
  }

  onFunctionSelectedChange(triggerId: string, event): void {
    let index = this.triggersList.findIndex(t => t.id == triggerId);
    if (index != -1) {
      this.updateModifiedCompatibleList(event.value, this.triggersList[index].compatibleTriggers, index);
      for (let v of event.value) {
        if (!this.triggersList[index].compatibleTriggers.includes(v)) {
          this.triggersList[index].compatibleTriggers.push(v);

          let i = this.triggersList.findIndex(t => t.id == v);
          if (i != -1) {
            this.updateModifiedCompatibleList(event.value, this.triggersList[i].compatibleTriggers, i);
            if (!this.triggersList[i].compatibleTriggers.includes(triggerId)) this.triggersList[i].compatibleTriggers.push(triggerId);
            let eValue = JSON.parse(JSON.stringify(this.triggersList[i].compatibleTriggers));
            this.triggersList[i].compatibleTriggers = this.deleteOldSelections(eValue, this.triggersList[i].compatibleTriggers);
          }
        }
        else this.deleteSelectionFromOthers(triggerId, event);
      }

      if (event.value.length === 0) this.deleteSelectionFromOthers(triggerId, event);

      this.triggersList[index].compatibleTriggers = this.deleteOldSelections(event.value, this.triggersList[index].compatibleTriggers);
    }
  }

  // Elimina de su lista correspondiente la aparición de triggerSelectedId
  private deleteSelectionFromOthers(triggerSelectedId: string, event) {
    this.triggersCompatibleList.forEach(t => {
      if (!event.value.includes(t.id) && t.id !== triggerSelectedId) {
        let i = this.triggersList.findIndex(t1 => t1.id === t.id);
        if (i != -1) {
          for (let j = 0; j < this.triggersList[i].compatibleTriggers.length; j++) {
            if (this.triggersList[i].compatibleTriggers[j] === triggerSelectedId) {
              this.triggersList[i].compatibleTriggers.splice(j, 1);
              this.triggersList[i].compatibleTriggers = [...this.triggersList[i].compatibleTriggers];
              this.modifiedCompatibleList[i] = true;
            }
          }
        }
      }
    })
  }

  // Indica que el trigger de indice 'index' ha sido modificado
  private updateModifiedCompatibleList(eventValue: string[], a: string[], index: number): void {
      eventValue.sort();
      a.sort();
      this.modifiedCompatibleList[index] = !Utils.areEqualArrays(a, eventValue);
  }

  // Elimina de la lista de compatibles del trigger seleccionado selecciones que ya no esten marcadas
  private deleteOldSelections(a1, a2): any {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    let result = JSON.parse(JSON.stringify(a2));
    let iter = JSON.parse(JSON.stringify(a2));
    iter.forEach(a => {
      if (!a1.includes(a)) result = result.filter(e => e !== a);
    });
    return result;
  }

  validateDuplicatedPriorities(triggers: any[]) {
    for (let i = 1; i < triggers.length; i++) {
        if (triggers[i].priority <= triggers[i - 1].priority) {
            triggers[i].priority = triggers[i - 1].priority + 1;
            this.modifiedIndexes[i] = true;
        }
    }
  }

  dropItem(event) {
    if( event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.triggersList, event.previousIndex, event.currentIndex);
      this.updatePriorities(event.previousIndex, event.currentIndex);
    }
  }

  updatePriorities(previousIndex, currentIndex){
    const previousPriority = this.triggersList[currentIndex].priority;
    if(previousIndex > currentIndex) {
      for (let i = currentIndex; i < previousIndex; i++) {
        this.triggersList[i].priority = this.triggersList[i+1].priority;
      }
      this.modifiedIndexes.fill(true, currentIndex, previousIndex + 1);
    } else {
      for (let i = currentIndex; i > previousIndex; i--) {
        this.triggersList[i].priority = this.triggersList[i-1].priority;
      }
      this.modifiedIndexes.fill(true, previousIndex, currentIndex + 1);
    }
    this.triggersList[previousIndex].priority = previousPriority;
  }

  enableSave() {
    return (this.modifiedIndexes && this.modifiedIndexes.some(e => e)) || (this.modifiedCompatibleList && this.modifiedCompatibleList.some(e => e));
  }

  savePriority(): void {
    const firebaseUpdates = [];
    for (let i = 0; i < this.triggersList.length; i++) {
      if (this.modifiedIndexes[i] || this.modifiedCompatibleList[i]) {
        const item = this.triggersList[i];
        let itemRedis = item;
        if (item.triggerType === CONSTANTS.TRIGGER_TYPE) {
          const triggerUpdate =
              this.currentSiteType != 'app' ? this.triggerService.update(item.id, { priority: item.priority, compatibleTriggers: item.compatibleTriggers || [] })
              : this.triggerService.updateApp(item.id, { priority: item.priority })
          firebaseUpdates.push(triggerUpdate);
        } else if (item.triggerType === CONSTANTS.PUSH_SUBSCRIPTION_TYPE) {
          firebaseUpdates.push(this.pushSubscriptionService.update(item.id, { priority: item.priority, compatibleTriggers: item.compatibleTriggers || [] }));
          itemRedis = Utils.mapperTriggerFromPushNotification(item);
        } else if (item.triggerType === CONSTANTS.NEWSLETTER_SUBSCRIPTION_TYPE) {
          firebaseUpdates.push(this.newsletterSubscriptionService.update(item.id, { priority: item.priority, compatibleTriggers: item.compatibleTriggers || [] }));
          itemRedis = Utils.mapperTriggerFromNewsletter(item);
        } else if (item.triggerType === CONSTANTS.TESTAB_TYPE) {
          firebaseUpdates.push(this.testAbService.update(item.id, { priority: item.priority, compatibleTriggers: item.compatibleTriggers || [] }));
        }

        const updateTriggerCache$ = item.triggerType === CONSTANTS.TESTAB_TYPE ? this.triggerRedisActionService.updateTestAbOnRedis(itemRedis) : this.triggerRedisActionService.updateTriggerOnRedis(itemRedis, this.currentSiteType);
        updateTriggerCache$.subscribe(
            () => {
                console.log('Redis cache on trigger successfully update');
            },
            error => {
                console.error(error);
            }
        );
      }
    }
    forkJoin(firebaseUpdates).subscribe(
      (quantity) => console.log('Newsletter Priority updated', quantity.length),
      () => console.log('Newsletter Priority update failed')
    );
    if(this.currentSiteType != 'app'){
      this.triggerService.updateVersion(this.currentSiteId).subscribe(
        () => {
            console.log('Redis cache on trigger successfully update');
            this.showSuccessMessage();
        },
        error => {
            console.error(error);
            this.showErrorMessage();
        }
      );
    } else {
      this.cacheService.deleteCacheForTriggerApp(this.currentSiteId).subscribe(
        () => {
          console.log('Redis cache on trigger successfully update');
          this.showSuccessMessage();
        },
        error => {
          console.log(error);
          this.showErrorMessage();
        }
      )
    }

  }

  getTriggerActiveText(trigger: any): any {
    if (trigger.active !== undefined) return trigger.active ? 'Activo' : 'Inactivo';
  }

  private showSuccessMessage() {
    this.showMessage('Prioridades', 'Guardado realizado con éxito', 'success');
  }

  private showErrorMessage() {
    this.showMessage('Prioridades', 'Ha ocurrido un error con el guardado', 'error')
  }

  private showMessage(title: string, message: string, icon: SweetAlertIcon): void {
    swal.fire(title, message, icon);
  }
}
