import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { errorTranslations, fieldTranslations } from '../../constants-error-list';
import { RecursiveErrorsNode } from '../../utils/utils-recursive-errors';

@Component({
  selector: 'app-error-list-item',
  templateUrl: './error-list-item.component.html',
})
export class ErrorListItemComponent {
  @Input() recursiveErrorsNode: RecursiveErrorsNode;

  readonly fieldTranslations: { [key: string]: string } = fieldTranslations;
  readonly errorTranslations: { [key: string]: string } = errorTranslations;

  getKeys(errors: ValidationErrors): string[] {
    return Object.keys(errors);
  }

  translateField(key: string): string {
    // TODO valorar crear un pipe para traducir en el template
    if (!this.fieldTranslations || !(key in this.fieldTranslations)) return key;
    return this.fieldTranslations[key];
  }

  translateError(key: string): string {
    // TODO valorar crear un pipe para traducir en el template
    if (!this.errorTranslations || !(key in this.errorTranslations)) return key;
    return this.errorTranslations[key];
  }
}
