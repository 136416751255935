<app-spinner-wrapper [isLoading]="!triggersList" [ngClass]="{ 'spinner-center': !triggersList }">
    <div class="position-relative page-title mx-5 mt-5 mb-1">
        <h1>Prioridad</h1>
        <hr class="float-left hr-title" />
    </div>
    <div class="d-flex justify-content-center py-3">
        <button mat-raised-button color="primary" (click)="savePriority()" [disabled]="!enableSave()">Guardar</button>
    </div>
    <div cdkDropList class="example-list" (cdkDropListDropped)="dropItem($event)">
        <div class="example-box" *ngFor="let item of triggersList" cdkDrag>
            <span class="font-weight-bold w-25">{{ item.name || item.title }}</span>
            <span class="w-25">{{ getTriggerActiveText(item) }}</span>
            <span *ngIf="item.triggerType !== 'Test A/B' && !item.typeWall" class="w-25">{{ item.triggerType }} - {{ item.type | triggersTypeToStringPipe }}</span>
            <span *ngIf="item.triggerType === 'Test A/B' && !item.typeWall" class="w-25">{{ item.triggerType }}</span>
            <span *ngIf="item.typeWall" class="w-25">Muro</span>
            <span *ngIf="item.isCompatible && currentSiteType != 'app'">Compatible con:
              <mat-form-field class= "px-2" appearance="outline">
                <mat-label>Triggers</mat-label>
                <mat-select
                    (selectionChange)="onFunctionSelectedChange(item.id, $event)"
                    [value]="item.compatibleTriggers"
                    multiple
                >
                  <div *ngFor="let trigger of triggersCompatibleList">
                    <mat-option *ngIf="trigger.id != item.id && (trigger.type !== item.type || trigger.type === 8)" [value]="trigger.id">
                      {{ trigger.name || trigger.title }}
                    </mat-option>
                  </div>
                </mat-select>
            </mat-form-field>
            </span>
            <span *ngIf="!item.isCompatible && currentSiteType != 'app'">No compatible</span>
        </div>
    </div>
    <div class="d-flex justify-content-center py-3">
        <button mat-raised-button color="primary" (click)="savePriority()" [disabled]="!enableSave()">Guardar</button>
    </div>
</app-spinner-wrapper>
