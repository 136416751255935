<ng-template matStepLabel>Previsualización</ng-template>
<div class="container card shadow mb-3">
    <div class="row p-4">
        <div class="col-md-3">
            <img
                mat-card-image
                class="c-article__img"
                [src]="image"
                onerror="this.src='/assets/images/image-placeholder.png';"
            />
        </div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-lg-12">
                    <h3 *ngIf="url; else emptyRedirect">
                        <a alt="" [href]="url" target="_blank">
                            {{ title }}
                        </a>
                    </h3>
                    <ng-template #emptyRedirect>
                        <h3>
                            <a alt="" href="javascript:void(0);">
                                Titulo de la notificación
                            </a>
                        </h3>
                    </ng-template>
                </div>
                <div class="col-lg-12">
                    {{ message || 'No hay un resumen disponible para esta noticia.' }}
                </div>
            </div>
        </div>
    </div>
</div>
