   <section *ngIf="metrics && metrics.length; else noData" class="row ml-1">
        <article
          class="grid card p-4"
          *ngFor="let method of methods"
          aria-label="Font Style">

          <div class="title font-weight-bold" style="font-size: 1.25rem;">
            <mat-icon
              class="mr-2 white-icon"
              fontSet="fas"
              [fontIcon]="method.icon"
            ></mat-icon>
            <span matLine class="mr-2">{{ getMethodName(method) }}</span>
          </div>

          <div class="subtitle_1"></div>
          <div *ngIf="isNotShow(method)" class="subtitle_2">Interacciones</div>
          <div *ngIf="isNotShow(method)" class="subtitle_3">CTR</div>

          <div class="column1 font-weight-bold">Total</div>
          <div [ngClass]="isShow(method)" class="font-weight-bold">{{ method.value | number: '':'es' }}</div>
          <div *ngIf="isNotShow(method)" class="column3 font-weight-bold">{{ getCTRFormated(method.value) }}</div>

          <ng-container *ngFor="let device of devices">
            <div class="column1">
              <mat-icon
                class="mr-2 white-icon fa-fw"
                fontSet="fas"
                [fontIcon]="device.icon"
              ></mat-icon>{{ device.name }}
            </div>

            <div [ngClass]="isShow(method)" class="font-weight-bold">
              {{ getValueForDevice(device.name, method.name) | number: '':'es' }}
            </div>
            <div *ngIf="isNotShow(method)" class="column3">{{ getDeviceCTRFormated(device.name, method.name)}}</div>
          </ng-container>
        </article>
      </section>

      <ng-template #noData>
        <div class="c-no-data-block">
            <p class="c-no-data-block__text c-no-data-block__text--gray">No hay datos de campañas que mostrar</p>
        </div>
      </ng-template>
