import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { NewslettersSubscriptionInterface } from '../interfaces/newsletters-subscription.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import NLTOPICENDPOINTS from 'src/app/shared/utils/newsletters-topic-endpoints';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { ConstantsServices } from 'src/app/shared/utils/constants-services';

@Injectable()
export class NewslettersSubscriptionService extends FirestoreService<NewslettersSubscriptionInterface> {
    constructor(db: AngularFirestore, aS: AuthService, private readonly httpClient: HttpClient) {
        super(ConstantsServices.CollectionsName.newsletterSubscriptionCollection, db, aS);
    }

    public getScriptWithCredentials(jsonCredentials: {
        audienceId: string,
        mediaId: string,
        topic: string,
        gigyaRegistration: boolean,
        gigyaLoginScreenset: string
    }): Observable<any> {
        let params = new HttpParams();
          params = params
              .set('audienceId', jsonCredentials.audienceId)
              .set('mediaId', jsonCredentials.mediaId)
              .set('topic', jsonCredentials.topic)
              .set ('gigyaRegistration', jsonCredentials.gigyaRegistration.toString())
              .set('gigyaLoginScreenset', jsonCredentials.gigyaLoginScreenset)

        const SUBSCRIPTION_GET_TAG_BY_SITE = `${NLTOPICENDPOINTS.endpoints.prefixSubscription}${NLTOPICENDPOINTS.endpoints.getSubcriptionsJavaScript}`;
        return this.httpClient.get<any>(SUBSCRIPTION_GET_TAG_BY_SITE, { params: params });
    }
}
