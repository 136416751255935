import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Datalayer from '../models/push-notification/data-layer';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionsService {
  constructor(private readonly httpClient: HttpClient) {}

  parseDatalayer(site: string, url: string): Observable<Datalayer> {
    const encodedUrl = encodeURIComponent(url);
    return this.httpClient
      .get<Datalayer>(`${environment.cloudFunctions.parseDatalayer}/${site}/v1/${encodedUrl}`)
      .pipe(map(res => new Datalayer(res)));
  }
}
