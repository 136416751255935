import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;

    public forgotForm: FormGroup;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService
    ) {}

    public ngOnInit(): void {
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    public resetPassword(email: string): void {
        this.authService
            .sendEmailPasswordReset(email)
            .pipe(first())
            .subscribe(
                (): void => {
                    this.successSwal.fire();
                },
                (err: { code: string; message: string }): void => {
                    console.log(err);
                    this.errorSwal.fire();
                }
            );
    }

    public finish(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }
}
