<mat-checkbox
    class="mat-option mt-2"
    color="primary"
    [indeterminate]="isIndeterminate()"
    [checked]="isChecked()"
    (click)="$event.stopPropagation()"
    (change)="toggleSelection($event)"
>
    {{ text }}
</mat-checkbox>
