import { environment } from '../../../../environments/environment';

export class ENDPOINTS {

  //#region Metrics
  public static readonly API_DRUID_DATASOURCE_METRICS = `${environment.druidMetricsApi}data_source/allDays`;
  public static readonly API_DRUID_DATASOURCE_METRICS_ARTICLES = `${environment.druidMetricsApi}data_source/articles`;
  public static readonly API_DRUID_DATASOURCE_METRICS_MEDIA = `${environment.druidMetricsApi}data_source/byMedia`;
  public static readonly API_DRUID_DATASOURCE_METRICS_CHILDREN_STATS = `${environment.druidMetricsApi}data_source/childrenStats`;
  public static readonly API_DRUID_DATASOURCE_METRICS_PARENT_STATS = `${environment.druidMetricsApi}data_source/parentsStats`;
  //#endregion
}
