import { Injectable } from '@angular/core';
import * as fs from 'file-saver';

@Injectable()
export class FileSaverService {
    public saveFile(buffer: any, typeFile: string, fileName: string): void {
        const blob = new Blob([buffer], { type: typeFile });
        fs.saveAs(blob, fileName);
    }
}
