<div [innerHTML]="'<style>' + widget.template.styles + '</style>' | safeHTMLPipe"></div>
<div [id]="widget.id">
    <section [id]="'prometeoGadget-' + widget.id" class="prometeo-main-content col contentBoxPrometeo wrap">
        <h3 class="prometeo-main-title tituloSeccion">{{ widget.title}}</h3>
        <ul
            [id]="'prometeoContentListado-' + widget.id"
            class="prometeo-articles-content contentListado content-prometeo-article"
        >
            <ng-container *ngFor="let article of widget.behaviour.data; let index = index">
                <li
                    *ngIf="article?.type != 'h'"
                    [id]="article?.id + '-li-' + (index + 1)"
                    [class]="'prometeo-article-box boxNoticia col-prometeo-li col' + widget.behaviour.data.length"
                    [data-prometeo-gaid]="article?.id"
                    [data-prometeo-gatitle]="widget.title"
                    [data-prometeo-dest]="article?.url"
                    [data-prometeo-uniqueid]="article?.uniqueId"
                    [data-prometeo-postitle]="article?.title"
                    [data-prometeo-camp]="article?.campaign"
                    [data-prometeo-type]="article?.type"
                    [data-prometeo-dsi]=""
                    [data-prometeo-pos]="index + 1"
                    [data-prometeo-imageurl]="article?.imageUrl"
                >
                    <div class="prometeo-article-image-box boxImagen">
                        <figure>
                            <a
                                [href]="article?.url"
                                class="prometeo-article-image-link enlaceImagen"
                                title="{{ article?.title }}"
                                target="_blank"
                                rel="nofollow"
                            >
                                <span
                                    class="prometeo-article-image-bg contentImagen"
                                    [ngStyle]="{ 'background-image': 'url(' + article?.imageUrl + ')' }"
                                >
                                </span>
                            </a>
                            <figcaption class="prometeo-article-image-cap pieNoticia">{{ article?.title }}</figcaption>
                        </figure>
                    </div>
                    <div class="prometeo-article-title-box">
                        <h4 class="prometeo-article-title titularNoticia">
                            <a
                                [href]="article?.url"
                                class="prometeo-article-title-link enlaceNoticia"
                                title="{{ article?.title }}"
                                >{{ article?.title }}</a
                            >
                        </h4>
                        <span class="prometeo-article-signature firmaNoticia">{{ article?.signature }}</span>
                    </div>
                </li>

                <li
                    *ngIf="article?.type == 'h'"
                    [id]="article?.id + '-li-' + (index + 1)"
                    [class]="'prometeo-article-box boxNoticia col-prometeo-li col' + widget.behaviour.data.length"
                    [data-prometeo-gaid]="article?.id"
                    [data-prometeo-gatitle]="widget.title"
                    [data-prometeo-dest]=""
                    [data-prometeo-uniqueid]=""
                    [data-prometeo-postitle]=""
                    [data-prometeo-camp]="article?.camp"
                    [data-prometeo-type]="article?.type"
                    [data-prometeo-dsi]="article?.datasourceId"
                    [data-prometeo-imageurl]=""
                >
                    {{ article?.html }}
                </li>
            </ng-container>
        </ul>
    </section>
</div>
