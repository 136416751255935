<div class="container d-flex h-100">
    <div class="row w-100 align-self-center">
        <div class="col-md-8 mx-auto">
            <div class="c-login-card card">
                <div class="card-header text-center bg-primary-color py-4">
                    <img class="c-login-card__logo" src="/assets/images/logo-white.svg" alt="Hipatia Logo" />
                </div>
                <div class="card-body">
                    <app-spinner-wrapper [isLoading]="!isLoaded" [ngClass]="{ 'spinner-center': !isLoaded }">
                        <!--RESET PASSWORD-->
                        <form *ngIf="actionCodeIsValid && mode === availableModes.resetPassword" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                            <div class="row">
                                <div class="col-md-12">
                                    <h5 style="text-align: center;">Establecer nueva contraseña</h5>
                                    <mat-form-field class="c-mat-form c-mat-form--no-padding col-md-12" appearance="outline" color="primary">
                                        <mat-label>Contraseña</mat-label>
                                        <input matInput type="password" formControlName="newPassword" placeholder="Introduzca una nueva contraseña" required/>
                                        <mat-icon matSuffix color="primary" fontSet="fas" fontIcon="fa-lock"></mat-icon>
                                        <mat-error *ngIf="resetPasswordForm.get('newPassword').hasError('minlength')">
                                            La contraseña debe tener mínimo 6 carácteres
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="c-mat-form c-mat-form--no-padding col-md-12" appearance="outline" color="primary">
                                        <mat-label>Confirmar contraseña</mat-label>
                                        <input matInput type="password" formControlName="confirmNewPassword" placeholder="Introduzca una nueva contraseña" required/>
                                        <mat-icon matSuffix color="primary" fontSet="fas" fontIcon="fa-lock"></mat-icon>
                                        <mat-error *ngIf="resetPasswordForm.get('confirmNewPassword').hasError('mustMatch')">
                                            Las contraseñas no coinciden
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-around">
                                <div class="col-md-3">
                                    <button mat-raised-button class="h-100 w-100" color="primary" type="submit" [disabled]="resetPasswordForm.invalid">
                                        Cambiar
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button mat-raised-button class="h-100 w-100" type="button" color="primary" routerLink="/login">
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div *ngIf="!actionCodeIsValid && mode === availableModes.resetPassword" style="text-align: center;">
                            <h5>Prueba a restablecer tu contraseña de nuevo</h5>
                            <span>La petición para restablecer tu contraseña ha expirado o el enlace ya ha sido usado</span>
                            <div class="mt-3" style="align-items: center;">
                                <button mat-raised-button type="button" color="primary" routerLink="/login">
                                    Continuar
                                </button>
                            </div>
                        </div>

                        <!--EMAIL VERIFICATION-->
                        <div *ngIf="actionCodeIsValid && mode === availableModes.verifyEmail" style="text-align: center;">
                            <h5>Tu correo electrónico ha sido verificado</h5>
                            <span>Ahora puedes acceder con tu nueva cuenta</span>
                            <div class="mt-3" style="align-items: center;">
                                <button mat-raised-button type="button" color="primary" routerLink="/login">
                                    Continuar
                                </button>
                            </div>
                        </div>
                        <div *ngIf="!actionCodeIsValid && mode === availableModes.verifyEmail" style="text-align: center;">
                            <h5>Prueba a verificar tu correo electrónico de nuevo</h5>
                            <span>La petición para verificar tu correo electrónico ha expirado o el enlace ya ha sido usado</span>
                            <div class="mt-3" style="align-items: center;">
                                <button mat-raised-button type="button" color="primary" routerLink="/login">
                                    Continuar
                                </button>
                            </div>
                        </div>

                        <!--EMAIL RECOVER-->
                        <div *ngIf="actionCodeIsValid && mode === availableModes.recoverEmail" style="text-align: center;">
                            <h5>Tu correo electrónico ha sido recuperado</h5>
                            <span>Ahora puedes acceder a tu cuenta con el correo electrónico con el que te registraste</span>
                            <div class="mt-3" style="align-items: center;">
                                <button mat-raised-button type="button" color="primary" routerLink="/login">
                                    Continuar
                                </button>
                            </div>
                        </div>
                        <div *ngIf="!actionCodeIsValid && mode === availableModes.recoverEmail" style="text-align: center;">
                            <h5>Prueba a recuperar tu correo electrónico de nuevo</h5>
                            <span>La petición para recuperar tu correo electrónico ha expirado o el enlace ya ha sido usado</span>
                            <div class="mt-3" style="align-items: center;">
                                <button mat-raised-button type="button" color="primary" routerLink="/login">
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </app-spinner-wrapper>
                </div>
            </div>
        </div>
    </div>
</div>
