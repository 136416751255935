import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/compat/firestore';
import { ConfigurationInterface } from '../interfaces/configuration.interface';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Observable, from } from 'rxjs';
import { AuthService } from './auth.service';

const CONFIGURATION_COLLECTION = 'configurations';

@Injectable()
export class ConfigurationService {
    public configurationCollection: AngularFirestoreCollection<ConfigurationInterface>;

    constructor(private readonly firestore: AngularFirestore, private authService: AuthService) {
        this.configurationCollection = this.firestore.collection<ConfigurationInterface>(CONFIGURATION_COLLECTION);
    }

    public get(id: string): Observable<ConfigurationInterface> {
        return this.configurationCollection.doc<ConfigurationInterface>(id).valueChanges();
    }

    public update(id: string, data: ConfigurationInterface, update: boolean): Observable<void> {
        if (update) {
            return from(
                this.configurationCollection.doc<ConfigurationInterface>(id).update(Object.assign({}, data))
            );
        } else {
            data.createdAt = firebase.firestore.Timestamp.now();
            data.updatedAt = firebase.firestore.Timestamp.now();
            data.updatedBy = this.authService.getEmail();
            return from(
                this.configurationCollection.doc<ConfigurationInterface>(id).set(Object.assign({}, data))
            );
        }
    }

    public initConfiguration(id: string): Promise<void> {
        const payload = {
          bannedUrls: [],
          createdAt: firebase.firestore.Timestamp.now(),
          updatedAt: firebase.firestore.Timestamp.now(),
          updatedBy: this.authService.getEmail(),
        }
        return this.configurationCollection.doc<ConfigurationInterface>(id).set(Object.assign({}, payload));
    }
}
