<div class="row mt-3">
  <div class="col p-0">
    <div class="card elevation">
      <form [formGroup]="categoriesFiltersForm" class="d-flex align-items-center px-5 pt-4">
        <h4 class="font-weight-bold flex-grow-1">Categorías con más activaciones</h4>

        <mat-form-field
          *ngIf="metricsCategories"
          class="c-mat-form c-mat-form--no-padding mr-4"
          appearance="outline"
        >
          <mat-label>Nombre de la categoría</mat-label>
          <input
              type="text"
              placeholder="Seleccione una categoría"
              matInput
              formControlName="name"
              [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
              <mat-option> Ninguno </mat-option>
              <mat-option *ngFor="let category of metricsCategoriesTable.filteredData" [value]="category.name">
                  {{ category.name}}
              </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <button
            *ngIf="metricsCategories"
            class="align-self-stretch my-1"
            style="font-size : 16px;"
            type="button"
            color="primary"
            title="Descargar Datos"
            (click)="exportCategoriesMetricsAsCSV()"
            mat-raised-button
        >
          Descargar Datos
        </button>
      </form>

      <app-spinner-wrapper [isLoading]="categoriesLoading" [ngClass]="{ 'spinner-center': categoriesLoading }">
        <app-generic-table
          *ngIf="!categoriesLoading"
          [thereIsData]="!!metricsCategoriesTable && !!metricsCategoriesTable.filteredData.length"
          [dataSource]="metricsCategoriesTable"
          [columns]="structureCategoriesColumns"
          tableStyle="c-statistics-triggerCategories-table"
        ></app-generic-table>
      </app-spinner-wrapper>
    </div>
  </div>
</div>

