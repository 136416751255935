
<div class= "radio-chart">
  <div *ngIf="innerLabel && innerLabel.length">
    <apx-chart [series]="apexchart.series" [labels]="apexchart.labels" [chart]="apexchart.chart"
      [plotOptions]="apexchart.plotOptions" [legend]="apexchart.legend">
    </apx-chart>
  </div>
  <div class="d-flex flex-column justify-content-center">
        <mat-chip-list>
            <div class="d-flex flex-column justify-content-end">
              <mat-chip color="primary" class="row" selected>
                  {{ outerLabel }}:
                  {{ value | number: '':'es-ES' }}
              </mat-chip>
              <mat-chip color="primary" class="row" selected>
                  Total:
                  {{ total | number: '':'es-ES' }}
              </mat-chip>
            </div>
        </mat-chip-list>
  </div>
</div>
