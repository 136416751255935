import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    CollectionReference,
    DocumentChangeAction,
    Query,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { combineLatest, from, Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { ArticleInterface } from '../interfaces/article.interface';
import { DatasourceInterface } from '../interfaces/datasource.interface';
import { AuthService } from './auth.service';

const DATASOURCES_COLLECTION = 'widget-datasources';

@Injectable()
export class DatasourceService {
    datasourcesCollection: AngularFirestoreCollection<DatasourceInterface>;
    dataSourceObservable: Observable<DatasourceInterface[]>;

    public constructor(private readonly firestore: AngularFirestore, private readonly authService: AuthService) {
        this.datasourcesCollection = this.firestore.collection<DatasourceInterface>(DATASOURCES_COLLECTION);
    }
    public getAll(): Observable<DatasourceInterface[]> {
        if (!this.dataSourceObservable) {
            this.dataSourceObservable = this.datasourcesCollection.valueChanges().pipe(shareReplay(1));
        }
        return this.dataSourceObservable;
    }

    public getAllAllowed(sitesIds: string[]): Observable<DatasourceInterface[]> {
        const queries = new Array<Observable<DocumentChangeAction<DatasourceInterface>[]>>();
        for (const siteId of sitesIds) {
            const datasources$ = this.firestore
                .collection<DatasourceInterface>(
                    DATASOURCES_COLLECTION,
                    (ref: CollectionReference): Query => ref.where('media', '==', siteId)
                )
                .snapshotChanges();
            const datasourcesShared$ = this.firestore
                .collection<DatasourceInterface>(
                    DATASOURCES_COLLECTION,
                    (ref: CollectionReference): Query => ref.where('mediasToShare', 'array-contains', siteId)
                )
                .snapshotChanges();
            queries.push(datasources$, datasourcesShared$);
        }

        return combineLatest(queries).pipe(
            switchMap(
                (res: DocumentChangeAction<DatasourceInterface>[][]): Observable<DatasourceInterface[]> => {
                    const datasources = new Array<DatasourceInterface>();
                    res.forEach((actions: DocumentChangeAction<DatasourceInterface>[]): void => {
                        actions.forEach((action: DocumentChangeAction<DatasourceInterface>): void => {
                            const datasource = action.payload.doc.data();
                            if (
                                datasource &&
                                !datasources.find((d: DatasourceInterface): boolean => d.id === action.payload.doc.id)
                            ) {
                                datasource.id = action.payload.doc.id;
                                datasources.push(datasource);
                            }
                        });
                    });
                    return of(datasources);
                }
            )
        );
    }

    public getBySite(siteId: string): Observable<DatasourceInterface[]> {
        return this.firestore
            .collection<DatasourceInterface>(
                DATASOURCES_COLLECTION,
                (ref: CollectionReference): Query => ref.where('media', '==', siteId)
            )
            .valueChanges();
    }

    public getBySharedSite(siteId: string): Observable<DatasourceInterface[]> {
        return this.firestore
            .collection<DatasourceInterface>(
              DATASOURCES_COLLECTION,
              (ref: CollectionReference): Query => ref.where('mediasToShare', 'array-contains', siteId)
            ).valueChanges();
    }

    public get(datasourceId: string): Observable<DatasourceInterface> {
        return this.datasourcesCollection.doc<DatasourceInterface>(datasourceId).valueChanges();
    }

    public add(datasource: DatasourceInterface): Observable<void> {
        const datasourceId = this.firestore.createId();
        datasource.id = datasourceId;
        datasource.createdAt = firebase.firestore.Timestamp.now();
        datasource.updatedAt = firebase.firestore.Timestamp.now();
        datasource.updatedBy = this.authService.getEmail();
        datasource.articles = datasource.articles.map((article: ArticleInterface): any => {
            article.datasourceId = datasourceId;
            return Object.assign({}, article);
        });
        datasource.objetive = Object.assign({}, datasource.objetive);
        return from(
            this.datasourcesCollection.doc<DatasourceInterface>(datasourceId).set(Object.assign({}, datasource))
        );
    }

    public delete(datasourceId: string): Observable<void> {
        return this.update(datasourceId, { deletedBy: this.authService.getEmail() }).pipe(
            switchMap(
                (): Observable<void> => from(this.datasourcesCollection.doc<DatasourceInterface>(datasourceId).delete())
            )
        );
    }

    public update(datasourceId: string, dataToUpdate: Partial<DatasourceInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        if (dataToUpdate.articles) {
            dataToUpdate.articles = dataToUpdate.articles.map((article: ArticleInterface): any => {
                article.datasourceId = datasourceId;
                return Object.assign({}, article);
            });
        }
        dataToUpdate.objetive = Object.assign({}, dataToUpdate.objetive);
        return from(
            this.datasourcesCollection.doc<DatasourceInterface>(datasourceId).update(Object.assign({}, dataToUpdate))
        );
    }

    public addSegments(dataSourceId: string, dataToUpdate: Partial<DatasourceInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return from(
            this.datasourcesCollection.doc<DatasourceInterface>(dataSourceId).update(Object.assign({}, dataToUpdate))
        );
    }
}
