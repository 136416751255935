import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TriggerInterface } from 'src/app/core/interfaces/trigger.interface';
import { CONSTANTSENDPOINTS } from '../utils/constants-urls/constants-endpoints';
import TestAbInterface from 'src/app/core/interfaces/trigger/test-ab.interface';

@Injectable()
export class TriggerRedisActionService {
    constructor(private readonly httpClient: HttpClient) {}

    public updateTriggerOnRedis(trigger: TriggerInterface, typePlatform: string): Observable<void> {
        const TRIGGER_UPDATE_URL = `${CONSTANTSENDPOINTS.triggersRedis.baseUrl}${CONSTANTSENDPOINTS.triggersRedis.prefixUpdate}`;
        return this.httpClient.post<any>(TRIGGER_UPDATE_URL, { trigger: trigger, typePlatform: typePlatform });
    }

    public updateTestAbOnRedis(testAb: TestAbInterface): Observable<void> {
      const TEST_AB_UPDATE_URL = `${CONSTANTSENDPOINTS.triggersRedis.baseUrl}${CONSTANTSENDPOINTS.triggersRedis.testAbUpdate}`;
      return this.httpClient.post<any>(TEST_AB_UPDATE_URL, { testAb });
    }

    public deleteTriggerOnRedis(siteId: string, triggerId: string): Observable<void> {
        const TRIGGER_UPDATE_URL = `${CONSTANTSENDPOINTS.triggersRedis.baseUrl}${CONSTANTSENDPOINTS.triggersRedis.prefixDelete}`;
        const params = new HttpParams().set('siteId', siteId).set('triggerId', triggerId);
        return this.httpClient.delete<any>(TRIGGER_UPDATE_URL, { params });
    }

    public deleteTestAbOnRedis(siteId: string, testAbId: string): Observable<void> {
      const TEST_AB_DELETE_URL = `${CONSTANTSENDPOINTS.triggersRedis.baseUrl}${CONSTANTSENDPOINTS.triggersRedis.testAbDelete}`;
      const params = new HttpParams().set('siteId', siteId).set('testAbId', testAbId);
      return this.httpClient.delete<any>(TEST_AB_DELETE_URL, { params });
    }

    public deleteTriggerAppOnRedis(siteId: string, triggerId: string): Observable<void> {
        const TRIGGER_UPDATE_URL_APP = `${CONSTANTSENDPOINTS.triggersRedis.baseUrl}${CONSTANTSENDPOINTS.triggersRedis.prefixDeleteApp}`;
        const params = new HttpParams().set('siteId', siteId).set('triggerId', triggerId);
        return this.httpClient.delete<any>(TRIGGER_UPDATE_URL_APP, { params });
    }

    public updateTriggerPriorityRedis(siteId: string, triggerId: string, priority: number, typePlatform: string): Observable<void> {
        const TRIGGER_UPDATE_URL = `${CONSTANTSENDPOINTS.triggersRedis.baseUrl}${CONSTANTSENDPOINTS.triggersRedis.prefixUpdatePriority}/${siteId}/${triggerId}/${priority}`;
        const params = new HttpParams().set('typePlatform', typePlatform);
        return this.httpClient.get<any>(TRIGGER_UPDATE_URL, { params });
    }

    public deleteTriggersAmpRedis(siteId: string): Observable<{ message: string }> {
        const AMP_TRIGGER_DELETE_URL = `${CONSTANTSENDPOINTS.triggersRedis.baseUrl}${CONSTANTSENDPOINTS.triggersRedis.prefixAmpDelete}`.replace(':siteId', siteId);
        return this.httpClient.delete<any>(AMP_TRIGGER_DELETE_URL);
    }
}
