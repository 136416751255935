<div *ngIf="trigger" class="row card elevation mt-3 p-4" style="align-items: center;">
  <div *ngIf="trigger" class="col-md-12 h-100">
      <h4 class="font-weight-bold">{{ trigger.name }}</h4>

      <div *ngIf="trigger" class="row ml-1">
          <h5 class="font-weight-medium">
            {{ trigger && trigger.active ? 'Activo' : 'Inactivo' }}
          </h5>

          <a *ngIf="triggerType === 'trigger'" class="ml-1" href="{{ '/triggers/list/' + trigger.id }}">(ver activador)</a>
          <a *ngIf="triggerType === 'newsletter'" class="ml-1" href="{{ '/newsletters/subscriptions/' + trigger.id }}">(ver suscripción newsletter)</a>
          <a *ngIf="triggerType === 'pushNotificationSubscription'" class="ml-1" href="{{ '/push-notifications/subscriptions/' + trigger.id }}">(ver suscripción de notificación push)</a>
      </div>

      <div *ngIf="metrics && metrics.length; else noData" class="row">
        <div class="col-lg-12">
          <div
            *ngIf="!!metricsChartOptions && !!metricsChartOptions.chart"
            class="chart-container">

            <apx-chart
              #metricsChart
              [series]="metricsChartOptions.series"
              [chart]="metricsChartOptions.chart"
              [xaxis]="metricsChartOptions.xaxis"
              [yaxis]="metricsChartOptions.yaxis"
              [stroke]="metricsChartOptions.stroke"
              [dataLabels]="metricsChartOptions.dataLabels"
            ></apx-chart>
          </div>
        </div>
      </div>
      <app-statistics-triggers-metrics
                [metrics]="metrics"
                [triggerId]="triggerId"
                [dates]="dates"
                [currentSiteId]="currentSiteId"
            >
            </app-statistics-triggers-metrics>

      <div *ngIf="metrics && metrics.length; else noData" class="row">
        <div class="trigger-style">
          <article
            [ngClass]="siteType === siteWebName ? 'grid2-web' : 'grid2-app'"
            class="card p-4 style-card"
            style="font-size: 1rem;"
            aria-label="Font Style">

            <div class="row1 justify-self-start font-weight-bold" style="font-size: 1.5rem;">Impresiones Perdidas</div>
            <div class="row2 justify-self-start" style="line-height: 1.5rem; font-size: 0.75rem;">
              Número de veces que el usuario no ha interactuado con ningún
              boton
            </div>

            <ng-container *ngFor="let device of ['total_imp'].concat(devicesName)">
              <div class="row1">{{ devices[device].title }}</div>
              <div class="row2">
                <span [ngClass]="{ 'font-weight-bold': device === 'total_imp' }">{{ getLostImpressions(device) }}</span>
                <mat-icon
                  *ngIf="device === 'total_imp'"
                  class="px-2 align-baseline horizontal"
                  fontSet="fas"
                  fontIcon="fa-info-circle"
                  matTooltipClass="c-multiline-tooltip style-info"
                  matTooltip="Porcentaje de impresiones perdidas del total de impresiones generadas por el activador"
                  matTooltipPosition="right"
                ></mat-icon>
              </div>
            </ng-container>
          </article>
        </div>
      </div>

      <div *ngIf="metrics && metrics.length; else noData" class="row">
        <div class="trigger-style">
          <article
            [ngClass]="siteType === siteWebName ? 'grid2-web' : 'grid2-app'"
            class="card p-4 style-card"
            style="font-size: 1rem;"
            aria-label="Font Style">

            <div class="row1 justify-self-start font-weight-bold" style="font-size: 1.5rem;">Número de Usuarios Únicos</div>
            <div class="row2 justify-self-start" style="line-height: 1.5rem; font-size: 0.75rem;">
              Número de usuarios únicos que han sido impactados al menos una vez
              con el activador
            </div>

            <ng-container *ngFor="let device of ['total_user'].concat(devicesName)">
              <div class="row1">{{ devices[device].title }}</div>
              <div class="row2" [ngClass]="{ 'font-weight-bold': device === 'total_user' }">{{ getTotalImpactedUsers(device) | number: '':'es' }}</div>
            </ng-container>
          </article>
        </div>
      </div>

      <div style="color: #161537; font-size: 0.85rem;" aria-label="Font Style">
        <span>Impresiones del activador: número de veces que el activador ha aparecido en pantalla.</span>
        <br>
        <span>Interacciones: número de veces que los usuarios han hecho clic en el botón.</span>
        <br>
        <span>CTR: número de interacciones por impresiones totales del activador.</span>
      </div>
  </div>
</div>

<ng-template #noData>
  <div class="c-no-data-block">
      <p class="c-no-data-block__text c-no-data-block__text--gray">No hay datos de campañas que mostrar</p>
  </div>
</ng-template>
