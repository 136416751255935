import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PushNotificationInterface } from 'src/app/core/interfaces/push-notification/push-notification.interface';

@Component({
    selector: 'app-scheduled-notification-form',
    templateUrl: './scheduled-notification-form.component.html',
    styleUrls: ['./scheduled-notification-form.component.scss'],
})
export class ScheduledNotificationFormComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() pushNotification: PushNotificationInterface;
    public minDate: Date = new Date();
    private unsubscribe: Subject<void> = new Subject();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit(): void {}

    subscribeToForm(): void {
        this.formGroup
            .get('dateNow')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((date: string): void => {
                this.pushNotification.dateNow = firebase.firestore.Timestamp.fromDate(new Date(date));
            });
        this.formGroup
            .get('time')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((time: string): void => {
                this.pushNotification.time = time;
            });
    }
}
