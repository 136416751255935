<h1 mat-dialog-title>{{ data.header }}</h1>
<div mat-dialog-content>
    <mat-form-field class="col-md-12" appearance="outline">
        <mat-label>Tipo de Acción</mat-label>
        <mat-select [(ngModel)]="data.typeAction" name="type">
            <mat-option *ngFor="let action of data.typeActions" [value]="action.name">
                {{ action.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-content>
    <mat-form-field class="col-md-12" appearance="outline" *ngIf="data.typeAction ==='url'">
        <mat-label>Enlace</mat-label>
        <input matInput name="link" [(ngModel)]="data.action" placeholder="Enlace" required />
    </mat-form-field>

    <mat-form-field class="col-md-12" appearance="outline" *ngIf="data.typeAction === 'screen'">
      <mat-label>Pantalla</mat-label>
      <mat-select [(ngModel)]="data.action" name="type">
        <mat-option *ngFor="let action of data.typeActions[1].actions" [value]="action">
            {{ action }}
        </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-md-12" appearance="outline" *ngIf="data.typeAction === 'action'">
    <mat-label>Acción</mat-label>
    <mat-select [(ngModel)]="data.action" name="type">
      <mat-option *ngFor="let action of data.typeActions[2].actions" [value]="action">
          {{ action }}
      </mat-option>
  </mat-select>
</mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="close()">{{ data.button.close.name }}</button>
    <button
        mat-raised-button
        color="primary"
        [disabled]="data.action == null"
        [mat-dialog-close]="data"
        cdkFocusInitial
    >
        {{ data.button.confirm.name }}
    </button>
</div>
