<div class="row card elevation p-3">
  <div class="col-md-12">
    <form [formGroup]="filtersForm" class="row">
      <mat-form-field class="col-md-5 c-mat-form c-mat-form--no-padding" appearance="outline">
          <mat-label>{{ selectorName }}</mat-label>
          <input type="text" [placeholder]="selectorName" matInput formControlName="elements" [matAutocomplete]="auto"/>
          <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let element of initialElementsList" [value]="getElementName(element)" (click)="onElementSelected(element)">
                  {{ getElementName(element) }}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="col-md-5 c-mat-form c-mat-form--no-padding"
        appearance="outline"
        (click)="picker.open()"
        (keydown)='picker.open()'
      >
        <mat-label>Seleccione rango de fechas</mat-label>
        <mat-date-range-input formGroupName='dates' [rangePicker]="picker" [min]='minDate' [max]='maxDate'>
          <input matStartDate placeholder="Fecha de inicio" formControlName='begin' >
          <input matEndDate placeholder="Fecha de fin" formControlName='end'>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <ng-content></ng-content>
    </form>
  </div>
</div>

