import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CONSTANTS } from '../utils/constants';
import { Observable } from 'rxjs';

@Injectable()
export class RecommendationService {
    constructor(private readonly httpClient: HttpClient) {}

    public getGroupedLayouts(siteId: string): Observable<{ top: string[]; latest: string[] }> {
        const params = new HttpParams().set('mediaId', siteId);
        return this.httpClient.get<{ top: string[]; latest: string[] }>(
            CONSTANTS.MEDIA_CONTENT_API_LAYOUTS_BY_MEDIA_URL,
            { params }
        );
    }

    public getGroupedSections(
        siteId: string
    ): Observable<{ category: string; category2: string[]; category3: string[] }[]> {
        const params = new HttpParams().set('mediaId', siteId);
        return this.httpClient.get<{ category: string; category2: string[]; category3: string[] }[]>(
            CONSTANTS.MEDIA_CONTENT_API_SECTIONS_BY_MEDIA_URL,
            { params }
        );
    }

    public getAccessTypes(
      siteId: string
  ): Observable<Array<string>> {
      const params = new HttpParams().set('mediaId', siteId);
      return this.httpClient.get<Array<string>>(
          CONSTANTS.MEDIA_CONTENT_API_ACCESSTYPES_BY_MEDIA_URL,
          { params }
      );
  }

    public getGroupedNSections(
        siteId: string
    ): Observable<{ category: string; category2: string[]; category3: string[] }[]> {
        const params = new HttpParams().set('mediaId', siteId);
        return this.httpClient.get<{ category: string; category2: string[]; category3: string[] }[]>(
            CONSTANTS.MEDIA_CONTENT_API_SECTIONS_BY_MEDIA_URL,
            { params }
        );
    }
}
