export class ConstantsFilters {
  // Tipos de componentes
  public static readonly DATASOURCE_TYPE: string = 'datasource';
  public static readonly TRIGGER_TYPE: string = 'trigger';
  public static readonly NEWSLETTER_TYPE: string = 'newsletter';
  public static readonly TRIGGER_FUNCTION_TYPE: string = 'triggerFunction';
  public static readonly AUDIENCE_TYPE: string = 'audience';
  public static readonly TOPIC_TYPE: string = 'topic';
  public static readonly TOPIC_APP_TYPE: string = 'topic-app';
  public static readonly HISTORICAL_SEGMENT_TYPE: string = 'historicalSegment';
  public static readonly REALTIME_SEGMENT_TYPE: string = 'realtimeSegment';
  public static readonly NOTIFICATION_TYPE: string = 'notification';
  public static readonly NOTIFICATION_APP_TYPE: string = 'notification-app';
  public static readonly NEWSLETTER_TEMPLATE_TYPE: string = 'newsletterTemplate';
  public static readonly PROMETEO_TEMPLATE_TYPE: string = 'prometeoTemplate';
  public static readonly TRIGGER_TEMPLATE_TYPE: string = 'triggerTemplate';
  public static readonly ADMIN_DIMENSION_TYPE: string = 'dimension';
  public static readonly ADMIN_ROLE_TYPE: string = 'role';
  public static readonly ADMIN_PAYWALL_TYPE: string = 'paywall';
  public static readonly ADMIN_AUTHENTICATION_TYPE: string = 'authentication';
  public static readonly ADMIN_UTM_CAMPAIGN_TYPE: string = 'utmCampaign';

  public static readonly FILTER_VALUE_NONE = 0;
  public static readonly FILTER_VALUE_ALL = 1;
  public static readonly FILTER_VALUE_ACTIVE = 2;
  public static readonly FILTER_VALUE_INACTIVE = 3;
}

export const FILTER_STATUS_OPTIONS = [
  { value: '', label: 'Todos' },
  { value: true, label: 'Activo' },
  { value: false, label: 'Inactivo' },
];