import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '../utils/constants';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TagsInterface } from 'src/app/core/interfaces/push-notification/tags.interface';
import ScheduledNotificationInterface from 'src/app/core/interfaces/push-notification/push-notification-scheduled.interface';
import APPSENDPOINTS from '../utils/constants-urls/apps-endpoints';
import AppsResponseInterface from 'src/app/core/interfaces/push-notification/apps/apps-response.interface';
import AppsNotificationInterface from 'src/app/core/interfaces/push-notification/apps/apps-notification.interface';
import TopicSubscriptionInterface from 'src/app/core/interfaces/push-notification/topic-subscription.interface';
import { NOTIFICATIONCONSTANTS } from '../utils/notification-constants';
import WebPushNotificationInterface from 'src/app/core/interfaces/push-notification/web/web-push-notification.interface';

@Injectable()
export class PushNotificationService {
    constructor(private readonly httpClient: HttpClient) {}

    public showTags(mediaId: string): Observable<TagsInterface[]> {
        const SHOW_TAGS_URL = CONSTANTS.API_REST_PUSH_NOTIFICATION_URL + CONSTANTS.PUSH_SHOW_TOPICS + mediaId;
        return this.httpClient.get<TagsInterface[]>(SHOW_TAGS_URL).pipe(take(1));
    }

    public showTopics(mediaId: string, active?: boolean): Observable<TopicSubscriptionInterface[]> {
      const url = new URL(NOTIFICATIONCONSTANTS.TOPICS_URL);
      url.searchParams.set('mediaId', mediaId);
      if (active !== undefined) url.searchParams.set('active', String(active));
      return this.httpClient.get<TopicSubscriptionInterface[]>(url.href).pipe(take(1));
    }

    // TODO: Identificar el tipo de dato que regresa el servicio y tiparlo.
    // En caso de hacer falta usar un DTO para transformarlo al lenguaje que se utiliza en hipatia
    public sendNotification(payload: WebPushNotificationInterface): Observable<any> {
        return this.httpClient.post<any>(CONSTANTS.API_SCHEDULE_CREATE, payload).pipe(take(1));
    }

    // TODO: Identificar el tipo de dato que regresa el servicio y tiparlo.
    // En caso de hacer falta usar un DTO para transformarlo al lenguaje que se utiliza en hipatia
    public sendAppNotification(payload: AppsNotificationInterface): Observable<any> {
        return this.httpClient.post<any>(CONSTANTS.API_SCHEDULE_APP, payload).pipe(take(1));
    }

    public getScriptWithCredentials(siteId: string): Observable<{ data: string }> {
        return this.httpClient.get<any>(CONSTANTS.API_REST_PUSH_NOTIFICATION_CREDENTIALS_SCRIPT_URL + siteId).pipe(map(response => response.data));
    }

    public updateNotification(id: string, payload: ScheduledNotificationInterface): Observable<string> {
        const url = CONSTANTS.API_SCHEDULE_UPDATE.replace(':id', id);
        return this.httpClient.put<any>(url, payload).pipe(take(1));
    }

    public updateAppNotification(payload: AppsNotificationInterface): Observable<string> {
        const url = CONSTANTS.API_SCHEDULE_APP;
        return this.httpClient.put<any>(url, payload).pipe(take(1));
    }

    public deleteNotification(id: string): Observable<string> {
        const url = CONSTANTS.API_SCHEDULE_UPDATE.replace(':id', id);
        return this.httpClient.delete<string>(url).pipe(take(1));
    }

    public getAppNotification(id: string, media: string): Observable<AppsResponseInterface> {
        const url = `${APPSENDPOINTS.endpoints.baseUrl}${APPSENDPOINTS.endpoints.notification}`
            .replace(':mediaId', media)
            .replace(':id', id);
        return this.httpClient.get<AppsResponseInterface>(url).pipe(take(1));
    }

    public getAppNotificationsForSite(media: string): Observable<AppsResponseInterface> {
        const url = `${APPSENDPOINTS.endpoints.baseUrl}${APPSENDPOINTS.endpoints.allNotifications}`.replace(
            ':mediaId',
            media
        );
        return this.httpClient.get<AppsResponseInterface>(url).pipe(take(1));
    }

    public deleteAppNotification(id: string, media: string): Observable<AppsResponseInterface> {
        const url = CONSTANTS.API_SCHEDULE_APP_DELETE.replace(':mediaId',media).replace(':notificationId', id);
        return this.httpClient.delete<AppsResponseInterface>(url).pipe(take(1));
    }

    public pushNotificationFromWebToApp(payload: WebPushNotificationInterface, templateType: string, appTopic: string): AppsNotificationInterface {
      const message = payload.message;
      const schedule = payload.schedule;
      return {
        jobId: '',
        id: payload.id,
        mediaId: payload.mediaId,
        schedule: {
          messageType: schedule.messageType,
          Time: schedule.messageType === CONSTANTS.PROMETEO_SCHEDULES_SCHEDULED_TYPE ? schedule.Time : '',
          Date: schedule.messageType === CONSTANTS.PROMETEO_SCHEDULES_SCHEDULED_TYPE ? schedule.Date : '',
          StartDate: schedule.StartDate,
          EndDate: schedule.EndDate,
          DaysOfWeek: schedule.messageType === CONSTANTS.PROMETEO_SCHEDULES_SCHEDULED_TYPE ? schedule.DaysOfWeek || [] : [],
          Seconds:0,
        },
        message: {
          title: message.title,
          message: message.description,
          imageUrl: message.urlImage,
          topic: appTopic,
          contentType: message.type,
          url: message.url,
          contentId: message.id,
          draft: false,
          type: 'app',
          templateType,
        },
      };
    }
}
