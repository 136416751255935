<div *ngIf="metrics && metrics.length">
  <div class="row card elevation mt-3 p-4">
    <div *ngIf="topic" class="col-md-12">
      <h4 class="font-weight-bold">Tópico: {{ topic.topic }}</h4>
      <h5 class="font-weight-bold">Suscriptores: {{ topic.subscribers }}</h5>

      <div class="row ml-1">
        <h5 class="font-weight-medium">
          {{ topic && topic.active ? 'Activo' : 'Inactivo' }}
        </h5>
      </div>

      <!-- AREA GRAPH -->
      <div *ngIf="metrics && metrics.length; else noData" class="row">
        <div class="col-lg-12">
          <div *ngIf="!!areaChartOptions && !!areaChartOptions.chart" class="chart-container">
            <apx-chart #metricsChart [series]="areaChartOptions.series" [chart]="areaChartOptions.chart"
              [xaxis]="areaChartOptions.xaxis" [yaxis]="areaChartOptions.yaxis" [stroke]="areaChartOptions.stroke"
              [dataLabels]="areaChartOptions.dataLabels"></apx-chart>
          </div>
        </div>
      </div>

      <!-- SUMMARY CARDS -->
      <div *ngIf="metrics && metrics.length; else noData" class="row">
        <div class="col card p-4 mx-4 summary-card" *ngFor="let status of STATUS">

          <div class="row title font-weight-bold">
            <div class="col offset-4">
              <span matLine>{{ TITLES[status] }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col offset-4">Interacciones</div>
            <div class="col">%</div>
          </div>
          <div class="mt-3">
            <div class="row" *ngFor="let device of DEVICES">
              <div class="col">
                <mat-icon class="fa-fw" fontSet="fas" [fontIcon]="ICONS[device]"></mat-icon>{{ device }}
              </div>
              <div class="col font-weight-bold">
                {{ summaryCards[status][device].value | number: '':'es' }}
              </div>
              <div class="col">{{ summaryCards[status][device].ctr | number: '':'es' }}%</div>
            </div>
          </div>
        </div>
      </div>

      <!-- LOST INTERACTIONS -->
      <div *ngIf="metrics && metrics.length; else noData" class="row mx-2 my-4">
        <div class="col card style-card p-4 px-4">
          <div class="row px-3">
            <div class="col-lg-3">
              <div class="justify-self-start font-weight-bold title">Interacciones Perdidas</div>
              <div class="justify-self-start">
                Número de veces que el usuario ha recibido una notificación pero no la ha abierto
              </div>
            </div>
            <div *ngFor="let device of DEVICES" class="col">
              <div class="mb-3">{{ device }}</div>
              <div>{{ lostInteractions[device].value | number: '':'es' }} ({{
                lostInteractions[device].ctr | number: '':'es' }}%)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noData>
      <div class="c-no-data-block">
        <p class="c-no-data-block__text c-no-data-block__text--gray">No hay datos de campañas que mostrar</p>
      </div>
    </ng-template>
  </div>

  <app-statistic-push-notification-top-content [metrics]="metrics">
  </app-statistic-push-notification-top-content>

  <!-- HISTOGRAM -->
  <div *ngIf="statisticLoaded" class="row card elevation mt-3 p-4">
    <div *ngIf="topic" class="col-md-12">
      <h4 class="font-weight-bold">Tráfico por horas</h4>
      <div class="row ml-1">
        <h5 class="font-weight-medium">
          Media de notificaciones push abiertas
        </h5>
      </div>
      <div *ngIf="metrics && metrics.length; else noData" class="row">
        <div class="col-lg-12">
          <div *ngIf="!!histogramChartOptions && !!histogramChartOptions.chart" class="chart-container">
            <apx-chart #histogramChart [series]="histogramChartOptions.series" [chart]="histogramChartOptions.chart"
              [xaxis]="histogramChartOptions.xaxis" [yaxis]="histogramChartOptions.yaxis"
              [legend]="histogramChartOptions.legend" [stroke]="histogramChartOptions.stroke"
              [dataLabels]="histogramChartOptions.dataLabels"></apx-chart>
          </div>
        </div>
      </div>

    </div>
    <ng-template #noData>
      <div class="c-no-data-block">
        <p class="c-no-data-block__text c-no-data-block__text--gray">No hay datos de campañas que mostrar</p>
      </div>
    </ng-template>
  </div>
</div>
