<app-no-data-wrapper [thereIsData]="thereIsData">
  <mat-table [dataSource]="dataSource" class="mat-elevation-z1" [ngClass]="tableStyle" matSort>

    <ng-container *ngFor="let column of columns; let i = index" matColumnDef={{column.columnDef}}>
      <mat-header-cell class="font-weight-bold px-2"
        [ngClass]="[!!column.cellStyle ? column.cellStyle : '', i==0 ? 'first-col' : '', column.isCentered ? 'centered-with-arrow' : '', column.headerIsCentered ? 'text-center' : '']"
        *matHeaderCellDef mat-sort-header [disabled]="!column.isSortable">
        {{column.Title}}
      </mat-header-cell>
      <mat-cell class="flex-wrap px-2"
        [ngClass]="[!!column.cellStyle ? column.cellStyle : '', i==0 ? 'first-col' : '', column.isCentered ? 'text-center' : '']"
        *matCellDef="let element">
        <div *ngIf="column.type == 'text'" class="d-flex flex-column">
          <span class="text-truncate" *ngIf="column.ValueHeader">
            {{ column.ValueHeader(element)}}
          </span>
          <span class="text-truncate"
            [ngClass]="[column.valueStyle ? column.valueStyle(element) : '', column.isCentered ? 'centered-with-arrow' : '']">
            <!-- // TODO ⚽ -> sustituir función cuando se actualice angular materials -> https://material.angular.io/cdk/clipboard/overview -->
            <button *ngIf="column.isCopyText" class="p-0 m-0 copy-button" matTooltip="Copiar al portapapeles"
              matTooltipPosition="above" (click)="copyToClipboard(column.Value(element))" value="column.Value(element)">
              <mat-icon fontSet="fas" fontIcon="fa-clipboard"></mat-icon>
            </button>
            {{ column.Value(element) }}
          </span>
          <span class="text-truncate" *ngIf="column.ValueFooter">
            {{ column.ValueFooter(element) }}
          </span>
        </div>
        <!-- This is a large text -->
        <div *ngIf="column.type == 'text-large'" class="d-flex flex-column">
          <span class="small-text" *ngIf="column.ValueHeader">
            {{ column.ValueHeader(element)}}
          </span>
          <span class="small-text">
            <!-- // TODO ⚽ -> sustituir función cuando se actualice angular materials -> https://material.angular.io/cdk/clipboard/overview -->
            <button *ngIf="column.isCopyText" class="p-0 m-0 copy-button" matTooltip="Copiar al portapapeles"
              matTooltipPosition="above" (click)="copyToClipboard(column.Value(element))" value="column.Value(element)">
              <mat-icon fontSet="fas" fontIcon="fa-clipboard"></mat-icon>
            </button>
            {{ column.Value(element) }}
          </span>
          <span class="small-text" *ngIf="column.ValueFooter">
            {{ column.ValueFooter(element) }}
          </span>
        </div>

        <!-- This is a long-text -->
        <div *ngIf="column.type == 'long-text'" class="d-flex flex-column">
          <span
            [ngClass]="[column.valueCssClass ? column.valueCssClass : '', column.isCentered ? 'centered-with-arrow' : '']">
            <!-- // TODO ⚽ -> sustituir función cuando se actualice angular materials -> https://material.angular.io/cdk/clipboard/overview -->
            <button *ngIf="column.isCopyText" class="p-0 m-0 copy-button" matTooltip="Copiar al portapapeles"
              matTooltipPosition="above" (click)="copyToClipboard(column.Value(element))" value="column.Value(element)">
              <mat-icon fontSet="fas" fontIcon="fa-clipboard"></mat-icon>
            </button>
            {{ column.Value(element) }}
          </span>
        </div>
        <!-- This is a checkbox -->
        <mat-checkbox *ngIf="column.type == 'boolean'" class="c-checkbox--big" color="primary" labelPosition="before"
          [checked]="column.Value(element)" [disabled]="true"></mat-checkbox>

        <!-- This is an icon -->
        <mat-icon *ngIf="column.type == 'icon'" [ngStyle]="{'color': column.valueColor(element)}"
          class="h4 align-baseline" fontSet="fas" fontIcon="fa-info-circle"
          [matTooltipClass]="'c-multiline-tooltip bg-primary-color'" matTooltip="{{ column.Value(element) }}"
          matTooltipPosition="right"></mat-icon>
        <!-- This is a button -->
        <button *ngIf="column.type =='button'" mat-raised-button type="button" color="primary"
          class="align-self-stretch" 
          [ngClass]="[column.valueStyle ? column.valueStyle(element) : '']"
          [routerLink]="column.Value && column.Value(element)"
          (click)="column.ValueAction && column.ValueAction(element)">
          <mat-icon class="h4 align-text-top" fontSet="fas"
            [fontIcon]="column.ValueIcon ? column.ValueIcon(element) : 'fa-chart-bar'"></mat-icon>
        </button>

        <!-- This is a statistic -->
        <ng-container *ngIf="column.type == 'statistics'">
          <mat-cell class="h4 align-baseline px-2 mb-0">
            <button mat-raised-button type="button" color="primary" (click)="onButtonClick(element)">
              <mat-icon class="h4 align-text-top" fontSet="fas" fontIcon="fa-chart-bar"></mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- This is an URL -->
        <div *ngIf="column.type == 'url'" class="d-flex flex-column">
          <span class="text-truncate mr-3" [ngClass]="column.valueStyle ? column.valueStyle(element) : ''">
            <a [target]="column.isBlank ? 'blank' : ''" [href]="column.ValueUrl(element)">{{ column.Value(element)
              }}</a>
          </span>
        </div>

        <!-- This is a Link -->
        <button *ngIf="column.type == 'link'" type="button" class="align-self-center button-link"
          (click)="column.ValueLink(element)">
          <span class="text-truncate mr-3">
            <a [ngClass]="column.valueStyle ? column.valueStyle(element) : ''">{{ column.Value(element) }}</a>
          </span>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions" *ngIf="actions || actionsPerRow">
      <mat-header-cell class="font-weight-bold pl-2 actions-col text-center"
        *matHeaderCellDef>ACCIONES</mat-header-cell>
      <mat-cell class="pl-2 actions-col" *matCellDef="let element">
        <!-- All rows have the same actions -->
        <mat-form-field class="c-mat-form c-mat-form--no-padding w-100" *ngIf="actions && actions.length > 0"
          appearance="outline">
          <mat-label>ACCIONES</mat-label>
          <mat-select name="actions" (selectionChange)="onActionChange(element, $event)">
            <mat-option *ngFor="let action of actions" [value]="action.id">
              {{ action.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Different actions per row -->
        <mat-form-field class="c-mat-form c-mat-form--no-padding w-100" *ngIf="!actions && element.actions.length > 0"
          appearance="outline">
          <mat-label>ACCIONES</mat-label>
          <mat-select name="actions" (selectionChange)="onActionChange(element, $event)">
            <mat-option *ngFor="let action of element.actions" [value]="action.id">
              {{ action.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsNames"></mat-header-row>
    <mat-row *matRowDef="let element; columns: columnsNames"></mat-row>
  </mat-table>
  <ng-container *ngIf="!paginationManagedByBackend">
    <mat-paginator #paginator [hidden]="!thereIsData" [length]="getLength()" [pageSize]="pageSize"
      [hidePageSize]="paginationManagedByBackend" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)">
    </mat-paginator>
  </ng-container>
</app-no-data-wrapper>
