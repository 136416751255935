export default class Datalayer {
  image: string;
  title: string;
  message: string;
  signature: string;
  contentId: string;

  //TODO: this parse we should do it in the cloud function, in the client we should only receive the parameters we want
  constructor(data: any) {
    this.title = data.title || Datalayer.similarKeyFrom(data, 'title');
    this.message = data.description || data.message || Datalayer.similarKeyFrom(data, 'description');
    this.image = data.image || Datalayer.similarKeyFrom(data, 'image');
    this.signature = data.firma || data.author || Datalayer.similarKeyFrom(data, 'author');
    this.contentId = data.noticia_id || Datalayer.similarKeyFrom(data, 'noticia');
  }

  private static similarKeyFrom(data: any, property: string): string {
    const key = Object.keys(data)
      .filter(key => key.match(property))
      .sort((a, b) => a.length - b.length)[0];
    return data[key] || '';
  }
}
