import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/shared/utils/utils';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { ConstantsStatistics } from 'src/app/shared/utils/constants-statistics';
import ConstantMessages from 'src/app/shared/utils/constants-messages/constants-messages';
import { ENDPOINTS } from 'src/app/shared/utils/constants-urls/metrics-endpoints';
import { DatasourceInterface } from '../interfaces/datasource.interface';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { StatisticsPushNotificationInterface } from '../interfaces/statistics/statistics-push-notification.interface';
import { StatisticsPushNotificationModel } from '../models/statistics/statistics-push-notification.model';
import { map } from 'rxjs/operators';

@Injectable()
export class MetricService {
    public constructor(private readonly httpClient: HttpClient, private datePipe: DatePipe) {}

    public getAllPageViewsGroupedByHour(mediaId: string): Observable<any> {
        const params = new HttpParams().set('mediaId', mediaId).set('date', Date.now().toString());
        return this.httpClient.get<any>(ConstantsStatistics.METRICS_PAGE_VIEWS_GROUPED_BY_HOUR, { params });
    }

    public getAllPageViewsGroupedByURLs(mediaId: string, URLs: string[]): Observable<any> {
        let params = new HttpParams().set('mediaId', mediaId).set('date', Date.now().toString());
        for (const URL of URLs) {
            params = params.append('URLs', URL);
        }
        return this.httpClient.get<any>(ConstantsStatistics.METRICS_PAGE_VIEWS_GROUPED_BY_URL, { params });
    }

    public getDatasourcesWithData(mediaId: string, startDate: Date, endDate: Date): Observable<{ datasourceId: string }[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      return this.httpClient.get<any>(ConstantsStatistics.API_DRUID_DATASOURCES_WITH_DATA, { params });
    }

    public getDatasourceById(
        mediaId: string,
        datasource: DatasourceInterface,
        startDate: Date,
        endDate: Date
    ): Observable<any[]> {
        const isSegmented = datasource.datasources && datasource.datasources.length ? 'true' : 'false';
        const params = new HttpParams()
            .set('data_source', datasource.id)
            .set('is_segmented', isSegmented)
            .set('media_id', mediaId)
            .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
        return this.httpClient.get<any>(ENDPOINTS.API_DRUID_DATASOURCE_METRICS, { params });
    }

    public getAllDatasourceGroupedByArticle(
        mediaId: string,
        datasource: DatasourceInterface,
        startDate: Date,
        endDate: Date
    ): Observable<any> {
        const isSegmented = datasource.datasources && datasource.datasources.length ? 'true' : 'false';
        let params = new HttpParams();
        params = params
            .set('data_source', datasource.id)
            .set('is_segmented', isSegmented)
            .set('media_id', mediaId)
            .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
        return this.httpClient.get<any>(ENDPOINTS.API_DRUID_DATASOURCE_METRICS_ARTICLES, {
            params,
        });
    }

    public getAllDatasourceGroupedByMedia(
        mediaId: string,
        datasource: DatasourceInterface,
        startDate: Date,
        endDate: Date
    ): Observable<any> {
        const isSegmented = datasource.datasources && datasource.datasources.length ? 'true' : 'false';
        let params = new HttpParams()
            .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null)
            .set('media_id', mediaId)
            .set('is_segmented', isSegmented)
            .set('data_source', datasource.id);
        const medias = [datasource.media, ...datasource.mediasToShare];

        medias.forEach(mediaToShare => {
            params = params.append('mediaIds[]', mediaToShare);
        });

        return this.httpClient.get<any>(ENDPOINTS.API_DRUID_DATASOURCE_METRICS_MEDIA, {
            params
        });
    }

    public getDatasourceChildrenByParentId(
      mediaId: string,
      datasource: DatasourceInterface,
      startDate: Date,
      endDate: Date
  ): Observable<any> {
      const params = new HttpParams()
          .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null)
          .set('media_id', mediaId)
          .set('data_source', datasource.id);

      return this.httpClient.get<any>(ENDPOINTS.API_DRUID_DATASOURCE_METRICS_CHILDREN_STATS, { params });
  }

    public getAllWidgetGroupedByArticle(
      mediaId: string,
      widgetId: string,
      startDate: Date,
      endDate: Date
    ): Observable<any> {
      let params = new HttpParams();
      params = params
          .set('widget_id', widgetId)
          .set('media_id', mediaId)
          .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      return this.httpClient.get<any>(ConstantsStatistics.API_DRUID_WIDGET_ARTICLES_METRICS, {
          params,
      });
    }

    public getAllWidgetAppsGroupedByArticle(mediaId: string, widgetId: string, startDate: Date, endDate: Date): Observable<any> {
      const params = new HttpParams()
      .set('mediaId', mediaId)
      .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
      .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      //TODO Revisar si la llamada a esta función está cumpliendo el objetivo para la que fue destinada.
      const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_WIDGETS_METRICS_BY_ARTICLE_APPS.replace(':widgetId', widgetId);
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllDaysWidgetById(widgetId: string, mediaId: string, startDate: Date, endDate: Date): Observable<any> {
        const params = new HttpParams()
            .set('widget_id', widgetId)
            .set('media_id', mediaId)
            .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
        const metricsURL = ConstantsStatistics.API_DRUID_WIDGET_ALLDAYS_METRICS;
        return this.httpClient.get<any>(metricsURL, { params: params });
    }

    public getAllHoursWidgetById(widgetId: string, mediaId: string, startDate: Date): Observable<any> {
        const params = new HttpParams()
            .set('widget_id', widgetId)
            .set('media_id', mediaId)
            .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null);
        const metricsURL = ConstantsStatistics.API_DRUID_WIDGET_ALLHOURS_METRICS;
        return this.httpClient.get<any>(metricsURL, { params: params });
    }

    public getAllDaysWidgetAppsById(widgetId: string, mediaId: string, startDate: Date, endDate: Date): Observable<any> {
      const params = new HttpParams()
      .set('mediaId', mediaId)
      .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
      .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      //TODO Revisar si la llamada a esta función está cumpliendo el objetivo para la que fue destinada.
      const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_WIDGETS_METRICS_BY_DATE_APPS.replace(':widgetId', widgetId);
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getCampaignByWidget(widgetId: string, mediaId: string, startDate: Date, endDate: Date): Observable<any> {
        const params = new HttpParams()
            .set('widget_id', widgetId)
            .set('media_id', mediaId)
            .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
        const metricsURL = ConstantsStatistics.API_DRUID_WIDGET_CAMPAIGN_METRICS;
        return this.httpClient.get<any>(metricsURL, { params: params });
    }

    public getAllWidgetActivationsGroupedBySegmentId(widgetId: string, mediaId: string, startDate: Date, endDate: Date): Observable<any> {
      const params = new HttpParams()
          .set('widget_id', widgetId)
          .set('media_id', mediaId)
          .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      return this.httpClient.get<any>(ConstantsStatistics.API_DRUID_WIDGET_SEGMENTS_METRICS, { params });
    }

    public getAllWidgetsWithData(mediaId: string, startDate: Date, endDate: Date): Observable<{widgetId: string}[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      return this.httpClient.get<any>(ConstantsStatistics.API_DRUID_WIDGETS_WITH_DATA, { params });
    }

    public async waitingForStatisticCheckNotification (
        componentTitle: string,
        componentId: string,
        componentType: string,
        mediaId: string,
        siteType: string,
        routeName: string,
        router: Router
    ) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() -CONSTANTS.STATISTICS_DAY_INTERVAL);
        const endDate = new Date();

        const article = Utils.getArticleByType(componentType) ? "la" : "el";

        Swal.fire({
          title: 'Espere por favor',
          html: `Comprobando si ${article} ${componentType} tiene datos registrados...`,
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => Swal.showLoading(),
        } as any);

        try {
          const hasStatistics = await this.getComponentWithData(componentId, componentType, siteType, mediaId, startDate, endDate);
          Swal.close();
          if (hasStatistics) router.navigate([`../../statistics/${routeName}`], { queryParams: { id: componentId } });
          else {
            Swal.fire({
              html: `No hay datos registrados para ${article} ${componentType}<br/><b>${componentTitle}</b>`,
              showCloseButton: false,
              showConfirmButton: true,
            });
          }
        } catch(error) {
          Swal.close();
          Utils.notify('Comprobación de estadísticas', ConstantMessages.ErrorType, ConstantMessages.CheckStatisticsText);
        }
    }

    public async getComponentWithData(componentId: string, componentType: string, siteType: string, mediaId: string, startDate: Date, endDate: Date): Promise<boolean> {
      const params = new HttpParams()
          .set('componentId', componentId)
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      switch (componentType) {
        case ConstantsStatistics.widgetType:
          if (siteType === CONSTANTS.SITE_WEB) return await this.getDruidWidgetData(params);
          else return await this.getCloudFunctionWidgetAppsData(params);
        case ConstantsStatistics.segmentedDatasourceType:
        case ConstantsStatistics.datasourceType:
          return await this.getDruidDatasourceData(params);
        case ConstantsStatistics.testABWidgetsType:
          return await this.getDruidTestABWidgetsData(params);
        case ConstantsStatistics.newsletterType:
        case ConstantsStatistics.pushNType:
        case ConstantsStatistics.triggerType:
          if (siteType === CONSTANTS.SITE_WEB) return await this.getNodeMetricsTriggerData(params);
          else return await this.getCloudFunctionTriggerAppsData(params);
        case ConstantsStatistics.testABType:
          return await this.getNodeMetricsTestABData(params);
        case ConstantsStatistics.segmentType:
          return await this.getNodeMetricsSegmentData(params);
      }
    }

    public getCloudFunctionWidgetAppsData(params: HttpParams): Promise<boolean> {
      //TODO Revisar si la llamada a esta función está cumpliendo el objetivo para la que fue destinada.
      return this.httpClient.get<boolean>(ConstantsStatistics.CLOUD_FUNCTION_WIDGET_WITH_DATA_APPS, { params }).toPromise();
    }

    public getCloudFunctionTriggerAppsData(params: HttpParams): Promise<boolean> {
      //TODO Revisar si la llamada a esta función está cumpliendo el objetivo para la que fue destinada.
      return this.httpClient.get<boolean>(ConstantsStatistics.CLOUD_FUNCTION_TRIGGER_WITH_DATA_APPS, { params }).toPromise();
    }

    public getDruidWidgetData(params: HttpParams): Promise<boolean> {
      return this.httpClient.get<boolean>(ConstantsStatistics.API_DRUID_WIDGET_WITH_DATA, { params }).toPromise();
    }

    public getDruidDatasourceData(params: HttpParams): Promise<boolean> {
      return this.httpClient.get<boolean>(ConstantsStatistics.API_DRUID_DATASOURCE_WITH_DATA, { params }).toPromise();
    }

    public getDruidTestABWidgetsData(params: HttpParams): Promise<boolean> {
      return this.httpClient.get<boolean>(ConstantsStatistics.API_DRUID_TEST_AB_WIDGETS_WITH_DATA, { params }).toPromise();
    }

    public getNodeMetricsTriggerData(params: HttpParams): Promise<boolean> {
      return this.httpClient.get<boolean>(ConstantsStatistics.API_NODE_TRIGGER_WITH_DATA, { params }).toPromise();
    }

    public getNodeMetricsTestABData(params: HttpParams): Promise<boolean> {
      return this.httpClient.get<boolean>(ConstantsStatistics.API_NODE_TEST_AB_WITH_DATA, { params }).toPromise();
    }

    public getNodeMetricsSegmentData(params: HttpParams): Promise<boolean> {
      return this.httpClient.get<boolean>(ConstantsStatistics.API_NODE_SEGMENT_WITH_DATA, { params }).toPromise();
    }

    public getAllWidgetsWithDataApps(mediaId: string, startDate: Date, endDate: Date): Observable<{widgetId: string}[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      //TODO Revisar si la llamada a esta función está cumpliendo el objetivo para la que fue destinada.
      return this.httpClient.get<any>(ConstantsStatistics.CLOUD_FUNCTION_WIDGETS_WITH_DATA_APPS, { params });
    }

    public getAllTestAbWidgetsWithData(mediaId: string, startDate: Date, endDate: Date): Observable<{testABWidgetId: string}[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      return this.httpClient.get<any>(ConstantsStatistics.API_DRUID_TESTS_AB_WIDGETS_WITH_DATA, { params });
    }

    public getAllTestAbWidgetsActivationsGroupedBySegmentId(widgetId: string, mediaId: string, startDate: Date, endDate: Date): Observable<any> {
      const params = new HttpParams()
          .set('test_ab_widget_id', widgetId)
          .set('media_id', mediaId)
          .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      return this.httpClient.get<any>(ConstantsStatistics.API_DRUID_TESTAB_WIDGETS_SEGMENTS_METRICS, { params });
    }

    public getAllTriggersWithData(mediaId: string, startDate: Date, endDate: Date): Observable<{trigger_id: string}[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      const metricsUrl = ConstantsStatistics.API_NODE_TRIGGERS_WITH_DATA;
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllTriggersWithDataApps(mediaId: string, startDate: Date, endDate: Date): Observable<{trigger_id: string}[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      //TODO Revisar si la llamada a esta función está cumpliendo el objetivo para la que fue destinada.
      const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_TRIGGERS_WITH_DATA_APPS;
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllEventsByTriggerIdGroupedByDate(triggerId: string, mediaId: string, startDate: Date, endDate: Date): any {
        const params = new HttpParams()
            .set('mediaId', mediaId)
            .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null)
            .set('triggerId', triggerId);
        const metricsUrl = ConstantsStatistics.API_NODE_TRIGGERS_METRICS_GROUPED_BY_DATE;
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllEventsByTriggerIdGroupedByDateInApps(triggerId: string, mediaId: string, startDate: Date, endDate: Date): any {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      //TODO Revisar si la llamada a esta función está cumpliendo el objetivo para la que fue destinada.
      const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_TRIGGERS_METRICS_BY_DATE_APPS.replace(':triggerId', triggerId);
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getImpactedUsersByTriggerInApps(triggerId: string, mediaId: string, startDate: Date, endDate: Date): any {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_TRIGGERS_IMPACTED_USERS_APPS.replace(':triggerId', triggerId);
      //TODO Revisar si la llamada a esta función está cumpliendo el objetivo para la que fue destinada.
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllTestsAbWithData(mediaId: string, startDate: Date, endDate: Date): Observable<{testABTriggerId: string}[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      const metricsUrl = ConstantsStatistics.API_NODE_TESTS_AB_WITH_DATA;
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllEventsByTestAbIdGroupedByDate(testAbId: string, mediaId: string, startDate: Date, endDate: Date): any {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null)
          .set('testAbId', testAbId);
      const metricsUrl = ConstantsStatistics.API_NODE_TESTS_AB_METRICS_GROUPED_BY_DATE;
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getImpactedUsersByTestAbId(testAbId: string, mediaId: string, startDate: Date, endDate: Date): any {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null)
          .set('testAbId', testAbId);
      const metricsUrl = ConstantsStatistics.API_NODE_TESTS_AB_IMPACTED_USERS;
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllTestAbActivationsGroupedBySegmentId(
      testAbId: string,
      mediaId: string,
      startDate: Date,
      endDate: Date
      ): any {
      const params = new HttpParams()
        .set('mediaId', mediaId)
        .set(
            'startDate',
            startDate
                .toISOString()
                .slice(0, 10)
                .split('-')
                .join('')
        )
        .set(
            'endDate',
            endDate
                .toISOString()
                .slice(0, 10)
                .split('-')
                .join('')
        );
      const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_TESTS_AB_METRICS_GROUPED_BY_SEGMENT.replace(':testAbId', testAbId);
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllEventsByTestAbWidgetIdGroupedByDate(
      mediaId: string,
      testAbWidgetId: string,
      startDate: Date,
      endDate: Date
    ): Observable<any> {
      let params = new HttpParams();
      params = params
          .set('test_ab_widget_id', testAbWidgetId)
          .set('media_id', mediaId)
          .set('date_start', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('date_end', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      return this.httpClient.get<any>(ConstantsStatistics.API_DRUID_TESTAB_WIDGETS_METRICS, {
          params,
      });
    }

    public getAllSegmentsWithData(mediaId: string, startDate: Date, endDate: Date): Observable<{segment_id: string}[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      const metricsUrl = ConstantsStatistics.API_NODE_SEGMENTS_WITH_DATA;
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllSegmentStatsGroupedByDatePosgreSql(
        segmentId: string,
        mediaId: string,
        startDate: Date,
        endDate: Date
    ): any {
        const params = new HttpParams()
            .set('segmentId', segmentId)
            .set('mediaId', mediaId)
            .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
        const metricsUrl = ConstantsStatistics.API_NODE_SEGMENTS_METRICS_GROUPED_BY_DATE;
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllSegmentActivationsGroupedByTrigger(
      segmentId: string,
      mediaId: string,
      startDate: Date,
      endDate: Date
    ): any {
      const params = new HttpParams()
        .set('mediaId', mediaId)
        .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
        .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null)
        .set('segmentId', segmentId);
      const metricsUrl = ConstantsStatistics.API_DRUID_TRIGGER_SEGMENTS_METRICS;
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllSegmentStatsByNumberOfImpactedUsers(segmentId: string, mediaId: string, startDate: Date): any {
        const params = new HttpParams().set('mediaId', mediaId).set(
            'startDate',
            startDate
                .toISOString()
                .slice(0, 10)
                .split('-')
                .join('')
        );
        const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_SEGMENTS_METRICS_BY_NUMBER_OF_IMPACTED_USERS.replace(':segmentId', segmentId);
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllSegmentStatsByNumberOfImpactedUsersPostgreSQL(
        segmentId: string,
        mediaId: string,
        startDate: Date,
        endDate: Date
    ): any {
        const params = new HttpParams()
            .set('segmentId', segmentId)
            .set('mediaId', mediaId)
            .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
        const metricsUrl = ConstantsStatistics.API_NODE_SEGMENTS_METRICS_BY_NUMBER_OF_IMPACTED_USERS;
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllTriggerStatsByNumberOfImpactedUsersPostgreSQL(
      triggerId: string,
      mediaId: string,
      startDate: Date,
      endDate: Date
    ): any {
      const params = new HttpParams()
          .set('triggerId', triggerId)
          .set('mediaId', mediaId)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      const metricsUrl = ConstantsStatistics.API_NODE_TRIGGERS_METRICS_BY_NUMBER_OF_IMPACTED_USERS;
      return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllTriggerActivationsGroupedBySegmentId(
        triggerId: string,
        mediaId: string,
        startDate: Date,
        endDate: Date
    ): any {
        const params = new HttpParams()
            .set('triggerId', triggerId)
            .set('mediaId', mediaId)
            .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
        const metricsUrl = ConstantsStatistics.API_NODE_TRIGGERS_METRICS_GROUPED_BY_SEGMENT;
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getPushNotificationStatisticsGroupedByDate(mediaId: string, topic: string, startDate: Date, endDate: Date): Observable<StatisticsPushNotificationModel[]> {
      const params = new HttpParams()
          .set('mediaId', mediaId)
          .set('topic', topic)
          .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
          .set('endDate', endDate ?  this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
      return this.httpClient.get<StatisticsPushNotificationInterface[]>(ConstantsStatistics.API_DRUID_PUSH_NOTIFICATION_METRICS, { params }).pipe(
        map(statistics => statistics.map(StatisticsPushNotificationModel.fromVPCPost))) ;
    }

    public getAllEventsByPushSubscriptionIdGroupedByDate(pushSubscriptionId: string) {
        const params = new HttpParams().set('pushSubscriptionId', pushSubscriptionId);
        const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_PUSH_SUBSCRIPTION_METRICS;
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllEventsByPushSubscriptionIdGroupedByArticles(pushSubscriptionId: string, limit: number) {
        const params = new HttpParams().set('pushSubscriptionId', pushSubscriptionId).set('limit', limit.toString());
        const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_PUSH_SUBSCRIPTION_METRICS_ARTICLES;
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getAllEventsPushSendingsByTopicGroupedByDate(topic: string, mediaId: string) {
        const params = new HttpParams().set('topic', topic).set('mediaId', mediaId);
        const metricsUrl = ConstantsStatistics.CLOUD_FUNCTION_PUSH_SENDING_METRICS;
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getArticlesTriggerStats(
        triggerId: string,
        mediaId: string,
        startDate: Date,
        endDate: Date
      ): any {
        const params = new HttpParams()
            .set('triggerId', triggerId)
            .set('mediaId', mediaId)
            .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
            .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
        const metricsUrl = ConstantsStatistics.API_NODE_ARTICLES_TRIGGERS;
        return this.httpClient.get<any>(metricsUrl, { params: params });
    }

    public getCategoriesTriggerStats(
    triggerId: string,
    mediaId: string,
    startDate: Date,
    endDate: Date
    ): any {
    const params = new HttpParams()
        .set('triggerId', triggerId)
        .set('mediaId', mediaId)
        .set('startDate', startDate ? this.datePipe.transform(startDate, 'yyyy-MM-dd') : null)
        .set('endDate', endDate ? this.datePipe.transform(endDate, 'yyyy-MM-dd') : null);
    const metricsUrl = ConstantsStatistics.API_NODE_CATEGORIES_TRIGGERS;
    return this.httpClient.get<any>(metricsUrl, { params: params });
    }
}
