import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class HtmlSanitizerService {
  public constructor(private readonly domSanitizer: DomSanitizer) {}

  getSanitizedHtml(html: string): string {
    return this.sanitize(SecurityContext.HTML, html);
  }

  getSanitizeScript(script: string): string {
    return this.sanitize(SecurityContext.SCRIPT, script);
  }

  private sanitize(securityContext: SecurityContext, text: string): string {
    return this.domSanitizer.sanitize(securityContext, text);
  }
}
