import { environment } from 'src/environments/environment';

export default class APPSENDPOINTS {
    public static readonly endpoints = {
        baseUrl: environment.prometeoAppsAPI,
        topicList: '/v1/topic/:mediaId/list',
        topic: '/v1/topic/:mediaId/:topicId',
        createTopic: '/v1/topic/:mediaId',
        notification: '/v1/notification/:mediaId/:id',
        allNotifications: '/v1/notification/:mediaId',
    };
}
