import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CONSTANTS } from 'src/app/shared/utils/constants';



@Injectable()
export class AdvertisersService {

    constructor(
        private readonly angularFirestore: AngularFirestore)
    {}

    public getAdvertisersByMedia(mediaId: string): Observable<any> {
        return this.angularFirestore
                    .collection<any>(
                        CONSTANTS.FIRESTORE.COLLECTIONS.ADVERTISERS,
                        (ref: CollectionReference): Query =>
                            ref.where(CONSTANTS.FIRESTORE.KEYS.MEDIA, '==', mediaId)
                              .where(CONSTANTS.FIRESTORE.KEYS.ACTIVE, '==', true)
                    )
                    .valueChanges();
    }

    public getAllAdvertisers(): Observable<any> {
      return this.angularFirestore
                  .collection<any>(
                      CONSTANTS.FIRESTORE.COLLECTIONS.ADVERTISERS,
                      (ref: CollectionReference): Query =>
                          ref.where(CONSTANTS.FIRESTORE.KEYS.ACTIVE, '==', true)
                  )
                  .valueChanges();
  }

}
