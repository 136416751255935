import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { AuthenticationInterface } from '../interfaces/authentication.interface';

@Injectable()
export class AuthenticationService {
    public constructor(private readonly httpClient: HttpClient) {}

    public createToken(data: AuthenticationInterface): Observable<string> {
        const TOKEN_APPS = `${CONSTANTS.API_TOKEN_APPS_CREATE}`;
        return this.httpClient.post<string>(TOKEN_APPS, data);
    }

    public getAllToken(): Observable<AuthenticationInterface[]> {
        const TOKEN_APPS = `${CONSTANTS.API_TOKEN_APPS_ALL_TOKEN}`;
        return this.httpClient.get<AuthenticationInterface[]>(TOKEN_APPS);
    }

    public updateToken(data: AuthenticationInterface): Observable<AuthenticationInterface[]> {
        const TOKEN_APPS = `${CONSTANTS.API_TOKEN_APPS_UPDATE}`;
        return this.httpClient.post<AuthenticationInterface[]>(TOKEN_APPS, data);
    }

    public deleteToken(data: AuthenticationInterface): Observable<AuthenticationInterface[]> {
        const TOKEN_APPS = `${CONSTANTS.API_TOKEN_APPS_DELETE}`;
        return this.httpClient.post<AuthenticationInterface[]>(TOKEN_APPS, data);
    }

    public deleteCredetialsToken(mediaId: string, groupId: string): Observable<string> {
      const TOKEN_APPS = `${CONSTANTS.API_TOKEN_APPS_DELETE_CREDENTIALS}`;
      return this.httpClient.post<string>(TOKEN_APPS, { mediaId: mediaId, groupId: groupId });
  }

}
