import { PipeTransform, Pipe } from '@angular/core';
import { CONSTANTS } from '../utils/constants';

@Pipe({ name: 'schedulesContentTypeToStringPipe' })
export class SchedulesContentTypeToStringPipe implements PipeTransform {
    public transform(value: number): string {
        switch (value) {
            case CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_MANUAL_TYPE:
                return CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_MANUAL_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_DYNAMIC_TYPE:
                return CONSTANTS.PROMETEO_SCHEDULES_MESSAGE_DYNAMIC_TYPE_LITERAL;
            default:
                return '';
        }
    }
}
