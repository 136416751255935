import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() title: string;

  @ViewChild('divResizable', {
    static: true,
  })
  divResizable: ElementRef;

  @ViewChild('divPreview', { static: true }) divPreview: ElementRef;

  DRAGGABLE_WIDTH = 14;
  maxWidth: number;
  sumWidth: number;
  cursor: { selectionStart: number; selectionEnd: number };

  private readonly unsubscribe = new Subject<void>();

  ngOnInit(): void {
    const element = document.getElementById('preview-card');

    this.maxWidth = element?.offsetWidth - this.DRAGGABLE_WIDTH;
    this.sumWidth = this.maxWidth;

    this.control.valueChanges.pipe(startWith(this.control.value), takeUntil(this.unsubscribe)).subscribe(value => {
      this.divPreview.nativeElement.innerHTML = value;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  inputTag(type: string): void {
    const initialValue = this.control.value || '';
    const selectedText = document.getSelection().toString();

    const tagMap = {
      underlined: `<span style="text-decoration: underline;">${selectedText || ''}</span>`,
      a: `<a href="">${selectedText || ''}</a>`,
      img: `<img src="${selectedText || ''}" alt="" />`,
      ul: `<ul>\n<li>${selectedText || ''}</li>\n</ul>`,
    };

    if (selectedText) {
      const newValue = tagMap[type]
        ? this.replaceSelectionOnCursor(initialValue, tagMap[type])
        : this.replaceSelectionOnCursor(initialValue, `<${type}>${selectedText}</${type}>`);
      this.control.patchValue(newValue);
    } else {
      const emptyTag = tagMap[type] || `<${type}></${type}>`;
      this.control.patchValue(initialValue + emptyTag);
    }
  }

  startResize(event: MouseEvent): void {
    const initialX = event.clientX;
    const initialWidth = this.divResizable.nativeElement.offsetWidth;

    const moving = (e: MouseEvent): void => {
      const newX = e.clientX - initialX;
      if (initialWidth + newX > this.maxWidth) return;
      this.sumWidth = initialWidth + newX;
      this.divResizable.nativeElement.style.width = `${initialWidth + newX}px`;
    };

    const stopMovement = (): void => {
      document.removeEventListener('mousemove', moving);
      document.removeEventListener('mouseup', stopMovement);
    };

    document.addEventListener('mousemove', moving);
    document.addEventListener('mouseup', stopMovement);
  }

  storeCursor(event: FocusEvent): void {
    if (!('tagName' in event.target) || (event.target as HTMLTextAreaElement).tagName !== 'TEXTAREA') {
      throw new Error('The target element must be a textarea');
    }

    const { selectionStart, selectionEnd } = event.target as HTMLTextAreaElement;
    this.cursor = { selectionStart, selectionEnd };
  }

  private replaceSelectionOnCursor(value: string, replacement: string): string {
    return value.substring(0, this.cursor.selectionStart) + replacement + value.substring(this.cursor.selectionEnd);
  }
}
