<div class="row mt-3">
  <div class="col p-0">
    <div class="card elevation">
      <h4 *ngIf="elementType === 'widget'" class="font-weight-bold pl-5 pt-4">Segmentos Asociados al Widget</h4>
      <h4 *ngIf="elementType === 'testab'" class="font-weight-bold pl-5 pt-4">Segmentos Asociados al Test A/B</h4>

      <app-spinner-wrapper [isLoading]="segmentsLoading" [ngClass]="{ 'spinner-center': segmentsLoading }">
        <app-generic-table
          [thereIsData]="!!widgetSegmentsTable && !!widgetSegmentsTable.filteredData.length"
          [dataSource]="widgetSegmentsTable"
          [columns]="structureSegmentsColumns"
          (buttonClick)="onButtonStatisticsClicked($event.element)"
          tableStyle="c-statistics-widgetSegments-table"
        ></app-generic-table>
      </app-spinner-wrapper>
    </div>
  </div>
</div>
