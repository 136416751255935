import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, from } from 'rxjs';
import { PushNotificationInterface } from '../interfaces/push-notification/push-notification.interface';

const PUSH_SENDINGS_COLLECTION = 'push-sendings';

@Injectable()
export class PushNotificationFBService {
    private notificationCollection: AngularFirestoreCollection<PushNotificationInterface>;

    public constructor(private readonly firestore: AngularFirestore) {
        this.notificationCollection = this.firestore.collection<PushNotificationInterface>(PUSH_SENDINGS_COLLECTION);
    }

    public add(notification: PushNotificationInterface): Observable<void> {
        const notificationId = this.firestore.createId();
        notification.id = notificationId;
        return from(
            this.notificationCollection
                .doc<PushNotificationInterface>(notificationId)
                .set(Object.assign({}, notification))
        );
    }
}
