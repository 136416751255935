import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-wrapper',
  templateUrl: './spinner-wrapper.component.html',
  styleUrls: ['./spinner-wrapper.component.scss']
})
export class SpinnerWrapperComponent {

  @Input() isLoading: boolean = true;
  @Input() diameter: number = 150 ;

  constructor() { }

}
