<div class="row">
    <div class="col-md-12">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        JSON preview
                    </mat-panel-title>
                    <mat-panel-description>
                        Visualiza el JSON
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <pre><code>{{data | json}}</code></pre>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
