<form *ngIf="form" [formGroup]="form" class="card elevation mx-5 p-3 mt-4">
  <div class="d-flex flex-wrap gap-1 justify-content-between align-items-center">
    <ng-container *ngFor="let field of config.fields">
      <ng-container [ngSwitch]="field.type">
        <ng-container *ngSwitchCase="'simple'">
          <mat-form-field appearance="outline" class="c-mat-form c-mat-form--no-padding flex-grow-1">
            <mat-label>{{ field.label }}</mat-label>
            <input [formControlName]="field.name" matInput />
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'select'">
          <mat-form-field appearance="outline" class="c-mat-form c-mat-form--no-padding flex-grow-1">
            <mat-label>{{ field.label }}</mat-label>
            <mat-select *ngIf="field.type === 'select'" [formControlName]="field.name" [multiple]="field.multiple">
              <mat-option *ngIf="!field.multiple && field.placeholder" [value]="">{{ field.placeholder }}</mat-option>
              <mat-option *ngFor="let option of field.options" [value]="option.value">{{ option.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'selectGroups'">
          <mat-form-field appearance="outline" class="c-mat-form c-mat-form--no-padding flex-grow-1">
            <mat-label>{{ field.label }}</mat-label>
            <mat-select [formControlName]="field.name" [multiple]="field.multiple">
              <mat-option *ngIf="!field.multiple && field.placeholder" [value]="">{{ field.placeholder }}</mat-option>
              <ng-container *ngFor="let group of field.groups">
                <mat-optgroup [label]="group.label">
                  <mat-option *ngFor="let option of group.options" [value]="option.value"
                    >{{ option.label }}
                  </mat-option>
                </mat-optgroup>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'dateRange'">
          <mat-form-field appearance="outline" class="c-mat-form c-mat-form--no-padding flex-grow-1">
            <mat-label>{{ field.label }}</mat-label>
            <mat-date-range-input
              (click)="picker.open()"
              (keydown)="picker.open()"
              [formGroupName]="field.name"
              [rangePicker]="picker"
            >
              <input [formControlName]="field.controls.start" matStartDate />
              <input [formControlName]="field.controls.end" matEndDate />
            </mat-date-range-input>
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </ng-container>

    <button
      (click)="reset()"
      class="c-button c-button--text-big c-button--mw c-button--br-10"
      color="primary"
      mat-raised-button
    >
      Reiniciar filtros
    </button>
  </div>
</form>
