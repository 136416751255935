<form [formGroup]="formGroup">
  <div class="row">
    <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Tipo de Artículo</mat-label>
        <mat-select name="endpoint" formControlName="endpoint" required>
            <mat-option
                *ngFor="let endpoints of endPointTypes"
                [value]="endpoints">
                {{ endpoints | schedulesEndpointTypeToStringPipe}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Sección</mat-label>
        <mat-select name="section" formControlName="section">
            <mat-option
                *ngFor="let section of sections"
                [value]="section">
                {{ section }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-6" appearance="outline">
        <mat-label>Tag</mat-label>
        <input matInput name="tag" formControlName="tag">
    </mat-form-field>
  </div>
</form>

