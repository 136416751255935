import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { UtmCampaignInterface } from 'src/app/core/interfaces/utm-campaign.interface';
import { AuthService } from './auth.service';
import firebase from 'firebase/compat/app';

const UTM_CAMPAIGNS_COLLECTION = 'utm-campaigns';

@Injectable()
export class UtmCampaignService {
    public utmCampaignsCollection: AngularFirestoreCollection<UtmCampaignInterface>;

    public constructor (private readonly firestore: AngularFirestore, private readonly authService: AuthService) {
        this.utmCampaignsCollection = this.firestore.collection<UtmCampaignInterface>(UTM_CAMPAIGNS_COLLECTION);
    }

    public get(utmCampaignId: string): Observable<UtmCampaignInterface> {
        return this.utmCampaignsCollection.doc<UtmCampaignInterface>(utmCampaignId).valueChanges();
    }

    public getAllBySite(groupId: string): Observable<UtmCampaignInterface[]> {
        return this.firestore.collection<UtmCampaignInterface>(UTM_CAMPAIGNS_COLLECTION,
            (ref: CollectionReference): Query => ref.where('groupId', '==', groupId)).valueChanges();
    }

    //Function to call attribute usedBy from utm-campaigns collection in firebase and get all the sites that are sharing the same utm campaign
    public getUsedBy(siteId: string): Observable<UtmCampaignInterface[]> {
        return this.firestore.collection<UtmCampaignInterface>(UTM_CAMPAIGNS_COLLECTION,
            (ref: CollectionReference): Query => ref.where('usedBy', 'array-contains', siteId)).valueChanges();
    }

    //TODO: Check if this function will be useful to keep for the future or delete it
    public getBySite(mediaId: string): Observable<UtmCampaignInterface[]> {
        return this.firestore.collection<UtmCampaignInterface>(UTM_CAMPAIGNS_COLLECTION,
            (ref: CollectionReference): Query => ref.where('mediaId', '==', mediaId).where('active', '==', true)).valueChanges();
    }

    public add(utmCampaign: UtmCampaignInterface): Observable<void> {
        const utmCampaignId = this.firestore.createId();
        utmCampaign.id = utmCampaignId;
        utmCampaign.createdAt = firebase.firestore.Timestamp.now();
        utmCampaign.updatedAt = firebase.firestore.Timestamp.now();
        utmCampaign.updatedBy = this.authService.getEmail();
        return from(this.utmCampaignsCollection.doc<UtmCampaignInterface>(utmCampaignId).set(Object.assign({}, utmCampaign)));
    }

    public delete(utmCampaignId: string): Observable<void> {
        return from(this.utmCampaignsCollection.doc<UtmCampaignInterface>(utmCampaignId).delete());
    }

    public update(utmCampaignId: string, dataToUpdate: Partial<UtmCampaignInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return from(this.utmCampaignsCollection.doc<UtmCampaignInterface>(utmCampaignId).update(Object.assign({}, dataToUpdate)));
    }
}
