<mat-card class="col-md-8" *ngIf="!data">
  <mat-card-content>
      <div class="col-md-12 c-no-data-block">
          <p class="c-no-data-block__text c-no-data-block__text--gray">
              No hay datos que mostrar
          </p>
      </div>
  </mat-card-content>
</mat-card>
<mat-card class="col-md-8" *ngIf="(isBox || isModalSimple) && data">
  <div class="row-12 d-flex justify-content-end" *ngIf="form2.get('closeButtonEnable').value && isModalSimple">
    <img class = "col-2 mb-2 md-0" [src]="form2.get('closeButton').value">
  </div>
  <mat-card-content>
      <div class="row-md-8" *ngIf="form2.get('imageEnable').value && form2.get('image').value">
        <img class = "image" [src]="form2.get('image').value">
      </div>
      <div class="row-md-8" *ngIf="form2.get('preTitleEnable').value">
          <span class="pre-title">{{ form2.get('preTitle').value }}</span>
      </div>
      <div class="row-md-8" *ngIf="form2.get('titleEnable').value">
          <span class="title">
              {{ form2.get('title').value }}
          </span>
      </div>
      <div class="row-md-8" *ngIf="form2.get('descriptionEnable').value">
          <span class="description">
              {{ form2.get('description').value }}
          </span>
      </div>
  </mat-card-content>
  <mat-card-actions
      class="text-center"
      *ngIf="
          form2.get('button').value &&
          form2.get('buttonEnable').value
      "
  >
      <button mat-raised-button color="primary" class="col-md-12 button">
          {{ form2.get('button').value }}
      </button>
  </mat-card-actions>
  <mat-card-content>
    <div class="row-md-8 text-center" *ngIf="form2.get('linkEnable').value">
        <a href="" class="link">{{ form2.get('link').value }}</a>
    </div>
    <div class="row-md-8 text-center" *ngIf="form2.get('footerEnable').value">
        <span class="footer">{{ form2.get('footer').value }}</span>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="col-md-6" *ngIf="isModalFS && data">
  <div class="d-flex justify-content-end md-0" *ngIf="form2.get('closeButtonEnable').value">
    <img class = "col-3 mb-2" [src]="form2.get('closeButton').value">
  </div>
  <mat-card-content>
      <div class="row-md-8 text-center" *ngIf="form2.get('logoEnable').value">
        <img class = "image-fs" [src]="form2.get('logo').value">
      </div>
      <div
          class="row-md-6 text-center"
          *ngIf="form2.get('titleEnable').value"
      >
          <span class="title-fs">
              {{ form2.get('title').value }}
          </span>
      </div>
      <div
          class="row-md-8 text-center"
          *ngIf="form2.get('descriptionEnable').value"
      >
          <span class="description-fs">
              {{ form2.get('description').value }}
          </span>
      </div>
  </mat-card-content>
  <mat-card-actions
      class="text-center"
      *ngIf="
          form2.get('button').value &&
          form2.get('buttonEnable').value
      "
  >
      <button mat-raised-button color="primary" class="col-md-8 button">
          {{ form2.get('button').value }}
      </button>
  </mat-card-actions>
  <mat-card-content>
    <div class="row-md-8 text-center" *ngIf="form2.get('footerEnable').value">
        <span class="footer">{{ form2.get('footer').value }}</span>
    </div>
    <div class="row-md-8 text-center" *ngIf="form2.get('linkEnable').value">
        <a href="{{form3.get('linkAction').value}}" class="link">{{ form2.get('link').value }}</a>
    </div>
  </mat-card-content>
</mat-card>
