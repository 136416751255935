import { environment } from 'src/environments/environment';

export default class NLTOPICENDPOINTS {
    public static readonly endpoints = {
        prefixSubscription: `${environment.newsletterAPI.baseUrl}/v1/subscriptions`,
        prefixSubscriptionV2: `${environment.newsletterAPI.baseUrl}/v2/subscriptions`,
        getSubcriptionsTagsBySite: '/tags',
        getSubcriptionsTagsById: '/tag-by-id',
        getSubscriptionAudienceBySite:'/audiences-by-site',
        getSubscribersByTag:'/users-by-tag/:tagId',
        createSuscriptionTags:'/tag-create',
        deleteSuscriptionTags:'/tag-delete',
        getSubcriptionsJavaScript: '/generate-javascript',
        updatePriorityTag: '/tag-priority-update'
    };
}
