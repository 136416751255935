export class ConstantsServices {
  public static CollectionsName = {
      triggerCollection: 'triggers',
      triggerAppCollection: 'triggers-apps',
      testABCollection: 'test-ab',
      widgetCollection: 'widget',
      pushSubscriptionCollection: 'push subscription',
      newsletterSubscriptionCollection: 'newsletters-subscriptions',
      historicalOperationCollection: 'operation segment',
      testABWidgetsCollection: 'test-ab-widgets',
      datasourceCollection: 'datasource',
      segmentedDatasourceCollection: 'segmented datasource'
  };
}
