import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-previsualization',
    templateUrl: './previsualization.component.html',
})
export class PrevisualizationComponent implements OnInit {
    @Input() image: string;
    @Input() url: string;
    @Input() message: string;
    @Input() title: string;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit(): void {}
}
