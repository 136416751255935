import { Pipe, PipeTransform } from '@angular/core';
import { CONSTANTS } from '../utils/constants';
import { ConstantsTriggersApps } from '../utils/constants-triggers-apps';

@Pipe({
    name: 'triggersTypeToStringPipe',
})
export class TriggersTypeToStringPipe implements PipeTransform {
    public transform(value: number): string {
        switch (value) {
            case CONSTANTS.PROMETEO_TRIGGERS_MODAL_TYPE:
                return CONSTANTS.PROMETEO_TRIGGERS_MODAL_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_TRIGGERS_STICKY_TOP_TYPE:
                return CONSTANTS.PROMETEO_TRIGGERS_STICKY_TOP_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_TRIGGERS_STICKY_BOTTOM_TYPE:
                return CONSTANTS.PROMETEO_TRIGGERS_STICKY_BOTTOM_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_TRIGGERS_SIDEBAR_TYPE:
                return CONSTANTS.PROMETEO_TRIGGERS_SIDEBAR_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_TRIGGERS_NONE_TYPE:
                return CONSTANTS.PROMETEO_TRIGGERS_NONE_TYPE_LITERAL;
            case CONSTANTS.PROMETEO_TRIGGERS_BOX_TYPE:
                return CONSTANTS.PROMETEO_TRIGGERS_BOX_TYPE_LITERAL;
            case ConstantsTriggersApps.PROMETEO_TRIGGERS_APP_BOX_TYPE:
                return ConstantsTriggersApps.PROMETEO_TRIGGERS_APP_BOX_TYPE_LITERAL;
            case ConstantsTriggersApps.PROMETEO_TRIGGERS_APP_MODAL_TYPE:
                return ConstantsTriggersApps.PROMETEO_TRIGGERS_APP_MODAL_TYPE_LITERAL;
            default:
                return '';
        }
    }
}
