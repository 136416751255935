import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CONSTANTS } from '../utils/constants';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TopicsService {
    constructor(private readonly httpClient: HttpClient) {}

    public getAllTopicsById(siteId: string): Observable<string[]> {
        const params = new HttpParams().set('media_id', siteId);
        const TOPICS_BY_MEDIA_URL = CONSTANTS.API_REST_HENNEO_RECOMMENDATIONS_TOPICS_BY_MEDIA_URL;
        return (
            this.httpClient
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .get<any>(TOPICS_BY_MEDIA_URL, { params })
                .pipe(
                    take(1),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    map((res: any): string[] => {
                        return res.sections;
                    })
                )
        );
    }
}
