import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeES from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard } from './guards/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { AuthService } from './services/auth.service';
import { AWSService } from './services/aws.service';
import { DatasourceService } from './services/datasource.service';
import { SegmentedDatasourceService } from './services/segmented-datasource.service';
import { LogService } from './services/log.service';
import { MediaService } from './services/media.service';
import { MetricService } from './services/metric.service';
import { TriggerService } from './services/trigger.service';
import { RealTimeSegmentService } from './services/real-time-segment.service';
import { RealTimeService } from './services/real-time.service';
import { RoleService } from './services/role.service';
import { RouteService } from './services/route.service';
import { SegmentService } from './services/segment.service';
import { TemplateService } from './services/template.service';
import { UserService } from './services/user.service';
import { WidgetService } from './services/widget.service';
import { NewslettersSendingService } from './services/newsletters-sending.service';
import { PushNotificationFBService } from './services/push-notification.service';
import { PushSubscriptionService } from './services/push-subscription.service';
import { NewslettersSubscriptionService } from './services/newsletters-subscription.service';
import { ConfigurationService } from './services/configuration.service';
import { ImportedSegmentsService } from './services/imported-segments.service';
import { AllSegmentsService } from './services/all-segments.service';
import { TriggerTemplateService } from './services/trigger-template.service';
import { TriggerFunctionService } from './services/trigger-function.service';
import { AuthenticationService } from './services/authentication.service';
import { ProgramPushSubscriptionService } from './services/program-push-subscription.service';
import { ProgramNewslettersSubscriptionService } from './services/program-newsletter-subscription.service';
import { NewslettersTopicService } from './services/newsletters-topic.service';
import { TestAbService } from './services/test-ab.service';
import { UtmCampaignService } from './services/utm-campaign.service';
import { FirebaseAuthComponent } from './firebase-auth/firebase-auth.component';
import { RetailMediaService } from './services/retal-media.service';
import { AllNotificationsService } from './services/all-notifications.service';
import { AngularFireModule } from './angular-fire/angular-fire.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { environment } from '../../environments/environment';

registerLocaleData(localeES, 'es');
const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY/MM/DD',
    },
    display: {
        dateInput: 'YYYY/MM/DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'L',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [LoginComponent, ForgotPasswordComponent, FirebaseAuthComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        AngularFireModule,
        SweetAlert2Module.forRoot(),
        SharedModule,
        HttpClientModule,
    ],
    exports: [
        BrowserModule,
        BrowserAnimationsModule,
        SweetAlert2Module,
        LoginComponent,
        HttpClientModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: LOCALE_ID, useValue: environment.locale },
        AuthService,
        ConfigurationService,
        RouteService,
        RoleService,
        UserService,
        MediaService,
        DatasourceService,
        SegmentedDatasourceService,
        WidgetService,
        TemplateService,
        SegmentService,
        RealTimeSegmentService,
        TriggerService,
        ProgramPushSubscriptionService,
        ProgramNewslettersSubscriptionService,
        NewslettersSendingService,
        PushSubscriptionService,
        RealTimeService,
        MetricService,
        LogService,
        AWSService,
        AuthGuard,
        PushNotificationFBService,
        NewslettersSubscriptionService,
        DatePipe,
        ImportedSegmentsService,
        AllSegmentsService,
        TriggerTemplateService,
        TriggerFunctionService,
        AuthenticationService,
        NewslettersTopicService,
        TestAbService,
        UtmCampaignService,
        RetailMediaService,
        AllNotificationsService
    ],
})
export class CoreModule {}
