import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';

export interface RecursiveErrorsNode {
  label: string;
  errors: ValidationErrors;
  children: RecursiveErrorsNode[];
}

export function recursiveErrors(control: AbstractControl, label = 'root'): RecursiveErrorsNode {
  if (!control) return;

  const children: RecursiveErrorsNode[] = [];
  if (control instanceof FormArray || control instanceof FormGroup) {
    if (Object.keys(control.controls).length) {
      children.push(
        ...Object.entries(control.controls)
          .map(([label, control]) => recursiveErrors(control, label))
          .filter(Boolean)
      );
    }
  }

  if (!control.errors && !children.length) return;

  return {
    label,
    errors: control.errors,
    children,
  };
}
