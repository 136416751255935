import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { TriggerFunctionInterface } from '../interfaces/trigger-function.interface';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Utils } from 'src/app/shared/utils/utils';
import firebase from 'firebase/compat/app';

const TRIGGER_FUNCTIONS_COLLECTION = '/trigger-functions';
@Injectable()
export class TriggerFunctionService {
    public allTriggerFunctions: Observable<TriggerFunctionInterface[]>;

    public constructor(
        private readonly authService: AuthService,
        private fireDataBase: AngularFireDatabase,
        private readonly firestore: AngularFirestore
    ) {}

    public getAll(currentSiteId: string): Observable<TriggerFunctionInterface[]> {
        const triggerFunctionsBasic$ = this.getAllBasic();
        const triggerFunctionsSite$ = this.getAllBySite(currentSiteId);
        return combineLatest([triggerFunctionsBasic$, triggerFunctionsSite$]).pipe(
            map(
                ([triggerFunctionsBasic, triggerFunctionsSite]: [
                    TriggerFunctionInterface[],
                    TriggerFunctionInterface[]
                ]): TriggerFunctionInterface[] =>
                    Utils.removeDuplicates([...triggerFunctionsSite, ...triggerFunctionsBasic])
            )
        );
    }

    public getAllBySite(siteId: string): Observable<TriggerFunctionInterface[]> {
        return this.fireDataBase
            .list<TriggerFunctionInterface>(TRIGGER_FUNCTIONS_COLLECTION, ref =>
                ref.orderByChild('siteId').equalTo(siteId)
            )
            .valueChanges();
    }

    public getAllBasic(): Observable<TriggerFunctionInterface[]> {
        return this.fireDataBase
            .list<TriggerFunctionInterface>(TRIGGER_FUNCTIONS_COLLECTION, ref =>
                ref.orderByChild('basic').equalTo(true)
            )
            .valueChanges();
    }

    public getTriggerFunctionNameById(functionId: string): Observable<string> {
        return this.getFunctionById(functionId).pipe(map(triggerFunction => triggerFunction.name));
    }

    public getFunctionById(functionId: string): Observable<TriggerFunctionInterface> {
        return this.fireDataBase
            .object<TriggerFunctionInterface>(`${TRIGGER_FUNCTIONS_COLLECTION}/${functionId}`)
            .valueChanges();
    }

    public delete(functionId: string) {
        return this.fireDataBase
            .object<TriggerFunctionInterface>(`${TRIGGER_FUNCTIONS_COLLECTION}/${functionId}`)
            .remove();
    }

    public add(triggerFunction: TriggerFunctionInterface) {
        const functionId = this.firestore.createId();
        triggerFunction.id = functionId;
        triggerFunction.createdAt = firebase.firestore.Timestamp.now();
        triggerFunction.updatedAt = firebase.firestore.Timestamp.now();
        triggerFunction.updatedBy = this.authService.getEmail();
        return this.fireDataBase
            .object<TriggerFunctionInterface>(`${TRIGGER_FUNCTIONS_COLLECTION}/${functionId}`)
            .set(triggerFunction);
    }

    public update(functionId: string, dataToUpdate: Partial<TriggerFunctionInterface>): Promise<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return this.fireDataBase
            .object<TriggerFunctionInterface>(`${TRIGGER_FUNCTIONS_COLLECTION}/${functionId}`)
            .update(dataToUpdate);
    }
}
