import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, from, forkJoin } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import {
    AngularFirestoreCollection,
    AngularFirestore,
    DocumentChangeAction,
    CollectionReference,
    Query,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { SegmentedDatasourceInterface } from '../interfaces/segmented-datasource.interface';
import { AuthService } from './auth.service';
import { DatasourceInterface } from '../interfaces/datasource.interface';

const SEGMENTED_DATASOURCE_COLLECTION = 'segmented-datasources';

@Injectable()
export class SegmentedDatasourceService {
    segmentedDatasourceCollection: AngularFirestoreCollection<SegmentedDatasourceInterface>;

    constructor(private readonly firestore: AngularFirestore, private readonly authService: AuthService) {
        this.segmentedDatasourceCollection = this.firestore.collection<SegmentedDatasourceInterface>(
            SEGMENTED_DATASOURCE_COLLECTION
        );
    }

    public getAll(): Observable<SegmentedDatasourceInterface[]> {
        return this.segmentedDatasourceCollection.valueChanges();
    }

    public getAllAllowed(sitesIds: string[]): Observable<SegmentedDatasourceInterface[]> {
        const queries = new Array<Observable<DocumentChangeAction<SegmentedDatasourceInterface>[]>>();
        for (const siteId of sitesIds) {
            const segmentedDatasources$ = this.firestore
                .collection<SegmentedDatasourceInterface>(
                    SEGMENTED_DATASOURCE_COLLECTION,
                    (ref: CollectionReference): Query => ref.where('media', '==', siteId)
                )
                .snapshotChanges();
            queries.push(segmentedDatasources$);
        }
        return combineLatest(queries).pipe(
            switchMap(
                (
                    res: DocumentChangeAction<SegmentedDatasourceInterface>[][]
                ): Observable<SegmentedDatasourceInterface[]> => {
                    const segmentedDatasources = new Array<SegmentedDatasourceInterface>();
                    res.forEach((actions: DocumentChangeAction<SegmentedDatasourceInterface>[]): void => {
                        actions.forEach((action: DocumentChangeAction<SegmentedDatasourceInterface>): void => {
                            const segmentedDatasource = action.payload.doc.data();
                            if (
                                segmentedDatasource &&
                                !segmentedDatasources.find(
                                    (d: SegmentedDatasourceInterface): boolean => d.id === action.payload.doc.id
                                )
                            ) {
                                segmentedDatasource.id = action.payload.doc.id;
                                segmentedDatasources.push(segmentedDatasource);
                            }
                        });
                    });
                    return of(segmentedDatasources);
                }
            )
        );
    }

    public getBySite(siteId: string): Observable<SegmentedDatasourceInterface[]> {
        return this.firestore
            .collection<SegmentedDatasourceInterface>(
                SEGMENTED_DATASOURCE_COLLECTION,
                (ref: CollectionReference): Query => ref.where('media', '==', siteId)
            )
            .valueChanges();
    }

    public get(segmentedDatasourceId: string): Observable<SegmentedDatasourceInterface> {
        return this.segmentedDatasourceCollection
            .doc<SegmentedDatasourceInterface>(segmentedDatasourceId)
            .valueChanges();
    }

    public add(segmentedDatasource: SegmentedDatasourceInterface): Observable<void> {
        const segmentedDatasourceId = this.firestore.createId();
        segmentedDatasource.id = segmentedDatasourceId;
        segmentedDatasource.createdAt = firebase.firestore.Timestamp.now();
        segmentedDatasource.updatedAt = firebase.firestore.Timestamp.now();
        segmentedDatasource.updatedBy = this.authService.getEmail();
        return from(
            this.segmentedDatasourceCollection
                .doc<SegmentedDatasourceInterface>(segmentedDatasourceId)
                .set(Object.assign({}, segmentedDatasource))
        );
    }

    public delete(segmentedDatasourceId: string): Observable<void> {
        return this.update(segmentedDatasourceId, { deletedBy: this.authService.getEmail() }).pipe(
            switchMap(
                (): Observable<void> =>
                    from(
                        this.segmentedDatasourceCollection
                            .doc<SegmentedDatasourceInterface>(segmentedDatasourceId)
                            .delete()
                    )
            )
        );
    }

    public update(
        segmentedDatasourceId: string,
        dataToUpdate: Partial<SegmentedDatasourceInterface>
    ): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return from(
            this.segmentedDatasourceCollection
                .doc<SegmentedDatasourceInterface>(segmentedDatasourceId)
                .update(Object.assign({}, dataToUpdate))
        );
    }

    public removeSegmentFromDatasource(mediaId: string, segmentId: string): Observable<void[]> {
        return this.getBySite(mediaId).pipe(
            first(),
            switchMap(datasources => {
                const updates = datasources.map(datasource => {
                    datasource.datasources = datasource.datasources.map(d => {
                        d.segments = d.segments.filter(segment => segment !== segmentId);
                        return d;
                    });
                    return this.update(datasource.id, datasource);
                });
                return updates.length ? forkJoin(updates) : of(null);
            })
        );
    }

    public updateChildrenDatasource(dataSourceToUpdate: DatasourceInterface, siteId: string): Observable<any> {
        return this.getBySite(siteId).pipe(
            first(),
            switchMap(segmentedDatasources => {
                const ids = [];
                segmentedDatasources.forEach(segmented => {
                    let updateNeeded = false;
                    segmented.datasources.forEach((datasource, index) => {
                        if (datasource.id === dataSourceToUpdate.id) {
                            segmented.datasources[index] = {
                                ...datasource,
                                articles: dataSourceToUpdate.articles,
                                jsonUrl: dataSourceToUpdate.jsonUrl,
                                name: dataSourceToUpdate.name,
                                media: dataSourceToUpdate.media,
                                mediasToShare: dataSourceToUpdate.mediasToShare,
                                mediasActions: dataSourceToUpdate.mediasActions,
                                share: dataSourceToUpdate.share,
                                hasIcon: dataSourceToUpdate.hasIcon,
                                type: dataSourceToUpdate.type,
                                pixel: dataSourceToUpdate.pixel,
                                campaign: dataSourceToUpdate.campaign,
                                campaignAssigned: dataSourceToUpdate.campaignAssigned,
                                campaignStartDate: dataSourceToUpdate.campaignStartDate,
                                campaignEndDate: dataSourceToUpdate.campaignEndDate,
                                objetive: dataSourceToUpdate.objetive,
                                objetiveAssigned: dataSourceToUpdate.objetiveAssigned,
                                distributionAssigned: dataSourceToUpdate.distributionAssigned,
                            };
                            updateNeeded = true;
                        }
                    });
                    updateNeeded && ids.push(segmented.id);
                    updateNeeded && this.update(segmented.id, segmented).subscribe();
                });
                return of({ ids });
            })
        );
    }
}
