import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONSTANTS } from 'src/app/shared/utils/constants';

@Injectable()
export class RealTimeService {
    public constructor(private readonly httpClient: HttpClient) {}

    public getUsersBySite(siteId: string, device: string[]): Observable<any> {
        let REALTIME_USERS_URL;
        if (device.length == 0 || device.length == 3) {
            REALTIME_USERS_URL = CONSTANTS.API_REST_REAL_TIME_CONCURRENT_USERS_URL.replace(':siteId', siteId);
        } else {
            REALTIME_USERS_URL = CONSTANTS.API_REST_REAL_TIME_CONCURRENT_USERS_URL_BY_DEVICE.replace(':siteId', siteId)
                .replace(':d1', device[0])
                .replace(':d2', device[1]);
        }
        return this.httpClient.get<any>(REALTIME_USERS_URL);
    }

    public getURLsBySite(siteId: string, device: string[]): Observable<any> {
        let REALTIME_USERS_URL;
        if (device.length == 0 || device.length == 3) {
            REALTIME_USERS_URL = CONSTANTS.API_REST_REAL_TIME_CONCURRENT_PAGE_VIEWS_URL.replace(':siteId', siteId);
        } else {
            REALTIME_USERS_URL = CONSTANTS.API_REST_REAL_TIME_CONCURRENT_PAGE_VIEWS_URL_BY_DEVICE.replace(
                ':siteId',
                siteId
            )
                .replace(':d1', device[0])
                .replace(':d2', device[1]);
        }
        return this.httpClient.get<any>(REALTIME_USERS_URL);
    }
}
