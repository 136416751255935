import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TemplateInterface } from 'src/app/core/interfaces/template.interface';

@Component({
    selector: 'app-template-preview',
    templateUrl: './template-preview.component.html',
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class TemplatePreviewComponent {
    @Input() public template: TemplateInterface = null;

    constructor() {}
}
