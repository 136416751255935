import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { from, Observable, of } from 'rxjs';
import { WidgetInterface } from '../interfaces/widget.interface';
import { switchMap, first, shareReplay } from 'rxjs/operators';
import { AuthService } from './auth.service';
import firebase from 'firebase/compat/app';
import { TemplateInterface } from '../interfaces/template.interface';
import { ArticleInterface } from '../interfaces/article.interface';
import { CacheService } from './cache.service';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { HttpClient } from '@angular/common/http';

const WIDGETS_COLLECTION = 'widgets-collection';

@Injectable()
export class WidgetService {
    public widgetsCollection: AngularFirestoreCollection<WidgetInterface>;
    public allWidgets: Observable<WidgetInterface[]>;

    public constructor(
        private readonly firestore: AngularFirestore,
        private readonly authService: AuthService,
        private readonly cacheService: CacheService,
        private readonly httpClient: HttpClient
    ) {
        this.widgetsCollection = this.firestore.collection<WidgetInterface>(WIDGETS_COLLECTION);
    }

    public getAll(): Observable<WidgetInterface[]> {
        if(!this.allWidgets) {
            this.allWidgets = this.widgetsCollection.valueChanges().pipe(
              shareReplay(1)
            );
        }
        return this.allWidgets;
    }

    public getBySite(siteId: string): Observable<WidgetInterface[]> {
        return this.firestore
            .collection<WidgetInterface>(
                WIDGETS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('media', '==', siteId)
            )
            .valueChanges();
    }

    public getActiveBySite(siteId: string): Observable<WidgetInterface[]> {
        return this.firestore
            .collection<WidgetInterface>(
                WIDGETS_COLLECTION,
                (ref: CollectionReference): Query =>
                    ref.where('media', '==', siteId).where('active', '==', true)
            )
            .valueChanges();
    }

    public getBySiteAndType(siteId: string, typeWidget: string): Observable<WidgetInterface[]> {
        return this.firestore
            .collection<WidgetInterface>(
                WIDGETS_COLLECTION,
                (ref: CollectionReference): Query =>
                    ref.where('media', '==', siteId).where('type_widget', '==', typeWidget)
            )
            .valueChanges();
    }

    public get(widgetId: string): Observable<WidgetInterface> {
        return this.widgetsCollection.doc<WidgetInterface>(widgetId).valueChanges();
    }

    public getAllByTemplateId(templateId: string): Observable<WidgetInterface[]> {
        return this.firestore
            .collection<WidgetInterface>(
                WIDGETS_COLLECTION,
                (ref: CollectionReference): Query => ref.where('template.id', '==', templateId)
            )
            .valueChanges();
    }

    public add(widget: WidgetInterface): Observable<void> {
        const widgetId = this.firestore.createId();
        widget.id = widgetId;
        widget.createdAt = firebase.firestore.Timestamp.now();
        widget.updatedAt = firebase.firestore.Timestamp.now();
        widget.updatedBy = this.authService.getEmail();
        return from(this.widgetsCollection.doc<WidgetInterface>(widgetId).set(Object.assign({}, widget)));
    }

    public delete(widgetId: string): Observable<void> {
        return this.update(widgetId, { deletedBy: this.authService.getEmail() }).pipe(
            switchMap((): Observable<void> => from(this.widgetsCollection.doc<WidgetInterface>(widgetId).delete()))
        );
    }

    public update(widgetId: string, dataToUpdate: Partial<WidgetInterface>): Observable<void> {
        dataToUpdate.updatedAt = firebase.firestore.Timestamp.now();
        dataToUpdate.updatedBy = this.authService.getEmail();
        return from(this.widgetsCollection.doc<WidgetInterface>(widgetId).update(Object.assign({}, dataToUpdate)));
    }

    public updateWidgetTemplate(templateId: string, mediaId: string, template: TemplateInterface): Observable<boolean> {
        return this.getAllByTemplateId(templateId).pipe(
            first(),
            switchMap(widgets => {
                widgets.forEach(widget => {
                    widget.template.name = template.name;
                    widget.template.slots = template.slots;
                    widget.template.styles = template.styles;
                    widget.template.basic = template.basic;
                    widget.template.description = template.description;
                    this.update(widget.id, widget).subscribe();
                    this.cacheService.deleteCacheForWidget(mediaId, widget.id).subscribe();
                });
                return of(!!widgets.length);
            })
        );
    }

    public getWidgetsByDatasourceId(datasourceId: string): Observable<WidgetInterface[]> {
        return this.getAll().pipe(
            first(),
            switchMap(widgets => {
                const widgetsUsingDatasource = widgets.filter(w =>
                    w.behaviour.data.some(
                        (article: ArticleInterface): boolean => article && article.datasourceId === datasourceId
                    )
                );
                return of(widgetsUsingDatasource);
            })
        );
    }

    public updateWidgetObjectiveForDatasource(datasourceId: string): Observable<boolean> {
        return this.getWidgetsByDatasourceId(datasourceId).pipe(
            switchMap(widgetsUsing => {
                widgetsUsing.forEach(widget => {
                    widget.behaviour.data.forEach(article => {
                        if (article.datasourceId === datasourceId) {
                            article.isSomeObjetiveCompleted = false;
                        }
                    });
                    this.update(widget.id, widget).subscribe();
                });
                return of(!!widgetsUsing.length);
            })
        );
    }

    public createId(): string {
      return this.firestore.createId();
    }

    public widgetHtmlUrl(media: string, id: string): Observable<any> {
      const widgetHtmlUrl = CONSTANTS.API_REST_GET_HTML_WIDGET.replace(':media', media).replace(':widget', id)
      return this.httpClient.get(widgetHtmlUrl, { responseType: 'text' })
    }
}
