import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if(localStorage.getItem('errors') || environment.env === 'local'){
          console.log(error);
        }

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
    }
}
