import { PipeTransform, Pipe } from '@angular/core';
import { CONSTANTS } from '../utils/constants';

@Pipe({ name: 'weekdaysToStringPipe' })
export class WeekdaysToStringPipe implements PipeTransform {
    public transform(value: number): string {
        switch (value) {
            case CONSTANTS.MONDAY:
                return CONSTANTS.MONDAY_LITERAL;
            case CONSTANTS.TUESDAY:
                return CONSTANTS.TUESDAY_LITERAL;
            case CONSTANTS.WEDNESDAY:
                return CONSTANTS.WEDNESDAY_LITERAL;
            case CONSTANTS.THURSDAY:
                return CONSTANTS.THURSDAY_LITERAL;
            case CONSTANTS.FRIDAY:
                return CONSTANTS.FRIDAY_LITERAL;
            case CONSTANTS.SATURDAY:
                return CONSTANTS.SATURDAY_LITERAL;
            case CONSTANTS.SUNDAY:
                return CONSTANTS.SUNDAY_LITERAL;
            default:
                return '';
        }
    }
}
