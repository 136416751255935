import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeHTMLPipe' })
export class SafeHTMLPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}
    public transform(value: string): SafeHtml {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
