import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-data-wrapper',
  templateUrl: './no-data-wrapper.component.html',
  styleUrls: ['./no-data-wrapper.component.scss']
})
export class NoDataWrapperComponent {

  @Input() thereIsData: boolean;

  constructor() { }
}
