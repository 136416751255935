import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AWSService as AWS } from 'src/app/core/services/aws.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PushNotificationInterface } from 'src/app/core/interfaces/push-notification/push-notification.interface';
import ConstantsPushApps from 'src/app/shared/utils/constants-push-apps';
import { Utils } from 'src/app/shared/utils/utils';
import ConstantMessages from 'src/app/shared/utils/constants-messages/constants-messages';

@Component({
    selector: 'app-manual-notification-form',
    templateUrl: './manual-notification-form.component.html',
    styleUrls: ['./manual-notification-form.component.scss'],
})
export class ManualNotificationFormComponent implements OnInit {
    @Input() formGroup;
    @Input() pushNotification: PushNotificationInterface;
    @Input() notificationType: string;
    templateTypes: {value: string, text: string}[] = ConstantsPushApps.templateTypes;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private AWSService: AWS,
        private readonly ref: ChangeDetectorRef
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit(): void {
        this.subscribeToForm();
    }

    private subscribeToForm(): void {
        this.formGroup
            .get('message')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((message: string): void => {
                this.pushNotification.message = message;
            });
        this.formGroup
            .get('title')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((title: string): void => {
                // eslint-disable-next-line no-console
                console.log('Title Changed');
                this.pushNotification.title = title;
            });

        this.formGroup
            .get('contentId')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((contentId: string): void => {
                this.pushNotification.contentId = contentId;
            });

        this.formGroup
            .get('url')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((urlRedirect: string): void => {
                this.pushNotification.urlRedirect = urlRedirect;
            });

        this.formGroup
            .get('urlImage')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((imageURL: string): void => {
                this.pushNotification.imageURL = imageURL;
            });
    }

    public setImage(imageFile: File): void {

        if (imageFile && !Utils.isValidImage(imageFile)) {
            Utils.notify(
                ConstantMessages.PUSH_NOTIFICATION_TITLE.replace(':title', this.formGroup.get('title').value),
                ConstantMessages.ErrorType,
                ConstantMessages.ERROR_PUSH_NOTIFICATION
            );
            return;
        }

        this.AWSService.uploadImage(imageFile)
            .subscribe(
                (sanitizedUrl: string): void => {

                    this.formGroup.get('urlImage').setValue(sanitizedUrl);
                    this.ref.detectChanges();

                },
                (err): void => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    Utils.notify(
                        ConstantMessages.PUSH_NOTIFICATION_TITLE.replace(':title', this.formGroup.get('title').value),
                        ConstantMessages.ErrorType,
                        ConstantMessages.ERROR_PUSH_NOTIFICATION
                    );
                }
            );
    }

}
