<div class="row m-5 ng-star-inserted">
  <div class="col-md-12 row page-title">
      <div class="col-sm-6 col-md-8 col-lg-9">
          <h1 class="row">Test A/B</h1>
      </div>
      <hr class="float-left hr-title" />
  </div>
</div>
<div *ngIf="testAb && formGroupStep1; else spinner" class="row card elevation m-5 p-3">
  <div class="col-md-12" *ngIf="isLoading$ | async; else loader">
      <div class="col-md-2 m-auto">
          <mat-icon class="m-5"><mat-spinner diameter="50"></mat-spinner></mat-icon>
      </div>
  </div>
  <ng-template #loader>
      <div class="col-md-12">
          <mat-horizontal-stepper (selectionChange)="selectedValueChangeStepper($event)" [linear]="true" #stepper>
              <mat-step [stepControl]="formGroupStep1">
                  <form [formGroup]="formGroupStep1">
                      <ng-template matStepLabel>Configuración</ng-template>

                      <div class="row">
                        <div class="col">
                            <mat-label *ngIf="!isEditMode">Estado inicial</mat-label>
                            <mat-label *ngIf="isEditMode">Estado actual</mat-label>
                            <mat-slide-toggle
                                class="m-3"
                                formControlName="active"
                                color="primary"
                                [checked]="formGroupStep1.get('active').value"
                            >
                                Activo
                            </mat-slide-toggle>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ng-container formArrayName="programDates"
                            *ngFor="let programDate of programDates.controls; let i = index">
                            <div [formGroupName]="i" class="d-flex align-items-center">
                              <mat-form-field appearance="outline" class="m-3">
                                <mat-label>Cambio de estado {{ i + 1 }}</mat-label>
                                <input matInput [ngxMatDatetimePicker]="picker" [min]="minProgramDate"
                                  placeholder="Cambio de estado {{ i + 1 }}" formControlName="programDate" appearance="outline" />
                                <mat-datepicker-toggle matSuffix [for]="picker" appearance="outline"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker appearance="outline" [showSeconds]="false">
                                </ngx-mat-datetime-picker>
                              </mat-form-field>
                              <button mat-raised-button class="mx-2" color="primary" (click)="removeProgramDate($event, i)"
                                *ngIf="i===programDates.controls.length-1">
                                <mat-icon>delete</mat-icon>
                              </button>
                              <span>En este momento el estado cambiará <strong>{{ (i % 2 ? formGroupStep1.get('active').value :
                                  !formGroupStep1.get('active').value) ? 'activado' : 'desactivado' }}</strong>
                              </span>
                            </div>
                          </ng-container>
                          <button mat-raised-button class="mx-2" color="primary" (click)="addProgramDate($event)">
                            Añadir fecha de programación
                            <mat-icon>event</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div class="row pb-3">
                        <mat-slide-toggle
                            class="mt-4 ml-3"
                            formControlName="isCompatible"
                            color="primary"
                            [checked]="formGroupStep1.get('isCompatible').value"
                          >
                          Este activador puede convivir con otros
                        </mat-slide-toggle>
                      </div>
                      <div class="row">
                          <mat-form-field class="col-md-6" appearance="outline">
                              <mat-label>Nombre</mat-label>
                              <input matInput name="name" formControlName="name" placeholder="Nombre" required />
                          </mat-form-field>
                          <mat-form-field class="col-md-6" appearance="outline">
                              <mat-label>Descripción</mat-label>
                              <input
                                  matInput
                                  name="description"
                                  formControlName="description"
                                  placeholder="Descripción"
                              />
                          </mat-form-field>
                      </div>
                      <div class="row">
                        <mat-form-field class="col-md-6" appearance="outline">
                          <mat-label>Frecuencia</mat-label>
                          <input
                              matInput
                              type="number"
                              name="frequency"
                              formControlName="frequency"
                              placeholder="Frecuencia del Modal (días)"
                              required
                          />
                        </mat-form-field>
                        <div class="col-md-6 d-flex">
                          <mat-form-field appearance="outline">
                              <mat-label>Tipo de activador</mat-label>
                              <mat-select name="type" formControlName="type">
                                  <mat-option *ngFor="let trigger of triggerTypes" [value]="trigger">
                                      {{ trigger | triggersComponentTypeToStringPipe }}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                          <mat-form-field
                              class="ml-1 flex-grow-1"
                              appearance="outline"
                              *ngIf="formGroupStep1.contains('identifier')"
                          >
                              <mat-label>Identificador</mat-label>
                              <input
                                  matInput
                                  name="identifier"
                                  formControlName="identifier"
                                  placeholder="Identificador"
                              />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class ="row">
                        <mat-form-field class="col-md-6" appearance="outline">
                          <mat-label>Número de Plantillas</mat-label>
                          <input
                            matInput
                            type="number"
                            name="templatesNumber"
                            formControlName="templatesNumber"
                            placeholder="Número de plantillas"
                            required
                          />
                        </mat-form-field>
                      </div>

                      <div class="row pb-3">
                        <mat-slide-toggle
                          class="mt-4 ml-3"
                          formControlName="resolveTest"
                          color="primary"
                          [checked]="formGroupStep1.get('resolveTest').value"
                        >
                        Resolver test A/B automáticamente
                        </mat-slide-toggle>
                      </div>

                      <div *ngIf="formGroupStep1.get('resolveTest').value" class ="row pb-3">
                        <span class="ml-5 pl-3">Selecciona las condiciones que deben cumplirse para mantener solo un activador.</span>
                      </div>
                      <div *ngIf="formGroupStep1.get('resolveTest').value" class ="row pb-3 align-items-center">
                        <mat-form-field class="text-center ml-5 col-md-1 control-height" appearance="outline">
                          <input
                            matInput
                            type="number"
                            name="minCTR"
                            formControlName="minCTR"
                          />
                        </mat-form-field>
                        <div class="col-md-5">
                          <span>% mínimo de diferencia en el CTR de un activador con respecto al resto.</span>
                          <br>
                          <span style="color:grey; font-size: 0.75rem;">Solo se tiene en cuenta los botones que incluye el evento TRIGGER_EVENTS.action().</span>
                        </div>
                      </div>
                      <div *ngIf="formGroupStep1.get('resolveTest').value" class ="row pb-3 ml-5">
                        <mat-slide-toggle
                          formControlName="addMoreConditions"
                          color="primary"
                          [checked]="formGroupStep1.get('addMoreConditions').value"
                        >
                          Añadir más condiciones
                        </mat-slide-toggle>
                      </div>
                      <div *ngIf="formGroupStep1.get('addMoreConditions').value" class="row pb-3 align-items-center ml-5 px-5">
                        <mat-slide-toggle
                          formControlName="minImpressionsCheck"
                          color="primary"
                          [checked]="formGroupStep1.get('minImpressionsCheck').value"
                        >
                          Número mínimo de impresiones que tiene que tener cada activador.
                        </mat-slide-toggle>
                        <mat-form-field *ngIf="formGroupStep1.get('minImpressionsCheck').value" class="text-center ml-3 col-md-1 control-height" appearance="outline">
                          <input
                            matInput
                            type="number"
                            name="minImpressions"
                            formControlName="minImpressions"
                          />
                        </mat-form-field>
                      </div>
                      <div *ngIf="formGroupStep1.get('addMoreConditions').value" class="row pb-3 align-items-center ml-5 px-5">
                        <mat-slide-toggle
                          style="padding: 0px 94px 0px 0px"
                          formControlName="minDateCheck"
                          color="primary"
                          [checked]="formGroupStep1.get('minDateCheck').value"
                        >
                          Fecha de finalización mínima hasta que finalice el test A/B.
                        </mat-slide-toggle>
                        <mat-form-field *ngIf="formGroupStep1.get('minDateCheck').value" class="control-height" appearance="outline">
                          <mat-label>Fecha de finalización mínima</mat-label>
                          <input matInput [ngxMatDatetimePicker]="picker" [min]="minProgramDate"
                            placeholder="" formControlName="resolveDateEnd" appearance="outline" />
                          <mat-datepicker-toggle matSuffix [for]="picker" appearance="outline"></mat-datepicker-toggle>
                          <ngx-mat-datetime-picker #picker appearance="outline" [showSeconds]="false">
                          </ngx-mat-datetime-picker>
                        </mat-form-field>
                      </div>
                      <div class="text-center mt-3">
                        <button mat-raised-button class="mx-2" (click)="finish()">Cancelar</button>
                        <button mat-raised-button color="primary" class="mx-2" matStepperNext>Siguiente</button>
                      </div>
                  </form>
              </mat-step>
              <mat-step>
                  <ng-template matStepLabel>Operación</ng-template>
                    <form *ngIf="formGroupStep2" [formGroup]="formGroupStep2">
                      <mat-form-field *ngFor="let index of [].constructor(this.testAb.templatesNumber); let i=index" class="col-md-6" appearance="outline">
                        <mat-label>Plantilla {{i}}</mat-label>
                        <mat-select name="type" [formControlName]="'trigger'+i">
                            <mat-option *ngFor="let trigger of currentTriggers" [value]="trigger.id" [disabled]="checkTriggerAvailability(trigger.id)">
                                {{ trigger.id }} - {{trigger.name}}
                            </mat-option>
                        </mat-select>
                      </mat-form-field>
                        <div class="text-center mt-3">
                            <button mat-raised-button color="primary" class="mx-2" matStepperPrevious>Atrás</button>
                            <button mat-raised-button class="mx-2" (click)="finish()">Cancelar</button>
                            <button mat-raised-button color="primary" class="mx-2" matStepperNext>
                                Siguiente
                            </button>
                        </div>
                    </form>

              </mat-step>
              <mat-step>
                  <ng-template matStepLabel>Segmentación</ng-template>
                  <app-dual-list
                      class="c-segments-dual-list"
                      [initialListTitle]="'Segmentos disponibles'"
                      [finalListTitle]="'Segmentos añadidos'"
                      [initialData]="initialSegments"
                      [finalData]="finalSegments"
                      [showChips]="true"
                      [chipsTitles]="chipsTitles"
                      (itemChange)="onSegmentsChange($event)"
                  ></app-dual-list>
                  <div class="text-center mt-3">
                      <button mat-raised-button color="primary" class="mx-2" matStepperPrevious>Atrás</button>
                      <button mat-raised-button class="mx-2" (click)="finish()">Cancelar</button>
                      <button mat-raised-button color="primary" class="mx-2" matStepperNext>
                          Siguiente
                      </button>
                  </div>
              </mat-step>
              <mat-step>
                  <ng-template matStepLabel>Previsualización</ng-template>
                  <div class="container card shadow" style="height: auto;margin-bottom: 3em;" *ngFor="let data of testAb.triggers let i=index">
                      <div class="pt-3">
                        <div class="row col-md-12 pl-5">
                          <h2>Activador {{i+1}}</h2>
                        </div>
                        <div class="row col-md-12 pl-5">
                          <h3>{{data.id}} {{data.name}}</h3>
                        </div>
                        <div class="row" >
                          <div [id]="data.id" class="col-md-12 pt-3"></div>
                          <br/>
                        </div>
                      </div>
                  </div>
                  <div class="text-center mt-3">
                      <button mat-raised-button color="primary" class="mx-2" matStepperPrevious>Atrás</button>
                      <button mat-raised-button class="mx-2" (click)="finish()">Cancelar</button>
                      <button mat-raised-button color="primary" class="mx-2" (click)="save()">Guardar</button>
                  </div>
              </mat-step>
          </mat-horizontal-stepper>
      </div>
  </ng-template>
</div>
<ng-template #spinner>
  <div class="c-progress-spinner">
      <mat-progress-spinner class="mx-auto" [mode]="'indeterminate'"> </mat-progress-spinner>
  </div>
</ng-template>
