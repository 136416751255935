import { PipeTransform, Pipe } from '@angular/core';
import ConstantsDatasources from '../utils/constants-datasources';

@Pipe({ name: 'shareToStringPipe' })
export class ShareToStringPipe implements PipeTransform {
    public transform(value: number): string {
        switch (value) {
            case ConstantsDatasources.PROMETEO_DATASOURCES_SHARE_TO_ACCOUNT:
                return ConstantsDatasources.PROMETEO_DATASOURCES_SHARE_TO_ACCOUNT_LITERAL;
            case ConstantsDatasources.PROMETEO_DATASOURCES_SHARE_TO_EVERYONE:
                return ConstantsDatasources.PROMETEO_DATASOURCES_SHARE_TO_EVERYONE_LITERAL;
            default:
                return '';
        }
    }
}
