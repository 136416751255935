import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { ArticleInterface } from 'src/app/core/interfaces/article.interface';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ArticleComponent {
    @Input() public article: ArticleInterface = null;
    @Input() public showDeleteButton = false;
    @Input() public showButtons = false;
    @Input() public activateObjectiveChecked? = false;

    @Output() public articleEdit: EventEmitter<ArticleInterface> = new EventEmitter<ArticleInterface>();
    @Output() public articleDelete: EventEmitter<ArticleInterface> = new EventEmitter<ArticleInterface>();

    constructor() {}

    public edit(): void {
        this.articleEdit.emit(this.article);
    }

    public delete(): void {
        this.articleDelete.emit(this.article);
    }
}
