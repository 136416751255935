import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImportedSegmentInterface } from '../interfaces/query-builder/imported-segment.interface';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

const IMPORTED_SEGMENT_COLLECTION = 'segment-imported';

@Injectable()
export class ImportedSegmentsService {
    private segmentsCollection: AngularFirestoreCollection<ImportedSegmentInterface>;
    constructor(private readonly firestore: AngularFirestore, private readonly httpClient: HttpClient, public datepipe: DatePipe) {
        this.segmentsCollection = this.firestore.collection<ImportedSegmentInterface>(IMPORTED_SEGMENT_COLLECTION);
    }

    public getAllBySite(mediaId: string): Observable<ImportedSegmentInterface[]> {
        return combineLatest([this.getImportedSegmentByMedia<ImportedSegmentInterface>(mediaId, IMPORTED_SEGMENT_COLLECTION),
            this.getSharedSegmentsForMedia<ImportedSegmentInterface>(mediaId, IMPORTED_SEGMENT_COLLECTION)]).pipe( map(([segments, sharedSegments]) => [
            ...segments,
            ...sharedSegments,
        ]));

    }

    public getImportedSegmentByMedia<T>(mediaId: string, collection: string): Observable<T[]> {
        return this.firestore
            .collection<T>(
                collection,
                (ref: CollectionReference): Query => ref.where('mediaId', '==', mediaId)
            )
            .valueChanges();
      }

      private getSharedSegmentsForMedia<T>(mediaId: string, collection: string): Observable<T[]> {
        return this.firestore
                .collection<T>(
                    collection,
                    (ref: CollectionReference): Query => ref.where('shared', 'array-contains', mediaId)
                )
                .valueChanges();
    }

    public get(id: string): Observable<ImportedSegmentInterface> {
        return this.segmentsCollection.doc<ImportedSegmentInterface>(id).valueChanges();
    }

    public updateSegmentComponents(segment: ImportedSegmentInterface): Observable<void> {
      const UPDATE_IMPORTED_SEGMENT_COMPONENTS = CONSTANTS.API_RULES_OPERATION_UPDATE_IMPORTED_SEGMENT_COMPONENTS;
      return this.httpClient.post<any>(UPDATE_IMPORTED_SEGMENT_COMPONENTS, segment);
    }
}
