<div *ngIf="filterForm">
    <form [formGroup]="filterForm">
        <mat-form-field *ngIf="inputNameText && inputPlaceholderNameText"
            class="col-xl-3 c-mat-form c-mat-form--no-padding" appearance="outline">
            <mat-label>{{ inputNameText }}</mat-label>
            <input type="text" [placeholder]="inputPlaceholderNameText" matInput formControlName="name"
                [matAutocomplete]="name" />
            <mat-autocomplete #name="matAutocomplete">
                <mat-option
                    *ngFor="let component of takeUnicAttributeObjects(nameFieldOfList || 'name')"
                    [value]="component[nameFieldOfList] || component.name">
                    {{ component[nameFieldOfList] || component.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="inputIdText && inputPlaceholderIdText" class="col-xl-2 c-mat-form c-mat-form--no-padding"
            appearance="outline">
            <mat-label>{{ inputIdText }}</mat-label>
            <input type="text" [placeholder]="inputPlaceholderIdText" matInput formControlName="id"
                [matAutocomplete]="id" />
            <mat-autocomplete #id="matAutocomplete">
                <mat-option *ngFor="let component of takeUnicAttributeObjects(idFieldOfList)"
                    [value]="component[idFieldOfList] || component.id">
                    {{ component[idFieldOfList] || component.id }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngIf="componentTypes" class="col-md-auto c-mat-form c-mat-form--no-padding"
            appearance="outline">
            <mat-label>Tipo</mat-label>
            <mat-select name="type" formControlName="type">
                <mat-option>Ninguno</mat-option>
                <mat-option *ngFor="let type of componentTypes" [value]="type">
                    {{ getComponentTypeName(type) }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="topics" class="col-md-auto c-mat-form c-mat-form--no-padding" appearance="outline">
            <mat-label>Tópico</mat-label>
            <mat-select name="topic" formControlName="topic">
                <mat-option>Ninguno</mat-option>
                <mat-option *ngFor="let topic of topics" [value]="topic">
                    {{ topic.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="modules" class="col-md-auto c-mat-form c-mat-form--no-padding" appearance="outline">
          <mat-label>Módulo</mat-label>
          <mat-select name="modules" formControlName="modules">
            <mat-option [value]="FILTER_VALUE_ALL">Todos</mat-option>
            <mat-optgroup *ngFor="let module of modules" [label]="module.name">
              <mat-option *ngFor="let filter of module.filters" [value]="filter.value">
                {{ filter.name }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="titles" class="col-md-4 c-mat-form c-mat-form--no-padding" appearance="outline">
          <mat-label>Título</mat-label>
          <mat-select name="title" formControlName="title">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let title of titles" [value]="title">
                  {{ title }}
              </mat-option>
          </mat-select>
      </mat-form-field>

        <mat-form-field *ngIf="sections" class="col-md-auto mr-3 c-mat-form c-mat-form--no-padding"
            appearance="outline">
            <mat-label>Filtros</mat-label>
            <mat-select formControlName="filter">
                <mat-option [value]="1">Todos</mat-option>
                <mat-optgroup *ngFor="let section of sections" [label]="section.name">
                    <mat-option *ngFor="let filter of section.filters" [value]="filter.value">
                        {{ filter.name }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="states" class="col-md-auto c-mat-form c-mat-form--no-padding" appearance="outline">
            <mat-label>Estados</mat-label>
            <mat-select name="state" formControlName="state">
                <mat-option [value]="1">Todos</mat-option>
                <mat-option *ngFor="let state of states" [value]="state">
                    {{ state }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="typeWall" class="col-md-auto c-mat-form c-mat-form--no-padding" appearance="outline">
          <mat-label>Tipo Muro</mat-label>
          <mat-select name="typeWall" formControlName="typeWall">
              <mat-option [value]="0">Ninguno</mat-option>
              <mat-option *ngFor="let type of typeWall" [value]="type">
                  {{ type }}
              </mat-option>
          </mat-select>
      </mat-form-field>

        <mat-form-field *ngIf="programDatesList" class="col-md-auto c-mat-form c-mat-form--no-padding" appearance="outline">
          <mat-label>Programados</mat-label>
          <mat-select name="programDates" formControlName="programDates">
              <mat-option>Todos</mat-option>
              <mat-option *ngFor="let programDates of programDatesList" [value]="programDates">
                  {{ programDates }}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="advertisers" class="col-md-auto c-mat-form c-mat-form--no-padding" appearance="outline">
            <mat-label>Anunciantes</mat-label>
            <mat-select name="advertiser" formControlName="advertiser">
                <mat-option>Ninguno</mat-option>
                <mat-option *ngFor="let advertiser of advertisers" [value]="advertiser">
                    {{ advertiser }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="vendors" class="col-md-auto c-mat-form c-mat-form--no-padding" appearance="outline">
            <mat-label>Vendedores</mat-label>
            <mat-select name="vendor" formControlName="vendor">
                <mat-option>Ninguno</mat-option>
                <mat-option *ngFor="let vendor of vendors" [value]="vendor">
                    {{ vendor }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="sites" class="col-md-auto c-mat-form c-mat-form--no-padding" appearance="outline">
          <mat-label>Medios</mat-label>
          <mat-select name="site" formControlName="sites">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let site of sites" [value]="site">
                  {{ site }}
              </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="date" class="col-md-4 c-mat-form c-mat-form--no-padding" appearance="outline">
          <mat-label>Rango de fecha</mat-label>
          <input type="text" matInput ngxDaterangepickerMd
              strictMode='true' formControlName="date" name="date" />
        </mat-form-field>

    <button
      *ngIf="downloadButtonText"
      mat-raised-button
      class="col-md-3 m-auto c-button c-button--text-medium c-button--br-10 py-1"
      color="primary"
      (click)="exportContentsListAsCSV()"
      [disabled]="!(componentList && componentList.length)"
    >{{ downloadButtonText }}<i class="material-icons">download</i>
</button>
    </form>
</div>
